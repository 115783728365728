import React from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from './view/login'
import VerifyEmail from './view/login/verifyEmail'
import ResetPSW from './view/login/reset'
import NotFound from './view/notFound'
import ChangePsw from './view/login/changePsw'
import HomePage from './view/homepage'
import {
  TwoFALayout,
  Set2FA,
  EnterPhone,
  Verify2FA,
  Finish2FA
} from './view/2fa'
import {
  HealthScore,
  // HealthySignalPage,
  HealthySignalLayout,
  HealthySignalDetail,
  NativeHSD,
  HealthySignalCompact,
  NutritionScore,
  NutritionDetail,
  SummaryEmail,
  SummaryEmailBackup
} from './view/public'
import {
  OnboardingLayout,
  SetupScreen,
  PreScreening,
  PersonalIntroductory,
  Questionnaire,
  WaiverForm,
  Payment
} from './view/onboarding'
import Membership from './view/onboarding/membership'
import MembershipSuccess from './view/onboarding/membership/success'
import OnboardingReady from './view/onboarding/ready'
import Redirected from './view/redirected'
import QuestionnaireRecord from './view/onboarding/questionnaire/record'
import MicrobiomeAnalysis from './view/public/microbiome'
import PhysicalAssessment from './view/physicalAssessment'

export default function RouterComponent(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/sign-in" element={<Login />} {...Login.routeProps} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/password-reset/:hash" element={<ResetPSW />} />
        <Route path="/change-psw" element={<ChangePsw />} />
        <Route element={<TwoFALayout />}>
          <Route path="/2fa" element={<Set2FA />} />
          <Route path="/2fa/phone" element={<EnterPhone />} />
          <Route path="/2fa/verify" element={<Verify2FA />} />
          <Route path="/2fa/done" element={<Finish2FA />} />
        </Route>
        <Route element={<HealthySignalLayout />}>
          <Route
            path="/healthy-signal-detail/:hash"
            element={<HealthySignalDetail />}
          />
          <Route
            path="/healthy-signal/:hash"
            element={<HealthySignalCompact />}
          />
        </Route>
        <Route path="/native-hsd/:hash" element={<NativeHSD />} />
        {/* <Route path="/healthy-signal/:hash" element={<HealthySignalPage />} /> */}
        <Route path="/health-score/:hash" element={<HealthScore />} />
        <Route
          path="/nutrition-score/:id/:version/:hash"
          element={<NutritionDetail />}
        />
        <Route path="/nutrition-score/:hash" element={<NutritionScore />} />
        <Route
          path="/microbiome-analysis/:hash"
          element={<MicrobiomeAnalysis />}
        />
        <Route path="/email/:id/:hash" element={<SummaryEmail />} />
        <Route
          path="/email-backup/:id/:hash"
          element={<SummaryEmailBackup />}
        />
        <Route path="/membership-selection" element={<Membership />} />
        <Route
          path="/membership-selection/success"
          element={<MembershipSuccess />}
        />
        <Route element={<OnboardingLayout />}>
          <Route path="/onboarding/ready" element={<OnboardingReady />} />
          <Route path="/onboarding" element={<SetupScreen />} />
          <Route path="/onboarding/pre-screening" element={<PreScreening />} />
          <Route
            path="/onboarding/introductory"
            element={<PersonalIntroductory />}
          />
          {/* <Route path="/onboarding/waiver" element={<WaiverForm />} /> */}
          <Route path="/onboarding/waiver/:section" element={<WaiverForm />} />
          <Route
            path={'/onboarding/questionnaire/:section'}
            element={<Questionnaire />}
          />
          <Route
            path="/onboarding/questionnaire-record/:hash"
            element={<QuestionnaireRecord />}
          />
          <Route path="/onboarding/questionnaire" element={<Questionnaire />} />
          <Route path="/onboarding/payment" element={<Payment />} />
        </Route>
        <Route
          path="/assessment-review/:id/:hash"
          element={<PhysicalAssessment />}
        />
        <Route path="/redirected" element={<Redirected />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
