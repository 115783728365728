import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { MicrobiomeMainContainer } from './index.style'
import { useImmerReducer } from 'use-immer'
import _, { get, head, last, round } from 'lodash'
import FixedRangeChart from './comp/FixedRangeChart'
import TimelineView from './comp/TimelineView'
import ConstantChart from './comp/ConstantChart'
import DynamicRangeChart from './comp/DynamicRangeChart'
import moment from 'moment'
import { BiomeWeightChart } from './comp/BiomeWeightChart'
import { useParams } from 'react-router-dom'
import { getMeByHash, getMicrobiomeScore } from '../../../api'
import { Toast } from 'antd-mobile'
import DateGroupPicker from '../../commonComponents/dateGroupPicker'
import ContainerWidthSizer from '../../commonComponents/ContainerWidthSizer'

const dateRanges = [
  {
    name: 'Month',
    barWidth: 8,
    picker: 'month',
    formatX: 'MMM/D'
  },
  {
    name: 'Quarterly',
    barWidth: 12,
    picker: 'quarter',
    type: 'quarterly',
    formatX: 'MM/DD'
  },
  {
    name: 'Annual',
    barWidth: 20,
    type: 'annual',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Lifetime',
    barWidth: 8,
    picker: 'date',
    type: 'lifetime'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
  }
]

function MicrobiomeAnalysis(props) {
  const { hash } = useParams()

  const [person, setPerson] = useState(null)
  const [state, dispatch] = useImmerReducer(
    (draft, action) => {
      switch (action.type) {
        case 'update':
          _.assign(draft, action.payload)
          break
        default:
          break
      }
    },
    {
      totals: [],
      details: [],
      from: null,
      to: null,
      weight: null
    }
  )

  useEffect(() => {
    initial()
  }, [])

  const { totals, details, from, to, weight } = state

  const weightChartDate = useMemo(() => {
    if (from && to) {
      return `${moment(from).format('MM/YYYY')}  - ${moment(to).format(
        'MM/YYYY'
      )}`
    } else {
      const validWeightData = (get(weight, 'values') || []).filter(
        (i) => i.value
      )
      if (validWeightData.length > 0) {
        return `${moment(head(validWeightData).date).format(
          'MM/YYYY'
        )}  - ${moment(last(validWeightData).date).format('MM/YYYY')}`
      } else {
        return ''
      }
    }
  }, [from, to, weight])

  return (
    <MicrobiomeMainContainer>
      <div className="timeline-container">
        <TimelineView
          totals={totals.map((item) => {
            return {
              date: item.date,
              total_score: round(item.total_score, 1)
            }
          })}
          person={person}
        />
      </div>

      <div className="qqt-container">
        <div className="date-picker-container">
          <DateGroupPicker
            dateRanges={dateRanges}
            fetchData={(from, to) => {
              fetchData(person, from, to)
            }}
            defaultSelectedDate={3}
            from={from}
            to={to}
          />
        </div>
        {totals.length > 0 &&
          details.map((metric, index) => {
            if (metric.choices) {
              return (
                <Fragment key={index}>
                  {metric.question.type === 'single_choice' ? (
                    <FixedRangeChart
                      details={[...metric.answers].reverse()}
                      {...metric.question}
                      {...metric}
                    />
                  ) : (
                    <DynamicRangeChart
                      showStatusGroup={false}
                      details={[...metric.answers].reverse()}
                      {...metric.question}
                      {...metric}
                    />
                  )}
                </Fragment>
              )
            } else {
              return (
                <Fragment key={index}>
                  <ConstantChart
                    details={[...metric.answers].reverse()}
                    {...metric.question}
                    {...metric}
                  />
                </Fragment>
              )
            }
          })}
        {totals.length > 0 && (
          <div className="weight-container">
            <div className="metric-title">Weight</div>
            <div className="basic-weight-info">
              {renderNumber(_.get(weight, 'latest'))}
              <div>
                <div className="more-week-avg">3-week avg</div>
                <div className="more-week-avg-value">
                  {get(weight, 'avg') ? _.round(get(weight, 'avg'), 1) : '-'}{' '}
                  lbs
                </div>
              </div>
            </div>
            <div className="weight-chart-container">
              <div className="title-view">
                <span className="title-view-date">{weightChartDate}</span>
                <span className="title-view-unit">lbs</span>
              </div>
              <ContainerWidthSizer>
                {({ width }) => (
                  <BiomeWeightChart
                    weight={get(weight, 'values') || []}
                    from={from}
                    width={width}
                  />
                )}
              </ContainerWidthSizer>
            </div>
          </div>
        )}
        {totals.length === 0 && <div className="no-data">No Data</div>}
      </div>
    </MicrobiomeMainContainer>
  )

  async function initial() {
    Toast.show({
      icon: 'loading',
      content: 'loading...'
    })
    const { person } = await getMeByHash(hash)

    setPerson(person)
    fetchData(person, from, to)
  }

  async function fetchData(person, from, to) {
    Toast.show({
      icon: 'loading',
      content: 'loading...'
    })
    const { totals, details, weight } = await getMicrobiomeScore(
      person.id,
      from,
      to,
      hash
    )
    dispatch({
      type: 'update',
      payload: { totals, details, from, to, weight }
    })
    Toast.clear()
  }

  function renderNumber(value) {
    return value ? (
      <div>
        <span className="l-number">{value}</span>{' '}
        <span className="number-unit">lbs</span>
      </div>
    ) : (
      <div className="l-number-no-data">No data</div>
    )
  }
}

export default MicrobiomeAnalysis
