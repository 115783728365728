import React, { useState, useEffect, useReducer } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ProgressBar, StepFooter, QuestionsContainer } from '../common'
import { Toast } from 'antd-mobile'
import Information from './information'
import {
  getIntroductory,
  removeAttachment,
  editAttachment,
  saveIntroductory
} from '../../../api'
import surveyData from './survey.json'
import _, { get, has, isFunction } from 'lodash'
import { baseURL, formatInch, parseInch } from '../../../utils'
import moment from 'moment'
import axios from 'axios'
// import { MemberInfoDone } from './completed'

const GOOGLE_TIMEZONE_KEY = process.env.REACT_APP_GOOGLE_TIMEZONE_KEY

export default function PersonalIntroductory(props) {
  const {
    person,
    setStepInfo,
    navigate,
    updateProfile,
    setSubActive,
    // setHideStep,
    steps
  } = useOutletContext() // updateProfile
  const [activeIndex, setActiveIndex] = useState(0)
  const [state, dispatch] = useReducer(reducer, {
    answers: {}
  })
  const [showError, setShowError] = useState(false)
  const [savingAnswer, setSavingAnswer] = useState(false)
  // const [done, setDone] = useState(false)

  useEffect(() => {
    const step = steps.find((item) => item.next === 'introductory')
    setStepInfo(step)
  }, [setStepInfo])

  useEffect(() => {
    let didCancel = false
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })

      const survey = surveyData

      if (!didCancel) {
        const introductory = await getIntroductory(person.id)
        if (introductory.height_inch) {
          const { height_ft, height_in } = formatInch(introductory.height_inch)
          introductory.height_ft = height_ft
          introductory.height_in = height_in
        }

        const answers = destructuringAnswers(survey.sections, introductory)

        _.forIn(survey.sections, (section) => {
          let question = _.find(section.questions, (question) =>
            _.includes(JSON.stringify(question), 'attachment')
          )
          if (question) {
            const target = _.find(question.choices, ['type', 'attachment'])
            if (target) {
              target.handleAttachment = {
                action: `${baseURL}/attachments/introductory.insurance_photos/${introductory.id}?person_id=${person.id}`,
                removeAttach: async (attach) => {
                  await removeAttachment(attach.id, person.id)
                },
                editAttach: async (attach, obj) => {
                  const response = await editAttachment(
                    attach.id,
                    person.id,
                    obj
                  )
                  return response
                }
              }
            }
          }
        })
        dispatch({
          type: 'initial',
          payload: { introductory, answers, survey }
        })
      }
      loading.close()
    }

    initial()
    return () => {
      didCancel = true
    }
  }, [person.id])

  const { survey, answers, introductory, progress, disabledNext } = state

  return (
    <>
      {/* {done ? (
        <MemberInfoDone action={onCompleted} />
      ) : (
        <> */}
      {progress && (
        <ProgressBar activeIndex={activeIndex + 1} progress={progress} />
      )}
      <QuestionsContainer>
        {introductory && (
          <Information
            introductory={introductory}
            section={survey.sections[activeIndex]}
            updateAnswers={updateAnswers}
            answers={answers[survey.sections[activeIndex].id]}
            showError={showError}
            person={person}
            // handleAttachment={this.handleAttachment}
          />
        )}
      </QuestionsContainer>
      <StepFooter
        showBack
        disabledNext={disabledNext}
        back={back}
        loading={savingAnswer}
        completeText={activeIndex === 0 ? 'NEXT' : 'COMPLETE'}
        onComplete={onCompleted}
      />
      {/* </>
      )} */}
    </>
  )

  function reducer(state, action) {
    if (action.type === 'initial') {
      const { survey } = action.payload

      if (!state.progress) {
        const progress = survey.sections.map(({ name, id }) => ({
          name,
          showIndex: id
        }))
        return { ...action.payload, progress }
      }

      return { ...action.payload, ...state }
    }

    const disabledNext =
      _.includes(JSON.stringify(state.answers[activeIndex + 1]), 'verify') ||
      _.includes(JSON.stringify(state.answers[activeIndex + 1]), 'required')

    return {
      ...state,
      disabledNext: disabledNext
    }
  }

  function updateAnswers(answer, params) {
    const section = survey.sections[activeIndex]
    const replaceIndex = answers[section.id].findIndex(
      (item) => item.question_id === answer.question_id
    )
    if (replaceIndex !== -1) {
      answers[section.id][replaceIndex] = answer
    }
    _.assignIn(answers[section.id])
    if (has(params, 'residence_address.geometry.location')) {
      fetchTimezone(params)
    }
    dispatch({ type: 'update', payload: { answers } })
    if (answer.verify) {
      return
    }
    if (params.height_ft || params.height_in) {
      params.height_inch = parseInch(
        params.height_ft || 0,
        params.height_in || 0
      )
      delete params.height_ft
      delete params.height_in
    }
    const paramsStr = JSON.stringify(params)

    if (
      !_.isEmpty(params) &&
      !(
        _.includes(paramsStr, 'required') ||
        _.includes(paramsStr, 'textRequired')
      )
    ) {
      saveAnswer(params)
    }
  }

  function back() {
    if (activeIndex === 1) {
      setActiveIndex(0)
      setSubActive(1)
      dispatch({ type: 'update', payload: { answers } })
    } else {
      navigate(-1)
    }
  }

  async function fetchTimezone(params) {
    const lat = get(params, 'residence_address.geometry.location.lat')
    const lng = get(params, 'residence_address.geometry.location.lng')
    if (!isFunction(lat) || !isFunction(lng)) {
      return
    }
    const result = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat()},${lng()}&timestamp=${moment().unix()}&key=${GOOGLE_TIMEZONE_KEY}`
    )
    const timeZone = get(result, 'data.timeZoneId')
    if (timeZone) {
      answers[1][7] = {
        session_id: 1,
        question_type: 'select',
        question_id: 8,
        answer: {
          value: timeZone
        }
      }
      _.assignIn(answers[1])
      dispatch({ type: 'update', payload: { answers } })
      saveAnswer({
        time_zone: timeZone
      })
    }
  }

  async function saveAnswer(params) {
    setSavingAnswer(true)
    setShowError(false)
    try {
      await saveIntroductory(introductory.id, params)
      _.assignIn(introductory, params)
      setSavingAnswer(false)
      dispatch({ type: 'update', payload: { introductory } })
    } catch (err) {
      console.error(err)
      setSavingAnswer(false)
    }
  }

  async function onCompleted() {
    // if (done) {
    //   setHideStep(false)
    //   navigate('/onboarding')
    //   return
    // }
    const section = survey.sections[activeIndex]
    const isCompleted = checkCompleted(section.questions, answers[section.id])
    if (isCompleted) {
      if (activeIndex === 0) {
        setActiveIndex(1)
        setSubActive(2)
      } else {
        const loading = Toast.show({
          icon: 'loading',
          content: 'loading...'
        })
        _.assign(person.profile, {
          introductory_required: false,
          onboarding_steps: _.assign(person.profile.onboarding_steps, {
            introductory_completed_steps: [1, 2]
          })
        })
        updateProfile(
          {
            onboarding_steps: person.profile.onboarding_steps
          },
          (_person) => {
            loading.close()
            navigate('/onboarding')
            // setDone(true)
            // setHideStep(true)
          }
        )
      }
    } else {
      setShowError(true)
      dispatch({ type: 'update', payload: { answers } })
    }
  }
}

function destructuringAnswers(sections, answer) {
  let answers = {}
  _.forIn(sections, (section) => {
    answers[section.id] = []
    _.forIn(section.questions, (question) => {
      let obj = destructuring(question.answer_format, answer)
      answers[section.id].push({
        answer: { value: obj },
        session_id: section.id,
        question_type: question.type,
        question_id: question.id
      })
    })
  })
  return answers
}

function destructuring(format, answer) {
  if (!format) return null
  let answerObj
  if (format.type === 'choice') {
    answerObj = answerObj || {}
    _.forIn(format.format, (_format, key) => {
      if (_.isString(_format)) {
        answerObj[key] = formatting(_format)
      } else if (answer[_format.key] === _format.value) {
        answerObj[key] = {}
        if (_format.text_key) {
          answerObj[key].text = answer[_format.text_key]
        } else if (_format.additional_key) {
          answerObj[key].additional_answer = formatting(_format.additional_key)
        } else if (_format.attachment_key) {
          answerObj[key] = formatting(_format.attachment_key)
        }
      }
    })
  } else {
    answerObj = formatting(format)
  }

  function formatting(format) {
    if (_.isString(format)) {
      return answer[format]
    } else if (_.isArray(format)) {
      return format.map((item) => formatting(item))
    } else {
      if (format.key) {
        if (format.isChecked) {
          // question position 1-3, 1-6
          let additional_answer
          if (format.format_key) {
            const obj = formatting(format.format_key)
            additional_answer = obj
          }
          return {
            isChecked: formatting(format.key),
            additional_answer
          }
        } else {
          return formatting(format.key)
        }
      } else {
        let obj = {}
        _.forIn(format, (value, key) => {
          obj[key] = formatting(value.isChecked ? value : value.key || value)
        })
        return obj
      }
    }
  }

  return answerObj
}

function checkCompleted(questions, answer) {
  let completed = true
  _.forIn(
    _.filter(questions, (question) => question.required),
    (question, index) => {
      const anObj = answer[index]
      if (_.isEmpty(anObj.answer.value)) {
        completed = false
        anObj.verify = { required: true }
      }
      let an = anObj.answer.value
      if (question.id === 2) {
        // check 1-2
        if (anObj.verify && anObj.verify.items) {
          completed = false
        }
        _.forIn(an, (_an, index) => {
          anObj.verify = anObj.verify || { items: [] }
          if (typeof _an !== 'string' && typeof _an !== 'number') {
            completed = false
            anObj.verify.items[index] = { required: true }
          } else {
            anObj.verify.items[index] = null
          }
        })
        if (anObj.verify.items.filter((item) => item).length === 0) {
          delete anObj.verify
        }
      } else if (question.id === 6) {
        // check 1-6
        if (_.isEmpty(_.filter(an, (_an) => _an))) {
          anObj.verify = { required: true }
          completed = false
        }
      } else if (question.id === 7) {
        // check 1-7
        // if (!an[0] && !an[1].isChecked && !an[2].isChecked) {
        if (!an[0]) {
          anObj.verify = { required: true }
          completed = false
        }
      } else if (question.id === 1 && question.type === 'intro_single_choice') {
        // check 2-1
        if (an[1]) {
          anObj.verify = anObj.verify || { additional: [] }
          if (_.isArray(an[1])) {
            an[1] = {
              additional_answer: ['', '', '', '']
            }
          }
          _.forIn(an[1].additional_answer, (_an, index) => {
            if (!_an) {
              anObj.verify.additional[index] = { required: true }
              completed = false
            }
          })
        } else if (an[0]) {
          if (_.isEmpty(an[0])) {
            an[0] = { required: true }
            completed = false
          }
        } else {
          anObj.verify = { required: true }
          completed = false
        }
      }
    }
  )
  return completed
}
