import { Button } from 'antd-mobile'
import styled from 'styled-components'
import arrowRight from '../../../asserts/icons/arrow-right.svg'
import bottomBG from '../../../asserts/icons/onboarding/bottom-bg.svg'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'

const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 36px;
  background-image: url(${bottomBG});
  background-repeat: no-repeat;
  background-position: bottom;
  height: calc(100vh - 115px);
  background-size: contain;
  .box {
    background-color: #fff;
  }
  .title {
    color: #29303e;
    font-size: 28px;
    font-weight: 600;
    line-height: 120%; /* 33.6px */
    letter-spacing: -0.2px;
    color: #29303e;
  }
  .desc {
    color: #52606c;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    opacity: 0.6;
    margin-top: 12px;
    margin-bottom: 40px;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .number {
        width: 30px;
        height: 30px;
        border: 2px solid #98a9bc;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #264382;
        font-size: 12px;
        font-weight: 800;
      }
      .name {
        color: #52606c;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        color: #52606c;
        margin-left: 14px;
        margin-right: 20px;
      }
      .time {
        padding: 4px 8px;
        color: #52606c;
        font-size: 12px;
        font-weight: 600;
        line-height: 116%;
        letter-spacing: 0.3px;
        border-radius: 22px;
        background: #e6e9f2;
      }
    }
  }
  .btn {
    text-align: center;
    margin-top: 102px;
    .adm-button {
      height: 48px;
      width: 100%;
      span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 100%; /* 14px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 10px;
        }
      }
    }
  }
`

const OnboardingReady = () => {
  const { steps, person } = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    const {
      profile: {
        pre_screening_completed,
        waiver_required,
        questionnaire_required,
        introductory_required
      }
    } = person

    if (
      pre_screening_completed ||
      !waiver_required ||
      !questionnaire_required ||
      !introductory_required
    ) {
      navigate('/onboarding')
    }
  }, [])

  const onClickStart = () => {
    navigate('/onboarding')
  }

  return (
    <Container>
      <div className="title">Ready to get started?</div>
      <div className="desc">
        Our advanced scientific testing gives you a deeper understanding of your
        body and performance. In conjunction with your personal goals and
        assessment, we are gathering this information to best design your action
        plan.
      </div>
      <div className="list">
        {steps
          .filter((e) => e.showIndex)
          .map((e) => (
            <div className="item">
              <div className="number">{e.showIndex}</div>
              <div className="name">{e.tag}</div>
              <div className="time">{e.min}</div>
            </div>
          ))}
      </div>
      <div className="btn">
        <Button shape="default" color="primary" onClick={onClickStart}>
          GET STARTED <img src={arrowRight} alt="" />
        </Button>
      </div>
    </Container>
  )
}

export default OnboardingReady
