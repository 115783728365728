import React from 'react'
import styled from 'styled-components'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { Divider } from 'antd-mobile'
import moment from 'moment'

const Container = styled.div`
  margin: 0 1rem;
  margin-bottom: 24px;
  border: 1px solid #e3e6ea;
  border-radius: 4px;
  border-top-width: 4px;
  color: #70808e;
  font-family: Gilroy;
  letter-spacing: 0.3px;
  height: calc(100% - 24px);
  padding: 16px 24px;
  &.OPTIMAL {
    border-top-color: #40c47c;
  }
  &.CONCERN {
    border-top-color: #f5ba40;
  }
  &.RISK {
    border-top-color: #ff6b00;
  }
  &.NEEDS {
    border-top-color: gold;
  }
  &.NONE {
    background: rgba(255, 107, 0, 0.05);
    border-color: #e75f25;
    .description {
      color: #e75f25;
      ul {
        padding-left: 20px;
        text-decoration: underline;
        line-height: 24px;
      }
    }

    &.User,
    &.Service_Provider {
      border-color: #e3e6ea;
      background: #ffffff;
      .description {
        color: inherit;
      }
    }
  }
  .OPTIMAL {
    color: #40c47c;
  }

  .CONCERN {
    color: #f5ba40;
  }

  .RISK {
    color: #ff6b00;
  }

  .NEEDS {
    color: gold;
  }

  header {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 1.125rem;
      color: #4c6072;
      text-transform: capitalize;
      line-height: 150%;
    }
    .status {
      font-size: 0.75rem;
      font-family: 'Gilroy-bold';
      margin-top: 0.5em;
    }
    .score {
      font-family: 'Gilroy-bold';
      font-size: 1.5rem;
      line-height: 1.29;
      color: #7fb800;
      text-align: right;
      white-space: nowrap;
    }
    .split {
      opacity: 0.1;
    }
    .compare-data {
      color: #0d2350;
    }
    .change {
      font-size: 0.875rem;
      display: block;
    }
  }
  .description {
    font-size: 0.94rem;
    line-height: 1.27;
    min-height: 6.67em;
  }
  footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.42em;
    transform: translateY(10px);
    width: 100%;
    font-size: 0.75rem;
    color: #97a0a7;
    letter-spacing: 0.3px;
  }
`

export default function HealthyCard(props) {
  const { name, description, compare, score_manual, score_auto } = props

  const score = (score_manual && score_manual.value) || score_auto
  const { status, compareScore } = getStatus(score, name)

  return (
    <Container className={status}>
      <header>
        <div className="name">
          {name}
          <div className={`status ${status}`}>
            {status !== 'NONE' ? status : 'No Data'}
          </div>
        </div>
        <div className="score">
          {status !== 'NONE' ? score : '--'}
          {compare && (
            <>
              <span className="split">/</span>{' '}
              <span className="compare-data">
                {compareScore ? compareScore : '--'}
              </span>
              <span className="change">{getChange(score, compareScore)}</span>
            </>
          )}
        </div>
      </header>
      <Divider />
      <div className="description">{description}</div>
      {renderFooter()}
    </Container>
  )

  function renderFooter() {
    const { latest_test_date, compare } = props
    const currentLatestData = latest_test_date
    const compareLatestData = compare && compare.latest_test_date

    return (
      <footer>
        {`${
          currentLatestData
            ? moment(currentLatestData).format('MMM DD, YYYY')
            : '--'
        } vs. ${
          compareLatestData
            ? moment(compareLatestData).format('MMM DD, YYYY')
            : '--'
        }`}
      </footer>
    )
  }

  function getChange(score, compareScore) {
    if (!score || !compareScore) return null

    const change = score - compareScore
    return (
      <>
        {change > 0 ? (
          <AiFillCaretUp style={{ color: '#40C47C' }} />
        ) : (
          <AiFillCaretDown style={{ color: '#FF6B00' }} />
        )}{' '}
        {Math.abs(change)}
      </>
    )
  }

  function getStatus(score, name) {
    const { compare } = props
    let compareScore
    if (compare) {
      compareScore =
        (compare.score_manual && compare.score_manual.value) ||
        compare.score_auto
    }
    let status = ''

    if (name === 'Cardiometabolic') {
      if (!score && score !== 0) {
        status = 'NONE'
      } else if (score < 80) {
        status = 'AT RISK'
      } else if (score >= 80 && score < 90) {
        status = 'CONCERN'
      } else if (score >= 90 && score < 95) {
        status = 'NEEDS MONITORING'
      } else if (score >= 95) {
        status = 'OPTIMAL'
      }
    } else {
      if (!score && score !== 0) {
        status = 'NONE'
      } else if (score <= 50) {
        status = 'AT RISK'
      } else if (score > 50 && score <= 80) {
        status = 'CONCERN'
      } else if (score > 80) {
        status = 'OPTIMAL'
      }
    }
    return { status, compareScore }
  }
}
