import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { CalendarPicker, DatePicker, Picker } from 'antd-mobile'
import { CalendarOutline } from 'antd-mobile-icons'
import moment from 'moment'

export const Container = styled.div`
  border: 1px solid #e4e7eb;
  border-radius: 6px;
  padding: 10px 8px;
  margin-top: 10px;
  display: inline-block;

  & > svg {
    margin-left: 8px;
  }
`

export default function DynamicDatePicker(props) {
  const { from, to, dateRange, onRangeChange } = props
  const [selectDateType, setSelectDateType] = useState(null)
  const [rangePickerValue, setRangePickerValue] = useState(null)

  useEffect(() => {
    setRangePickerValue([moment(from).toDate(), moment(to).toDate()])
  }, [from, to])

  const dateString = useMemo(() => {
    switch (dateRange.picker) {
      case 'date':
        return `${moment(from).format('MM/DD/YYYY')} - ${moment(to).format(
          'MM/DD/YYYY'
        )}`
      case 'year':
        return from.slice(0, 4)
      case 'quarter':
        return `${from.slice(0, 4)} - Q${moment(from).quarter()}`
      case 'month':
        return `${moment(from).format('YYYY-MM')}`
      default:
        return '-'
    }
  }, [from, to, dateRange])

  return (
    <>
      <Container
        onClick={() => {
          setSelectDateType(dateRange.picker)
        }}
      >
        {dateString}
        <CalendarOutline />
      </Container>
      <DatePicker
        visible={selectDateType === 'year' || selectDateType === 'month'}
        onClose={() => {
          setSelectDateType(null)
        }}
        max={new Date()}
        precision={dateRange.picker}
        onConfirm={(val) => {
          if (selectDateType === 'year') {
            onRangeChange([
              moment(val).startOf('year').format('YYYY-MM-DD'),
              moment(val).endOf('year').format('YYYY-MM-DD')
            ])
          } else {
            onRangeChange([
              moment(val).startOf('month').format('YYYY-MM-DD'),
              moment(val).endOf('month').format('YYYY-MM-DD')
            ])
          }
        }}
      />
      <Picker
        columns={getQuarterColumns()}
        visible={selectDateType === 'quarter'}
        onClose={() => {
          setSelectDateType(null)
        }}
        value={[2024, 3]}
        onConfirm={([year, quarter]) => {
          onRangeChange([
            moment(`${year}`, 'YYYY')
              .quarter(quarter)
              .startOf('quarter')
              .format('YYYY-MM-DD'),
            moment(`${year}`, 'YYYY')
              .quarter(quarter)
              .endOf('quarter')
              .format('YYYY-MM-DD')
          ])
        }}
      />
      <CalendarPicker
        visible={selectDateType === 'date'}
        defaultValue={rangePickerValue}
        selectionMode="range"
        max={new Date()}
        min={moment().subtract(10, 'year').toDate()}
        onClose={() => setSelectDateType(null)}
        onMaskClick={() => setSelectDateType(null)}
        onChange={(val) => {
          setRangePickerValue(val)
        }}
        onConfirm={([from, to]) => {
          onRangeChange([
            moment(from).format('YYYY-MM-DD'),
            moment(to).format('YYYY-MM-DD')
          ])
        }}
      />
    </>
  )
}

function getQuarterColumns() {
  const currentYear = moment().format('YYYY')
  return [
    new Array(10)
      .fill(0)
      .map((_, index) => {
        return {
          label: `${currentYear - index}`,
          value: currentYear - index
        }
      })
      .reverse(),
    [
      { label: 'Q1', value: 1 },
      { label: 'Q2', value: 2 },
      { label: 'Q3', value: 3 },
      { label: 'Q4', value: 4 }
    ]
  ]
}
