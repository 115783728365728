import React from 'react'
import styled from 'styled-components'

const RichTextContainer = styled.div`
  display: flex;
  border: 1px solid #e4eaef;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 12px;
  color: #70808e;
  position: relative;
  line-height: 1.7;
  .icon {
    margin-right: 16px;
    margin-top: 3px;
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
    color: #383e48;
    margin-top: 3px;
    margin-bottom: 0.5em;
  }
`
export default function RichText(props) {
  const { initialValue, icon, title } = props

  return (
    <RichTextContainer>
      <span className="icon">{icon}</span>

      <div>
        <h4>{title}</h4>
        <p dangerouslySetInnerHTML={{ __html: initialValue }} />
      </div>
    </RichTextContainer>
  )
}
