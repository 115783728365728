import React, { useState, useEffect } from 'react'
import _, { get } from 'lodash'
import { Container } from './index.styled'

import {
  PersonalInfoSection,
  IntroductionSection,
  AerobicFitnessSection,
  BodyCompSection,
  MuscleSection,
  BalanceSection,
  CognitiveHealthSection,
  MovementQualitySection,
  HealthCompassSection
} from './sections/index'

import { SpinLoading } from 'antd-mobile'
import { useParams } from 'react-router-dom'
import {
  getMeByHash,
  getARDetail,
  getRangeAgeScopes,
  getMetrics
} from '../../api'
import { healthCardList as list } from '../../utils/constant'
import { Element, Events, scrollSpy } from 'react-scroll'
import { Position } from './components/Position'
import { ARHeaderSections } from './wrapper'
import PositionSheet from './components/PositionSheet'
import BannerSection from './sections/BannerSection'
import Cardiometabolic from './sections/cardiometabolic'
import BoneHealth from './sections/BoneHealth'

export const PAGE_ID = 'PhysicalAssessment'

export default function PhysicalAssessment(props) {
  const { id, hash } = useParams()
  const [person, setPerson] = useState(null)
  const [arDetail, setArDetail] = useState(null)
  const [ageScopes, setAgeScopes] = useState(null)
  const [hideHeader, setHideHeader] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [trendMetrics, setTrendMetrics] = useState(null)
  const [visibleSection, setVisibleSection] = useState(
    ARHeaderSections.INTRODUCTION
  )

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      setModalShow(false)
    })

    // Updating scrollSpy when the component mounts.
    scrollSpy.update()

    return () => {
      Events.scrollEvent.remove('begin')
    }
  }, [])

  useEffect(() => {
    const initial = async () => {
      const [person, result, age_scopes_res] = await Promise.all([
        getMeByHash(hash),
        getARDetail(hash, id),
        getRangeAgeScopes()
      ])
      const metrics = await getMetrics(person.person.id, hash)
      setTrendMetrics(
        metrics.data.filter((item) => item.category === 'Blood Metrics')
      )
      setPerson(person.person)
      setArDetail(result.data)
      const ageScopes = Object.entries(age_scopes_res.age_scope).map(
        ([k, v]) => ({
          tag: k,
          label: v.label,
          scope: v.scope
        })
      )
      setAgeScopes(ageScopes)
    }
    initial()
  }, [])

  const dynamicData = _.get(arDetail, 'detail.dynamicData', {})

  const previous = get(dynamicData, 'healthSpanDomainSummary.1', {})
  const latest = get(dynamicData, 'healthSpanDomainSummary.0', {})

  const getDataList = (snapshot, graph_type) => {
    return list.map((item) => {
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      return {
        ...item,
        ...detail
      }
    })
  }

  const compareData = getDataList(previous.snapshot || {}, 'previous')
  const dataList = getDataList(latest.snapshot || {}, 'latest')

  const commonProps = {
    hideSection: get(arDetail, 'detail.hideSection', []),
    arDetail,
    person,
    loginRole: 'client',
    view: 'Preview',
    compareData,
    dataList,
    ageScopes
  }

  const { device_info, latest_height, latest_weight, residence } = get(
    arDetail,
    'detail',
    { dynamicData: {} }
  )

  return arDetail ? (
    <>
      <Position
        show={!hideHeader}
        onClick={() => {
          setModalShow(true)
        }}
        person={person}
        section={visibleSection}
      />
      <Container id={PAGE_ID}>
        <Element name={ARHeaderSections.BANNER.name}>
          <BannerSection
            person={person}
            period={arDetail.time_period}
            visible={(value) => {
              setHideHeader(value)
            }}
          />
        </Element>

        <Element name={ARHeaderSections.INTRODUCTION.name}>
          <IntroductionSection
            notes={arDetail.notes}
            visible={() => {
              setVisibleSection(ARHeaderSections.INTRODUCTION)
            }}
          />
        </Element>

        <Element name={ARHeaderSections.PERSONNAL_INFO.name}>
          <PersonalInfoSection
            {...{
              person,
              latest_height,
              latest_weight,
              device_info,
              residence
            }}
            visible={() => {
              setVisibleSection(ARHeaderSections.PERSONNAL_INFO)
            }}
          />
        </Element>

        {_.has(arDetail, 'detail.dynamicData.healthSpanDomainSummary') && (
          <Element name={ARHeaderSections.HEALTH_COMPASS.name}>
            <HealthCompassSection
              {...{
                compareData,
                dataList,
                person,
                dynamicData
              }}
              visible={() => {
                setVisibleSection(ARHeaderSections.HEALTH_COMPASS)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData') && (
          <Element name={ARHeaderSections.CARDIO_HEALTH.name}>
            <Cardiometabolic
              {...commonProps}
              {...{
                previous,
                latest,
                dynamicData,
                trendMetrics
              }}
              visible={() => {
                setVisibleSection(ARHeaderSections.CARDIO_HEALTH)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.dxaSummary') && (
          <Element name={ARHeaderSections.BONE_HEALTH.name}>
            <BoneHealth
              {...commonProps}
              {...{
                previous,
                latest,
                dynamicData
              }}
              visible={() => {
                setVisibleSection(ARHeaderSections.BONE_HEALTH)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.bodyCompResults') && (
          <Element name={ARHeaderSections.BODY_COMP.name}>
            <BodyCompSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList
              }}
              data={_.get(arDetail, 'detail.dynamicData.bodyCompResults', {})}
              visible={() => {
                setVisibleSection(ARHeaderSections.BODY_COMP)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.cognitiveHealth') && (
          <Element name={ARHeaderSections.COGNITIVE_HEALTH.name}>
            <CognitiveHealthSection
              {...commonProps}
              {...{
                compareData,
                dataList,
                ageScopes
              }}
              data={_.get(arDetail, 'detail.dynamicData.cognitiveHealth', {})}
              visible={() => {
                setVisibleSection(ARHeaderSections.COGNITIVE_HEALTH)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.movementBalance') && (
          <Element name={ARHeaderSections.BALANCE.name}>
            <BalanceSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList
              }}
              squatImage={_.get(arDetail, 'detail.single_leg_squat_image')}
              data={_.get(arDetail, 'detail.dynamicData.movementBalance', {})}
              visible={() => {
                setVisibleSection(ARHeaderSections.BALANCE)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.movementQuality') && (
          <Element name={ARHeaderSections.MOVEMENT_QUALITY.name}>
            <MovementQualitySection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList
              }}
              painfulImage1={_.get(arDetail, 'detail.painful_image_1')}
              painfulImage2={_.get(arDetail, 'detail.painful_image_2')}
              painfulImage3={_.get(arDetail, 'detail.painful_image_3')}
              data={_.get(arDetail, 'detail.dynamicData.movementQuality', {})}
              visible={() => {
                setVisibleSection(ARHeaderSections.MOVEMENT_QUALITY)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData.lowerBodyStrength') && (
          <Element name={ARHeaderSections.MUSCLE_FITNESS.name}>
            <MuscleSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList
              }}
              lowerBodyStrength={_.get(
                arDetail,
                'detail.dynamicData.lowerBodyStrength',
                {}
              )}
              upperBodyAndCoreStrength={_.get(
                arDetail,
                'detail.dynamicData.upperBodyAndCoreStrength',
                {}
              )}
              visible={() => {
                setVisibleSection(ARHeaderSections.MUSCLE_FITNESS)
              }}
            />
          </Element>
        )}

        {_.has(arDetail, 'detail.dynamicData') && (
          <Element name={ARHeaderSections.AEROBIC_FITNESS.name}>
            <AerobicFitnessSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList
              }}
              data={_.get(arDetail, 'detail.dynamicData', {})}
              visible={() => {
                setVisibleSection(ARHeaderSections.AEROBIC_FITNESS)
              }}
            />
          </Element>
        )}

        <PositionSheet
          person={person}
          modalShow={modalShow}
          setModalShow={setModalShow}
          section={visibleSection}
        />
      </Container>
    </>
  ) : (
    <SpinLoading
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  )
}
