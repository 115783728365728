import React, { useState, useEffect, useReducer, useRef } from 'react'
import _ from 'lodash'
import { Button, Toast } from 'antd-mobile'
// import { animateScroll as scroll } from 'react-scroll'
import Questions from './questions'
import AnswerUpdater, {
  verifyAnswer,
  checkCompleted
} from '../common/answerUpdater'
import {
  getSectionDetails,
  getRecordSessions,
  completeNewSessionEmail
} from '../../../api'
import { useParams, useOutletContext } from 'react-router-dom'
import { QuestionsContainer, StepFooter, ProgressBar } from '../common'
import Success from '../../../asserts/icons/onboarding/pre-screen-completed-success.svg'
import ArrowRight from '../../../asserts/icons/arrow-right.svg'
import { RecordCompletedPage } from './RecordCompletedPage'

const initialState = {
  sections: [],
  answers: [],
  answerUpdater: null,
  loading: false,
  saveCount: 0,
  // pageTitle: '',
  progress: []
}

export default function QuestionnaireRecord(props) {
  // const navigate = useNavigate()
  const { hash } = useParams()

  const { person } = useOutletContext()
  // const [pageLoading, setPageLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false)
  const containerRef = useRef(null)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater } = state
      setSaveLoading(answerUpdater.isLoading())
      return _.assignIn(state, { answers: answerUpdater._answers })
    } else if (action.type === 'check answers') {
      return _.assign({}, state, { answers: action.answers })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  useEffect(() => {
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      try {
        const { section_ids, person_id, record_date } = JSON.parse(
          window.atob(hash)
        )
        if (Number(person_id) !== person.id) throw new Error()
        // setPageLoading(true)
        setShowSuccess(false)
        const sections = await getSectionDetails(person_id, section_ids)
        const answers = await getRecordSessions(section_ids, record_date)
        let activeStep = 0
        if (sections.length > 1) {
          activeStep = Math.max(
            answers.findIndex((answerInfo) => answerInfo.completion_rate < 100),
            0
          )
        }
        dispatch({
          type: 'initial',
          payload: {
            sections,
            answers,
            activeStep,
            progress: sections.map(({ name }, index) => ({
              name,
              showIndex: index + 1
            })),
            answerUpdater: new AnswerUpdater({
              answers,
              sections,
              saveCallback: () => dispatch({ type: 'update answers' })
            })
          }
        })
        // setPageLoading(false)
      } catch (err) {
        // history.push('/404')
        console.log('Questionnaire-Record get data failed: ', err)
      } finally {
        loading.close()
      }
    }

    initial()
  }, [hash, person.id])

  const { sections, answers, answerUpdater, progress, activeStep } = state

  const { original_id, questions, name } = sections[activeStep] || {}
  const answerInfo = answers.find(
    (item) => item.section_original_id === original_id
  )

  useEffect(() => {
    setConfirmBtnDisabled(saveLoading || showError)
  }, [saveLoading, showError])

  return (
    <div className="main-container">
      {showSuccess && showSuccess.success ? (
        <RecordCompletedPage
          img={Success}
          destinations={showSuccess.destinations}
        />
      ) : (
        <>
          {progress && progress.length > 1 && (
            <ProgressBar activeIndex={activeStep + 1} progress={progress} />
          )}
          <QuestionsContainer ref={containerRef}>
            {/* <div className={`main-content questionnaire ${params.section}`}> */}
            <h2
              style={{
                margin: '0',
                fontWeight: 600,
                color: 'rgba(0,0,0,.85)'
              }}
            >
              {!(progress.length > 1) && name}
            </h2>
            {/* {pageLoading && <SpinLoading />} */}
            {showError && (
              <div className="error-message">
                Error: Please answer all required questions.
              </div>
            )}

            {original_id && (
              <Questions
                {...{
                  original_id,
                  questions,
                  answerInfo,
                  updateAnswer,
                  setConfirmBtnDisabled
                }}
              />
            )}
          </QuestionsContainer>
          <StepFooter
            showBack={activeStep > 0}
            back={() => {
              dispatch({
                type: 'update',
                payload: { activeStep: activeStep - 1 }
              })
            }}
            confirmOpr={
              <Button
                block
                shape="rounded"
                color="primary"
                disabled={confirmBtnDisabled}
                loading={saveLoading}
                onClick={onConfirm}
              >
                <div className="next-button">
                  <span>
                    {activeStep === sections.length - 1 ? 'CONFIRM' : 'NEXT'}{' '}
                  </span>
                  <img src={ArrowRight} alt="" />
                </div>
              </Button>
            }
          />
        </>
      )}
    </div>
  )

  async function updateAnswer(answer, original_id, question) {
    if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)

    if (!JSON.stringify(_answer).includes('verify')) {
      setSaveLoading(true)
      answerUpdater.autoSave(_answer, original_id)
    } else {
      const answerInfo = answers.find((item) => item.id === _answer.session_id)
      const targetIndex = answerInfo.answers.findIndex(
        (an = {}) => an.question_original_id === _answer.question_original_id
      )
      if (targetIndex > -1) {
        answerInfo.answers[targetIndex] = _answer
      } else {
        answerInfo.answers.push(_answer)
      }
      dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
    }
  }

  async function onConfirm() {
    if (!answerInfo || !sections[activeStep]) return
    const { isCompleted, answers: _answers } = await checkCompleted(
      [answerInfo],
      [sections[activeStep]]
    )
    if (!isCompleted) {
      setShowError(true)
      dispatch({
        type: 'check answers',
        answers: _answers
      })
      setTimeout(() => {
        if (containerRef) {
          const invalidElem =
            containerRef.current.getElementsByClassName('invalid')[0]
          invalidElem &&
            invalidElem.parentNode.parentNode.parentNode.scrollIntoView({
              behavior: 'smooth'
            })
        }
      }, 500)
    } else {
      if (sections.length - 1 > activeStep) {
        // scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
        dispatch({ type: 'update', payload: { activeStep: activeStep + 1 } })
      } else {
        setSaveLoading(true)
        try {
          await completeNewSessionEmail({
            session_ids: state.answers.map((e) => e.id)
          })
          setShowSuccess({
            success: true,
            destinations: completedDest()
          })
        } catch {
          setSaveLoading(false)
        }
      }
    }
  }

  function completedDest() {
    const nutritionDest = {
      path: '/nutrition-score/xxx',
      name: 'Nutrition Result',
      inAppFunc: () => {
        window.webkit.messageHandlers.nutritionScore.postMessage({})
      }
    }
    const microbiomeDest = {
      path: '/microbiome-analysis/xx',
      name: 'Microbiome Analysis',
      inAppFunc: () => {
        window.webkit.messageHandlers.microbiomeResult.postMessage({})
      }
    }
    const heathCompassDest = {
      path: '/health-score/xxx',
      name: 'Health Compass',
      inAppFunc: () => {
        window.webkit.messageHandlers.healthScore.postMessage({})
      }
    }
    let destinations = []
    const hasNutrition = sections.find(
      (s) => s.original_id === 'nutrition_eating_habits'
    )
    const hasMicrobiome = sections.find((s) => s.original_id === 'gut_health')
    const onlyNutrition =
      sections.length === 1 &&
      sections[0].original_id === 'nutrition_eating_habits'
    const onlyMicrobiome =
      sections.length === 1 && sections[0].original_id === 'gut_health'
    if (onlyNutrition) {
      destinations.push(nutritionDest)
    } else if (onlyMicrobiome) {
      destinations.push(microbiomeDest)
    } else {
      hasNutrition && destinations.push(nutritionDest)
      hasMicrobiome && destinations.push(microbiomeDest)
      if (
        sections.filter(
          (item) =>
            item.original_id !== 'nutrition_eating_habits' &&
            item.original_id !== 'gut_health'
        ).length > 0
      ) {
        destinations.unshift(heathCompassDest)
      }
    }

    return destinations
  }
}
