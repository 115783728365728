import React from 'react'

export function AvatarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="rgba(71, 159, 236, 1)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12.1 12.3C11.7 11.6 10.9 11.3 10 11C9.3 10.8 9.1 10.4 9 9.8C10.1 9.4 11 8.3 11 7C11 5.3 9.7 4 8 4C6.3 4 5 5.3 5 7C5 8.3 5.8 9.4 7 9.8C6.9 10.4 6.7 10.8 6 11C5.1 11.3 4.3 11.6 3.9 12.3C2.7 11.2 2 9.7 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.7 13.3 11.2 12.1 12.3Z" />
    </svg>
  )
}

export function GreenCheckIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#7BAD2D" />
      <path
        d="M5.33337 8L7.33337 10L10.6667 6"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function LifestyleGoalSvgIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_297_45109)">
        <path
          d="M29 1.99951H6V0.999512C6 0.447266 5.55273 -0.000488281 5 -0.000488281C4.44727 -0.000488281 4 0.447266 4 0.999512V30.9995C4 31.5518 4.44727 31.9995 5 31.9995C5.55273 31.9995 6 31.5518 6 30.9995V19.9995H29C29.5527 19.9995 30 19.5518 30 18.9995V2.99951C30 2.44727 29.5527 1.99951 29 1.99951ZM28 9.99951H22V3.99951H28V9.99951ZM20 11.9995V17.9995H14V11.9995H20ZM12 3.99951V9.99951H6V3.99951H12Z"
          fill="#264382"
        />
      </g>
      <defs>
        <clipPath id="clip0_297_45109">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 -0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
