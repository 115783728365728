import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { VictoryChart, VictoryAxis, VictoryLabel } from 'victory'
import {
  calculateAxisY,
  calculateDataValues,
  dateFormatConfirm,
  unifyAxisY
} from './chartHandle'
import BarChart from './BarChart'
import LineChart from './LineChart'
import RangeAxisChart from './RangeAxisChart'
import { ColorLuminance } from '../../../../utils'
import ContainerWidthSizer from '../../../commonComponents/ContainerWidthSizer'

const fontFamily = 'Gilroy,Lato,-apple-system'

const strokeColor = '#E4E7EB'
const strokeActiveColor = '#616E7C'
const fontSize = 8
const chartPadding = {
  top: 30,
  left: 30,
  bottom: 10,
  right: 0
}
export default function Chart(props) {
  const [active, setActive] = useState(null)
  const [charts, setCharts] = useState(null)
  const {
    showNumber,
    showGraphType,
    metric,
    showIndex,
    dateRange,
    getTheme,
    setNotes
  } = props

  useEffect(() => {
    const axisX = metric.values.map((item) => item.date)
    const data = calculateDataValues(metric)
    let comparedMetric = metric.add_metrics[0]
    let charts = [
      {
        data,
        axisX,
        axisY: calculateAxisY(data, metric),
        theme: getTheme(metric.categories),
        name: metric.name,
        metric_key: metric.metric_key,
        ranges: metric.ranges,
        up_color: metric.up_color
      }
    ]
    if (comparedMetric) {
      const { unit, name, metric_key, ranges, up_color } = comparedMetric
      const compareData = calculateDataValues(comparedMetric)
      const axisY = calculateAxisY(compareData, comparedMetric)
      let theme = getTheme(comparedMetric.categories)
      if (_.isEqual(charts[0].theme, theme)) {
        if (theme.lighter) {
          theme = theme.lighter
        } else {
          const { primary, barBg } = theme
          const lighter = 0.3
          theme = {
            primary: ColorLuminance(primary, lighter),
            barBg: barBg.map((item) => ColorLuminance(item, lighter))
          }
        }
      }
      comparedMetric = {
        theme,
        name: name,
        metric_key: metric_key,
        axisX,
        axisY,
        data: compareData,
        ranges,
        up_color
      }
      charts.push(comparedMetric)
      if (unit === metric.unit) {
        charts = unifyAxisY(charts)
      }
      setNotes(charts)
    }
    setCharts(charts)
  }, [metric.values, metric.add_metrics])
  const comparedMetric = charts && charts[1]

  let travelData = metric.values.filter((item) => {
    return item.is_traveling
  })
  if (
    travelData.length === 0 &&
    metric.add_metrics[0] &&
    metric.add_metrics[0].values
  ) {
    travelData = metric.add_metrics[0].values.filter((item) => {
      return item.is_traveling
    })
  }

  if (metric.ranges || (comparedMetric && comparedMetric.ranges)) {
    return (
      (charts && (
        <RangeAxisChart
          {...{
            dateRange,
            showGraphType,
            metric: charts[0],
            showIndex,
            comparedMetric,
            showNumber,
            axisX: charts[0].axisX,
            active,
            charts,
            setActive,
            strokeColor,
            fontFamily,
            strokeActiveColor,
            fontSize,
            chartPadding,
            travelData: travelData.length > 0 ? travelData : null
          }}
        />
      )) ||
      null
    )
  }

  const chartProps = {
    showIndex,
    showNumber,
    dateRange,
    charts,
    active,
    setActive,
    strokeColor,
    fontFamily,
    strokeActiveColor,
    fontSize,
    chartPadding,
    travelData: travelData.length > 0 ? travelData : null,
    narrowAxisDown: metric.categories.includes('Nutrition')
  }

  return (
    (charts && (
      <>
        {showGraphType === 'line' ? (
          <>
            <LineChart {...chartProps} />
          </>
        ) : (
          <BarChart {...chartProps} />
        )}
        {/* {travelData.length > 0 && (
          <TravelChart
            travelData={travelData}
            {...{
              chartPadding,
              showGraphType,
              dateRange,
              charts
            }}
          />
        )} */}
      </>
    )) ||
    null
  )
}

export function AxisYLabel(props) {
  const { metric, dateRange, showGraphType } = props
  let axisX = metric.values.map((item) => item.date)
  if (axisX.length > 35) {
    axisX = [...axisX.filter((item, index) => (index + 3) % 3 === 0)]
  } else if (axisX.length > 21) {
    axisX = axisX.filter((item, index) => index % 2 === 0)
  }
  const format = dateFormatConfirm(dateRange, metric)
  return (
    <ContainerWidthSizer>
      {({ width }) => (
        <VictoryChart width={width} height={32} padding={chartPadding}>
          <VictoryAxis
            offset={showGraphType === 'bar' ? 20 : 0}
            tickValues={axisX}
            tickLabelComponent={
              <VictoryLabel
                text={(x) => {
                  return moment(axisX[x - 1]).format(format)
                }}
              />
            }
            style={{
              grid: {
                stroke: 'transparent',
                strokeDasharray: '6, 3'
              },
              axis: { stroke: 'transparent' },
              tickLabels: {
                fill: '#7B8794'
              }
            }}
          />
        </VictoryChart>
      )}
    </ContainerWidthSizer>
  )
}
