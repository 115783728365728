import React, { useState, useEffect, useMemo } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
// import { ProgressBar } from '../common'
import { Button } from 'antd-mobile'
import {
  getWaiverSignedForm,
  getLiabilityForm,
  getWaiverForm,
  updateProfileStatus,
  skipNotification
} from '../../../api'
import HelloSign from 'hellosign-embedded'
import WaiverMedical from './medical'
import WaiverLiability from './liability'
import WaiverMedical2 from './medical2'
import arrowRight from '../../../asserts/icons/arrow-right.svg'
import arrowLeft from '../../../asserts/icons/arrow-left.svg'
import './style.js'
import Container from './style'
import { loadUser, saveUser } from '../../../utils'
import { WaiverFormKey } from '../index.js'
import { last } from 'lodash'
import { useQuery } from '../../../hooks/useQuery.js'
import { MemberInfoDone } from '../introductory/completed.jsx'

export default function WaiverForm(props) {
  const {
    setStepInfo,
    steps,
    // stepInfo,
    navigate,
    updateProfile,
    // subActive,
    setPerson,
    setHideStep
  } = useOutletContext()
  const { section } = useParams()
  const [buttonName] = useState('NEXT')
  const [understand, setUnderstand] = useState(true)
  const [signedMedical, setSignedMedical] = useState([undefined])
  const [signedLiability, setSigneLiability] = useState([])
  const [otherProvider, setOtherProvider] = useState(false)

  const [relaseHealth, setRelaseHealth] = useState()
  const [step, setStep] = useState(1)
  const [allDone, setAllDone] = useState(false)

  const hasCallback = !!useQuery().get('callback')
  const inApp = navigator.userAgent.includes('life.apeiron.ios')

  useEffect(() => {
    getWaiverSignedForm(false).then((res) => {
      if (res.medical_release_form.length) {
        setSignedMedical(res.medical_release_form)
        if (res.medical_release_form.length > 0) {
          setRelaseHealth(true)
        }
        setStep(2)
      }
      if (res.liability_release_form.length) {
        setSigneLiability(res.liability_release_form)
      }
    })
    const temp = steps.find((item) => last(item.next.split('/')) === section)
    setStepInfo(temp)
  }, [setStepInfo])

  // useEffect(() => {
  //   if(step === 1){
  //     setButtonName('NEXT')
  //     return
  //   }
  //   if(!signedMedical.includes(undefined)){
  //     if(otherProvider){
  //       setButtonName('NEXT')
  //     }else{
  //       setButtonName('COMPLETE')
  //     }
  //   }
  // }, [signedMedical, step, otherProvider])

  const disableButton = useMemo(() => {
    if (section === 'liabilities') {
      return signedLiability.length === 0
    } else if (section === 'medical') {
      if (step === 1 && relaseHealth === undefined) {
        return true
      }
      if (step === 2) {
        if (!relaseHealth) return false
        return signedMedical.includes(undefined)
      }
    }
  }, [signedMedical, signedLiability, relaseHealth, step, section])

  async function done() {
    if (section === 'liabilities') {
      navigate('/onboarding')
    } else if (section === 'medical') {
      if (step === 2) {
        if (otherProvider && relaseHealth) {
          setSignedMedical([...signedMedical, undefined])
          setOtherProvider(false)
          return
        } else if (!relaseHealth) {
          skipMedical()
          return
        }
        judgeNextPage()
      } else {
        setStep(step + 1)
      }
    }
  }

  async function skipMedical() {
    let user = loadUser()
    user.profile.onboarding_steps = user.profile.onboarding_steps || {}
    let arr = user.profile.onboarding_steps.waiver_forms_skipped || []
    if (arr.includes(0)) {
      return
    }
    arr.push(0)
    user.profile.onboarding_steps.waiver_forms_skipped = arr
    const [person, _] = await Promise.all([
      updateProfileStatus(user.id, {
        onboarding_steps: user.profile.onboarding_steps
      }),
      skipNotification(
        { form_name: 'Medical Release Form', operation_type: 2 },
        user.id
      )
    ])
    setPerson(person)
    judgeNextPage()
  }

  async function back() {
    if (step === 1) {
      if (hasCallback && inApp) {
        postMessageToApp()
      } else {
        await updateProfile({})
        navigate('/onboarding')
      }
      return
    }
    setStep(step - 1)
  }

  function judgeNextPage() {
    if (hasCallback && inApp) {
      postMessageToApp()
    } else {
      setHideStep(true)
      setAllDone(true)
    }
  }

  function postMessageToApp() {
    window.webkit.messageHandlers.onboardingCompleted.postMessage({})
  }

  const performSign = (sign_url, cb) => {
    const client = new HelloSign({
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.REACT_APP_WORKFLOW === 'develop'
    })
    client.open(sign_url)
    client.on('sign', (data) => {
      cb(data)
      client.close()
    })
    client.on('error', (data) => {
      const errStr = `Something went wrong! Please try again. Error code: ${data.code}`
      console.error(errStr)
      client.close()
    })
  }

  const form = useMemo(() => {
    if (section === 'liabilities') {
      return (
        <WaiverLiability
          onClick={async () => {
            const {
              embedded: { sign_url }
            } = await getLiabilityForm(signedMedical.length - 1)
            performSign(sign_url, (data) => {
              setSigneLiability([data])
            })
            const user = loadUser()
            user.profile.completed_signature =
              user.profile.completed_signature || []
            if (
              !user.profile.completed_signature.includes(
                WaiverFormKey.LIABILITY
              )
            ) {
              user.profile.completed_signature.push(WaiverFormKey.LIABILITY)
              setPerson(user)
              saveUser(user)
            }
          }}
          signedLiability={signedLiability}
        />
      )
    } else if (section === 'medical') {
      if (step === 1) {
        return (
          <WaiverMedical
            relaseHealth={relaseHealth}
            setPermison={(e) => {
              if (signedMedical.find((s) => s !== undefined)) {
                setRelaseHealth(true)
                return
              }
              setRelaseHealth(e)
            }}
          />
        )
      } else if (step === 2) {
        return (
          <WaiverMedical2
            onClick={async () => {
              const {
                embedded: { sign_url }
              } = await getWaiverForm(signedMedical.length - 1)
              performSign(sign_url, (data) => {
                setSignedMedical(
                  signedMedical.map((e) => (e === undefined ? data : e))
                )
                setOtherProvider(false)
              })
              const user = loadUser()
              user.profile.completed_signature =
                user.profile.completed_signature || []
              if (
                !user.profile.completed_signature.includes(
                  WaiverFormKey.MEDICAL
                )
              ) {
                user.profile.completed_signature.push(WaiverFormKey.MEDICAL)
                user.profile.waiver_required = false
                setPerson(user)
                saveUser(user)
              }
            }}
            relaseHealth={relaseHealth}
            signedMedical={signedMedical}
            understand={understand}
            setUnderstand={setUnderstand}
            otherProvider={otherProvider}
            setOtherProvider={(e) => {
              setOtherProvider(e)
            }}
          />
        )
      }
    }
  }, [
    step,
    relaseHealth,
    understand,
    signedMedical,
    signedLiability,
    otherProvider,
    section
  ])

  return (
    <Container>
      {/* <ProgressBar
        activeIndex={subActive}
        progress={stepInfo?.subSteps || []}
      /> */}
      {allDone ? (
        <MemberInfoDone
          action={() => {
            setHideStep(false)
            navigate('/onboarding')
          }}
        />
      ) : (
        <>
          {form}
          <div className="control">
            <div className="back" onClick={back}>
              <img src={arrowLeft} alt="" /> BACK
            </div>
            <Button
              className="next"
              shape="default"
              color="primary"
              disabled={disableButton}
              onClick={done}
            >
              {buttonName}{' '}
              {buttonName === 'NEXT' ? <img src={arrowRight} alt="" /> : ''}{' '}
            </Button>
          </div>
        </>
      )}
    </Container>
  )
}

export const SignedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#22A95F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 0.9375C9.99219 0.9375 11.6797 1.62891 13.0625 3.01172L12.2891 3.82031C11.1172 2.64844 9.6875 2.0625 8 2.0625C6.28906 2.0625 4.82422 2.67187 3.60547 3.89062C2.41016 5.08594 1.8125 6.53906 1.8125 8.25C1.8125 9.96094 2.41016 11.4258 3.60547 12.6445C4.82422 13.8398 6.28906 14.4375 8 14.4375C9.71094 14.4375 11.1641 13.8398 12.3594 12.6445C13.5781 11.4258 14.1875 9.96094 14.1875 8.25C14.1875 7.78125 14.1289 7.3125 14.0117 6.84375L14.9258 5.92969C15.1836 6.60938 15.3125 7.38281 15.3125 8.25C15.3125 10.2656 14.5977 11.9883 13.168 13.418C11.7383 14.8477 10.0156 15.5625 8 15.5625C5.98438 15.5625 4.26172 14.8477 2.83203 13.418C1.40234 11.9883 0.6875 10.2656 0.6875 8.25C0.6875 6.23438 1.40234 4.51172 2.83203 3.08203C4.26172 1.65234 5.98438 0.9375 8 0.9375ZM14.3281 3.32812L15.1719 4.17188L8.42188 10.9219L8 11.2734L7.57812 10.9219L4.76562 8.10938L5.60938 7.26562L8 9.69141L14.3281 3.32812Z" />
  </svg>
)
