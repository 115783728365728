import React from 'react'
// import logo from "./logo.svg"
// import { RouterProvider } from "react-router-dom"
// import router from "./models/routes.model"
import RouterComponent from './routes'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
// import "./App.css"

function App() {
  return (
    <div className="App">
      <ConfigProvider locale={enUS}>
        <RouterComponent />
      </ConfigProvider>
    </div>
  )
}

export default App
