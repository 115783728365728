import axios from './axios'
import { questionnaireConfig } from '../utils'
import moment from 'moment'

export const getQuestions = (personId) => {
  let url = `/survey/${questionnaireConfig.surveyName}`
  if (personId) {
    url += `?person_id=${personId}`
  }
  return axios.get(url)
}

export const getSectionDetails = (personId, ids) =>
  axios.get(`/survey/section/details?original_ids=${ids}&person_id=${personId}`)

export const getSectionsSessions = (personId, ids) =>
  axios.get(
    `/survey/section/sessions?person_id=${personId}&original_ids=${ids}`
  )

export const getSessionOrInit = (personId, ids, record_date) => {
  if (!record_date) {
    record_date = moment().format('YYYY-MM-DD')
  }
  return axios.get(
    `/survey/section/sessions/init?person_id=${personId}&original_ids=${ids}&record_date=${record_date}`
  )
}

export const getRecordSessions = (ids, date, personId) =>
  axios.get(
    `/survey/section/sessions?original_ids=${ids}&record_date=${date}${
      personId ? '&person_id=' + personId : ''
    }`
  )

export const updateAnswer = (answer) =>
  axios.putJson('/survey/section/session/answer', answer)

export const getWaiverSignedForm = (urlIncluded, person_id) => {
  let url = `/people/waiver_signed?urlIncluded=${urlIncluded}`
  if (person_id) {
    url += `&person_id=${person_id}`
  }
  return axios.get(url)
}

export const sendPreScreeningEmail = (personId, surveyId) =>
  axios.post(`/clients/questionnaire_pre_screening_email?person_id=${personId}`)

export const getWaiverForm = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=medical_release_form`
  )
}

export const getLiabilityForm = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=liability_release_form`
  )
}

export const getMembershipAgreement = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=membership_agreement`
  )
}

export const getIntroductory = (personId) =>
  axios.get(`/introductory?person_id=${personId}`)

export const saveIntroductory = (id, params) =>
  axios.putJson(`/introductory/${id}`, params)

export const completeNewSessionEmail = (params) =>
  axios.postJson('/survey/section/complete-sessions-email', params)

export const getMembershipFees = (id) =>
  axios.get(`/clients/membership-fees?person_id=${id}`)

export function skipNotification(params, person_id) {
  return axios.postJson(
    `/clients/onboarding_email?person_id=${person_id}`,
    params
  )
}
