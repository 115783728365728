import { hashRequest } from './axios'

export const getARDetail = (hash, id) =>
  hashRequest({
    hash: hash,
    method: 'GET',
    path: `/assessment_review/detail/${id}`
  })

export const getMetrics = (id, hash) =>
  hashRequest({
    hash: hash,
    method: 'GET',
    path: `/trend_statistics/metrics?person_id=${id}`
  })
