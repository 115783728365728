import styled from 'styled-components'

export const StepListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
  li {
    color: #29303e;
    font-size: 1.125rem;
    line-height: 140%;
    padding: 0.56rem 0;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      font-size: 0.75rem;
      color: #98a9bc;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      text-align: center;
      line-height: 1.5rem;
      margin-right: 1rem;
      border: 1px solid #98a9bc;
    }
    .customize-icon {
      color: #05b800;
      font-size: 1.625rem;
      margin-right: 1rem;
    }

    &.active {
      border: 1px solid #e6e9f2;
      padding: 0.75rem 0;
      border-left-width: 0;
      border-right-width: 0;
      span {
        background: #264382;
        border-color: #264382;
        color: #ffffff;
      }
    }
  }
  &.on-side {
    li {
      font-size: 1rem;
      color: #a9acb2;
      padding: 1rem 0;
      &.active,
      &.done {
        color: #29303e;
        .antd-mobile-icon {
          color: #29303e;
        }
      }
      &.active {
        border-bottom: 1px solid #e6e9f2;
        padding-bottom: 1.6rem;
      }
      .tag {
        width: calc(100% - 4rem);
      }
    }
    .antd-mobile-icon {
      color: #a9acb2;
      font-size: 0.625rem;
      margin-right: 0;
    }
  }
  @media (min-width: 767px) and (min-height: 767px) {
    width: ${(props) => (props.notSetUpView ? '100%' : '60%')};
    li.active {
      border: none;
    }
  }
  @media (min-width: 1024px) {
  }
`

export const QuestionsContainer = styled.div`
  padding: 1.5rem;
  min-height: calc(100vh - 15rem);
  padding-bottom: ${(props) => props.paddingBottom || '7rem'};
  background: #f1f2f5;
  > div:first-child {
    .question {
      margin-top: 0px;
    }
  }
  &.ipad-view {
    display: none;
  }
  &.iphone-view {
    display: block;
    background: #f1f2f5;
  }
  @media (min-width: 768px) and (min-height: 767px) {
    &.ipad-view {
      display: block;
    }
    &.iphone-view {
      display: none;
    }
  }

  .error-message {
    color: #ff0000;
  }
`
