import React, { useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { tickFormatY } from './chartHandle'
import _, { capitalize } from 'lodash'

const TooltipContainer = styled.div`
  position: relative;
  z-index: 999;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 12px;
  .name {
    color: #616e7c;
  }
  .source {
    font-size: 10px;
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.6);
  }
  .detail {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9aa5b1;
    span:first-child {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 8px;
    }
  }
`

function CustomTooltip(props) {
  const { datum, x, y, setActive, metrics, containerWidth } = props
  useEffect(() => {
    setActive(datum)
    return () => {
      setActive(null)
    }
  }, [])

  if (!y) return null
  let positionX = x
  if (containerWidth && containerWidth - x < 202) {
    positionX = containerWidth - 222
  }
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject
        x={positionX}
        y={y}
        width="222"
        height={metrics.length === 1 ? 100 : 150}
      >
        <TooltipContainer>
          {metrics &&
            metrics.map((item, index) => {
              const data =
                (item.dataSet || item.data).find((d) => d.x === datum.x) || {}
              return (
                <div key={index}>
                  <span className="name">{item.name}</span>
                  {item.metric_key === 'weight' && (
                    <span className="source">
                      ({ConvertWeightSource(datum.source)})
                    </span>
                  )}
                  <div className="detail">
                    <span
                      style={{ background: data.color || item.theme.primary }}
                    />
                    <span>
                      {moment(datum.x).format(
                        // dateRange.formatTooltip ||
                        //   dateRange.formatX ||
                        'MM/DD/YYYY'
                      )}
                    </span>
                    <span style={{ color: data.color || item.theme.primary }}>
                      {tickFormatY(data.value || data.y, item) || '--'}
                    </span>
                    <Growth ratio={data.ratio} up_color={item.up_color} />
                    {/* {data.ratio ? (
                     
                    ) : (
                      <span className="none-place"> none </span>
                    )} */}
                  </div>
                </div>
              )
            })}
        </TooltipContainer>
      </foreignObject>
    </g>
  )
}

export default CustomTooltip

const arrowColors = {
  increase: '#53c45a',
  reduce: '#c45a53',
  neutral: '#9aa5b1'
}

const GrowthContainer = styled.span`
  min-width: 40px;
  display: inline-block;
  padding: 4px 8px;
  margin: 0;
  border-radius: 4px;
  font-size: 12px;
  &.increase {
    color: ${arrowColors.increase};
    background: rgba(83, 196, 90, 0.15);
  }
  &.reduce {
    color: ${arrowColors.reduce};
    background: rgba(196, 103, 83, 0.15);
  }
  &.neutral {
    color: ${arrowColors.neutral};
    background: rgba(235, 237, 239, 0.15);
  }
`
export function Growth(props) {
  const { ratio, up_color, extraStyle } = props
  const getClassName = (ratio) => {
    if (up_color === 'green') {
      return ratio < 0 ? 'reduce' : 'increase'
    } else if (up_color === 'red') {
      return ratio > 0 ? 'reduce' : 'increase'
    } else {
      return 'neutral'
    }
  }
  const containerClass = ratio && getClassName(ratio)

  return (
    <GrowthContainer
      className={ratio ? containerClass : ''}
      style={(ratio && extraStyle) || {}}
    >
      {ratio < 0 && (
        <svg
          width="4"
          height="7"
          viewBox="0 0 4 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.7388 4.54064L4 4.24631L3.4776 3.65763L3.2164 3.95197L3.7388 4.54064ZM2 5.91133L1.7388 6.20571L2 6.5L2.26121 6.20571L2 5.91133ZM0.783612 3.95197L0.52241 3.65763L0 4.24631L0.261209 4.54064L0.783612 3.95197ZM3.2164 3.95197L1.7388 5.61695L2.26121 6.20571L3.7388 4.54064L3.2164 3.95197ZM2.26121 5.61695L0.783612 3.95197L0.261209 4.54064L1.7388 6.20571L2.26121 5.61695ZM2.3694 5.91133L2.3694 0.5L1.63061 0.5L1.63061 5.91133H2.3694Z"
            fill={arrowColors[containerClass]}
          />
        </svg>
      )}
      {ratio > 0 && (
        <svg
          width="4"
          height="7"
          viewBox="0 0 4 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.261202 2.45936L3.27512e-07 2.75369L0.522403 3.34237L0.783605 3.04803L0.261202 2.45936ZM2 1.08867L2.2612 0.794293L2 0.5L1.7388 0.794293L2 1.08867ZM3.21639 3.04803L3.47759 3.34237L4 2.75369L3.73879 2.45936L3.21639 3.04803ZM0.783605 3.04803L2.2612 1.38305L1.7388 0.794293L0.261202 2.45936L0.783605 3.04803ZM1.7388 1.38305L3.21639 3.04803L3.73879 2.45936L2.2612 0.794293L1.7388 1.38305ZM1.6306 1.08867L1.6306 6.5L2.36939 6.5L2.3694 1.08867L1.6306 1.08867Z"
            fill={arrowColors[containerClass]}
          />
        </svg>
      )}{' '}
      {!!ratio && ratio !== 0 && <span> {Math.abs(ratio) + '%'} </span>}
    </GrowthContainer>
  )
}

export function ConvertWeightSource(source) {
  if (source) {
    switch (source) {
      case 'dataset':
        return 'Apeiron Scale'
      default:
        return isFirstCharUpperCase(source) ? source : capitalize(source)
    }
  }
  return 'Manual Entry'
}

function isFirstCharUpperCase(str) {
  const firstChar = _.first(str)
  return firstChar >= 'A' && firstChar <= 'Z'
}
