import React from 'react'
import { ProgressCircle } from 'antd-mobile'
import { AiFillCaretDown } from 'react-icons/ai'
import styled from 'styled-components'
import { FlexBox } from '../../commonComponents/FlexBox'

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 88px;
  position: fixed;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  /* top: ${(props) => (props.up ? 0 : '-88p')}; */
  top: 0;
  z-index: 9999;
  padding: 10px 16px;
  /* transition: top 0.4s ease-in-out; */
  box-sizing: border-box;
  .main-view {
    color: #52606c;
    border-radius: 9px;
    display: flex;
    border: 2px solid #f1f3f6;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    .step-text {
      font-size: 1.14rem;
      font-weight: 600;
    }
  }
`

export const Position = (props) => {
  const { section } = props
  const index = section.index
  return (
    <Container {...props}>
      <div className="main-view">
        <FlexBox alignItems="center">
          <ProgressCircle
            percent={(index / 12) * 100}
            style={{ '--size': '36px', marginRight: '0.75rem' }}
          >
            <span>{index}</span>
          </ProgressCircle>
          <div className="step-text">{section.name}</div>
        </FlexBox>
        <AiFillCaretDown />
      </div>
    </Container>
  )
}
