import React from 'react'
import { ScoreColorGroupStyle } from '../index.styled'
import { RangeColors } from '../../../utils'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel
} from 'victory'
import moment from 'moment'
import _ from 'lodash'

const { red, orange, yellow, green, emerald } = RangeColors

const Tips = {
  muscle: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'Very good',
      color: green
    },
    {
      name: 'Excellent',
      color: emerald
    }
  ],
  movement: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'optimal',
      color: green
    }
  ],
  balance: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'optimal',
      color: green
    }
  ]
}

export const ScoreColorGroup = (props) => {
  return (
    <ScoreColorGroupStyle>
      {Tips[props.domain].map((item, index) => (
        <li key={index}>
          <span style={{ background: item.color }} /> {item.name}
        </li>
      ))}
    </ScoreColorGroupStyle>
  )
}

const arrowColors = {
  increase: '#53c45a',
  reduce: '#c45a53',
  neutral: '#9aa5b1'
}

const GrowthContainer = styled.span`
  min-width: 40px;
  display: inline-block;
  padding: 4px 8px;
  margin: 0;
  border-radius: 4px;
  font-size: 12px;
  &.increase {
    color: ${arrowColors.increase};
    background: rgba(83, 196, 90, 0.1);
  }
  &.reduce {
    color: ${arrowColors.reduce};
    background: rgba(196, 103, 83, 0.1);
  }
  &.neutral {
    color: ${arrowColors.neutral};
    background: rgba(235, 237, 239, 0.1);
  }
`
export function Growth(props) {
  const { ratio, up_color, extraStyle } = props
  const getClassName = (ratio) => {
    if (up_color === 'green') {
      return ratio < 0 ? 'reduce' : 'increase'
    } else if (up_color === 'red') {
      return ratio > 0 ? 'reduce' : 'increase'
    } else {
      return 'neutral'
    }
  }
  const containerClass = ratio && getClassName(ratio)

  return (
    <GrowthContainer
      className={ratio ? containerClass : ''}
      style={(ratio && extraStyle) || {}}
    >
      {ratio < 0 && (
        <svg
          width="4"
          height="7"
          viewBox="0 0 4 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.7388 4.54064L4 4.24631L3.4776 3.65763L3.2164 3.95197L3.7388 4.54064ZM2 5.91133L1.7388 6.20571L2 6.5L2.26121 6.20571L2 5.91133ZM0.783612 3.95197L0.52241 3.65763L0 4.24631L0.261209 4.54064L0.783612 3.95197ZM3.2164 3.95197L1.7388 5.61695L2.26121 6.20571L3.7388 4.54064L3.2164 3.95197ZM2.26121 5.61695L0.783612 3.95197L0.261209 4.54064L1.7388 6.20571L2.26121 5.61695ZM2.3694 5.91133L2.3694 0.5L1.63061 0.5L1.63061 5.91133H2.3694Z"
            fill={arrowColors[containerClass]}
          />
        </svg>
      )}
      {ratio > 0 && (
        <svg
          width="4"
          height="7"
          viewBox="0 0 4 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.261202 2.45936L3.27512e-07 2.75369L0.522403 3.34237L0.783605 3.04803L0.261202 2.45936ZM2 1.08867L2.2612 0.794293L2 0.5L1.7388 0.794293L2 1.08867ZM3.21639 3.04803L3.47759 3.34237L4 2.75369L3.73879 2.45936L3.21639 3.04803ZM0.783605 3.04803L2.2612 1.38305L1.7388 0.794293L0.261202 2.45936L0.783605 3.04803ZM1.7388 1.38305L3.21639 3.04803L3.73879 2.45936L2.2612 0.794293L1.7388 1.38305ZM1.6306 1.08867L1.6306 6.5L2.36939 6.5L2.3694 1.08867L1.6306 1.08867Z"
            fill={arrowColors[containerClass]}
          />
        </svg>
      )}{' '}
      {!!ratio && ratio !== 0 && <span> {Math.abs(ratio) + '%'} </span>}
    </GrowthContainer>
  )
}

const axisStyle = {
  axis: { stroke: 'transparent' },
  grid: {
    stroke: 'transparent'
  },
  tickLabels: {
    fill: 'transparent'
  }
}

export function HistoryChart(props) {
  const { series, unit } = props
  const data = series
    .map((item, index) => {
      if (item && item.test_date) {
        return { x: index, y: item.value, date: item.test_date }
      } else {
        return { x: index, y: item }
      }
    })
    .filter((item) => _.isNumber(item.y))
  const values = data.map((item) => item.y) //.filter((item) => item)
  const minY = Math.min(...values),
    maxY = Math.max(...values)
  const tickValuesY =
    _.isFinite(minY) && _.isFinite(maxY) ? [minY - 10, maxY + 10] : []
  return (
    <VictoryChart
      width={230}
      height={70}
      padding={{
        top: 5,
        bottom: 25,
        left: 50,
        right: 50
      }}
    >
      <VictoryAxis
        // invertAxis={true}
        dependentAxis
        style={axisStyle}
        tickValues={tickValuesY}
      />
      <VictoryAxis style={axisStyle} />
      <VictoryLine
        data={data}
        style={{ data: { stroke: '#40C47C', strokeWidth: 3 } }}
      />
      <VictoryScatter
        data={data}
        dataComponent={<Dot />}
        labels={({ datum }) =>
          _.isNumber(datum.y) && [
            datum.y + unit,
            datum.date && moment(datum.date).format('M/D/YY')
          ]
        }
        labelComponent={
          <VictoryLabel
            dy={30}
            backgroundPadding={5}
            lineHeight={[1.4, 1.2]}
            style={[
              {
                fill: '#4C6072',
                fontSize: 10,
                fontWeight: 600,
                fontFamily: 'Gilroy'
              },
              { fill: '#4C6072', fontSize: 10, fontFamily: 'Gilroy' }
            ]}
          />
        }
      />
    </VictoryChart>
  )
}

function Dot(props) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="5"
        cy="5"
        r="3.5"
        fill="white"
        stroke="#40C47C"
        strokeWidth="3"
      />
    </svg>
  )
}

export const AerobicTestTypeCycleIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 12, y: y - 12 }}
    >
      <g clipPath="url(#clip0_2784_2966)">
        <path
          d="M23 12C23 5.37258 17.8513 0 11.5 0C5.14873 0 0 5.37258 0 12C0 18.6274 5.14873 24 11.5 24C17.8513 24 23 18.6274 23 12Z"
          fill="#2B4B8E"
        />
        <g clipPath="url(#clip1_2784_2966)">
          <path
            d="M7.85833 17.2008C9.02273 17.2008 9.96667 16.2158 9.96667 15.0008C9.96667 13.7858 9.02273 12.8008 7.85833 12.8008C6.69393 12.8008 5.75 13.7858 5.75 15.0008C5.75 16.2158 6.69393 17.2008 7.85833 17.2008Z"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M15.1435 17.2008C16.3079 17.2008 17.2518 16.2158 17.2518 15.0008C17.2518 13.7858 16.3079 12.8008 15.1435 12.8008C13.9791 12.8008 13.0352 13.7858 13.0352 15.0008C13.0352 16.2158 13.9791 17.2008 15.1435 17.2008Z"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M13.6029 8C14.1321 8 14.5612 7.55228 14.5612 7C14.5612 6.44772 14.1321 6 13.6029 6C13.0736 6 12.6445 6.44772 12.6445 7C12.6445 7.55228 13.0736 8 13.6029 8Z"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M11.4957 16.0004V13.2004L9.91332 11.8804C9.87123 11.8453 9.83672 11.8013 9.81204 11.7512C9.78737 11.7012 9.77308 11.6463 9.77011 11.5902C9.76715 11.534 9.77557 11.4778 9.79483 11.4253C9.81409 11.3727 9.84375 11.325 9.88188 11.2852L11.9595 9.11724C11.9977 9.07745 12.0434 9.04649 12.0937 9.02639C12.1441 9.0063 12.1979 8.99751 12.2518 9.00061C12.3056 9.0037 12.3582 9.01861 12.4061 9.04436C12.4541 9.07011 12.4963 9.10612 12.5299 9.15004L13.7957 10.8004H15.329"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2784_2966">
          <rect width="23" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_2784_2966">
          <rect
            width="12.2667"
            height="12.8"
            fill="white"
            transform="translate(5.36719 5.19922)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AerobicTestTypeTreadmillIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 12, y: y - 12 }}
    >
      <g clipPath="url(#clip0_2784_2974)">
        <path
          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
          fill="#2B4B8E"
        />
        <g clipPath="url(#clip1_2784_2974)">
          <path
            d="M12.4023 8.66485L15.3335 7.68765C15.5102 7.62878 15.6707 7.52964 15.8023 7.39805L17.2023 5.99805"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M15.5938 7.5625L16.8002 13.9977"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
          />
          <path
            d="M14.0664 8.10938L15.1948 14.1274"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
          />
          <path
            d="M16.7999 16.398C17.1181 16.398 17.4234 16.2716 17.6484 16.0466C17.8734 15.8215 17.9999 15.5163 17.9999 15.198C17.9999 14.8798 17.8734 14.5746 17.6484 14.3495C17.4234 14.1245 17.1181 13.998 16.7999 13.998L6.70707 14.8072C6.50448 14.8305 6.31847 14.9304 6.18723 15.0865C6.05599 15.2426 5.98948 15.4429 6.00136 15.6465C6.01323 15.8501 6.10259 16.0414 6.25109 16.1812C6.39958 16.3209 6.59595 16.3985 6.79987 16.398H16.7999Z"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M7.59766 17.1984V16.3984"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M16 17.1984V16.3984"
            stroke="white"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2784_2974">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_2784_2974">
          <rect
            width="12.8"
            height="12.8"
            fill="white"
            transform="translate(5.59766 5.19922)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AerobicTestTypeOtherIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 12, y: y - 12 }}
    >
      <g clipPath="url(#clip0_2781_2949)">
        <path
          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
          fill="#2B4B8E"
        />
        <path
          d="M13.875 9.5L7.625 9.51188C7.28 9.51188 7 9.23188 7 8.88688C7 8.54188 7.28 8.26188 7.625 8.26188L13.875 8.25V9.5Z"
          fill="white"
        />
        <path
          d="M16.375 12.625H10.125V11.375H16.375C16.72 11.375 17 11.655 17 12C17 12.345 16.72 12.625 16.375 12.625Z"
          fill="white"
        />
        <path
          d="M16.375 9.51188L15.125 9.5V8.25L16.375 8.26188C16.72 8.26188 17 8.54188 17 8.88688C17 9.2325 16.72 9.51188 16.375 9.51188Z"
          fill="white"
        />
        <path
          d="M13.875 15.75H7.625C7.28 15.75 7 15.47 7 15.125C7 14.78 7.28 14.5 7.625 14.5H13.875V15.75Z"
          fill="white"
        />
        <path
          d="M16.375 15.75H15.125V14.5H16.375C16.72 14.5 17 14.78 17 15.125C17 15.47 16.72 15.75 16.375 15.75Z"
          fill="white"
        />
        <path
          d="M8.875 12.625H7.625C7.28 12.625 7 12.345 7 12C7 11.655 7.28 11.375 7.625 11.375H8.875V12.625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2781_2949">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
