import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Toast } from 'antd-mobile'
import LockStatus from '../../commonComponents/lockStatus'
import Empty from '../../commonComponents/empty'
import ScoreChart from './components/chart'
import ScoreSection from './components/section'
import { getMeByHash, getNutritionScore } from '../../../api'
import { userType } from '../../../utils'

const Container = styled.div`
  min-height: 100vh;
`

export default function NutritionScore(props) {
  const { hash } = useParams()
  const [data, setData] = useState(null)
  const [person, setPerson] = useState(null)
  const [targetScore, setTargetScore] = useState(null)
  useEffect(() => {
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      const { person } = await getMeByHash(hash)
      setPerson(person)
      if (person.profile.account_type !== userType.Partners) {
        let score = await getNutritionScore(person.id, hash)
        score = score.reverse()
        const latestDate = score[0].completion_date || score[0].date
        score = score.filter((item) => {
          return item.is_complete
        })
        setData({
          score: score.length > 8 ? score.slice(0, 8) : score,
          latestDate
        })
      }
      loading.close()
    }
    initial()
  }, [hash])

  if (person) {
    const isPartner = person.profile.account_type === userType.Partners
    if (isPartner) {
      return <LockStatus />
    }
  }
  const { score } = data || {}
  const isEmpty =
    score &&
    (score.length === 0 ||
      (score.length === 1 &&
        !score[0].answers.find((answer) => answer.percentage_score)))
  return (
    <Container>
      {isEmpty ? (
        <Empty
          message={
            <span sytle={{ fontFamily: 'Heebo' }}>
              Your nutrition report will appear here when you <br /> complete
              the{' '}
              <b style={{ color: '#383E48', fontWeight: 700 }}>Questionnaire</b>
              .
            </span>
          }
        />
      ) : (
        <>
          {data && (
            <ScoreChart
              {...data}
              setTargetScore={setTargetScore}
              targetScore={targetScore}
            />
          )}
          {data && <ScoreSection {...data} targetScore={targetScore} />}
        </>
      )}
    </Container>
  )
}
