import { get, concat } from 'lodash'
import React from 'react'
import {
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryPolarAxis,
  VictoryScatter
} from 'victory'

const OFFSET_SORCE_COMPARE_Y = [-20, -7, -5, 25, 25, 25, -5, -7]
const SCORE_X = [0, -10, -10, 0, 0, 10, 10, 10]
const LABEL_SCORE_ANCHOR = [
  'middle',
  'end',
  'end',
  'end',
  'middle',
  'start',
  'start',
  'start'
]

export const HSDSpiderGraph = (props) => {
  const domains = [
    {
      key: 'balance',
      name: ['BALANCE'],
      lineHeight: [1]
    },
    {
      key: 'joint_fitness',
      name: ['MOVEMENT QUALITY'],
      lineHeight: [1]
    },
    {
      key: 'muscular_fitness',
      name: ['MUSCLE FITNESS'],
      lineHeight: [1]
    },
    {
      key: 'body_composition',
      name: ['BODY', 'COMPOSITION'],
      lineHeight: [1, 1]
    },
    {
      key: 'bone_health',
      name: ['BONE DENSITY'],
      lineHeight: [1]
    },

    {
      key: 'aerobic_fitness',
      name: ['AEROBIC FITNESS'],
      lineHeight: [1]
    },
    {
      key: 'cognitive_health',
      name: ['COGNITIVE', 'HEALTH'],
      lineHeight: [1, 1]
    },
    {
      key: 'cardiometabolic',
      name: ['CARDIOMETABOLIC'],
      lineHeight: [1]
    }
  ]
  const data = domains.map((domain, index) => {
    const targetDomain = props.dataList[index]
    const score =
      get(targetDomain, 'score_manual.value') || get(targetDomain, 'score_auto')
    const config = score && HSDScoreConfig(score)
    return {
      x: index,
      y: score || 0,
      score: score,
      color: config ? config.color : 'rgba(77, 77, 77, 1)',
      text: concat(
        domain.name,
        config ? [`${score}`, config.text] : ['--', '--']
      ),
      lineHeight: concat(domain.lineHeight, config ? [1.5, 1] : [1.2])
    }
  })

  const textStyle = {
    fontSize: 15,
    fontFamily: 'Gilroy',
    fontWeight: 600
  }

  const commonTextStyle = {
    fill: (t) => {
      return data[t.index].color
    },
    ...textStyle
  }

  return (
    <VictoryChart
      polar
      domain={{ y: [0, 100] }}
      startAngle={90}
      endAngle={450}
      padding={{ top: 60, bottom: 60, left: 0, right: 0 }}
    >
      <VictoryPolarAxis
        name="graph-score"
        labelPlacement="vertical"
        tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
        tickLabelComponent={
          <VictoryLabel
            dx={(t) => SCORE_X[t.index]}
            dy={(y) => OFFSET_SORCE_COMPARE_Y[y.index || 0]}
            textAnchor={(t) => LABEL_SCORE_ANCHOR[t.index]}
            text={({ index }) => data[index].text}
            lineHeight={({ index }) => data[index].lineHeight}
            style={[
              {
                fill: (t) => {
                  return 'rgba(76, 96, 114, 0.6)'
                },
                ...textStyle
              },
              {
                fill: (t) => {
                  return t.text.length > 3
                    ? 'rgba(76, 96, 114, 0.6)'
                    : data[t.index].color
                },
                ...textStyle
              },
              { ...commonTextStyle },
              { ...commonTextStyle }
            ]}
          />
        }
        style={{
          axis: {
            fill: 'rgba(205, 208, 214, 0.2)' // background
          },
          grid: {
            stroke: '#B1B8BD'
          }
        }}
      />
      <VictoryPolarAxis
        dependentAxis
        tickFormat={(t) => null}
        style={{
          grid: {
            stroke: (t) => (t === 100 ? '#0D2350' : '#B1B8BD')
          },
          axis: {
            stroke: 'transparent'
          }
        }}
      />
      <VictoryArea
        style={{ data: { fill: 'rgba(180, 232, 195, 0.2)' } }}
        data={data}
      />

      <VictoryLine
        data={data}
        style={{
          data: {
            stroke: '#7FB800',
            strokeWidth: 1.5
          }
        }}
      />
      <VictoryScatter
        data={data.filter((i) => i.score)}
        style={{
          data: {
            stroke: ({ datum }) => datum.color,
            strokeWidth: 2,
            fill: 'white'
          }
        }}
      />
    </VictoryChart>
  )
}

export function HSDScoreConfig(score) {
  if (score >= 90) {
    return {
      color: '#40C49C',
      text: 'Exceptional'
    }
  } else if (score >= 75) {
    return {
      color: '#40C47C',
      text: 'Exceptional'
    }
  } else if (score >= 60) {
    return {
      color: '#7BAD2D',
      text: 'Very Good'
    }
  } else if (score >= 40) {
    return {
      color: '#FDB51C',
      text: 'Average'
    }
  } else if (score >= 26) {
    return {
      color: '#FC5108',
      text: 'Below Average'
    }
  } else {
    return {
      color: '#D5001C',
      text: 'Needs Attention'
    }
  }
}
