import React, { useState, useEffect } from 'react'
import { PasscodeInput, Button, DotLoading } from 'antd-mobile'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { enable2FA, verify2FA } from '../../api'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import phoneIcon from '../../asserts/icons/2fa/phone.svg'

export default function Verify2FA(props) {
  const { person, phone } = useOutletContext()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isResend, setIsResend] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (phone) {
      const sendCode = async () => {
        await enable2FA({ profile: { phone_number: phone } }, person.id)
      }
      sendCode()
    }
  }, [phone, person])

  const navigate = useNavigate()
  return (
    <div className="main-container">
      <div className='back' onClick={() => navigate(-1)}>
      <MdOutlineArrowBackIosNew/> BACK</div>
      <div className='verify-container'>
        <img src={phoneIcon} alt="background" />
        <p className="lighter-text upper">2 FACTOR AUTHENTICATION</p>
        <h2>Enter a verification code</h2>
        <p className="center-text">
          A text message with a verification code was just sent to (***){' '}
          {phone && phone.slice(0, phone.length - 2).replace(/./gi, '*')}
          {phone && phone.slice(phone.length - 2)}
        </p>

        <div className={`passcodeInput ${!!errorMessage && 'error-code'}`}>
          <PasscodeInput
            seperated
            length={4}
            onChange={onChange}
          />
          {errorMessage && <p>{errorMessage}</p>}
        </div>

        <Button
              block
              type="button"
              color="primary"
              size="large"
              className='continue'
              loading={loading}
              onClick={onFill}
            >
              Continue
            </Button>

        {!errorMessage && <p>Haven’t received the text message?</p>}

        <Button color="primary" className='resend' fill="none" onClick={resend}>
          {isResend ? <DotLoading /> : 'Resend'}
        </Button>
      </div>
    </div>
  )

  function onChange(value) {
    if (errorMessage) {
      setErrorMessage(null)
    }
  }

  async function resend() {
    if (isResend) return
    setIsResend(true)
    await enable2FA({ profile: { phone_number: phone } }, person.id)
    setIsResend(false)
  }

  async function onFill(code) {
    setLoading(true)
    try {
      await verify2FA({ code }, person.id)
      navigate('/2fa/done')
    } catch (err) {
      console.error(err)
      setErrorMessage('The code is invalid. Try again')
    } finally {
      setLoading(false)
    }
  }
}
