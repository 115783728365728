import React, { useCallback, useState } from 'react'
import DayActivity, {
  ActivityAnswerKeys,
  NO_ACTIVITY_VALUE
} from './DayActivity'
import { Container } from './index.styled'
import { every, keys, some } from 'lodash'

const DefaultActivity = {
  [ActivityAnswerKeys.SESSION]: NO_ACTIVITY_VALUE,
  [ActivityAnswerKeys.DURATION]: null,
  [ActivityAnswerKeys.INTENSITY]: null
}

export default function WeeklyActivities(props) {
  const { answer, question, update, setConfirmBtnDisabled } = props

  const [answerInfo, setAnswerInfo] = useState(answer.answer)

  const checkAnswer = useCallback((answerInfo) => {
    let enabled = true
    for (const day in answerInfo) {
      for (const activityAnswers of answerInfo[day].filter(
        (answer) => answer
      )) {
        const noValue = !some(activityAnswers, (v) => v)
        const hasVaildValue =
          activityAnswers[ActivityAnswerKeys.SESSION] === NO_ACTIVITY_VALUE ||
          (keys(activityAnswers).length === keys(ActivityAnswerKeys).length &&
            every(activityAnswers, (v) => v))
        enabled = noValue || hasVaildValue
        if (!enabled) {
          break
        }
      }
      if (!enabled) {
        break
      }
    }
    return enabled
  }, [])

  // const getAnswerParams = useCallback(
  //   (answerInfo) => {
  //     const answerParams = {}
  //     for (const subQuestion of question.sub_questions) {
  //       const { original_id } = subQuestion
  //       const dayAnswers = !answerInfo[original_id]
  //         ? [DefaultActivity]
  //         : answerInfo[original_id]
  //             .filter((answer) => answer)
  //             .map((answer) => {
  //               if (answerInfo[ActivityAnswerKeys.SESSION] === NO_ACTIVITY_VALUE) {
  //                 return DefaultActivity
  //               }
  //               return answer
  //             })
  //       answerParams[original_id] = dayAnswers
  //     }
  //     return answerParams
  //     // const params = {
  //     //   answer: answerParams,
  //     //   question_original_id: question.original_id
  //     //   // session_id
  //     // }
  //     // return params
  //   },
  //   [question]
  // )

  const getUpdatedAnswerParams = useCallback((answerInfo) => {
    const params = {}
    for (const key in answerInfo) {
      params[key] = answerInfo[key]
        .filter((answer) => answer)
        .map((answer) => {
          if (answerInfo[ActivityAnswerKeys.SESSION] === NO_ACTIVITY_VALUE) {
            return DefaultActivity
          }
          return answer
        })
    }
    return params
  }, [])

  const handleUpdateActivityAnswer = useCallback(
    (value) => {
      const newAnswer = {
        ...answerInfo,
        ...value
      }
      setAnswerInfo(newAnswer)
      const verified = checkAnswer(newAnswer)
      setConfirmBtnDisabled(!verified)
      if (verified) {
        const params = getUpdatedAnswerParams(newAnswer)
        update(
          {
            ...answer,
            answer: params
          },
          question
        )
      }
    },
    [
      answer,
      answerInfo,
      checkAnswer,
      getUpdatedAnswerParams,
      question,
      setConfirmBtnDisabled,
      update
    ]
  )

  return (
    <Container>
      {/* <div className='title'>{question.question}</div> */}
      {question.sub_questions.map((item, index) => (
        <DayActivity
          key={index}
          className="activity-item"
          index={index + 1}
          title={item.question}
          question={item}
          answer={answerInfo[item.original_id] || []}
          onUpdateAnswer={handleUpdateActivityAnswer}
        />
      ))}
    </Container>
  )
}
