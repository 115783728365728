import React from 'react'
import { Divider } from 'antd-mobile'
import styled from 'styled-components'

const Container = styled.div`
  .section-title {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
  }
  .adm-divider{
    background-color: #E6E9F2;
  }
  .name {
    font-weight: 700;
    font-size: 16px;
    color: #323F4A;
    font-family: 'Lato';
  }
  .description {
    font-size: 14px;
    color: #38435e;
    white-space: pre-wrap;
  }
  .extra-info.list {
    display: none;
    width: 50%;
    align-items: flex-end;
    ul {
      margin: 0;
      padding: 0;
      font-size: 12px;
      color: #52606c;
      font-family: 'Gilroy';
      list-style: none;
      display: flex;
      justify-content: space-between;
    }
  }
`

export default function SectionTitle(props) {
  const {
    question: { question, description, extra_info }
  } = props
  return (
    <Container>
      <div className="section-title">
        <div>
          <div className="name">{question}</div>
          <div className="description">{description}</div>
        </div>
        {extra_info && (
          <div className={`extra-info ${extra_info.type}`}>
            {extra_info.type === 'list' && (
              <ul className="list">
                {extra_info.content.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
      <Divider />
    </Container>
  )
}
