import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BGImage from '../../../../asserts/images/nutrition-score-bg.png'
import TimeLineChart from './TimelineChart'
import { last } from 'lodash'
import moment from 'moment'

const Container = styled.div`
  position: relative;
  letter-spacing: 0.3px;
  overflow: hidden;
  .background {
    position: absolute;
    width: 100%;
  }
  & > div {
    background: linear-gradient(
      180deg,
      rgba(11, 28, 51, 0.85) 0%,
      #0b1c33 100%
    );
    position: relative;
    z-index: 1;
  }
  .total-score {
    padding: 0;
    padding-top: 24px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    .value {
      line-height: 1.2;
      font-family: 'Gilroy-Bold';
      font-size: 64px;
      color: #40c47c;
      span {
        font-size: 20px;
        vertical-align: super;
      }
    }
  }
  .chart-container {
    position: relative;
    display: inline-block;
    width: calc(50% + 100px);
    margin-left: -50px;
    margin-top: -50px;
  }
  .label-list {
    position: absolute;
    right: -103px;
    top: -8px;
    margin-top: 65px;
    .label-item {
      position: relative;
      margin: 24px 0;
      padding: 6px 8px;
      width: 113px;
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      border-radius: 2px;
      background: #38414e;
      color: #f4f6fa;
      &:before {
        content: '';
        display: inline-block;
        border-color: transparent #38414e transparent transparent;
        border-width: 6px;
        border-style: solid;
        height: 8px;
        margin-left: -20px;
        margin-top: 4px;
      }
      & > div {
        position: absolute;
        top: 8px;
        width: 100px;
        height: 26px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

export default function TimelineView(props) {
  const { totals } = props
  const [showScore, setShowScore] = useState(last(props.totals) || {})

  useEffect(() => {
    setShowScore(last(props.totals))
  }, [totals])

  const getLabelColor = (record) => {
    if (record >= 80) {
      return '#40C47C'
    } else if (record >= 50) {
      return '#F5BA40'
    } else {
      return '#FF6B00'
    }
  }

  const selectScore = (targetScore) => {
    if (props.onChange) {
      props.onChange(targetScore)
    }
    setShowScore(targetScore)
  }

  return (
    <Container>
      <img src={BGImage} alt="bg" className="background" />
      <div
        style={{
          minHeight: 200
        }}
      >
        {showScore && totals.length > 0 && (
          <>
            <div className="total-score">
              <div>Total Score</div>
              <div
                className="value"
                style={{
                  color: getLabelColor(showScore.total_score)
                }}
              >
                {showScore.total_score}
              </div>
              <div
                style={{
                  color: 'rgba(255, 255, 255, 0.6)'
                }}
              >
                {moment(showScore.date).format('DD MMM, YYYY')}
              </div>
            </div>

            <div className="chart-container">
              <TimeLineChart
                totals={props.totals}
                getLabelColor={getLabelColor}
                selectScore={selectScore}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
