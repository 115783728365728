import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { Toast } from 'antd-mobile'
import { healthCardList } from '../../../utils'

import UserInfo from './components/userInfo'
import Graph from './components/graph'
import HealthyCard from './components/healthCard'

import { getHealthStatus, getMeByHash } from '../../../api'

const Container = styled.div`
  background: #ffffff;
  .compare-labels {
    text-align: center;
    margin-bottom: 2.5em;
    margin-top: 0.625em;
    text-transform: uppercase;
    div {
      color: #5a657c;
      padding: 0.33em 1.25em;
      min-width: 7em;
      display: inline-block;
      border-radius: 19px;
      border: 1px solid rgba(33, 57, 89, 0.2);
      font-size: 0.75em;
      line-height: 130%;
      letter-spacing: 0.5px;
      margin: 0.31em;
      svg {
        margin-right: 0.375em;
      }
    }
  }
  .current:before {
    background: #4c6072;
  }
  .compare:before {
    background: #9ca1b4;
  }
`

export default function HealthScore(props) {
  const { hash } = useParams()
  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.payload) {
        return _.assign({}, state, action.payload)
      }
      return state
    },
    {
      data: null,
      person: null
    }
  )
  useEffect(() => {
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      const { person } = await getMeByHash(hash)
      // setPerson(person)
      dispatch({ type: 'update', payload: { person } })
      const { results, latest_bmi } = await getHealthStatus(person.id, hash)
      if (results && results[0]) {
        // setData()
        dispatch({
          type: 'update',
          payload: {
            data: {
              latest: results.filter((item) => item.graph_type === 'latest')[0],
              previous: results.filter(
                (item) => item.graph_type === 'previous'
              )[0],
              latest_bmi: latest_bmi
            }
          }
        })
      }
      loading.close()
    }
    initial()
  }, [hash])

  const { person, data } = state
  return (
    (person && data && (
      <Container>
        <UserInfo person={person} bmi={data && data.latest_bmi} />
        {renderData()}
      </Container>
    )) || <></>
  )

  function renderData() {
    const { latest, previous } = data
    const { snapshot } = latest
    const dataList = getDataList(snapshot, 'latest')
    let compareDataList
    if (previous) {
      compareDataList = getDataList(previous.snapshot, 'previous')
    }
    return (
      <>
        <Graph compareData={compareDataList} dataList={dataList} />
        <div className="compare-labels">
          <div>
            <Circle fillOpacity={0.2} fill="#7FB800" stroke="#7FB800" /> Latest
          </div>
          <div>
            <Circle
              fillOpacity={0.2}
              dasharray
              fill="#7FB800"
              stroke="#7FB800"
            />
            Estimate
          </div>
          <div>
            <Circle fillOpacity={0.2} fill="#0D2350" stroke="#0D2350" />{' '}
            Previous
          </div>
          <div>
            <Circle fillOpacity={1} fill="white" stroke="#0D2350" />
            carried forward
          </div>
        </div>
        <div>
          {dataList.map((item, index) => (
            <HealthyCard
              key={index}
              {...item}
              compare={compareDataList && compareDataList[index]}
            />
          ))}
        </div>
      </>
    )
  }

  function getDataList(snapshot, graph_type) {
    return healthCardList.map((item) => {
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      return {
        ...item,
        ...detail
      }
    })
  }
}

function Circle(props) {
  const { fillOpacity, fill, stroke, dasharray } = props
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={stroke}
        strokeWidth="2"
        strokeDasharray={dasharray ? '2,2' : null}
      />
    </svg>
  )
}
