import React from 'react'

export const AOFIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14Z" />
    <path d="M8 4C5.8 4 4 5.8 4 8C4 10.2 5.8 12 8 12C10.2 12 12 10.2 12 8C12 5.8 10.2 4 8 4ZM8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10Z" />
  </svg>
)

export const LTAIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0V4C0 6.06548 1.60369 8 4.14142 8C4.48578 9.32947 5.49066 10.3786 6.79016 10.7935C6.612 11.7306 6.28198 12.9044 5.66724 14H4V16H12V14H10.3328C9.71802 12.9044 9.388 11.7306 9.20984 10.7935C10.5093 10.3786 11.5142 9.32947 11.8586 8C14.3879 8 16 6.07178 16 4V0H0ZM2 4V2H4V6C2.89746 6 2 5.10303 2 4ZM14 4C14 5.10303 13.1025 6 12 6V2H14V4Z" />
  </svg>
)

export const AvatarIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12.1 12.3C11.7 11.6 10.9 11.3 10 11C9.3 10.8 9.1 10.4 9 9.8C10.1 9.4 11 8.3 11 7C11 5.3 9.7 4 8 4C6.3 4 5 5.3 5 7C5 8.3 5.8 9.4 7 9.8C6.9 10.4 6.7 10.8 6 11C5.1 11.3 4.3 11.6 3.9 12.3C2.7 11.2 2 9.7 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.7 13.3 11.2 12.1 12.3Z" />
  </svg>
)

export const GreenCheck = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#7BAD2D" />
    <path
      d="M5.33337 8L7.33337 10L10.6667 6"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
