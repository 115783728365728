import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Toast, Dropdown } from 'antd-mobile'
import TodayTag from './components/todayTag'
import styled from 'styled-components'
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom'
import DateRange from './components/dateRange'
import moment from 'moment/moment'
import {
  getShowWeeklyDate,
  prevWeekDate,
  nextWeekDate,
  getThisWeekRange
} from '../../../utils/dateHandles'
import { getInterpolateColor } from '../../../utils/canvasTool'
import {
  getHealthySignals,
  getHealthySignalsSettings,
  getMeByHash,
  getHealthySignalsPercentageTarget
} from '../../../api'
import _ from 'lodash'
import HealthySignalDetail from './detail'
import HealthySignalCompact from './compact'
import NativeHSD from './native'

const Container = styled.div`
  .default-gradient-line {
    height: 0;
    overflow: hidden;
  }
  .date-type-container {
    background: #ffffff;
    padding: 0 1em;
    height: 4.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      width: 40%;
      text-align: center;
    }
  }

  font-size: 1rem;

  @media screen and (min-width: 481px) {
    font-size: 1.25rem;
  }
`

const initialState = {}

function HealthySignalLayout(props) {
  const { hash } = useParams()
  const [searchParams] = useSearchParams()
  const [rangeType, setRangeType] = useState('week')
  const [pageLoading, setPageLoading] = useState(false)
  const [settings, setSettings] = useState([])
  const [targets, setTargets] = useState({})
  const [state, dispatch] = useReducer(reducer, initialState)

  const { dateInfo, data, progress, progressText } = state

  const navigate = useNavigate()
  const location = useLocation()

  const ref = useRef()

  useEffect(() => {
    let from, to

    if (searchParams.has('from') && searchParams.has('to')) {
      from = searchParams.get('from')
      to = searchParams.get('to')
    }
    dispatch({
      type: 'initial',
      payload: { from: moment(from), to: moment(to) }
    })
  }, [searchParams])

  const cardList = settings.map((item) => {
    return (
      item && {
        ...item,
        data: (data || {})[item.domain],
        target: targets && targets[item.domain]
      }
    )
  })

  return (
    <Container ref={ref}>
      <div className="date-type-container">
        {location.pathname.includes('healthy-signal-detail') ? (
          <div></div>
        ) : (
          <DateRangeType
            type={rangeType}
            onTypeChange={switchType}
            parentContainer={ref.current}
          />
        )}
        <TodayTag />
      </div>
      {dateInfo && (
        <DateRange
          prevDate={() => !pageLoading && prevDate()}
          nextDate={() => !pageLoading && nextDate()}
          showDate={dateInfo && getShowWeeklyDate(dateInfo.from, dateInfo.to)}
        />
      )}
      <Outlet
        context={{
          rangeType,
          data,
          dateInfo,
          cardList,
          progress,
          progressText,
          jumpTo: (path) => navigate(`${path}/${hash}`)
        }}
      />
    </Container>
  )

  async function switchType(type) {
    await setRangeType(type)
    // if (type === 'week') {
    //   const { startDate: from, endDate: to } = getLatest7Days()
    //   await fetchData(from, to, type)
    // } else {
    //   const { startDate: from, endDate: to } = getThisMonthRange()
    //   await fetchData(from, to, type)
    // }
  }

  async function initial(from, to) {
    if (!(from && to)) {
      const { startDate, endDate } = getThisWeekRange()
      from = startDate
      to = endDate
    }

    const { settings } = await getHealthySignalsSettings(hash)
    const { person } = await getMeByHash(hash)
    const result = await getHealthySignalsPercentageTarget(
      person.id,
      moment(from).format('YYYY-MM-DD'),
      hash
    )
    const targets = {}
    _(result).forEach((item) => {
      targets[item.domain] = item.target
    })
    setTargets(targets)

    setSettings(settings)
    const payload = await fetchData(from, to, null, true)
    dispatch({ type: 'update', payload })
  }

  function reducer(state, action) {
    if (action.type === 'initial') {
      const { from, to } = action.payload
      initial(from, to)

      return state // _.assign({}, state, action.payload)
    }
    if (pageLoading) {
      setPageLoading(false)
    }

    return _.assign({}, state, action.payload || {})
  }
  async function fetchData(from, to, type, isInitial) {
    const requestType = 'week' //type || rangeType
    setPageLoading(true)
    const loading = Toast.show({
      icon: 'loading',
      content: 'loading...'
    })
    const result = await getHealthySignals({
      rangeType: requestType,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      hash: hash
    })
    const payload = {
      dateInfo: { from, to },
      data: requestType === 'week' ? result.week_data : result.month_data,
      progress: result.progress,
      progressText: result.progress_text
    }
    if (isInitial) {
      return payload
    } else {
      dispatch({ type: 'update', payload })
    }
    loading.close()
  }

  async function prevDate() {
    const { startDate: from, endDate: to } = prevWeekDate(
      dateInfo.from,
      dateInfo.to
    )
    await fetchData(from, to)
  }

  async function nextDate() {
    const { startDate: from, endDate: to } = nextWeekDate(
      dateInfo.from,
      dateInfo.to
    )
    await fetchData(from, to)
  }
}

const RangeTypeContainer = styled.div`
  .adm-dropdown {
    width: 100%;
    font-size: 0.875rem;
    border: 1px solid #dee2ec;
    border-radius: 0.75rem;
    padding: 0.25rem 1rem;
    color: #52606c;
    .adm-dropdown-item-title {
      padding: 0;
      justify-content: space-between;
      width: 100%;
    }
    .adm-dropdown-item .adm-dropdown-item-title-arrow {
      color: inherit;
    }
  }
`

function DateRangeType(props) {
  const { type, onTypeChange } = props
  const ref = useRef()
  return (
    <RangeTypeContainer>
      <Dropdown ref={ref}>
        <Dropdown.Item
          key="sorter"
          title={type === 'week' ? 'WEEK' : '3-WEEK AVG'}
        >
          <div
            onClick={() => {
              onTypeChange('week')
              ref.current.close()
            }}
          >
            WEEK
          </div>
          <div
            onClick={() => {
              onTypeChange('avg')
              ref.current.close()
            }}
          >
            3-WEEK AVG
          </div>
        </Dropdown.Item>
      </Dropdown>
    </RangeTypeContainer>
  )
}

const getGradientRanges = (target) => {
  const gray = '#BBCCDE',
    blue = '#2F8CE9',
    green = '#A7C22A',
    yellow = '#FFC402'
  if (target <= 100) {
    return [
      { color: gray, offset: 0 },
      { color: blue, offset: 1 }
    ]
  } else if (target <= 200) {
    return [
      { color: blue, offset: 0 },
      { color: green, offset: 1 }
    ]
  } else {
    return [
      { color: blue, offset: 0 },
      { color: green, offset: 0.7098 },
      { color: yellow, offset: 1 }
    ]
  }
}

function getColor(value, hightestValue) {
  if (!hightestValue) return
  // const colors = getGradientRanges(Math.round(hightestValue / 1.25))
  const colors = getGradientRanges(Math.round(hightestValue))
  const ratio = value / hightestValue
  const { color } = getInterpolateColor(colors, ratio === 1 ? 0.99 : ratio || 0)
  return color
}

export {
  HealthySignalLayout,
  HealthySignalDetail,
  HealthySignalCompact,
  NativeHSD,
  getColor
}
