import moment from 'moment'

export function getThisWeekRange(targetDate) {
  let startDate = moment(),
    endDate
  if (targetDate) {
    startDate = moment(targetDate)
  }
  // if today is Sunday
  if (startDate.format('dddd') === 'Sunday') {
    startDate = startDate.subtract(1, 'days').day('Monday')
  } else {
    startDate = startDate.day('Monday')
  }
  endDate = startDate.clone().day(7)

  return { startDate, endDate }
}

export function getThisMonthRange() {
  return {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  }
}

export function getShowWeeklyDate(startDate, endDate) {
  let showDate = `${startDate.format('MMM D')} - ${endDate.format(
    'MMM D, YYYY'
  )} `
  let monday = moment().day('Monday')
  if (moment().format('dddd') === 'Sunday') {
    // if today is Sunday
    monday = moment().subtract(1, 'days').day('Monday')
  }
  if (
    moment(startDate.format('YYYY-MM-DD')).isSame(monday.format('YYYY-MM-DD'))
  ) {
    showDate = 'This week'
  } else if (
    moment(startDate.format('YYYY-MM-DD')).isSame(
      monday.day(-6).format('YYYY-MM-DD')
    )
  ) {
    showDate = 'Last week'
  }

  return showDate
}

export function getShowMonthlyDate(startDate, endDate) {
  const year = startDate.get('year')
  const month = startDate.get('month')
  const current = {
    year: moment().get('year'),
    month: moment().get('month')
  }
  if (year === current.year) {
    if (month === current.month) {
      return 'This Month'
    } else if (month + 1 === current.month) {
      return 'Last Month'
    }
  }

  return startDate.format('MMM, YYYY')
}

export function prevWeekDate(startDate, endDate) {
  startDate = startDate.subtract(7, 'days')
  endDate = endDate.subtract(7, 'days')
  return { startDate, endDate }
}

export function prevMonthDate(startDate) {
  startDate = startDate.clone().subtract(1, 'month')
  return {
    startDate,
    endDate: startDate.clone().endOf('month')
  }
}

export function nextWeekDate(startDate, endDate) {
  startDate = startDate.add(7, 'days')
  endDate = endDate.add(7, 'days')
  return { startDate, endDate }
}

export function nextMonthDate(startDate) {
  startDate = startDate.clone().add(1, 'month')
  return {
    startDate,
    endDate: startDate.clone().endOf('month')
  }
}

export function getWeekDateRange(weekStr) {
  let year = weekStr.split('-')[0]
  let weekNo = weekStr.split('-')[1]
  let firstDayOfYear = moment(year + '-01-01')
  let oneDayOfTargetWeek = firstDayOfYear.add(parseInt(weekNo), 'week')
  return [
    oneDayOfTargetWeek.clone().startOf('week'),
    oneDayOfTargetWeek.clone().endOf('week')
  ]
}

export function getQuarterStrDateRange(quarterStr) {
  const year = quarterStr.slice(0, 4)
  const quarterNo = Number(quarterStr.split('').pop())
  let firstDayOfYear = moment(year + '-01-01')
  let oneDayOfTargetWeek = firstDayOfYear.add(
    parseInt(quarterNo) - 1,
    'quarter'
  )

  return [oneDayOfTargetWeek, oneDayOfTargetWeek.clone().endOf('quarter')]
}
