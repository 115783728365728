import styled from 'styled-components'

export const QuestionContainer = styled.div`
  --adm-color-text: #52606c;

  .question {
    margin-top: 24px;
    margin-bottom: 24px;
    &.q-group + div {
      margin-top: 0;
    }
    .introduction {
      /* padding-top: 18px; */
      /* padding-bottom: 24px; */
      width: 100%;
    }
    .bottom-line {
      border-bottom: 1px solid rgba(152, 169, 188, 0.2);
    }
  }

  .q-title {
    font-size: 1.125rem;
    color: #38435e;
    display: flex;
    margin-bottom: 18px;
    .required {
      color: #ff0000;
      font-size: 26px;
      margin-right: 5px;
      height: 24px;
    }
    .note_title {
      color: #98a9bc;
      font-size: 14px;
    }
    span {
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #dee4ea;
      color: #98a9bc;
      font-size: 12px;
      border-radius: 100%;
      margin-right: 10px;
    }

    svg {
      align-self: center;
    }

    p {
      max-width: 90%;
      margin: 0;
      margin-right: 12px;
    }
  }

  .q-level-bar {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 8px;
    background: #ffffff;
    overflow: hidden;
    li {
      display: inline-block;
      text-align: center;
      line-height: 54px;
      font-family: 'Gilroy-bold';
      border-left: 1px solid #f1f3f6;
      cursor: pointer;
      box-sizing: border-box;
      &:first-child {
        border-left: none;
      }

      &.selected {
        background: #05b800;
        color: #ffffff;
      }
    }
  }

  .q-label-level-bar {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      color: #5e687d;
      cursor: pointer;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-left: 2px solid #f1f3f6;
        font-size: 14px;
        font-family: Gilroy;
        background-color: #ffffff;
      }

      .label {
        margin-top: 16px;
        font-size: 12px;
        font-family: Heebo;
        text-align: center;
        line-height: 16px;
      }

      &.selected .title {
        background: #05b800;
        color: #ffffff;
      }

      &:first-child .title {
        border-radius: 8px 0 0 8px;
        border-left: none;
      }

      &:last-child .title {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .q-level-bar-extra {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    li {
      text-align: center;
      display: inline-block;
      color: #52606c;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  .q-sub-title {
    border-top: 1px solid rgba(152, 169, 188, 0.2);
    padding-top: 40px;
    .container {
      display: flex;
      padding: 21px;
      background: rgba(38, 67, 130, 0.05);
      border-radius: 8px;
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: 18px;
    }

    .content {
      font-size: 14px;
      color: #38435e;
    }
  }

  input.invalid {
    border: 1px solid rgba(252, 46, 46, 0.5);
  }

  p.invalid {
    color: #ff0000;
  }

  .q-form-sub-group {
    padding-left: 50px;
  }

  .multi-column {
    columns: 2;
  }

  .q-space {
    height: 16px;
  }
  .q-form-group {
    .q-text-group {
      padding: 16px;
      display: flex;
      .ant-checkbox-wrapper {
        width: auto;
        margin-right: 16px;
        padding-top: 2px;
      }
      .q-text-group-title strong {
        font-family: 'Gilroy-Bold';
      }
      .adm-checkbox {
        width: 2.5rem;
        padding: 0;
        & + div {
          width: calc(100% - 2.5rem);
        }
      }
    }
    .q-text-group-description {
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
    }
    .q-multiple-answer {
      color: #264382;
      font-size: 14px;
      margin: 16px 0;
      display: inline-block;
      cursor: pointer;
    }
  }

  .q-form-item {
    --font-size: 1rem;
    position: relative;
    background: #fbfbfb;
    min-height: 54px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 0 16px;
    background: #ffffff;
    width: auto;
    &.hide {
      display: none;
    }

    &.ant-select {
      padding: 0;
      width: 100%;
      .ant-select-selector {
        min-height: inherit;
        border-radius: 8px;
        background: #fbfbfb;
        align-items: center;
      }
      .ant-select-selection__rendered {
        padding: 12px 16px;
        margin: 0;
      }
      .ant-select-selection__placeholder,
      .ant-select-search__field__placeholder {
        left: 16px;
      }
    }

    &.ant-input-group-wrapper {
      padding: 0;
    }

    .additional-item {
      margin-bottom: 16px;
      &:first-child {
        padding-top: 16px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }

    & > .adm-input {
      --font-size: 1rem;
      padding-bottom: 16px;
      &:first-child {
        padding: 16px 0;
      }
    }

    .adm-input,
    .adm-text-area {
      letter-spacing: 0.3px;
      &:first-child:not(&:last-child) {
        padding: 0;
        padding-top: 1rem;
      }
      &.invalid {
        border: 1px solid rgba(252, 46, 46, 0.5);
      }
      & + .q-form-inner-after {
        position: absolute;
        font-size: var(--font-size);
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.valid {
      border: 1px solid rgba(5, 184, 0, 0.5);
    }

    &.invalid //, &.invalid input
    {
      border: 1px solid rgba(252, 46, 46, 0.5);
    }

    .adm-radio,
    .adm-checkbox,
    .item-text {
      padding: 16px 0;
      width: 100%;
      white-space: normal;
      .adm-checkbox-icon {
        border-radius: 0;
      }
    }

    .adm-checkbox-content,
    .adm-radio-content {
      font-size: 1rem;
      color: #323f4a;
    }

    .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
      border-color: #05b800;
      background-color: #05b800;
    }

    .adm-radio.adm-radio-checked .adm-radio-icon {
      border-color: #05b800;
      background-color: #05b800;
    }

    .elems-group {
      display: flex;
      align-items: center;
      min-height: 54px;
    }

    .attach-list {
      margin-top: 0;
    }

    &.picker-value-container {
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--adm-color-text);
    }
  }

  .elem-group {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 54px;
    .adm-input {
      --font-size: 1rem;
      height: 36px;
    }
  }
`
