import React from 'react'
import styled from 'styled-components'

import HealthCard from '../components/HealthCard'
import Information from '../components/Information'
import HideWrap from '../components/HideWrap'
import { SectionStyle } from '../index.styled'
import { ScoreColorGroup } from '../components/Widgets'
import AsymmetryChart from '../components/AsymmetryChart'
import CervicalLateralFlexionImage from '../../../asserts/images/physical-assessment/ic-cervical-lateral-flexion.svg'
import MultiSegmentalFlexionImage from '../../../asserts/images/physical-assessment/ic-multi-segmental-flexion.png'
import InternalExternalImage from '../../../asserts/images/physical-assessment/ic-internal-external-range.svg'
import SingleLegSquatImage from '../../../asserts/images/physical-assessment/ic-single-leg-squat.svg'
import OverheadSquatImage from '../../../asserts/images/physical-assessment/ic-overhead-squat.svg'

import { RangeColors } from '../../../utils'
import { Divider, Grid, Popover } from 'antd-mobile'
import { RangeBar } from '../components/RangeBar'
import { FlexBox } from '../../commonComponents/FlexBox'
import { useInView } from 'react-intersection-observer'
import ManFront from '../components/Painful/manFront'
import ManBack from '../components/Painful/manBack'
import WomanFront from '../components/Painful/womanFront'
import WomanBack from '../components/Painful/womanBack'
import { levelColor, PopContent, PopTitle } from '../components/Body'
import { RenderImageUrl } from '../wrapper'

const painfulList = [
  {
    name: 'Neck',
    key: 'neck'
  },
  {
    name: 'Multi segmental',
    key: 'multiSegmental'
  },
  {
    name: 'Shoulder rotation',
    key: 'shoulderRotation'
  },
  {
    name: 'Legs',
    key: 'legs'
  }
]

const Container = styled.section`
  ${SectionStyle}

  overflow: hidden;
  .detail {
    margin-top: 30px;
    .images {
      display: flex;
    }
    .body-metrics-view {
      text-align: center;
      font-weight: 700;
      font-size: 1rem;
      color: #4c6072;
      margin-top: 24px;
      .classification-title {
        font-size: 1.14rem;
      }
      .classification-value {
        font-size: 1.43rem;
        margin-bottom: 37px;
      }

      & > div .label {
        opacity: 0.6;
        text-transform: uppercase;
      }

      & > div .level {
        font-size: 1.14rem;
        border-bottom: 1px dashed;
        display: inline;

        &.Optimal {
          color: ${levelColor.green};
        }
        &.Monitoring,
        &.Concern {
          color: ${levelColor.yellow};
        }
        &.risk,
        &.Risk {
          color: ${levelColor.red};
        }
      }

      .asymmetry-chart-container {
        margin-left: -60px;
        margin-right: -60px;
      }
    }
  }
`

export function MovementQualitySection(props) {
  const {
    hideSectionHandle,
    hideSection,
    dataList,
    compareData,
    save,
    loginRole,
    onScoreChangeHandle,
    arDetail: { notes, status },
    view,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    person,
    ageScopes,
    painfulImage1,
    painfulImage2,
    painfulImage3,
    data: {
      quality: { summary, front, back },
      opacity,
      sfma_total_score,
      cervical_lateral_flexion_asymmetry,
      cervical_lateral_flexion_left,
      cervical_lateral_flexion_right,
      multi_segment_flexion,
      peak_knee_flexion_asymmetry,
      peak_knee_flexion_left,
      peak_knee_flexion_right,
      sier_asymmetry,
      sier_left,
      sier_right,
      single_leg_squat_depth_asymmetry,
      single_leg_squat_depth_left,
      single_leg_squat_depth_right,
      total_limitations,
      total_pain_points
    },
    visible
  } = props

  const data = dataList.find((item) => item.name === 'Movement Quality') || {}
  const compare =
    compareData.find((item) => item.name === 'Movement Quality') || {}

  const movementQuality = notes.find(
    (item) => item.pillar === 'movement quality'
  )

  const metrics = [
    {
      name: 'Cervical Lateral Flexion',
      image: CervicalLateralFlexionImage,
      bars: [
        {
          ...cervical_lateral_flexion_right,
          name: 'Cervical Lateral Flexion Right'
        },
        {
          ...cervical_lateral_flexion_left,
          name: 'Cervical Lateral Flexion Left'
        }
      ],
      asymmetry: {
        ...cervical_lateral_flexion_asymmetry
      },
      asymmetryText: 'Cervical Lateral Flexion Asymmetry'
    },
    {
      name: 'Multi Segmental Flexion',
      image: MultiSegmentalFlexionImage,
      imageStyle: {
        width: 120
      },
      bars: [
        {
          ...multi_segment_flexion,
          name: 'Multi Segmental Flexion'
        }
      ]
    },
    {
      name: 'Internal and External Range of Motion',
      image: InternalExternalImage,
      imageStyle: {
        width: 120
      },
      bars: [
        {
          ...sier_right,
          name: 'Shoulder Internal External Range of Motion Right'
        },
        {
          ...sier_left,
          name: 'Shoulder Internal External Range of Motion Left'
        }
      ],
      asymmetry: {
        ...sier_asymmetry
      },
      asymmetryText: 'Shoulder Internal External Range of Motion Asymmetry'
    },
    {
      name: 'Single Leg Squat',
      image: SingleLegSquatImage,
      bars: [
        {
          ...single_leg_squat_depth_right,
          name: 'Single Leg Squat Depth Right '
        },
        {
          ...single_leg_squat_depth_left,
          name: 'Single Leg Squat Depth Left'
        }
      ],
      asymmetry: {
        ...single_leg_squat_depth_asymmetry
      },
      asymmetryText: 'Single Leg Squat Depth Asymmetry'
    },
    {
      name: 'Overhead Squat',
      image: OverheadSquatImage,
      bars: [
        {
          ...peak_knee_flexion_right,
          name: 'Peak Knee Flexion Right'
        },
        {
          ...peak_knee_flexion_left,
          name: 'Peak Knee Flexion left'
        }
      ],
      asymmetry: {
        ...peak_knee_flexion_asymmetry
      },
      asymmetryText: 'Peak Knee Flexion Asymmetry'
    }
  ]

  function renderImageGroup() {
    if (!(painfulImage1 || painfulImage2 || painfulImage3)) {
      return null
    }

    return (
      <HideWrap
        name="Movement Quality - images"
        title="images"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <div
          style={{
            marginTop: 45
          }}
        >
          <Grid columns={3} gap={7}>
            {painfulImage1 && (
              <Grid.Item>
                <RenderImageUrl url={painfulImage1} width={160} height={160} />
              </Grid.Item>
            )}
            {painfulImage2 && (
              <Grid.Item>
                <RenderImageUrl url={painfulImage2} width={160} height={160} />
              </Grid.Item>
            )}
            {painfulImage3 && (
              <Grid.Item>
                <RenderImageUrl url={painfulImage3} width={160} height={160} />
              </Grid.Item>
            )}
          </Grid>
        </div>
      </HideWrap>
    )
  }

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })

  return (
    <>
      <HideWrap
        name="Movement Quality"
        isSection={true}
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
        ref={ref}
      >
        <Container>
          <h1 className="ar-section-title">Movement Quality</h1>
          <h3 className="ar-section-card-title">Summary</h3>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          {movementQuality && movementQuality.memo && (
            <Information
              title="COMMENT/NOTE"
              disMarkDone={true}
              initialValue={movementQuality.memo}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              updateCommend={(value, status) =>
                handleCommendSave(value, 'movement quality', status)
              }
              updateEditingCount={updateEditingCount}
            />
          )}

          <ScoreColorGroup domain={'movement'} />
          {metrics.map((metric, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 && <Divider />}
                <MovementMetricCard
                  {...metric}
                  {...{
                    person,
                    ageScopes
                  }}
                />
              </React.Fragment>
            )
          })}
          <div className="detail">
            <div className="images">
              <PainfulImages
                {...{ ...opacity, person, quality: { front, back } }}
              />
            </div>
            <div className="body-metrics-view">
              <div className="classification-title">Classification</div>
              <div
                style={{ color: levelColor[sfma_total_score.color] }}
                className="classification-value"
              >
                {sfma_total_score.level || '--'}
              </div>
              <Grid columns={2} gap={20}>
                {painfulList.map((item, index) => (
                  <Grid.Item key={index}>
                    <div>
                      <div className="label">{item.name}</div>
                      <Popover
                        trigger="click"
                        content={
                          <div style={{ padding: 8 }}>
                            <PopTitle>
                              <div>{item.name}</div>
                              <div
                                className="level"
                                style={
                                  summary[item.key].color
                                    ? {
                                        color:
                                          levelColor[summary[item.key].color]
                                      }
                                    : {}
                                }
                              >
                                {summary[item.key].level || '--'}
                              </div>
                            </PopTitle>
                            <Divider />
                            {getContent(summary[item.key].metrics)}
                          </div>
                        }
                      >
                        <div
                          className="level"
                          style={
                            summary[item.key].color
                              ? {
                                  color: levelColor[summary[item.key].color]
                                }
                              : {}
                          }
                        >
                          {summary[item.key].level || '--'}
                        </div>
                      </Popover>
                    </div>
                  </Grid.Item>
                ))}
              </Grid>
              <Grid
                columns={2}
                gap={2}
                style={{
                  marginTop: 30
                }}
              >
                <Grid.Item>
                  <div className="asymmetry-chart-container">
                    <div className="polar-chart-name">
                      <div>Limitations</div>
                    </div>
                    <AsymmetryChart
                      metric={total_limitations}
                      {...{ ageScopes, person }}
                    />
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <div className="asymmetry-chart-container">
                    <div className="polar-chart-name">
                      <div>Pain Points</div>
                    </div>
                    <AsymmetryChart
                      metric={total_pain_points}
                      {...{ ageScopes, person }}
                    />
                  </div>
                </Grid.Item>
              </Grid>
            </div>
          </div>
          {renderImageGroup()}
        </Container>
      </HideWrap>
    </>
  )
}

function getContent(metrics) {
  return (
    <PopContent
      style={{
        padding: 8
      }}
    >
      <ul>
        {Object.keys(metrics).map((item, index) => (
          <li key={index}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {metrics[item].icon ? (
                <img src={metrics[item].icon} alt="icon" className="icon" />
              ) : (
                <span className="icon" />
              )}
              {item}
            </div>
            <div
              className="level"
              style={
                metrics[item] && metrics[item].color
                  ? { color: levelColor[metrics[item].color] }
                  : {}
              }
            >
              {(metrics[item] && metrics[item].level) || '--'}
            </div>
          </li>
        ))}
      </ul>
    </PopContent>
  )
}

function PainfulImages(props) {
  const { front, back, person, quality } = props

  const overlayStyle = { minWidth: 230 }
  const colorMaps = {
    0: 'transparent',
    34: '#7FB800',
    // 'radial-gradient(71.76% 71.76% at 49.11% 50.38%, #7FB800 0%, rgba(127, 184, 0, 0.2) 100%)',
    67: '#FFB800',
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, #FFB800 0%, rgba(255, 184, 0, 0.2) 100%)',
    100: '#D5001C'
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, rgba(213, 0, 28, 0.9) 0%, rgba(213, 0, 28, 0.2) 100%)'
  }

  return person.profile.gender === 'male' ? (
    <>
      <ManFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <ManBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  ) : (
    <>
      <WomanFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <WomanBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  )
}

const MetricContainer = styled.div`
  position: relative;
  overflow: hidden;
  .metric-name {
    max-width: 146px;
    top: ${(props) => props.nameMarginTop || '68px'};
    color: #4c6072;
    font-size: 1.29rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 27px;
  }

  .metric-image-conatiner {
    margin-top: 20px;
  }

  .metric-middle-container {
    display: flex;
    flex-direction: column;
  }

  .metric-polar-chart-container {
    position: absolute;
    width: 220px;
    top: 0;
    right: -50px;
    .polar-chart-name {
      font-size: 0.86rem;
      line-height: 17px;
      padding: 0px 50px;
      color: #4c6072;
      display: flex;
      text-align: center;
      justify-content: center;
      & > div {
        max-width: 142px;
      }
    }
  }
`

export function MovementMetricCard(props) {
  const { bars, person, ageScopes } = props
  return (
    <MetricContainer height={props.height} nameMarginTop={props.nameMarginTop}>
      <FlexBox
        style={{
          position: 'relative'
        }}
      >
        <div>
          <div className="metric-name">{props.name}</div>
          <div className="metric-image-conatiner">
            <img src={props.image} alt="bg" style={props.imageStyle || {}} />
          </div>
        </div>
        {props.asymmetry && (
          <div className="metric-polar-chart-container">
            <div className="polar-chart-name">
              <div>{props.asymmetryText}</div>
            </div>
            <AsymmetryChart
              metric={props.asymmetry}
              {...{ ageScopes, person }}
            />
          </div>
        )}
      </FlexBox>

      <div className="metric-middle-container">
        {bars.map((bar, index) => {
          return (
            <RangeBar
              key={index}
              {...bar}
              {...{ person, ageScopes }}
              levelStyle={{
                padding: '5px 13px',
                color: 'white',
                backgroundColor: RangeColors[bar.color] || 'transparent',
                fontWeight: '600',
                borderRadius: '6px'
              }}
              showRangeArea={false}
              hideName={false}
              hideValueNumber={false}
              showLevelOnNameRight={true}
            />
          )
        })}
      </div>
    </MetricContainer>
  )
}
