import React from 'react'
import { Divider } from 'antd-mobile'
import HealthCard from '../components/HealthCard'
import HideWrap from '../components/HideWrap'
import Information from '../components/Information'
import DxaSummary from '../components/DxaSummary'

import styled from 'styled-components'
import { SectionStyle } from '../index.styled'
import { useInView } from 'react-intersection-observer'

export const Container = styled.section`
  ${SectionStyle}
`

export default function BoneHealth(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    handleSave,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    visible,
    arDetail: { notes, status, detail },
    dynamicData: { ui_version, dxaSummary }
  } = props

  const bodyCompData =
    dataList.find((item) => item.type === 'bone_health') || {}

  const bodyCompCompare =
    compareData.find((item) => item.type === 'bone_health') || {}

  const bodyComp = notes.find((item) => item.pillar === 'bone health')

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })

  return (
    <HideWrap
      name="Bone Health"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
    >
      <Container ref={ref}>
        <h1 className="ar-section-title">Bone Health</h1>
        <HideWrap
          name="Bone Health - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <div className="sub-title">Summary</div>
          <HealthCard
            {...bodyCompData}
            compare={bodyCompCompare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={bodyComp ? bodyComp.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'body comp', status)
            }
            updateEditingCount={updateEditingCount}
          />
        </HideWrap>
        {dxaSummary && (
          <>
            <Divider />
            <HideWrap
              name="Body Composition - DXA"
              title="DXA Results Summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <div className="sub-title">DXA Results Summary</div>
              <DxaSummary
                {...{
                  view,
                  person,
                  detail,
                  handleSave,
                  dxaSummary,
                  ui_version,
                  ageScopes,
                  notes,
                  handleCommendSave,
                  updateEditingCount
                }}
              />
            </HideWrap>
          </>
        )}
      </Container>
    </HideWrap>
  )
}
