import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import {
  getWeekDateRange,
  getQuarterStrDateRange
} from '../../../utils/dateHandles'
import { DatePicker } from 'antd-mobile'

const PickerContainer = styled.div`
  /* .ant-picker {
    max-width: 240px;
    border: 1px solid #e4e7eb;
    border-radius: 6px;
    height: 40px;
  }
  .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  } */
`

export default function DatePickerByRange(props) {
  const [limitedBy, setLimitedBy] = useState(null)
  const { dateRange, from, to, onRangeChange } = props
  const [value, setValue] = useState(null)
  const [hackValue, setHackValue] = useState(null)
  const { name, picker } = dateRange
  useEffect(() => {
    setValue([moment(from), moment(to)])
  }, [from, to])

  function onChange(date, dateStr) {
    if (!date) {
      return
    }
    let [from, to] = _.isArray(date) ? date : []
    switch (name) {
      case 'Week':
        const weekDates = getWeekDateRange(date)
        from = weekDates[0]
        to = weekDates[1]
        break
      case 'Month':
        from = moment(dateStr + '-01')
        to = from.clone().endOf('month')
        break
      case 'Quarterly':
        const quarterDates = getQuarterStrDateRange(dateStr)
        from = quarterDates[0]
        to = quarterDates[1]
        break
      case 'Year':
      case 'Annual':
      case '365 days':
        const annualFirstDay = dateStr + '-01-01'
        from = moment(annualFirstDay)
        to = moment(annualFirstDay).endOf('year')
        break
      default:
        break
    }
    setValue([from, to])
    onRangeChange([from, to].map((date) => date.format('YYYY-MM-DD')))
  }

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null])
      setLimitedBy(null)
    } else {
      setHackValue(null)
    }
  }

  const attributes = {
    size: 'large',
    value: value && value[0],
    picker,
    onChange,
    allowClear: false
  }
  let PickerType = DatePicker
  if (name === 'Bi-Weekly' || name === 'Custom') {
    attributes.value = hackValue || value
    attributes.onOpenChange = onOpenChange
    attributes.format = 'MM/DD/YYYY'
    PickerType = DatePicker.RangePicker
    if (name === 'Bi-Weekly') {
      attributes.onCalendarChange = (val) => setLimitedBy(val)
      attributes.disabledDate = (currentDate) => {
        if (!limitedBy) {
          const isMonday = currentDate.format('ddd') === 'Mon'
          return !isMonday
        }
        const isTwoWeeks =
          limitedBy[0] && currentDate.diff(limitedBy[0], 'days') === 13
        return !isTwoWeeks
      }
    }
  }
  return (
    <PickerContainer>
      {/* <PickerType {...attributes} popupStyle={{ zIndex: 9999 }} /> */}
    </PickerContainer>
  )
}
