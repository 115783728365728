import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Radio } from 'antd-mobile'
import styled from 'styled-components'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import radioUseIcon from '../../../asserts/icons/radio-use.svg'
import radioUnuseIcon from '../../../asserts/icons/radio-unuse.svg'
import { RadioLayout } from './yesOrNo'

const Container = styled.div`
  .slider {
    height: 45px;
    margin-bottom: 5px;
    padding: 0 5px;
  }

  .slider-title {
    display: flex;
    justify-content: space-between;
    color: rgba(56, 67, 94, 0.7);
    margin-bottom: 6px;
    margin-left: -2px;
    margin-right: -2px;

    span:last-child {
      text-align: end;
    }
  }
`

export default function SliderContainer(props) {
  const { question, answer, update } = props //update
  const _value = (answer || { answer: {} }).answer.value
  const [value, setValue] = useState(null)
  const {
    slider: { max, min, max_plus, stepping: step, unit = '', single_choice }
  } = question
  const middle = Math.round(max / step / 2) * step
  const marks = {
    [min]: min + unit,
    [middle]: middle + unit,
    [max]: max + unit + (max_plus ? '+' : '')
  }

  const maxTitle = _.get(question, 'slider.max_title')
  const minTitle = _.get(question, 'slider.min_title')
  const hasTitle = maxTitle || minTitle

  if (_.isNumber(value)) {
    marks[value] = {
      label: value + unit,
      style: {
        color: '#5E687D'
      }
    }
  }

  useEffect(() => {
    if (_value) {
      setValue(_value)
    }
  }, [_value])

  return (
    <Container>
      {hasTitle && (
        <div className="slider-title">
          <span className="slider-left">{minTitle || ' '}</span>
          <span className="slider-right">{maxTitle || ''}</span>
        </div>
      )}
      <div className="slider">
        <Slider
          max={max}
          min={min}
          marks={marks}
          step={step}
          dots={true}
          styles={{
            rail: {
              backgroundColor: '#C5CAD2',
              height: 16,
              top: -1,
              width: '106%',
              left: '-3%'
            },
            track: {
              backgroundColor: '#05B800',
              height: 16,
              top: -1
            },
            handle: {
              border: 'none',
              height: 20,
              width: 20,
              marginTop: -8,
              backgroundImage: `url(${radioUseIcon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              opacity: 1
            }
          }}
          dotStyle={{
            border: 'none'
          }}
          activeDotStyle={{
            backgroundColor: 'white',
            border: 'none'
          }}
          startPoint={min === 0 ? -(max * 0.03) : min - (max - min) * 0.03}
          value={_.isNumber(value) ? value : null}
          onChange={onChange}
        />
      </div>
      {single_choice &&
        single_choice.map((choice, index) => (
          <RadioLayout className="q-form-item" key={index}>
            <Radio
              onChange={(e) => {
                onChange(choice.title, choice.score)
              }}
              checked={value === choice.title}
              icon={(checked) =>
                checked ? (
                  <img src={radioUseIcon} alt="" />
                ) : (
                  <img src={radioUnuseIcon} alt="" />
                )
              }
            >
              {choice.title}
            </Radio>
          </RadioLayout>
        ))}
      {answer.verify && answer.verify.required && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </Container>
  )

  function onChange(val, score) {
    setValue(val)
    const newAnswer = _.assignIn(answer, { answer: { value: val, score } })
    update(newAnswer, question)
  }
}
