import React, { useState } from 'react'
import { Popover } from 'antd-mobile'

export default function ManFront(props) {
  const { opacities, getContent, quality, overlayStyle, colorMaps } = props
  const [hoverIndex, setHover] = useState(null)
  return (
    <svg
      width="350"
      height="350"
      viewBox="0 0 262 506"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M165.581 383.384C165.396 384.173 164.607 384.637 164.143 384.73C161.917 385.333 162.334 387.19 162.613 388.489C163.308 391.924 163.076 395.405 162.844 398.794C162.473 404.224 161.546 409.608 160.896 415.039C160.34 419.773 159.18 424.368 157.51 428.778C156.351 431.887 156.072 435.229 154.681 438.246C151.434 445.301 150.182 452.913 148.28 460.293C147.074 464.888 147.445 469.855 147.909 474.636"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.2122 383.569C97.4905 383.848 97.7224 384.312 98.0471 384.358C100.552 384.637 100.32 386.54 100.042 388.072C99.3922 391.924 99.5777 395.776 99.9024 399.582C100.505 406.916 101.572 414.157 102.917 421.398C103.659 425.296 105.654 428.824 106.396 432.723C107.416 437.968 110.06 442.702 111.174 447.947C112.287 453.192 114.281 458.251 114.791 463.589C115.116 467.116 114.838 470.69 114.838 474.264"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.457 271.523C168.735 278.857 169.384 286.191 169.06 293.524C168.735 300.44 168.41 307.402 166.184 314.086C164.375 319.656 162.288 325.179 160.479 330.749C158.299 337.433 156.675 344.302 155.701 351.311C155.609 351.868 155.701 352.425 155.701 352.935"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1044 271.523C93.8724 278.114 93.2695 284.659 93.5014 291.25C93.7797 300.301 94.5682 309.166 97.6759 317.799C99.3921 322.58 101.479 327.221 102.685 332.141C104.309 338.872 106.489 345.509 106.86 352.471"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.111 305.686C116.276 311.348 114.003 316.686 113.029 322.349C111.776 329.45 111.266 336.598 112.797 343.746C113.354 346.438 114.884 348.666 116.925 350.29C119.569 352.333 123.372 350.708 124.671 348.48C124.857 348.155 124.855 348.245 125.32 347.691"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.683 306.103C146.054 310.976 147.723 315.618 149.022 320.259C149.996 323.647 150.321 327.129 150.506 330.563C150.785 335.065 150.924 339.661 149.764 344.116C149.115 346.576 147.723 348.619 145.868 350.29C143.317 352.564 138.864 351.079 137.658 347.644"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.2187 194.243C56.7195 201.994 50.3186 208.353 45.1236 215.548C41.5058 220.56 38.63 225.991 35.1512 231.004C32.4146 234.949 29.3069 238.523 25.8746 241.865C24.7614 242.932 23.2771 243.489 21.7928 243.954"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.76 194.661C204.172 200.045 208.532 204.037 212.011 208.632C216.927 215.177 221.937 221.675 225.879 228.915C228.477 233.743 232.419 237.641 236.13 241.679C237.336 242.979 239.052 243.722 240.815 244.186"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.188 225.898C173.188 228.451 173.188 230.958 173.188 233.51C173.142 238.802 174.255 243.907 175.832 248.92C177.223 253.33 178.105 257.925 178.939 262.473C179.682 266.465 180.331 270.503 180.192 274.588C180.145 275.47 180.238 276.398 180.006 277.28"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3732 225.48C89.3732 228.219 89.3732 230.957 89.3732 233.696C89.3268 238.43 88.3064 242.933 87.0076 247.481C85.3378 253.19 84.0855 259.038 83.1578 264.933C82.6476 268.368 82.3229 271.756 82.4157 275.237C82.4157 275.794 82.3229 276.351 82.6012 276.862"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.925 197.956C205.981 205.29 211.593 212.205 217.113 219.168C220.777 223.763 223.514 228.961 226.575 233.974C228.616 237.316 229.868 240.89 229.868 244.882C229.868 245.763 229.683 246.599 229.219 247.342"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6361 197.956C56.2556 205.382 50.4113 212.484 44.8453 219.771C40.5781 225.341 37.0529 231.375 33.9916 237.687C32.6929 240.379 31.9972 243.536 33.064 246.692"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8499 123.136C80.6997 126.199 85.1061 127.87 89.9763 128.241C95.6815 128.706 100.969 126.849 106.442 125.642C110.385 124.76 114.281 123.693 118.363 123.553"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.758 123.507C181.027 126.849 175.739 128.705 169.941 128.612C166.648 128.566 163.494 127.638 160.293 126.849C155.006 125.549 149.764 124.11 144.291 123.739"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.825 255.929C125.506 260.756 125.042 266.094 123.558 271.153C121.471 278.254 120.728 285.588 120.218 292.921C120.125 294.035 120.079 295.103 120.033 296.217"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.6013 86.0967C85.1988 86.6537 87.7962 86.0503 90.3937 86.468C94.058 87.1178 97.7687 87.6284 101.479 88.1389C105.7 88.7423 109.921 89.2065 114.003 90.4133C116.832 91.2487 119.615 92.177 122.445 93.0589"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.015 256.532C136.406 259.271 136.823 262.241 137.473 265.165C138.679 270.457 140.488 275.609 141.137 280.993C141.694 285.727 142.281 288.899 142.791 293.633M142.791 293.633C142.837 293.958 142.837 293.308 142.791 293.633Z"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.006 86.5133C174.208 85.7243 168.596 87.2096 162.984 87.9986C157.882 88.6948 152.733 89.2518 147.77 90.8299C145.312 91.619 142.761 92.2688 140.349 93.2899"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.62 113.249C156.907 115.988 161.546 119.701 167.019 122.114C173.744 125.085 180.377 124.899 186.964 121.65C187.149 121.557 187.428 121.372 187.381 121.047"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.942 113.249C106.906 115.152 103.288 117.844 99.4385 120.026C95.1249 122.439 90.5793 124.389 85.4771 124.11C81.8593 123.924 78.3341 123.089 75.1801 121.047"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.283 144.903C130.376 149.87 129.588 154.79 129.495 159.71C129.449 163.005 129.634 166.301 129.912 169.55C130.005 170.803 129.959 172.01 129.866 173.263"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.324 145.322C132.231 150.149 133.02 154.93 133.113 159.711C133.159 163.146 133.02 166.58 132.649 169.969C132.556 171.083 133.113 172.197 132.51 173.264"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M216.453 203.865C219.885 213.009 224.627 220.978 229.451 229.426C229.683 229.797 230.147 230.261 229.915 230.865"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0513 202.877C42.7117 212.531 37.424 221.257 32.6929 230.215"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.449 175.724C129.031 179.112 129.309 182.454 129.634 185.796C129.912 188.581 129.495 191.319 128.799 194.011C128.382 195.496 128.382 196.889 128.846 198.328"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.159 176.142C133.669 179.576 133.252 183.011 132.974 186.399C132.742 189.045 133.252 191.644 133.855 194.197C134.18 195.636 134.319 196.935 133.809 198.328"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.031 199.998C128.382 202.087 129.031 204.129 129.309 206.171C129.68 209.003 130.005 211.787 130.052 214.619C130.052 216.197 130.33 217.682 130.886 219.121"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.972 259.41C141.786 261.36 143.317 262.566 144.013 264.144C146.054 268.647 147.511 271.725 148.021 276.691"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.577 200.417C134.319 202.413 133.53 204.409 133.298 206.358C132.974 209.097 132.695 211.835 132.556 214.574C132.51 216.152 132.278 217.637 131.721 219.076"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.32 254.258C124.114 255 123.048 255.929 122.027 256.95C120.775 258.203 120.589 259.92 119.94 261.452C118.919 263.773 117.435 265.815 116.647 268.229C115.58 271.478 114.142 274.634 114.003 278.115"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.825 87.3494C129.541 84.0539 130.237 80.5728 130.098 76.8596C130.051 74.9566 129.866 73.0072 130.469 71.1041C130.654 70.5472 130.469 70.0366 131.35 70.083C132.139 70.1294 132.092 70.5472 132.185 71.1041C132.788 73.8426 132.463 76.5811 132.556 79.3196C132.649 82.1509 133.437 84.843 134.829 87.3494"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.196 66.7871C128.66 71.9856 128.753 77.0912 126.573 82.0112"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.365 66.7871C133.994 71.9392 133.716 77.1376 135.989 82.0112"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.025 85.3077C103.103 84.8435 100.32 83.8688 97.3976 83.4046C95.5886 83.1261 93.826 83.0333 92.0635 82.8477"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.544 83.0332C165.72 83.0796 161.128 84.2864 156.583 85.7253"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.455 86.3281C148.048 87.5349 143.688 88.8345 140.117 91.8979"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.111 409.701C117.018 414.807 118.78 419.17 122.259 422.837C122.537 423.115 122.677 423.394 122.862 423.672"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.2491 140.588C94.29 145.879 97.3049 150.567 101.062 154.791"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.259 91.2487C118.548 88.7887 114.467 87.0714 110.153 86.0967"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.312 141.005C168.132 146.064 165.535 150.891 161.499 154.79"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.066 410.366C145.159 415.75 142.688 420.016 138.884 423.822"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.241 254.676C138.957 255.511 140.488 256.625 141.323 258.389C141.74 259.271 142.668 259.132 143.224 259.781C145.915 263.03 148.293 265.708 150.473 269.329"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.218 259.41C119.105 259.457 118.502 260.338 117.992 261.081C116.136 263.912 113.678 266.326 112.008 269.297"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.194 481.646C112.148 484.987 112.333 488.422 109.736 491.114"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.528 482.062C117.482 483.873 118.409 485.451 118.688 487.168C118.966 488.746 118.966 490.092 118.363 491.485"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.905 482.479C116.739 485.961 115.209 488.931 113.864 491.948"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.414 481.646C150.228 484.987 150.506 488.236 152.455 491.114"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.703 482.898C145.915 486.24 147.26 489.118 148.744 491.949"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.08 482.479C144.662 484.243 144.245 486.053 143.827 487.817C143.549 489.024 143.41 490.184 144.013 491.298"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.982 150.474C169.802 156.136 168.225 162.031 164.793 167.137"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.811 150.659C92.5736 155.626 94.0579 160.685 96.7481 165.28C97.0264 165.744 97.583 165.93 97.583 166.487"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.2285 159.524C91.5996 161.706 92.2953 163.841 92.5272 166.115C92.5736 166.765 92.62 167.369 92.4809 167.972"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.626 156.832C174.533 157.482 174.069 158.085 173.605 158.224C170.637 159.246 170.869 162.031 170.451 164.258C170.219 165.465 169.431 166.811 170.127 168.157"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.605 391.182C143.481 392.667 144.779 395.22 145.522 398.19"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.42 165.466C173.466 165.976 172.91 166.348 172.771 166.441C169.477 168.065 168.782 171.453 167.251 174.285C166.926 174.842 166.787 175.538 165.999 175.724"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.1879 165.048C89.327 165.326 89.3734 165.698 89.6053 165.837C92.6202 167.694 93.919 170.757 95.1713 173.867C95.4032 174.377 95.8207 174.841 96.1917 175.306"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.656 391.182C119.105 391.739 118.363 394.013 117.389 395.962C117.157 396.427 116.6 397.076 116.925 397.773"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.241 65.9521C136.684 68.3193 137.937 70.1759 138.864 72.1253"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.552 65.9521C125.413 68.1337 125.089 70.2223 123.511 71.8933"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.043 139.566C173.884 141.655 172.121 142.769 169.709 142.862"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.5181 139.566C88.7704 141.33 90.2547 142.676 92.6666 142.444"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.043 146.946C173.234 150.149 170.312 151.216 166.833 151.077"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.5181 146.946C88.121 147.55 88.7704 148.153 89.3734 148.803C91.136 150.752 93.2696 151.17 95.7279 150.659"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.9353 156.832C89.466 158.364 90.8575 160.22 93.5013 159.106"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.732 65.8129C126.712 65.488 126.48 64.6526 125.97 63.8171C123.882 60.4752 123.743 56.7156 123.001 53.0488"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.333 32.1152C112.797 34.6216 115.023 35.1322 116.879 35.9677C119.105 36.9888 121.424 36.896 123.651 35.5964C124.485 35.1322 125.413 34.9001 126.294 34.5288"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.135 32.1152C147.909 35.9213 143.178 37.6386 139.282 35.9677C138.261 35.5499 137.055 35.3179 136.174 34.5752"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.168 42.373C115.023 44.2296 116.786 46.1791 118.734 47.8964C120.311 49.2889 120.636 50.7742 119.754 52.6772C119.291 53.6519 119.059 54.7194 118.688 55.7406"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.883 42.79C146.981 44.6466 145.033 46.5032 143.132 48.3598C141.926 49.5202 141.786 50.7734 142.482 52.3051C142.992 53.4655 143.224 54.7651 143.549 55.9719"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.05 53.0947C138.679 56.8079 138.493 60.614 136.128 63.7702C135.803 64.2343 136.128 64.4664 136.128 64.8377"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.219 37.4531C142.853 39.4954 139.977 40.795 137.426 42.6052C136.963 42.9301 136.406 43.1157 135.803 43.0229"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.25 37.8701C119.847 39.5411 122.584 40.9799 125.088 42.7437C125.32 42.8829 125.877 43.7648 126.48 43.0222"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.84 58.0146C129.077 59.2214 128.011 60.7995 127.779 62.9346"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.86 58.2002C133.159 59.4998 134.133 60.9851 134.736 62.7024"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M179.612 137.014C178.963 137.756 179.195 138.638 179.195 139.474C179.195 145.833 179.241 152.191 177.525 158.365C176.133 163.424 174.51 168.483 173.258 173.589C172.191 177.998 170.382 182.175 169.872 186.724C169.222 192.015 170.103 197.121 171.449 202.134C172.747 206.961 174.51 211.881 174.046 216.708C173.536 221.535 175.669 225.295 176.829 229.472C178.128 234.207 179.148 238.941 179.473 243.861C180.03 252.773 180.54 261.684 180.122 270.596C179.89 275.516 179.612 280.482 179.055 285.402C178.777 287.862 178.916 290.322 178.638 292.782C178.221 296.728 177.432 300.626 176.736 304.479C176.18 307.496 175.809 310.513 175.252 313.53C174.835 315.943 174.324 318.31 173.814 320.724C172.794 325.783 171.727 330.843 170.753 335.948C170.475 337.433 170.011 338.872 169.454 340.218C168.295 342.91 167.831 345.788 166.625 348.48C165.883 350.151 166.254 351.915 166.068 353.632C165.836 355.674 165.836 357.763 165.836 359.805C165.836 363.333 165.419 366.861 164.677 370.295C163.795 374.612 164.213 378.696 165.512 382.874C166.625 386.494 168.109 390.022 168.898 393.735C170.428 401.161 171.124 408.634 169.5 416.153C169.315 416.942 168.898 419 168.113 421.129"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.8118 121.372C76.0845 123.089 77.6616 125.503 79.5633 127.545C79.8416 127.824 80.0271 128.195 80.3982 128.334C83.4131 129.634 82.9029 132.094 82.4391 134.507C82.3463 134.972 82.1608 135.482 82.2999 135.946C83.4131 139.799 82.9029 143.79 82.9957 147.689C83.1812 153.073 84.3872 158.226 85.8251 163.285C87.3557 168.622 88.7936 174.053 90.5098 179.344C92.7362 186.167 93.0608 193.037 91.0664 199.906C89.7212 204.501 89.1183 209.282 87.9123 213.923C87.5412 215.409 87.4949 217.173 88.1442 218.843C88.4225 219.54 88.3761 220.607 88.0051 221.489C86.2425 226.131 84.7119 230.865 83.5059 235.692C82.9029 238.106 82.4391 240.612 82.4855 243.072C82.5782 247.76 81.9752 252.401 81.9752 257.043C81.9752 260.338 81.3259 263.634 81.6506 266.929C82.1144 271.803 82.2535 276.677 82.8101 281.55C83.0884 284.196 83.0884 286.888 83.1812 289.58C83.3204 294.175 84.1553 298.677 84.9902 303.133C85.6859 306.846 86.2889 310.559 86.8919 314.273C87.4485 317.522 88.237 320.678 88.84 323.927C89.4893 327.64 90.417 331.307 91.0664 335.02C91.5766 338.037 92.7826 340.822 93.803 343.653C95.5192 348.341 96.2613 353.076 96.0758 358.042C95.8902 363.565 97.0498 369.042 97.9311 374.473C98.2094 376.237 97.9311 377.815 97.4673 379.393C96.2149 383.802 94.8698 388.165 93.5711 392.575C92.597 395.777 92.226 399.119 91.8549 402.415C91.4374 406.035 91.4374 409.563 91.9477 413.09C92.226 415.04 91.9477 413.09 93.803 419.947"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.588 222.139C169.872 223.949 167.692 225.388 166.718 227.708C165.975 229.472 165.28 231.236 164.584 233C162.404 238.338 159.853 243.49 157.58 248.827C155.307 254.258 153.637 259.874 151.782 265.444C150.066 270.596 148.211 275.748 146.634 280.9C143.758 290.183 140.465 299.327 138.331 308.842C137.635 311.812 137.403 314.876 135.965 317.707C135.27 319.053 135.502 320.956 135.455 322.581C135.409 326.572 135.362 330.518 135.455 334.509C135.641 341.286 135.733 348.062 136.244 354.885C136.847 363.008 137.867 371.084 140.233 378.928C140.465 379.625 140.511 380.367 140.511 381.017C140.696 384.545 143.016 387.144 144.222 390.207C144.964 392.11 145.103 394.013 145.196 395.823C145.52 400.743 145.52 405.71 145.057 410.63C145.01 411.326 144.917 412.022 144.917 412.672C145.056 425.297 143.758 437.875 143.758 450.5C143.758 453.981 142.494 459.326 141.52 462.76"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.463 318.883C134.92 316.222 134.991 314.968 135.038 313.807C135.316 307.356 134.713 300.904 134.528 294.499C134.388 289.068 133.554 283.684 133.275 278.254C133.09 274.633 132.904 270.966 132.162 267.346C131.976 266.557 131.837 265.768 130.91 265.722C129.982 265.675 130.214 266.511 130.075 267.114C129.286 270.409 129.101 273.798 128.869 277.186C128.544 281.735 128.08 286.237 127.756 290.786C127.477 295.474 127.199 300.208 127.153 304.942C127.106 309.212 127.199 313.483 126.225 317.706C125.993 318.774 126.457 320.027 126.596 321.187C127.013 325.55 126.967 329.96 126.735 334.323C126.132 345.834 126.086 357.438 124.277 368.856C123.72 372.476 122.746 376.05 121.772 379.531C120.566 383.708 120.752 387.7 122.097 391.692C124.416 398.654 125.576 405.755 125.112 413.089C124.973 415.549 124.37 418.055 123.906 420.469C123.256 423.997 123.303 427.617 122.7 431.144C122.05 434.904 121.911 438.71 121.401 442.423C120.705 447.343 120.381 452.31 120.705 457.23C120.937 460.618 120.334 463.96 121.03 467.302C121.216 468.184 120.984 468.973 120.288 469.808C117.69 472.918 114.768 475.656 111.614 478.209C110.594 479.045 109.527 479.973 109.341 481.458"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.8382 257.089C96.2612 261.591 94.8233 266.14 93.942 270.874C93.2463 274.494 92.3186 278.115 91.7156 281.782C90.9271 286.516 90.5096 291.297 90.7879 296.17C91.0662 300.487 91.2518 304.803 91.6692 309.12C92.0867 313.344 92.8288 317.475 93.5245 321.652C94.0811 324.948 94.2667 328.243 94.9624 331.538C96.122 337.155 97.7454 342.632 99.1369 348.155C100.018 351.636 101.456 355.024 102.569 358.459C102.987 359.759 104.007 360.501 104.61 361.569C107.347 366.535 109.063 371.827 108.506 377.582C108.414 378.418 108.785 379.624 107.254 380.135C106.837 378.232 106.465 376.375 106.048 374.472C105.213 370.388 104.146 366.396 103.219 362.312C103.126 361.801 102.662 361.244 103.172 360.687"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.985 224.181C171.031 225.573 170.846 226.919 170.15 228.08C168.526 230.632 168.248 233.51 167.97 236.341C167.413 242.004 166.671 247.667 164.538 252.958C163.378 255.789 164.445 258.203 165.28 260.57C167.692 267.625 168.99 274.912 170.335 282.199C171.82 290.183 171.217 298.213 170.567 306.242C170.011 313.065 168.758 319.795 167.831 326.572C166.532 336.226 163.61 345.509 160.734 354.746C160.131 356.695 159.806 359.109 158.647 360.316C156.699 362.404 155.817 364.864 154.751 367.278C153.313 370.62 152.803 377.257 154.055 379.996C154.751 380.181 154.983 379.439 155.029 379.16C156.003 373.265 157.812 367.556 158.74 361.708C158.786 361.523 158.6 361.29 158.554 361.105"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.0217 136.828C80.8621 138.731 80.9085 140.959 80.6765 143.001C80.2127 146.993 79.1459 150.799 78.311 154.698C77.2442 159.571 76.0846 164.445 75.0642 169.319C74.7395 170.757 75.2497 172.196 75.4352 173.635C75.8527 176.652 74.554 179.205 73.3944 181.851C71.6318 185.889 70.6114 190.252 69.0807 194.383C66.4833 201.206 62.4943 207.286 58.459 213.32C54.1453 219.818 48.5329 225.341 44.4048 231.979C42.9669 234.3 41.3899 236.481 40.6478 239.173C40.3231 240.426 40.8797 241.401 41.0188 242.469C41.6682 246.971 41.3435 251.287 39.9984 255.65C38.1895 261.545 35.7311 267.161 33.5511 272.87C33.2728 273.613 32.9018 274.356 32.3452 274.959C31.6958 275.655 31.0928 275.841 30.2579 275.191C29.5622 274.681 29.5158 274.448 29.7941 273.474C31 269.714 32.6698 266.094 33.9686 262.381"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.03 137.014C181.328 139.288 181.236 141.934 181.607 144.44C182.256 148.989 183.694 153.305 184.529 157.808C185.224 161.753 187.033 165.512 186.755 169.504C186.477 174.378 187.033 178.834 189.167 183.336C191.486 188.256 192.646 193.733 194.919 198.746C197.377 204.223 200.809 209.003 204.056 213.923C208.694 220.932 214.632 226.919 219.038 234.067C220.383 236.249 221.867 238.477 221.264 241.262C220.847 243.165 220.754 245.068 220.615 247.017C220.337 250.962 221.728 254.49 222.749 258.11C224.279 263.355 226.784 268.229 228.686 273.334C229.567 275.655 230.448 275.934 232.35 275.005"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.304 153.026C114.49 160.546 113.701 167.972 113.052 175.445C112.495 181.943 111.985 188.441 112.263 194.985C112.356 197.678 112.032 200.323 112.171 203.015C112.403 207.703 113.469 212.205 114.351 216.801C115.278 221.535 116.067 226.316 117.366 231.003C118.943 236.62 120.705 242.19 123.767 247.249C123.952 247.527 123.906 247.806 123.952 248.084"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.793 153.026C147.747 161.149 148.489 169.225 149.185 177.301C149.417 180.04 149.788 182.778 149.649 185.517C149.417 189.555 149.788 193.593 150.066 197.631C150.437 203.294 149.509 208.817 148.35 214.248C147.376 218.704 146.68 223.206 145.567 227.615C143.804 234.717 141.671 241.633 137.96 247.945"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5451 87.8594C92.9217 89.2982 92.2724 91.3405 91.4838 93.1971C87.8196 101.83 82.9029 109.442 75.0178 114.734C69.8692 118.215 66.0194 122.903 62.4015 127.776C61.01 129.633 60.5926 132.232 59.8504 134.506C58.227 139.426 58.4589 144.625 57.9023 149.684C57.3457 155.161 57.1138 160.824 53.0784 165.372C51.3623 167.275 50.1099 169.55 48.7184 171.731C44.2656 178.693 41.4826 186.305 39.6737 194.335C38.6532 198.745 37.8183 203.201 36.4268 207.517C35.6847 209.745 34.6643 211.834 33.5511 213.876C31.8813 216.939 31.4638 220.467 30.4898 223.762C29.3302 227.754 27.4285 231.235 24.2744 234.02"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.692 87.6738C170.243 91.4799 171.634 95.8429 173.768 99.8345C177.479 106.843 182.952 112.088 189.26 116.637C193.713 119.793 197.238 123.924 199.974 128.473C201.783 131.536 202.618 135.342 203.268 139.055C204.056 143.743 204.01 148.524 204.52 153.258C205.123 158.596 206.978 163.098 210.179 167.229C214.075 172.195 216.951 177.765 219.224 183.66C220.801 187.791 221.867 192.061 222.749 196.378C224.187 203.526 225.321 209.975 228.568 216.52C229.217 217.773 230.577 219.89 231.227 223.438"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.983 228.683C95.7975 229.425 96.1222 230.121 96.4005 230.725C99.2299 237.13 102.291 243.396 104.981 249.894C107.115 255 108.599 260.384 110.269 265.675C112.032 271.245 113.98 276.769 115.742 282.338C118.015 289.579 120.659 296.727 122.514 304.153C123.488 308.099 124.282 309.709 125.117 313.701C125.21 314.212 125.806 318.085 126.037 318.41"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.392 385.751C140.279 391.135 138.238 396.287 137.45 401.764C136.847 406.22 136.661 410.676 137.125 415.132C137.357 417.406 137.682 419.727 138.285 421.908C138.98 424.275 138.656 426.735 139.027 429.103C139.815 434.255 140.325 439.499 140.836 444.698C141.253 449.015 141.068 453.331 141.114 457.648C141.16 460.108 141.717 462.521 141.207 465.074C140.789 467.116 140.65 469.112 139.119 470.876C137.589 472.686 136.893 477.56 137.403 479.88C137.496 480.345 137.774 480.855 137.635 481.319C136.383 485.914 136.661 490.602 136.615 495.29C136.615 495.708 136.568 496.126 136.615 496.543C136.8 497.704 135.594 499.143 137.032 500.025C138.656 500.999 140.557 501.139 142.32 500.767C143.248 500.582 144.082 500.489 145.01 500.628C146.494 500.814 147.097 500.117 146.865 498.586C146.355 495.43 145.335 495.058 142.552 496.961"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.791 385.379C153.73 388.164 152.988 392.109 151.457 395.683C149.741 399.628 150.02 403.852 149.834 408.029C149.648 412.903 149.834 417.777 149.788 422.604C149.741 426.642 149.788 430.68 149.556 434.718C149.185 441.355 148.257 447.993 146.68 454.444C145.613 458.9 145.103 463.495 144.453 467.998"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.677 385.983C108.785 389.186 109.805 393.456 111.197 397.494C112.032 399.908 112.124 402.507 112.078 405.106C112.032 410.026 112.449 414.993 112.403 419.913C112.356 424.508 112.403 429.103 112.634 433.698C113.006 439.918 113.562 446.091 115 452.218C116.206 457.23 117.18 462.382 117.366 467.627"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.576 93.8468C122.793 89.9479 120.613 85.7242 118.804 81.3147C117.598 78.437 116.299 75.6057 115.371 72.6351C115 71.3819 113.562 72.3102 113.609 71.1963C107.44 74.5846 101.549 78.1121 95.3337 81.1291C91.623 82.9393 87.4949 83.4498 83.5987 84.7959C79.9344 86.0955 76.2702 87.5344 72.9306 89.5302C68.6633 92.083 66.1122 95.9355 64.3033 100.438C62.3552 105.311 60.7781 110.278 59.9896 115.43C59.3402 119.839 59.9896 124.109 61.8449 128.194"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.396 71.2432C153.266 73.9352 158.183 76.4881 162.96 79.3194C166.532 81.408 170.382 82.522 174.278 83.636C179.89 85.1676 185.456 86.885 190.28 90.2269C194.13 92.919 196.681 96.957 198.212 101.32C199.835 105.915 201.459 110.557 202.015 115.477C202.525 119.886 202.015 124.203 200.16 128.334"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.2557 257.089C100.204 261.313 101.317 265.815 102.616 270.224C104.239 275.748 105.955 281.271 108.46 286.516C111.243 292.411 114.397 298.166 116.531 304.386C118.293 309.538 119.592 314.829 121.308 319.981C122.885 324.623 124.48 328.465 124.944 333.431C124.991 334.127 125.299 335.767 126.422 337.393"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.795 257.274C162.636 258.62 162.311 260.431 161.801 262.009C159.946 267.857 158.461 273.845 156.421 279.693C154.936 283.963 152.895 287.955 151.04 291.993C148.906 296.681 146.68 301.276 145.01 306.149C142.645 313.112 140.928 320.259 138.331 327.175C137.403 329.635 136.894 332.116 136.615 334.761C136.48 336.047 135.872 337.072 135.64 337.304"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.478 29.47C151.643 27.1956 152.756 25.0141 153.22 22.9255C153.869 19.8157 153.035 17.1236 152.014 14.4779C149.695 8.62966 145.335 4.54515 139.537 2.13157C137.867 1.43534 136.058 1.01761 134.203 1.06402C130.631 1.20327 127.014 0.553459 123.535 1.66742C115.789 4.17383 110.872 9.41872 108.738 17.2628C107.996 20.0477 108.367 22.7398 108.924 25.4783C109.202 26.6851 109.713 27.9383 108.924 29.1915"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.427 385.565C118.201 388.072 117.087 391.135 116.995 394.384C116.809 400.975 116.763 407.566 116.995 414.111C117.366 425.297 117.922 436.436 118.108 447.622C118.154 452.357 119.209 459.183 120.601 463.825"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.419 394.385C165.326 397.634 166.115 400.836 166.532 404.039C167.089 407.938 167.135 411.883 167.738 415.782C168.109 418.056 167.877 420.331 167.923 422.559C167.97 425.947 166.949 429.057 165.975 432.213C164.12 438.293 162.126 444.327 160.038 450.315C158.925 453.471 157.58 456.488 156.745 459.783C155.771 463.636 154.38 467.442 153.591 471.341C153.22 473.058 153.313 475.008 153.823 476.911C154.101 478.071 153.823 478.491 153.313 480.809"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.07 128.381C182.998 130.516 182.302 132.79 182.673 134.972C183.647 140.774 183.972 146.715 186.245 152.238C188.007 156.555 190.419 160.546 193.434 164.167C194.037 164.909 194.872 165.466 195.661 166.023C197.423 167.23 199.279 166.72 200.392 164.817C201.644 162.635 201.969 160.268 202.293 157.808C202.757 154.048 202.85 150.289 202.479 146.483"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.7487 128.381C79.4704 132.094 79.1921 135.807 78.7747 139.474C77.6615 148.711 74.8321 157.158 68.4776 164.12C67.2252 165.513 65.7874 167.091 63.7929 166.534C61.7984 165.977 61.1954 163.981 60.6852 162.078C59.4792 157.901 59.2473 153.631 59.6648 149.314C59.7575 148.386 59.6648 147.411 59.6648 146.436"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.8179 394.385C96.0758 399.722 95.1945 405.014 94.7306 410.398C94.4987 413.136 94.3132 415.875 94.0349 418.613C93.7102 421.909 94.1277 425.251 94.9626 428.453C96.957 436.112 99.5081 443.631 102.291 451.011C104.657 457.231 106.048 463.682 108.182 469.948C109.109 472.64 108.275 475.193 108.321 477.792C108.367 479.138 108.182 478.964 109.109 481.691"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.728 352.471C138.099 354.745 139.259 356.462 140.696 358.319C144.407 363.007 147.236 368.252 148.164 374.286C148.582 377.117 147.979 379.948 147.329 382.687C147.005 384.033 145.52 384.219 144.5 383.105C143.108 381.619 142.552 379.67 142.042 377.767C140.975 373.543 139.49 369.412 139.305 365.049C139.166 362.218 138.238 359.479 138.702 356.602"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.932 356.787C119.638 359.897 117.551 363.796 115.742 367.88C113.748 372.336 113.516 376.931 114.536 381.666C114.722 382.548 114.907 383.662 115.974 383.894C116.948 384.079 117.505 383.29 118.015 382.501C119.453 380.459 119.685 377.999 120.52 375.725C122.561 370.062 122.978 364.121 123.442 358.226C123.627 356.184 123.488 354.188 124.787 352.471"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.331 93.8477C144.5 95.3329 149.88 98.3963 154.936 102.11C159.621 105.498 163.981 109.35 168.341 113.156C171.541 115.988 175.252 117.983 179.612 118.819C183.787 119.562 187.961 120.443 191.996 121.975C193.295 122.486 194.223 123.878 195.893 123.507"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.535 93.8477C117.737 95.4258 112.542 98.1642 107.718 101.645C103.08 104.987 98.4876 108.422 94.4523 112.414C89.7676 116.962 84.0624 118.68 77.9399 119.747C74.7394 120.304 71.6317 121.232 68.7096 122.671C68.0138 122.996 67.3644 123.043 66.6223 123.043"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.113 134.554C175.113 139.567 175.206 144.579 175.066 149.546C174.788 158.968 173.258 168.158 169.547 176.884"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9846 134.554C86.3352 137.199 86.9382 139.891 86.8918 142.583C86.8454 145.693 86.9846 148.896 87.1237 152.052C87.4948 159.896 88.9327 167.508 91.7157 174.888C91.9476 175.585 92.365 176.188 92.3187 176.931"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5101 235.274C27.2428 233.603 23.393 233.975 19.3113 236.388C15.7861 238.477 13.5597 241.308 11.7508 244.743C10.2201 247.667 8.08649 250.22 5.99924 252.773C5.62817 253.237 5.16435 253.608 4.79329 254.026C4.23669 254.629 4.19031 255.465 4.42222 256.068C4.74691 256.765 5.67457 256.904 6.4167 256.672C7.94735 256.208 9.52439 255.883 10.7767 254.676C12.3538 253.191 13.9772 251.937 15.1832 249.988C16.3427 248.085 18.3836 246.785 20.007 245.207"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.587 235.506C234.344 233.51 238.055 233.835 241.673 235.738C245.569 237.734 248.352 240.844 250.347 244.742C252.109 248.177 254.66 251.055 257.119 254.025C257.49 254.49 257.768 254.954 258 255.418C256.562 256.81 254.243 256.996 252.619 255.65C251.553 254.814 250.439 254.072 249.465 253.051C248.816 252.354 248.352 251.38 247.239 251.194"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.413 60.3359C142.969 61.3571 142.552 62.3318 142.181 63.2137C138.934 70.6865 137.635 78.7163 135.501 86.4676C134.667 89.4845 133.368 92.4087 132.765 95.4721"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.638 60.9395C119.407 61.3572 119.453 61.7285 119.638 62.1927C122.793 70.4545 124.648 79.1341 126.828 87.6745C127.524 90.3201 128.73 92.7801 129.286 95.4722"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.573 481.552C110.223 481.784 110.779 481.645 111.197 481.135C113.469 478.582 115.835 479.789 117.922 481.181C120.149 482.666 122.05 484.616 124.323 486.101C124.972 486.519 125.158 487.447 125.112 488.143C124.88 491.114 125.668 494.038 125.575 497.008C125.483 500.257 125.39 500.443 122.282 500.907C120.195 501.186 118.201 500.304 116.113 500.629C115.278 500.768 114.536 499.7 114.907 498.679C115.974 495.662 116.299 495.059 119.592 497.008"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.8806 224.181C90.927 226.594 92.504 228.544 93.1534 230.772C94.3594 235.135 94.3594 239.683 95.1943 244.139C95.7509 247.202 96.6322 250.173 97.5135 253.144C97.8381 254.258 98.1628 255.279 98.07 256.439"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.068 228.312C165.14 227.848 164.862 228.73 164.445 229.148C161.059 232.443 157.58 235.692 153.962 238.802C149.092 243.026 143.526 246.275 138.145 249.663C137.774 249.895 137.31 250.034 136.939 250.313C135.084 251.52 134.806 251.52 134.064 249.477C133.461 247.806 132.718 246.135 132.394 244.325"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.6489 222.139C92.1796 224.227 94.1277 225.945 95.7047 227.987C96.8179 229.426 98.8588 230.308 100.343 231.747C104.425 235.692 108.46 239.776 113.284 242.84C117.366 245.439 121.262 248.317 125.529 250.545C126.967 251.287 127.292 250.962 127.848 249.848C128.73 248.085 129.101 246.135 129.704 244.279"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6267 251.519C14.8586 251.843 15.0441 252.122 15.0441 252.54C15.0441 256.996 15.1369 261.451 14.9977 265.907C14.905 269.249 14.673 272.637 14.5803 275.979C14.5339 277.418 14.8586 278.764 15.0905 280.064C15.508 282.292 16.8994 280.574 17.9199 280.481"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.073 72.0322C114.026 75.1884 114.119 78.3447 113.516 81.5009C112.959 84.1929 111.243 85.5854 108.924 86.3745C107.579 86.8386 106.327 86.1888 105.677 84.9356C105.26 84.0537 105.909 83.2646 106.651 82.7077C109.11 80.8975 111.197 78.7624 112.82 76.1167"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.793 72.0322C148.211 74.91 147.886 77.7877 148.396 80.6654C148.86 83.2646 150.112 85.1677 152.524 86.1888C154.101 86.8386 155.539 86.3745 156.189 84.982C156.838 83.5431 155.725 82.8469 154.751 82.1043C152.617 80.4333 150.53 78.716 149.417 76.1632"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M234.066 263.633C234.595 264.893 234.808 265.165 235.04 265.908C236.339 270.549 237.498 275.191 238.519 279.879C238.658 280.482 239.215 280.807 239.215 281.41C237.266 283.128 236.71 283.035 235.318 280.714C233.556 277.836 232.721 274.587 231.608 271.478C230.541 268.507 229.474 265.536 228.129 262.612"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.6263 175.027C71.3535 178.369 68.5242 181.201 65.1846 183.428C60.3607 186.631 56.0007 190.391 51.5015 194.011C50.5274 194.8 49.9245 196.239 49.4142 197.446C47.7908 201.437 45.1006 204.733 42.364 207.889C38.6997 212.066 35.3274 216.225 32.3125 220.867C32.127 221.145 31.6329 221.709 31.0568 222.227"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.471 174.842C190.883 179.158 194.687 182.083 198.722 184.728C203.036 187.56 206.885 191.041 210.828 194.382C211.756 195.172 212.173 196.378 212.637 197.492C214.678 202.227 218.064 205.986 221.357 209.792C223.955 212.809 226.088 216.151 228.546 219.261C230.634 221.907 231.979 224.784 233.046 227.941C233.881 230.4 235.968 232.118 237.545 234.114"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M242.461 245.346C243.946 247.017 246.218 248.223 246.821 250.312C247.285 251.89 246.682 253.84 246.775 255.603C247.1 261.545 246.961 267.532 247.285 273.473C247.378 275.423 246.868 277.326 247.193 279.229C247.378 280.296 246.543 280.714 245.987 281.271C245.244 281.039 244.688 280.482 243.899 280.482"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.6468 160.871C74.6468 162.403 74.1829 163.888 73.951 165.373C73.6727 167.462 72.8378 169.411 70.7506 170.525C68.5242 171.732 66.3442 173.125 64.1641 174.424C60.8245 176.374 58.1343 179.019 55.3513 181.619"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.636 160.871C187.915 164.074 187.776 167.462 190.512 169.922C191.393 170.711 192.46 171.407 193.434 171.918C198.212 174.239 202.386 177.302 205.911 181.247C206.097 181.479 206.282 181.479 206.514 181.433"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.245 137.199C121.587 137.013 116.206 138.823 110.594 139.334C108.275 139.52 105.909 139.148 103.59 139.659"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.1906 214.944C89.814 215.037 90.9735 216.104 92.0404 217.033C94.6842 219.307 97.8383 220.653 100.946 222.092C103.033 223.067 104.703 222.138 105.677 219.864C106.419 218.193 106.837 216.476 107.115 214.712"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M179.195 130.841C177.571 130.795 176.736 132.233 175.53 132.93C173.443 134.09 171.448 135.715 169.315 136.457C166.532 137.432 163.61 138.175 160.734 138.917C155.493 140.263 150.437 139.149 145.335 138.453C141.902 137.989 138.563 136.921 135.084 137.2"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.127 481.181C153.081 484.476 155.4 486.565 157.255 488.746C159.621 491.485 163.007 492.645 166.254 493.945C167.831 494.548 168.619 496.73 167.877 498.447C167.228 499.839 166.114 500.443 164.63 499.7"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.675 214.945C171.078 215.641 169.408 217.869 167.135 219.076C165.094 220.143 163.1 221.257 160.966 222.093C158.925 222.882 157.394 222.093 156.467 220.05C155.725 218.426 155.354 216.662 155.029 214.898"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.251 46.9685C151.875 47.1077 152.895 45.9474 153.498 44.8798C155.817 40.5632 157.905 36.1074 157.302 30.9089C157.163 29.6093 156.467 28.7738 155.4 28.4953C154.24 28.2169 153.266 29.0059 152.571 29.8878C151.828 30.7697 151.457 31.7444 151.504 32.9512C151.782 38.1032 150.808 43.1625 149.788 48.1289C149.602 49.15 149.602 50.2175 149.231 51.2387C148.628 52.8168 147.932 54.2092 146.587 55.416C143.433 58.2009 141.068 61.8213 137.682 64.3741C134.62 66.6949 131.188 67.3911 127.431 66.2307C127.06 66.1379 126.781 65.9522 126.55 65.7201"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.576 65.4883C122.885 64.5135 121.401 62.1 119.499 60.2898C117.69 58.526 116.021 56.6694 114.165 54.9521C112.866 53.6989 112.31 51.6102 112.032 49.7072C111.197 44.1838 109.852 38.7068 110.315 33.0442C110.408 31.7446 109.712 30.5378 108.878 29.6095C108.275 28.9133 107.3 28.3563 106.234 28.5419C104.981 28.8204 104.471 29.9808 104.239 31.0019C103.312 34.9008 106.929 44.6944 109.944 46.6438C110.455 46.9687 110.918 46.9687 111.429 47.0151"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.396 54.3486C148.86 55.3233 148.489 56.2516 148.396 57.2264C148.118 61.1252 147.979 65.0241 147.979 68.9229C147.979 69.6656 147.886 70.4546 148.257 71.3365C146.726 71.8935 146.448 73.3324 145.891 74.6784C143.433 80.7587 141.114 86.8855 137.45 92.3625C137.125 92.873 136.986 93.6621 136.244 93.8013"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.891 162.913C204.566 166.673 205.772 170.293 206.097 174.006C206.329 176.466 206.885 178.88 206.839 181.386C206.793 183.104 208.045 184.728 208.463 186.492C208.926 188.534 209.622 190.576 210.643 192.479C210.967 193.036 210.735 193.593 210.828 194.15"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1602 162.913C57.3922 165.93 56.7428 168.808 56.3253 171.732C55.8615 174.888 55.7223 178.091 55.0266 181.154C54.2381 184.867 53.0785 188.534 51.5942 192.062C51.3623 192.572 50.8985 193.083 51.4087 193.686"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M240.235 262.009C241.07 265.258 241.441 268.553 241.905 271.895C242.229 274.309 242.74 276.722 243.389 279.089C243.667 280.111 243.574 281.039 243.528 281.967C243.482 282.849 243.157 283.685 242.09 283.824C241.163 283.917 240.281 283.917 240.235 282.617C240.235 282.385 239.864 282.199 239.632 282.014"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5676 263.633C26.0833 269.064 24.6918 274.541 23.5786 280.064C23.2539 281.689 21.7697 282.942 20.4709 284.102C18.059 282.849 18.1054 281.039 18.7084 278.858C20.1463 273.52 20.4245 267.996 21.6305 262.612"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.129 136.828C169.037 138.824 167.274 139.752 166.161 140.866C162.497 144.626 158.6 148.153 153.73 150.428"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.7 490.185C118.479 492.134 114.165 493.155 109.573 491.809C108.367 491.438 107.161 491.113 106.094 490.417"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.42 490.185C150.901 493.155 145.381 493.016 139.769 490.37"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.042 131.073C83.4594 130.98 83.9696 131.026 84.2479 131.305C84.7118 131.723 85.1292 132.094 85.6858 132.326C90.9735 134.368 93.9421 139.103 97.8382 142.816C100.575 145.415 103.404 147.968 106.837 149.639C107.393 149.917 108.089 149.917 108.506 150.428"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.079 51.7028C134.713 49.4285 131.745 49.4285 128.869 49.7534C127.431 49.8926 125.854 50.3568 124.509 51.2851C123.488 51.9349 123.488 52.6775 124.138 53.3738C128.544 57.8296 134.435 56.7156 137.264 53.4202C137.682 52.956 137.821 52.4919 137.45 51.9349C133.136 53.1881 128.776 53.9772 124.509 51.7492"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5814 126.523C64.5814 127.962 63.8393 129.169 63.3291 130.422C61.9376 133.95 61.1491 137.663 60.2678 141.33C60.175 141.701 60.5925 142.258 59.8503 142.351"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.887 55.1846C113.516 57.1804 113.655 59.1298 113.887 61.1257C114.258 64.4676 114.212 67.8559 113.887 71.1977"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.099 126.756C199.65 131.815 200.995 137.246 202.015 142.769"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.975 469.252C143.062 470.876 144.222 473.383 146.309 475.007C148.535 476.678 150.622 478.489 152.478 480.577"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.331 474.589C140.557 475.424 142.134 477.188 144.314 478.534C142.366 480.205 140.186 480.808 137.914 481.18"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.9215 136.596C96.1683 137.153 99.1369 138.545 102.384 139.056C102.801 139.102 102.987 139.427 103.172 139.706C106.002 143.697 110.408 145.507 114.49 147.735"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.461 139.891C157.765 142.026 155.678 142.768 154.147 143.975C152.292 145.46 150.112 146.435 147.979 147.503"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.958 30.5371C143.479 32.3473 139.908 32.3937 136.29 31.1405"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.907 30.7236C117.969 32.0697 121.123 32.4874 124.37 31.3734C124.741 31.2342 125.205 31.3734 125.576 31.3734"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.895 481.97C152.153 482.063 151.643 481.738 151.04 481.366C147.747 479.231 146.262 479.324 143.247 481.784C141.67 483.084 140.093 484.337 138.516 485.729C138.099 486.101 137.728 486.147 137.264 486.101"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.423 274.959C28.2634 276.862 27.9851 279.136 26.5936 280.947C25.1093 282.85 25.0166 282.989 23.0685 281.736"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.034 34.2036C127.848 36.6172 127.384 38.938 126.41 41.2123C125.668 42.9297 126.271 43.6259 128.451 43.8579C128.776 43.9044 128.915 44.1364 129.054 44.2757C130.167 45.4361 131.234 45.6217 132.394 44.3221C132.533 44.1829 133.043 43.8115 133.553 43.8115C135.548 43.7651 135.409 43.7187 135.223 41.7693C135.084 40.0055 133.971 38.6131 133.832 36.8493C133.785 36.0138 133.693 35.0391 133.553 34.1572"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.239 121.186C185.735 123.646 183.833 126.755 180.864 128.798C179.751 129.587 179.38 130.654 179.566 132.093C179.798 133.578 179.473 135.11 179.798 136.595"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.734 492.691C100.621 493.991 99.6472 495.337 98.2557 496.405C97.1889 497.24 97.328 498.725 97.7455 499.607C98.1166 500.443 99.508 500.443 100.482 500.257C101.503 500.071 102.384 499.654 102.384 498.215C102.337 495.755 104.425 494.641 105.863 493.063"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.376 498.864C147.886 498.632 148.396 499.189 148.582 499.328C150.298 500.953 152.153 500.906 154.194 500.117C154.89 499.839 155.446 500.442 156.049 500.488C157.348 500.628 159.064 500.86 159.667 499.467C160.131 498.353 159.714 496.868 158.925 495.8C158.183 494.826 157.163 494.13 156.467 493.108"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.952 474.589C125.529 476.678 124.416 478.952 124.648 481.227C122.19 480.623 119.917 480.438 117.969 478.72C119.407 477.003 121.262 475.982 122.932 474.868C124.138 474.079 124.23 473.29 123.674 472.362C122.978 471.155 122.468 469.762 121.262 468.834"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.49 498.864C112.82 499.607 111.568 501.139 109.341 500.582C107.532 500.117 107.208 499.653 107.857 497.843C108.321 496.544 109.759 495.569 109.341 493.944"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.897 236.527C225.903 237.873 223.676 238.105 221.311 237.966"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.734 493.294C161.708 494.269 162.682 495.197 163.61 496.172C163.888 496.45 164.166 496.682 164.259 497.193C164.445 498.028 164.723 499.003 164.166 499.653C163.424 500.535 162.172 500.349 161.151 500.256C160.734 500.21 160.317 499.746 159.899 499.467"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.575 28.8665C123.674 27.6597 121.911 26.0352 119.406 26.1744"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.71 493.898C152.802 494.92 153.034 495.894 153.684 496.823C154.287 497.658 154.982 498.633 154.751 499.886"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5715 236.713C36.4269 237.734 38.375 238.291 40.5086 237.734"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.042 26.2209C139.722 26.0353 138.192 27.5205 136.476 28.6809"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6123 233C38.143 234.3 40.1838 234.16 41.9464 234.857"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.207 233.231C223.583 234.253 221.682 234.392 219.873 234.856"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6549 227.477C30.6753 229.101 32.206 230.261 33.5511 231.608"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.569 499.469C104.007 501.094 105.631 500.63 107.3 499.887"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.628 227.477C231.144 228.498 230.541 230.494 228.732 231.19"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.37 481.552C124.741 482.944 125.854 484.29 124.973 485.868"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.4208 499.654C95.3799 500.257 94.2667 499.468 94.1275 497.379C93.9884 495.43 94.4986 494.269 96.5859 493.62C101.595 491.995 105.538 488.978 108.182 484.244C108.692 483.362 108.785 482.387 109.341 481.552"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* part 1 */}
      <Popover trigger="click" content={getContent(quality[0])}>
        <g
          filter="url(#filter0_f_33395_32626)"
          onMouseEnter={() => setHover(0)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 0 ? 1 : 0}
            d="M131.145 66.9112C124.455 66.9112 117.382 59.2618 114.017 55.6621V70.9302C115.241 77.1397 122.097 93.9279 131.145 94.2403C138.907 94.5082 145.839 74.9778 147.471 71.1981L148.006 54.3184C144.03 57.0181 136.498 66.9112 131.145 66.9112Z"
            fill="url(#paint0_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 3 */}
      <Popover trigger="click" content={getContent(quality[2])}>
        <g
          filter="url(#filter1_f_33395_32626)"
          onMouseEnter={() => setHover(2)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 2 ? 1 : 0}
            d="M131.145 94.2404C120.708 93.7046 118.567 81.3797 113.482 71.1982C108.558 74.1991 99.4763 78.879 96.0864 80.5759C92.8749 82.0763 84.1325 84.9521 81.0994 85.6666C85.3814 101.957 87.0763 123.624 86.9871 132.555C99.8332 144.612 115.088 138.717 131.145 137.378V94.2404Z"
            fill="url(#paint1_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 4 */}
      <Popover trigger="click" content={getContent(quality[3])}>
        <g
          filter="url(#filter2_f_33395_32626)"
          onMouseEnter={() => setHover(3)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 3 ? 1 : 0}
            d="M130.61 94.2404C141.048 93.7046 143.189 81.3797 148.273 71.1982C153.198 74.1991 162.279 78.879 165.669 80.5759C168.881 82.0763 177.623 84.9521 180.656 85.6666C176.374 101.957 174.679 123.624 174.768 132.555C161.922 144.612 146.668 138.717 130.61 137.378V94.2404Z"
            fill="url(#paint2_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 6 */}
      <Popover trigger="click" content={getContent(quality[5])}>
        <g
          filter="url(#filter3_f_33395_32626)"
          onMouseEnter={() => setHover(5)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 5 ? 1 : 0}
            d="M131.145 137.377C117.496 138.449 99.0303 144.612 86.9872 132.555C86.9872 155.061 86.9872 155.597 92.3397 192.304C91.5368 200.074 87.999 210.344 87.5224 214.006C98.2275 224.991 116.694 226.867 131.145 228.207V137.377Z"
            fill="url(#paint3_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 7 */}
      <Popover trigger="click" content={getContent(quality[6])}>
        <g
          filter="url(#filter4_f_33395_32626)"
          onMouseEnter={() => setHover(6)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 6 ? 1 : 0}
            d="M131.145 137.377C144.794 138.449 163.26 144.612 175.304 132.555C175.304 155.061 175.304 155.597 169.951 192.304C170.754 200.074 174.292 210.344 174.768 214.006C164.063 224.991 145.597 226.867 131.145 228.207V137.377Z"
            fill="url(#paint4_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 8 */}
      <Popover trigger="click" content={getContent(quality[7])}>
        <g
          filter="url(#filter5_f_33395_32626)"
          onMouseEnter={() => setHover(7)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 7 ? 1 : 0}
            d="M131.163 228.206C109.468 226.331 96.6222 223.116 87.5229 214.006C86.6665 215.721 88.2366 219.097 88.5934 220.436C82.5986 228.796 81.7238 254.553 81.9022 265.449C81.7238 273.13 85.399 292.189 99.3156 303.763C113.232 315.338 123.313 330.199 126.613 336.183C127.042 328.038 126.435 319.214 126.078 315.82C127.684 312.337 128.487 292.51 128.487 280.989C128.487 271.772 130.271 267.324 131.163 266.253V228.206Z"
            fill="url(#paint5_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 9 */}
      <Popover trigger="click" content={getContent(quality[8])}>
        <g
          filter="url(#filter6_f_33395_32626)"
          onMouseEnter={() => setHover(8)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 8 ? 1 : 0}
            d="M131.145 228.206C152.84 226.331 165.686 223.116 174.785 214.006C175.642 215.721 174.072 219.097 173.715 220.436C179.71 228.796 180.585 254.553 180.406 265.449C180.585 273.13 176.909 292.189 162.993 303.763C149.076 315.338 138.996 330.199 135.695 336.183C135.267 328.038 135.873 319.214 136.23 315.82C134.624 312.337 133.822 292.51 133.822 280.989C133.822 271.772 132.037 267.324 131.145 266.253V228.206Z"
            fill="url(#paint6_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 10 */}
      <Popover trigger="click" content={getContent(quality[9])}>
        <g
          filter="url(#filter7_f_33395_32626)"
          onMouseEnter={() => setHover(9)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 9 ? 1 : 0}
            d="M98.594 303.422C88.9588 296.188 82.8029 279.396 82 271C82 298.653 86.8176 315.48 88.9588 326.198C88.9588 328.342 89.1194 332.951 90.8323 336.38C91.9029 342.007 94.4901 347.099 94.847 348.706C96.1852 353.529 95.1146 362.586 96.1852 365.587C96.7205 370.41 97.7911 375.144 97.7911 377.109C88.1559 406.316 91.0107 415.605 93.5088 419.982C93.5088 422.661 92.7058 428.824 98.0587 438.738C103.84 453.315 108.586 477.591 108.765 480.539L121.076 468.481C121.076 458.031 119.203 451.064 123.485 424.001C126.911 402.35 124.02 394.169 122.147 391.579C120.22 386.863 121.076 383.808 121.612 380.325C125.037 369.607 126.072 344.151 126.162 335.845C122.147 327.538 109.568 312.265 98.594 303.422Z"
            fill="url(#paint7_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 11 */}
      <Popover trigger="click" content={getContent(quality[10])}>
        <g
          filter="url(#filter8_f_33395_32626)"
          onMouseEnter={() => setHover(10)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 10 ? 1 : 0}
            d="M163.406 303.422C173.041 296.188 179.197 279.396 180 271C180 298.653 175.182 315.48 173.041 326.198C173.041 328.342 172.881 332.951 171.168 336.38C170.097 342.007 167.51 347.099 167.153 348.706C165.815 353.529 166.885 362.586 165.815 365.587C165.279 370.41 164.209 375.144 164.209 377.109C173.844 406.316 170.989 415.605 168.491 419.982C168.491 422.661 169.294 428.824 163.941 438.738C158.16 453.315 153.414 477.591 153.235 480.539L140.924 468.481C140.924 458.031 142.797 451.064 138.515 424.001C135.089 402.35 137.98 394.169 139.853 391.579C141.78 386.863 140.924 383.808 140.388 380.325C136.963 369.607 135.928 344.151 135.838 335.845C139.853 327.538 152.432 312.265 163.406 303.422Z"
            fill="url(#paint8_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 12 */}
      <Popover trigger="click" content={getContent(quality[11])}>
        <g
          filter="url(#filter9_f_33395_32626)"
          onMouseEnter={() => setHover(11)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 11 ? 1 : 0}
            d="M124.145 474.01C124.646 472.507 122.684 470.044 121.64 469C121.327 469 117.256 474.949 114.125 476.515C111.62 477.767 109.742 480.585 109.115 481.838C108.614 487.349 101.6 491.649 98.1561 493.11C94.1482 493.611 93.7724 496.241 94.0855 497.494C94.837 500.5 96.6949 499.999 97.5298 499.373C99.0328 500.876 101.496 499.999 102.54 499.373C104.043 501.377 106.506 500.416 107.55 499.686C109.053 502.191 113.186 500.312 115.065 499.06C113.562 501.314 120.492 501.251 124.145 500.938C126.901 500.187 125.92 490.814 125.085 486.222C125.836 485.721 125.189 482.464 124.772 480.899C126.024 479.396 124.876 475.68 124.145 474.01Z"
            fill="url(#paint9_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 13 */}
      <Popover trigger="click" content={getContent(quality[12])}>
        <g
          filter="url(#filter10_f_33395_32626)"
          onMouseEnter={() => setHover(12)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 12 ? 1 : 0}
            d="M137.946 474.01C137.445 472.507 139.407 470.044 140.451 469C140.764 469 144.835 474.949 147.966 476.515C150.471 477.767 152.35 480.585 152.976 481.838C153.477 487.349 160.491 491.649 163.935 493.11C167.943 493.611 168.319 496.241 168.006 497.494C167.254 500.5 165.397 499.999 164.562 499.373C163.059 500.876 160.595 499.999 159.552 499.373C158.049 501.377 155.585 500.416 154.542 499.686C153.039 502.191 148.905 500.312 147.027 499.06C148.53 501.314 141.599 501.251 137.946 500.938C135.191 500.187 136.172 490.814 137.007 486.222C136.255 485.721 136.902 482.464 137.32 480.899C136.067 479.396 137.215 475.68 137.946 474.01Z"
            fill="url(#paint10_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 2 */}
      <Popover trigger="click" content={getContent(quality[1])}>
        <g
          filter="url(#filter11_f_33395_32626)"
          onMouseEnter={() => setHover(1)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 1 ? 1 : 0}
            d="M60.76 111.388C63.3292 93.5974 75.2117 87.0063 80.8319 85.9346C85.328 99.0097 86.8088 122.463 86.9872 132.555C86.1308 146.702 88.7713 169.172 90.1987 178.639C83.5616 161.92 82.2591 143.808 82.4375 136.842C82.2591 136.306 81.6882 136.574 80.8318 141.932C79.9754 147.291 76.5498 163.099 74.944 170.333C76.0145 174.62 75.0332 178.55 74.4088 179.979C72.9101 182.551 70.7512 188.91 69.8591 191.768C67.7181 202.271 52.5527 222.401 45.2376 231.154C42.6684 234.369 40.9556 238.567 40.4203 240.264C41.919 242.193 41.58 247.676 41.2232 250.177C41.2232 255.536 36.7628 267.057 34.5326 272.148C33.4621 275.577 31.0534 275.541 29.9829 275.095L28.1096 279.114C26.3968 282.972 24.006 282.686 23.0247 282.061C20.4555 286.348 18.5642 282.776 17.9398 280.453C15.7988 282.168 14.7283 279.382 14.4607 277.774V251.517C13.3902 252.589 10.4463 254.821 9.10818 255.804C4.61207 257.947 3.84488 255.982 4.02329 254.732C5.9502 253.66 9.64343 248.034 11.2492 245.354C15.7453 236.995 21.6866 234.369 24.0952 234.101C28.1631 230.457 30.429 224.724 31.0534 222.312C30.9642 221.33 31.3746 218.24 33.7297 213.738C36.0848 209.237 37.2088 205.611 37.4765 204.361C40.688 184.427 48.4491 170.869 51.9282 166.582C55.3538 163.581 56.9239 158.366 57.2808 156.133C56.2103 142.629 59.8679 131.572 61.8305 127.732C59.0471 122.802 59.9571 114.782 60.76 111.388Z"
            fill="url(#paint11_radial_33395_32626)"
          />
        </g>
      </Popover>

      {/* part 5 */}
      <Popover trigger="click" content={getContent(quality[4])}>
        <g
          filter="url(#filter12_f_33395_32626)"
          onMouseEnter={() => setHover(4)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 4 ? 1 : 0}
            d="M201.263 111.12C198.694 93.3291 186.276 87.2739 180.656 86.2021C176.16 99.2772 174.679 122.73 174.501 132.822C175.357 146.969 172.717 169.44 171.289 178.907C177.926 162.188 179.229 144.076 179.05 137.109C179.229 136.573 179.8 136.841 180.656 142.2C181.513 147.559 184.938 163.367 186.544 170.601C185.473 174.888 186.455 178.817 187.079 180.246C188.578 182.819 190.737 189.177 191.629 192.035C193.77 202.538 208.935 222.669 216.25 231.422C218.82 234.637 220.532 238.834 221.068 240.531C219.569 242.46 219.908 247.944 220.265 250.445C220.265 255.803 224.725 267.324 226.955 272.415C228.026 275.845 230.434 275.809 231.505 275.362L233.378 279.381C235.091 283.24 237.482 282.954 238.463 282.329C241.032 286.616 242.924 283.043 243.548 280.721C245.689 282.436 246.76 279.649 247.027 278.042V251.784C248.098 252.856 251.042 255.089 252.38 256.071C256.876 258.215 257.643 256.25 257.465 255C255.538 253.928 251.844 248.301 250.239 245.622C245.743 237.262 239.801 234.637 237.393 234.369C233.325 230.725 231.059 224.991 230.434 222.58C230.524 221.597 230.113 218.507 227.758 214.006C225.403 209.505 224.279 205.879 224.011 204.628C220.8 184.694 213.039 171.137 209.56 166.85C206.134 163.849 204.564 158.633 204.207 156.4C205.278 142.897 201.62 131.84 199.657 128C202.441 123.07 202.066 114.514 201.263 111.12Z"
            fill="url(#paint12_radial_33395_32626)"
          />
        </g>
      </Popover>
      <defs>
        <filter
          id="filter0_f_33395_32626"
          x="110.017"
          y="50.3184"
          width="41.9884"
          height="47.9248"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter1_f_33395_32626"
          x="77.0994"
          y="67.1982"
          width="58.046"
          height="76.7993"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter2_f_33395_32626"
          x="126.61"
          y="67.1982"
          width="58.046"
          height="76.7993"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter3_f_33395_32626"
          x="82.9872"
          y="128.555"
          width="52.1582"
          height="103.652"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter4_f_33395_32626"
          x="127.145"
          y="128.555"
          width="52.1582"
          height="103.652"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter5_f_33395_32626"
          x="77.8816"
          y="210.006"
          width="57.2814"
          height="130.177"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter6_f_33395_32626"
          x="127.145"
          y="210.006"
          width="57.2814"
          height="130.177"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter7_f_33395_32626"
          x="78"
          y="267"
          width="52.1615"
          height="217.539"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter8_f_33395_32626"
          x="131.839"
          y="267"
          width="52.1615"
          height="217.539"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter9_f_33395_32626"
          x="90"
          y="465"
          width="39.9614"
          height="40.0972"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter10_f_33395_32626"
          x="132.13"
          y="465"
          width="39.9614"
          height="40.0972"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter11_f_33395_32626"
          x="0"
          y="81.9346"
          width="94.1987"
          height="206.131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>
        <filter
          id="filter12_f_33395_32626"
          x="167.289"
          y="82.2021"
          width="94.1987"
          height="206.131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_32626"
          />
        </filter>

        {/* part 1 */}
        <radialGradient
          id="paint0_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(130.531 72.9407) rotate(90) scale(25.3779 24.5807)"
        >
          <stop stopColor={colorMaps[opacities[0]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[0]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 3 */}
        <radialGradient
          id="paint1_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.379 102.861) rotate(90) scale(42.4185 34.8388)"
        >
          <stop stopColor={colorMaps[opacities[2]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[2]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 4 */}
        <radialGradient
          id="paint2_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(155.377 102.861) rotate(90) scale(42.4185 34.8388)"
        >
          <stop stopColor={colorMaps[opacities[3]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[3]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 6 */}
        <radialGradient
          id="paint3_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(107.479 173.742) rotate(90) scale(57.2241 33.2904)"
        >
          <stop stopColor={colorMaps[opacities[5]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[5]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 7 */}
        <radialGradient
          id="paint4_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(154.811 173.742) rotate(90) scale(57.2241 33.2904)"
        >
          <stop stopColor={colorMaps[opacities[6]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[6]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 8 */}
        <radialGradient
          id="paint5_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(105.049 268.293) rotate(90) scale(71.3298 36.7332)"
        >
          <stop stopColor={colorMaps[opacities[7]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[7]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 9 */}
        <radialGradient
          id="paint6_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(157.259 268.293) rotate(90) scale(71.3298 36.7332)"
        >
          <stop stopColor={colorMaps[opacities[8]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[8]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 10 */}
        <radialGradient
          id="paint7_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(103.186 352.763) rotate(90) scale(114.824 32.3185)"
        >
          <stop stopColor={colorMaps[opacities[9]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[9]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 11 */}
        <radialGradient
          id="paint8_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(158.814 352.763) rotate(90) scale(114.824 32.3185)"
        >
          <stop stopColor={colorMaps[opacities[10]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[10]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 12 */}
        <radialGradient
          id="paint9_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(119.006 479.803) rotate(133.091) scale(34.1075 20.9012)"
        >
          <stop stopColor={colorMaps[opacities[11]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[11]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 13 */}
        <radialGradient
          id="paint10_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(143.086 479.803) rotate(46.9092) scale(34.1075 20.9012)"
        >
          <stop stopColor={colorMaps[opacities[12]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[12]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 2 */}
        <radialGradient
          id="paint11_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.5408 177.118) rotate(90) scale(122.158 60.0059)"
        >
          <stop stopColor={colorMaps[opacities[1]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[1]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 5 */}
        <radialGradient
          id="paint12_radial_33395_32626"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(213.947 177.385) rotate(90) scale(122.158 60.0059)"
        >
          <stop stopColor={colorMaps[opacities[4]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[4]]}
            stopOpacity={0.2}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}
