import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import bg404 from '../asserts/images/404.png'
const Container = styled.div`
  height: 100vh;
  padding: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  .center-item {
    width: 80%;
  }
  .center-item {
    display: inline-block;
    width: 80%;
    margin: 5em auto;
  }
  img {
    width: 100%;
  }
`

export default function NotFound(props) {
  return (
    <Container>
      <div>
        <h1 className="title">Error: 404 Page Not Found</h1>
        <div className="center-item">
          <img src={bg404} alt="404" />
        </div>
        <div className="description">
          Sorry,the page you're looking for cannot be accessed.
        </div>
        <div style={{ marginTop: '10px' }}>
          <Link to="/">Refresh Page</Link>
        </div>
      </div>
    </Container>
  )
}
