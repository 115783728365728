import React, { useState } from 'react'
import styled from 'styled-components'
import { Divider } from 'antd-mobile'
import { AiFillCaretDown } from 'react-icons/ai'
import { VictoryPie } from 'victory'
import MarkdownIt from 'markdown-it'
import TimingChart from './timingChart'
import IconCheckGrey from '../../../../asserts/icons/check-grey.svg'

const Container = styled.div`
  padding: 1.43em;
  section {
    background: #ffffff;
    margin-top: 1.43em;
    overflow: hidden;
    border: 1px solid #e3e6ea;
    border-radius: 4px;
    display: flex;
    display: block;
    color: rgba(0, 0, 0, 0.85);
    h1 {
      font-size: 1.75rem;
    }
    &:first-child {
      margin-top: 0;
    }
    .score-left {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #e3e6ea;
      padding-top: 0.36em;
      background: #fafafa;
      .title {
        display: flex;
        justify-content: space-between;
        font-size: 1.125rem;
        margin: 0 1.33em;
        padding: 0.89em 0;
        border-bottom: 1px solid #e3e6ea;
        text-transform: capitalize;
        .value {
          font-weight: 700;
        }
      }
      .pipe-container {
        padding-left: 0.714em;
        padding-top: 0.714em;
        .pipe {
          width: 7.857rem;
          display: inline-block;
          vertical-align: top;
        }
        .pipe-list {
          width: calc(100% - 10.5rem);
          display: inline-block;
          list-style: none;
          font-size: 0.75rem;
          padding-left: 0.834em;
          padding-top: 1.25em;
          padding-right: 2em;
          li {
            display: flex;
            justify-content: space-between;
            color: #4c6072;
            margin-bottom: 0.834em;
            letter-spacing: 0.3px;
            font-family: 'Gilroy-Bold';
            .label-name {
              color: #a5afb8;
              letter-spacing: 0.7px;
              span {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
    .score-right {
      padding: 2em;
      font-size: 0.75rem;
      .information-content {
        background: rgba(38, 67, 130, 0.05);
        padding: 1em 1.33em;
        border-radius: 8px;
        letter-spacing: 0.3px;
        display: flex;
        text-align: left;
        font-size: 0.875rem;
        color: #70808e;
        .avatar {
          color: #479fec;
          font-size: 1rem;
          padding-right: 1em;
        }
      }
      .item-list {
        margin: 0 -2em;
        padding-top: 1.5em;
        display: flex;
        flex-wrap: wrap;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 0 2em;
          line-height: 2.4;
          width: 100%;
          border-right: none;
          .name img {
            width: 0.75rem;
            margin-right: 0.3rem;
            vertical-align: middle;
          }
          .status {
            text-transform: uppercase;
            min-width: 4em;
            font-weight: 700;
            text-align: right;
            &.score-1 {
              color: #ff6b00;
            }
            &.score-2 {
              color: #f5ba40;
            }

            &.score-3 {
              color: #40c47c;
            }
          }
        }
      }
      .more-opr {
        color: #2b4b8f;
        line-height: 1em;
        margin-top: 1.917em;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  .timing-chart-container {
    margin-left: -2em;
    margin-right: -2em;
    & + .adm-divider-horizontal {
      margin: 0;
    }
  }
`

export default function ScoreSection(props) {
  const { score, targetScore } = props
  const showScore = targetScore || score[0]
  return (
    <Container>
      {showScore.answers.map((item, index) => {
        const pipeData = getPipeData(item.entries, item.type)
        return (
          <section key={index}>
            <div className="score-left">
              <div className="title">
                <div>{item.type}</div>
                <div className="value">
                  {item.percentage_score || item.percentage_score === 0
                    ? item.percentage_score + '%'
                    : '--'}
                </div>
              </div>
              <div className="pipe-container">
                <div className="pipe">
                  <VictoryPie
                    innerRadius={100}
                    data={pipeData}
                    style={{
                      labels: { fill: 'transparent' },
                      data: {
                        fill: ({ datum }) => datum.color
                      }
                    }}
                  />
                </div>
                <ul className="pipe-list">
                  {pipeData.map((item, index) => (
                    <li key={index}>
                      <div className="label-name">
                        <span style={{ background: item.color }} />
                        {item.x}
                      </div>
                      <div>{item.y}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="score-right">
              {showScore.recommendation && (
                <Information
                  initialValue={showScore.recommendation[item.type]}
                />
              )}

              <Entries
                entries={item.entries}
                showDetail={props.showDetail}
                version={showScore.section_version}
              />

              {item.type === 'timing' && showScore.section_version > 1 && (
                <>
                  <div className="timing-chart-container">
                    <TimingChart
                      entries={item.entries}
                      version={showScore.section_version}
                    />
                  </div>
                  <Divider />
                </>
              )}
            </div>
          </section>
        )
      })}
    </Container>
  )

  function getPipeData(entries, type) {
    if (type === 'timing') {
      entries = entries.filter((item) => item.score)
    }
    const pipeData = [
      { x: 'AT RISK', y: 0, color: '#FF6B00', score: 1 },
      { x: 'CONCERN', y: 0, color: '#F5BA40', score: 2 },
      { x: 'OPTIMAL', y: 0, color: '#40C47C', score: 3 },
      { x: 'UNKNOW', y: 0, color: 'rgb(201, 208, 224)', score: null }
    ]
    return pipeData
      .map((data) => {
        if (data.score) {
          data.y = entries.filter((entry) => entry.score === data.score).length
        } else {
          data.y = entries.filter((entry) => !entry.score).length
        }

        return data
      })
      .filter((data) => data.y)
  }
}

function Information(props) {
  const { initialValue } = props
  if (!initialValue) return null

  const md = new MarkdownIt('zero').enable([
    'heading',
    'list',
    'link',
    'autolink',
    'emphasis',
    'blockquote'
  ])
  const titleStyle =
    'font-weight: bold;color: #383E48;margin-top:0;margin-bottom:0.5em;'

  let result = md.render(initialValue || '')

  result = result
    .replace('<h1>', `<h1 style="${titleStyle}">`)
    .replace('<h2>', `<h2 style="${titleStyle}">`)
    .replace('<h3>', `<h3 style="${titleStyle}">`)
    .replace('<h4>', `<h4 style="${titleStyle}">`)
    .replace('<h5>', `<h5 style="${titleStyle}">`)

  return (
    <div className="information-content">
      <div className="avatar">
        <Avatar />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: result
        }}
      />
    </div>
  )
}

function Entries(props) {
  const [showAll, setShowAll] = useState(false)
  const entries = showAll ? props.entries : props.entries.slice(0, 8)

  return (
    <>
      <div className="item-list">
        {entries.map((entry, _index) => (
          <div className="item" key={_index}>
            <div
              className="name"
              onClick={() => {
                if (window.webkit) {
                  window.webkit.messageHandlers.jsBridgeShowNutritionEntry.postMessage(
                    {
                      ...entry,
                      version: props.version
                    }
                  )
                }
              }}
            >
              {entry.selected && <img src={IconCheckGrey} alt="" />}
              {entry.subject}
            </div>
            {entry.original_id && entry.original_id.includes('eating_window_')
              ? renderAnswer(entry.answer)
              : renderState(entry.score)}
          </div>
        ))}
      </div>
      {props.entries.length > 8 &&
        (showAll ? (
          <div className="more-opr" onClick={() => setShowAll(false)}>
            View Less
            <AiFillCaretDown style={{ transform: 'rotate(180deg)' }} />
          </div>
        ) : (
          <div className="more-opr" onClick={() => setShowAll(true)}>
            View More <AiFillCaretDown />
          </div>
        ))}
    </>
  )
  function renderState(score) {
    let scoreStr = '--'
    switch (score) {
      case 1:
        scoreStr = 'AT RISK'
        break
      case 2:
        scoreStr = 'CONCERN'
        break
      case 3:
        scoreStr = 'OPTIMAL'
        break
      default:
        break
    }
    return <div className={`status score-${score}`}>{scoreStr}</div>
  }

  function renderAnswer(answer) {
    return <div className="status">{answer.value || answer}</div>
  }
}

function Avatar(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12.1 12.3C11.7 11.6 10.9 11.3 10 11C9.3 10.8 9.1 10.4 9 9.8C10.1 9.4 11 8.3 11 7C11 5.3 9.7 4 8 4C6.3 4 5 5.3 5 7C5 8.3 5.8 9.4 7 9.8C6.9 10.4 6.7 10.8 6 11C5.1 11.3 4.3 11.6 3.9 12.3C2.7 11.2 2 9.7 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.7 13.3 11.2 12.1 12.3Z" />
    </svg>
  )
}
