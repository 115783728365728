import React, { useState } from 'react'
import { Button, Checkbox } from 'antd-mobile'
import pdfIcon from '../../../asserts/images/pdf.svg'
import viewIcon from '../../../asserts/images/view.svg'
import vectorIcon from '../../../asserts/images/vector.svg'
import classNames from 'classnames'
import { SignedIcon } from '.'

const WaiverLiability = ({onClick, signedLiability}) => {
    const [understand, setUnderstand] = useState(true)
  
    return (
      <div className='middle'>
        <div className='title'>Liability Release</div>
        <div className='description b-line'>By signing this form, you acknowledge and accept the risks innvolved in participating in the Apeiron program, such as ones relating to physical activity.</div>
        <div className='sign-title'>Sign with eSignature</div>
        <div className='card'>
          <div className='top'>
            <img src={pdfIcon} alt='PDF'/>
            <div>
              <div className='title'>Liability Release Form - 1</div>
              <div className='desc'>Please review and sign document.</div>
            </div>
          </div>
          {signedLiability[0] !== undefined ? <div className='signed'><SignedIcon/> Signed</div> : <Button shape="default" color="primary" className='review' onClick={onClick} disabled={!understand}>
          <img src={viewIcon} alt=''/>
          Preview & Sign</Button>}
        </div>
        <div className={classNames('understand', {'hidden':signedLiability.length > 0})} onClick={() => setUnderstand(!understand)}>
          <Checkbox checked={understand}/>
          I understand this is a legal representation of my signature.
        </div>
        <div className={classNames('secret', {'hidden':signedLiability.length > 0})}>
          <img align="absmiddle" src={vectorIcon} alt=''/>
          Apeiron Life is compliant with all major eSignature laws including ESIGN, UETA, European eIDAS, and eSignature service provided by HelloSign.
        </div>
      </div>
    )
  }

  export default WaiverLiability
