import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'
import { Button } from 'antd-mobile'
import promptIcon from '../../../asserts/icons/icon-payment-tips.svg'
import cardsIcon from '../../../asserts/icons/payment-support-banks.svg'
import tipcheckedIcon from '../../../asserts/icons/icon-payment-tip-checked.svg'
import './index.css'

export default function Payment() {
  const { setStepInfo, navigate, updateProfile, steps } = useOutletContext()

  useEffect(() => {
    const step = steps.find((item) => item.next === 'payment')
    setStepInfo(step)
  }, [setStepInfo])

  return (
    <div className="payment">
      <div className="middle">
        <div className="tips">
          <img src={promptIcon} alt="" />
          YOUR CARD WILL BE CHARGED ON YOUR PROGRAM START DATE.
        </div>
        <div className="title">SUPPORT CREDIT CARD</div>
        <div className="cards">
          {' '}
          <img src={cardsIcon} alt="" />{' '}
        </div>
        <div className="title">PROVIDED PAYMENT METHOD</div>
        <div className="payment">
          <input placeholder="Name on card" className="name" />
          <input placeholder="XXXX-XXXX-XXXX-XXXX" />
        </div>
        <div className="title">Charge Credit Card</div>
        <div className="membership">
          <div className="ms-top">
            <img alt="" />
            <div>
              <div className="oym">One Year Membership</div>
              <div className="yopt">
                Your one-year program totals $30,000 and includes:
              </div>
            </div>
          </div>
          <ul>
            <li>
              <img src={tipcheckedIcon} alt="" />
              Comprehensive health assessment with advanced biomarker and
              physical testing
            </li>
            <li>
              <img src={tipcheckedIcon} alt="" />
              One-on-one guidance from your dedicated Client Advocate
            </li>
            <li>
              <img src={tipcheckedIcon} alt="" />
              Personalized action plan designed to help you reach your goals
            </li>
          </ul>
        </div>
        <div className="note">
          By checking the checkbox below, you agree to our Terms of Use, Privacy
          Statement, and that you are over 18. You may cancel at any time in
          your membership and will not be charged. Apeiron will automatically
          start your membership at the end of assessment phase and start charge
          the membership fee (currently USD35,000 per quarter) to your payment
          method on a quarterly
        </div>
      </div>
      <div className="control">
        <div className="back" onClick={done}>
          <AiOutlineLeft /> BACK
        </div>
        <Button className="next" shape="default" color="primary" onClick={done}>
          COMPLETE
        </Button>
      </div>
    </div>
  )

  async function done() {
    // todo 1, build credit card; 2, update user profile
    await updateProfile({})
    navigate('/onboarding')
  }
}
