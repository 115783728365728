import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { Toast } from 'antd-mobile'
import GeneralChart from './components/generalChart'
import { getMeByHash, getNutritionScoreDetail } from '../../../api'
import whatIcon from '../../../asserts/icons/nutrition-score/what.png'
import whyIcon from '../../../asserts/icons/nutrition-score/why.png'
import howIcon from '../../../asserts/icons/nutrition-score/how.png'

const Container = styled.div`
  background: white;
  padding-bottom: 2.5rem;
  .chart-container {
    position: relative;
    padding-left: 1.25rem;
    .chart-label {
      position: absolute;
      font-weight: 700;
      font-size: 0.75rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808e;
      height: 0.81rem;
      left: 1.56rem;
      top: 50%;
      margin-top: -1.25rem;
      transform: translateY(-50%);
      .label-text {
        transform: translateX(-50%) rotate(-90deg) scale(0.72);
      }
    }
  }
  .info-container {
    padding: 1rem;
    .icons {
      width: 100%;
      border-radius: 0.5rem;
      border: 1px solid #f4f6f9;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #4c6072;
      font-size: 0.81rem;
      div {
        min-width: 50%;
        text-align: center;
        padding: 0.31rem 0.19rem;
      }
      img {
        margin-bottom: 0.75rem;
      }
    }
    .info-list {
      background: rgba(38, 67, 130, 0.05);
      border-radius: 0.5rem;
      list-style: none;
      padding: 1.5rem;
      margin-top: 1rem;
      text-align: left;
      padding: 1.25rem;

      li {
        display: flex;
        color: #70808e;
        font-size: 0.81rem;
        line-height: 1.1875rem;
        margin-bottom: 0.9375rem;
        white-space: pre-wrap;
        img {
          margin-right: 0.9375rem;
        }
        p {
          margin-top: 0;
          margin-bottom: 1em;
        }
        a {
          color: #2b4b8f;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
`

export default function NutritionDetail(props) {
  const [detail, setDetail] = useState(null)
  const { id, version, hash } = useParams()
  useEffect(() => {
    async function initial() {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      const { person } = await getMeByHash(hash)
      const params = {
        personId: person.id,
        hash,
        sectionVersion: version
      }

      if (Number(version) > 1) {
        if (id === 'Eating Window') {
          params.questionId = id
        } else {
          params.questionOriginalId = id
        }
      } else {
        if (!_.isNaN(Number(id)) || id.trim().includes(' ')) {
          params.questionId = id
        } else {
          params.questionOriginalId = id
        }
      }

      const detail = await getNutritionScoreDetail(params)

      setDetail(detail)

      loading.close()
    }
    initial()
  }, [hash, id, version])
  if (!detail) return null
  return (
    <Container>
      <div className="chart-container">
        <div className="chart-label">
          <div className="label-text">{detail.unit}</div>
        </div>
        {detail && detail.subject !== 'Largest Meal' && (
          <GeneralChart
            details={detail.details}
            choices={detail.choices}
            type={detail.unit}
            subject={detail.subject}
          />
        )}
      </div>
      {detail.unit === 'Food type' && (
        <ul className="choice-list">
          {detail.choices.map((choice, index) => (
            <li key={index}>
              <div className={`score score-${choice.score}`}>
                {choice.score}
              </div>
              <div>{choice.title}</div>
            </li>
          ))}
        </ul>
      )}
      <div className="info-container">
        {detail.icons && (
          <div className="icons">
            <div>
              {detail.icons.map((icon, index) => {
                return (
                  <div key={index}>
                    {icon.path && (
                      <img
                        src={require(`../../../asserts/icons/nutrition-score/${icon.path}`)}
                        alt="icon"
                      />
                    )}
                    <div>{icon.description}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <ul
          className={`info-list ${
            detail.icons && detail.icons.length ? 'has-icon' : ''
          }`}
        >
          {detail.what && (
            <li>
              <div>
                <img src={whatIcon} alt="what" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: detail.what
                }}
              />
            </li>
          )}
          {detail.why && (
            <li>
              <div>
                <img src={whyIcon} alt="why" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: detail.why
                }}
              />
            </li>
          )}
          {detail.how && (
            <li>
              <div>
                <img src={howIcon} alt="how" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: detail.how
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </Container>
  )
}
