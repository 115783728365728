import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

const EditContainer = styled.div`
  border: ${(props) => (props.editing ? '1px solid #ccc' : 'none')};

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .ql-container {
    border: none;
  }

  .custom-font {
    font-family: ${(props) => props.fontFamily || 'Gilroy'};
    font-size: ${(props) => props.fontSize || '1rem'};
    color: ${(props) => props.fontColor || 'rgba(112, 128, 142, 1)'};
    &.placeholder {
      color: rgba(169, 169, 169, 0.7);
    }
  }

  .submit-button {
    text-align: end;
    padding: 17px;
    button {
      color: #264382;
    }
  }
`

export default function EditableText(props) {
  const { className, value, editing, fontStyle = {}, placeholder = '' } = props
  return (
    <EditContainer
      className={classNames(className)}
      editing={editing}
      {...fontStyle}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: value || placeholder
        }}
        className={classNames('custom-font', { placeholder: !value })}
      />
    </EditContainer>
  )
}
