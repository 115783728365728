const MOBILE_OFFSET_SORCE_COMPARE_Y = [0, 5, 5, 30, 30, 15, 10]
const MOBILE_OFFSET_NAME_COMPARE_Y = [0, 5, 5, 30, 30, 15, 10]

const LABEL_TEXT_ANCHOR = [
  'middle',
  'end',
  'end',
  'end',
  'start',
  'start',
  'start'
]

export {
  LABEL_TEXT_ANCHOR,
  MOBILE_OFFSET_SORCE_COMPARE_Y,
  MOBILE_OFFSET_NAME_COMPARE_Y
}
