import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { RangeColors, remoteImgToBase64 } from '../../utils'

export const ARStatus = {
  published: 1,
  draft: 0
}

export const ARHeaderSections = {
  BANNER: {
    name: 'Banner',
    index: 1
  },
  INTRODUCTION: {
    name: 'Introduction',
    index: 2
  },
  PERSONNAL_INFO: {
    name: 'Personal Information',
    index: 3
  },
  HEALTH_COMPASS: {
    name: 'Health Compass',
    index: 4
  },
  CARDIO_HEALTH: {
    name: 'Cardiometabolic Health',
    index: 5
  },
  BONE_HEALTH: {
    name: 'Bone Health',
    index: 6
  },
  BODY_COMP: {
    name: 'Body Composition Results',
    index: 7
  },
  COGNITIVE_HEALTH: {
    name: 'Cognitive Health',
    index: 8
  },
  BALANCE: {
    name: 'Balance',
    index: 9
  },
  MOVEMENT_QUALITY: {
    name: 'Movement Quality',
    index: 10
  },
  MUSCLE_FITNESS: {
    name: 'Muscle Fitness',
    index: 11
  },
  AEROBIC_FITNESS: {
    name: 'Aerobic Fitness',
    index: 12
  }
}

export function handleMetricRanges(ranges = [], person, ageScopes) {
  const gender = person.profile.gender
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const targetScope = ageScopes.find(({ scope: [min, max] }) => {
    if (age >= min && age <= max) return true
    return false
  })

  const ageTag = targetScope && targetScope.tag

  let _ranges = _.cloneDeep(ranges)
  try {
    _ranges = ranges
      .map((range) => ({
        scope:
          range[`${gender}_age_scope`] && range[`${gender}_age_scope`][ageTag],
        name: range.name
      }))
      .filter((range) => range)
      .map(({ scope, name }) => {
        const [min, max, color] = scope
        return {
          min: min,
          max: max,
          color: RangeColors[color],
          name
        }
      })
      .sort((a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE))
  } catch (err) {
    _ranges = ranges
      .sort((a, b) => a.order - b.order)
      .map((range) => {
        range.color =
          range[`${gender}_age_scope`][ageTag] &&
          RangeColors[range[`${gender}_age_scope`][ageTag][2]]
        return range
      })
  }
  return _ranges
}

export const RenderImageUrl = ({ url, width = 212, height = 220 }) => {
  const [data, setData] = useState()
  useEffect(() => {
    if (!url) return
    remoteImgToBase64(url).then((e) => {
      setData(e)
    })
  }, [url])
  return (
    <>
      {' '}
      {data && (
        <img
          style={{ borderRadius: 8, width: width, height: height }}
          src={data}
          alt=""
        />
      )}{' '}
    </>
  )
}
