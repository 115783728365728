import React from 'react'
import { useOutletContext } from 'react-router-dom'
import HealthySignalCard from './components/card'

export default function HealthySignalDetail(props) {
  const { rangeType, data, dateInfo, cardList } = useOutletContext()
  const svgWidth = 'calc(100vw - 7.5rem)'
  const cardProps = { rangeType, data, dateInfo, svgWidth }
  return (
    <>
      {cardList.map((item, index) => (
        <HealthySignalCard {...cardProps} key={index} {...item} />
      ))}
    </>
  )
}
