import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { Pluralize } from '../../../../utils'

const Container = styled.div`
  padding: 2.88em 0;
  margin-bottom: 2.5em;
  border-bottom: 1px solid #f4f6f8;
  text-align: center;
  h1 {
    color: #323f4a;
    font-size: 2.5em;
  }
  .content {
    display: flex;
    justify-content: center;
  }
  .info-list {
    width: 16rem;
    padding: 0 1.5em;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    line-height: 150%;
    letter-spacing: 0.3px;
    white-space: nowrap;
    &:first-child {
      border-right: 1px solid rgba(35, 48, 100, 0.1);
    }
  }
  .info-item,
  .info-value {
    color: #94a0aa;
    div {
      margin: 0.83em 0;
      text-align: left;
    }
  }
  .info-value {
    color: #4c6072;
    div::first-letter {
      text-transform: capitalize;
    }
  }
`

export default function UserInfo(props) {
  const { person, bmi } = props
  const years = person.profile.date_of_birth
    ? moment().diff(person.profile.date_of_birth, 'years')
    : null
  const months = person.profile.client_since
    ? moment().diff(
        moment(person.profile.client_since).format('YYYY-MM-DD'),
        'months'
      )
    : null
  return (
    <Container>
      <h1>{`${person.profile.nickname || person.first_name} ${
        person.last_name
      }`}</h1>
      <div className="content">
        <div className="info-list">
          <div className="info-item">
            <div>Age</div>
            <div>Gender</div>
            <div>Height</div>
          </div>
          <div className="info-value">
            <div>
              {typeof years === 'number' ? years : '--'}{' '}
              {typeof years === 'number' &&
                `${Pluralize('years', years || 1)} old`}
            </div>
            <div>{person.profile.gender}</div>
            <div>
              {person.profile.height_inch || '--'}{' '}
              {person.profile.height_inch > 0 && 'inches'}
            </div>
          </div>
        </div>
        <div className="info-list">
          <div className="info-item">
            <div>Weight</div>
            <div>BMI</div>
            <div>Apeiron Life</div>
          </div>
          <div className="info-value">
            <div>
              {_.round(person.profile.weight_lbs) || '--'}{' '}
              {person.profile.weight_lbs && 'lbs'}
            </div>
            <div>
              {typeof bmi === 'number' ? bmi : '--'}{' '}
              {typeof bmi === 'number' && 'kg/m²'}
            </div>
            <div>
              {typeof months === 'number' ? months : '--'}{' '}
              {typeof months === 'number' && Pluralize('months', months || 1)}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
