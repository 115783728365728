import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd-mobile'
import enabledBg from '../../asserts/icons/2fa/icon-enable2fa.svg'
import { useNavigate } from 'react-router-dom'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

export default function Set2FA(props) {
  const navigate = useNavigate()
  return (
    <div className="main-container">
      <div className='back' onClick={() => navigate(-1)}>
      <MdOutlineArrowBackIosNew/> BACK</div>
      <div className="setting-container">
        <img src={enabledBg} alt="background" />
        <h2>Protect your account with 2 Factor Authentication</h2>
        <p>
          Two-factor (2FA) is an additional security layer for your account to
          protect you from vulnerabilities of a standard password-only approach.
        </p>
        <Link to="/2fa/phone">
          <Button
            block
            type="button"
            color="primary"
            size="large"
            // onClick={onSend}
          >
            Enable 2FA
          </Button>
        </Link>
        <Link to="/">
          <Button color="primary" fill="none">
            Skip, I'll do it later
          </Button>
        </Link>
      </div>
    </div>
  )
}
