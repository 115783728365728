import styled from 'styled-components'
const Container = styled.div`
  background: #f1f3f6;

  .hidden {
    display: none !important;
  }

  .mHidden {
    display: none;
  }

  .middle {
    padding: 1.143rem;
    padding-bottom: 6rem;
    .b-line {
      border-bottom: 1px solid #e6e9f2;
    }
    & > .title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 120%;
      letter-spacing: -0.0125rem;
      color: #29303e;
      margin-bottom: 1.5rem;
      margin-top: 2.1875rem;
    }
    .quesiton {
      font-weight: 400;
      font-size: 1.286rem;
      line-height: 135%;
      font-style: normal;
      font-weight: 500;
      font-size: 1.143rem;
      line-height: 140%;
      letter-spacing: 0.021rem;
      color: #52606c;
      &.bottom {
        margin-bottom: 24px;
      }
    }
    .dontRelase {
      font-style: normal;
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 140%;
      letter-spacing: 0.021rem;
      color: #52606c;
    }
    .adm-space {
      width: calc(100% - 2.286rem);
    }
    .adm-radio {
      background: #ffffff;
      border: 0.071rem solid #e6e9f2;
      border-radius: 0.571rem;
      background-color: white;
      padding: 1.143rem;
      width: 100%;
    }
    .adm-radio .adm-radio-custom-icon {
      display: flex;
    }
    .sign-title {
      padding-top: 1.5rem;
      font-weight: 700;
      font-size: 0.857rem;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }
    .card.disable {
      opacity: 0.6;
    }

    .card {
      background: #fcfdfd;
      border-radius: 0.571rem;
      padding: 1.714rem;
      margin-top: 1.143rem;
      .top {
        display: flex;
      }
      .review {
        border-radius: 2.143rem;
        height: 3.571rem;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 100%;
        letter-spacing: 0.021rem;
        color: #f1f3f6;
        margin-top: 1.714rem;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          margin-right: 0.857rem;
        }
      }
      .top > div {
        margin-left: 1.786rem;
        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.786rem;
          color: #29303e;
        }
        .desc {
          font-style: normal;
          font-weight: 400;
          font-size: 0.857rem;
          line-height: 1.214rem;
          letter-spacing: 0.3px;
          color: #52606c;
        }
      }
      .signed {
        margin-top: 1.714rem;
        color: #22a95f;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.021rem;
        background: #f8f8f9;
        border-radius: 2.143rem;
        height: 3.571rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .signed svg {
        margin-right: 0.786rem;
      }
    }
    .understand {
      margin-top: 2.143rem;
      display: flex;
      align-items: flex-start;
      font-style: normal;
      font-weight: 500;
      font-size: 0.857rem;
      line-height: 140%;
      color: #52606c;
      .adm-checkbox {
        margin-right: 0.857rem;
        .adm-checkbox-icon {
          border-radius: 3px;
        }
        &.adm-checkbox-checked .adm-checkbox-icon {
          border-color: #1bb934;
          background-color: #1bb934;
        }
      }
    }
    .secret {
      margin-top: 12px;
      display: flex;
      align-items: flex-start;
      font-family: 'Lato';
      font-style: italic;
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 150%;
      letter-spacing: 0.021rem;
      color: #5e687d;
      img {
        margin-right: 0.857rem;
      }
    }
    .other .title {
      font-style: normal;
      font-weight: 700;
      font-size: 0.857rem;
      line-height: 1rem;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #38435e;
      margin-top: 2rem;
      margin-bottom: 1.143rem;
    }
  }

  > .control {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5.714rem;
    background-color: white;
    padding: 0px 1.714rem;
    justify-content: space-between;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    .back {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 0.875rem;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.175rem;
      color: #264382;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
    .next {
      padding: 0.8125rem 1.5rem;
      span {
        display: flex;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 0.875rem;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.175rem;
        img {
          margin-left: 0.875rem;
        }
      }
    }
  }

  @media (min-width: 820px) {
    .mHidden {
      display: block;
    }
    > .control {
      left: 0%;
    }
    .middle {
      > .title {
        margin-bottom: 2.286rem;
      }
      .quesiton {
        font-size: 1.286rem;
      }
      .dontRelease {
        font-weight: 500;
        font-size: 1rem;
        line-height: 140%;
      }
      .card {
        display: flex;
        justify-content: space-between;
        .top {
          flex: 1;
          img {
            width: 2.286rem;
          }
        }
        .signed {
          margin-top: 0px;
          padding: 14.5px 63.5px;
          height: auto;
        }
        .review {
          margin-top: 0px;
          width: auto;
          padding: 1.286rem 2.75rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    > .control {
      left: 27%;
    }
  }
`

export default Container
