import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Input, Button, Form } from 'antd-mobile'
import { editClient, enable2FA } from '../../api'
import { saveUser, pattern } from '../../utils'
import phoneIcon from '../../asserts/icons/2fa/phone.svg'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

export default function EnterPhone(props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const { person, phone, setPhone } = useOutletContext()

  const navigate = useNavigate()

  useEffect(() => {
    form && form.setFieldValue('phone', phone || '')
  }, [phone, form])
  return (
    <div className="main-container">
      <div className='back' onClick={() => navigate(-1)}>
      <MdOutlineArrowBackIosNew/> BACK</div>
      <div className="phone-container">
        <img src={phoneIcon} alt=''/>
        <p className="lighter-text upper">2 FACTOR AUTHENTICATION</p>
        <h2>Enter Your Phone Number</h2>
        <p>
        Enter your phone number in here and get a verification code from the Apeiron Life App
        </p>
        <Form
          mode="card"
          layout="horizontal"
          requiredMarkStyle="none"
          form={form}
          footer={
            <Button
              block
              type="button"
              color="primary"
              size="large"
              loading={loading}
              onClick={getCode}
            >
              Next
            </Button>
          }
        >
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'This field is required' },
              {
                pattern: pattern.usPhoneNumber,
                message: 'Please input a valid phone number'
              }
            ]}
          >
            <Input placeholder="Enter your phone number" clearable />
          </Form.Item>
        </Form>
      </div>
    </div>
  )

  async function getCode() {
    setLoading(true)
    try {
      await form.validateFields()
      let { phone } = form.getFieldsValue()
      phone = `+1${phone}`
      const result = await editClient(
        { profile: { phone_number: phone } },
        person.id
      )
      saveUser(result.team.person)
      await enable2FA({ profile: { phone_number: phone } }, person.id)
      setPhone(phone)
      setLoading(false)
      navigate('/2fa/verify')
    } catch (err) {
      setLoading(false)
    }
  }
}
