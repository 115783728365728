import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ProgressCircle, SpinLoading } from 'antd-mobile'
import { AiFillCaretDown, AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import {
  CheckedCircleIcon,
  MailIcon,
  LogoutIcon
} from '../commonComponents/icons'
import SetupScreen from './setup/screen'
import PreScreening from './preScreening'
import PersonalIntroductory from './introductory'
import Questionnaire from './questionnaire'
import WaiverForm from './waiver'
import Payment from './payment'
import { loadUser, logOutAction, saveUser, setTokenItem } from '../../utils'
import { getMe, updateProfileStatus } from '../../api'
import { StepListContainer } from './common'

import phsLogo from '../../asserts/icons/latest-logo.svg'
import setUpWaiverForms from '../../asserts/images/client-setup-waiver-forms.svg'
import setUpQuestionnaire from '../../asserts/images/client-setup-questionnaire.svg'
// import setUpPayment from '../../asserts/images/client-setup-payment.svg'
import setUpComplete from '../../asserts/images/client-setup-complete.svg'
import setUpIllustration from '../../asserts/images/client-setup-Illustration.svg'
import ActionProgress from './common/actionProgress'

export const WaiverFormKey = {
  LIABILITY: 'liability_release_form',
  MEDICAL: 'medical_release_form'
}

const LayoutContainer = styled.div`
  min-height: 100vh;
  header {
    z-index: 99;
    padding: 1.75rem 1.5rem;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .opr {
      display: flex;
      justify-content: space-between;
      color: #9aaac9;
      font-size: 1rem;
      svg {
        font-size: 20px;
      }
    }
  }
  .side-step {
    display: none;
  }
  .show-step {
    z-index: 1;
    background: #ffffff;
    padding: 0.625rem 1rem;
    position: sticky;
    top: 76px;
    /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05); */
    .step-info {
      border: 1px solid #e6e9f2;
      border-radius: 9px;
      display: flex;
      padding: 1rem;
      color: #8d919a;
      font-size: 0.75rem;
      align-items: center;
      .step-text {
        width: calc(100% - 3.5rem);
      }
      .tag {
        color: #29303e;
        font-weight: 500;
        font-size: 1.143rem;
      }
      .antd-mobile-icon {
        font-size: 0.5rem;
      }
    }
  }
  @media (max-width: 767px) {
    background: #f1f3f6;
    header {
      background: #ffffff;
    }
  }
  @media (min-width: 768px) and (min-height: 767px) {
    .outlet-container {
      padding: 2.25rem;
      padding-top: ${(props) =>
        props.isSetUpView ? 'calc(50vh - 5.6rem)' : '2.25rem'};
      overflow: ${(props) =>
        props.isSetUpView ? 'hidden' : 'auto'} !important;
      & > div {
        transform: ${(props) =>
          props.isSetUpView ? 'translateY(-50%)' : 'none'};
      }
    }
    header {
      background: ${(props) => (props.isSetUpView ? 'transparent' : '#ffffff')};
      box-shadow: ${(props) => (props.isSetUpView ? 'none' : 'inset')};
    }
  }
  @media (min-width: 1024px) {
    header .opr {
      display: ${(props) => (props.isSetUpView ? 'flex' : 'none')};
    }
    .show-step {
      display: none;
    }
    .side-step {
      position: relative;
      display: inline-block;
      width: calc(27% - 2rem);
      background: #f1f3f6;
      border-right: 1px solid #e6e9f2;
      height: calc(100vh - 10.625rem);
      vertical-align: top;
      padding: 2.5rem 1rem;
      .opr {
        position: absolute;
        color: #264382;
        font-size: 0.625rem;
        bottom: 0;
        text-transform: uppercase;
        div {
          padding: 0.8rem 0;
        }
        .customize-icon {
          font-size: 1rem;
          margin-right: 0.63rem;
        }
      }
    }
    .outlet-container {
      display: inline-block;
      width: ${(props) =>
        props.isSetUpView ? 'calc(100% - 4.5rem)' : 'calc(72% - 4rem)'};
      max-height: ${(props) =>
        props.isSetUpView ? '45vh' : 'calc(100vh - 10.3rem)'};
      overflow: scroll;
    }
  }
`
function OnboardingLayout(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const [person, setPerson] = useState(() => {
    if (navigator.userAgent.includes('life.apeiron.ios')) {
      return { profile: { onboarding_steps: {} } }
    } else {
      return loadUser()
    }
  })
  const [stepInfo, setStepInfo] = useState(null)
  const [subActive, setSubActive] = useState(1)
  const [activeStep, setActiveStep] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [hideStep, setHideStep] = useState(false)

  const refreshPerson = async (token) => {
    setTokenItem(token)
    const result = await getMe()
    setPerson(result.person)
    saveUser(result.person)
  }

  useEffect(() => {
    window.handleMessageFromNative = (token) => {
      refreshPerson(token)
    }
    return () => {
      delete window.handleMessageFromNative
    }
  }, [])

  useEffect(() => {
    if (navigator.userAgent.includes('life.apeiron.ios')) {
      window.webkit.messageHandlers.loadUser.postMessage({})
    }
  }, [])

  const {
    profile: {
      // waiver_required,
      questionnaire_required,
      // payment_required,
      completed_signature,
      // introductory_required,
      pre_screening_completed,
      // pre_screening_locked,
      membership,
      onboarding_steps: {
        introductory_completed_steps,
        // onboarding_required,
        waiver_forms_skipped
        // billing_info_skipped,
        // questionnaire_skipped
      }
    }
  } = person

  const steps = [
    {
      showIndex: 1,
      tag: 'Pre-Assessment Medical Review',
      title: 'Let’s get your Apeiron Life program started',
      description:
        'Your safety is our priority. We follow evidence-based guidelines to evaluate each client for participation in our physical assessments',
      introduction: 'Why we need you to do this Pre-Assessment Medical Review?',
      min: '1 min',
      setupImage: setUpIllustration,
      isDone: pre_screening_completed,
      targetUrl: '/onboarding/pre-screening',
      disabledBack: true,
      next: 'pre-screening'
    },
    {
      showIndex: 2,
      tag: 'Liability Release',
      description:
        'This form releases Apeiron Life from liability for all membership-related activities.',
      introduction: 'Why do we need a liability release?',
      min: '5 min',
      setupImage: setUpWaiverForms,
      isDone: (completed_signature || []).includes(WaiverFormKey.LIABILITY),
      targetUrl: '/onboarding/waiver/liabilities',
      next: 'waiver/liabilities'
    },
    {
      showIndex: 3,
      tag: 'Member Information',
      description:
        // eslint-disable-next-line quotes
        "We'll start with some logistical information so we can start scheduling your appointments",
      introduction: 'Why we need you to do this Member Information?',
      min: '3 min',
      setupImage: setUpWaiverForms,
      isDone:
        introductory_completed_steps &&
        introductory_completed_steps.length === 2 &&
        membership,
      targetUrl: '/onboarding/introductory',
      disabledBack: true,
      next: 'introductory',
      subSteps: [
        {
          showIndex: 1,
          name: 'Personal Information'
        },
        {
          showIndex: 2,
          name: 'Insurance Information'
        }
      ]
    },
    {
      showIndex: 4,
      tag: 'Medical Release',
      description:
        'These forms include a medical release to your care team and a liability release.',
      introduction: 'Why do we need waivers?',
      min: '5 min',
      setupImage: setUpWaiverForms,
      isDone:
        (completed_signature || []).includes(WaiverFormKey.MEDICAL) ||
        (waiver_forms_skipped || []).length > 0,
      targetUrl: '/onboarding/waiver/medical',
      next: 'waiver/medical'
    },
    {
      showIndex: 5,
      tag: 'Initial Intake Questionnaire',
      description:
        'Alongside your assessment, these questionnaires factor into your guidance and action plan. This final step will take 5 minutes.',
      introduction: 'Why do we need Health and Lifestyle Information?',
      min: '10 min',
      setupImage: setUpQuestionnaire,
      isDone: !questionnaire_required,
      targetUrl: '/onboarding/questionnaire',
      next: 'questionnaire',
      subSteps: [
        {
          showIndex: 1,
          param: 'lifestyle',
          name: 'Lifestyle & goals',
          sectionIds: 'lifestyle_goals',
          next: '/onboarding/questionnaire/medical',
          isChecked: false
        },
        {
          showIndex: 2,
          param: 'medical',
          name: 'Medical history',
          sectionIds: 'medical_history',
          next: '/onboarding/questionnaire/medication',
          isChecked: false
        },
        {
          showIndex: 3,
          param: 'medication',
          name: 'Medication & supplements',
          sectionIds: 'medications_supplements',
          next: '/onboarding/questionnaire/nutrition',
          isChecked: false
        },
        {
          showIndex: 4,
          param: 'nutrition',
          name: 'Nutrition Signals',
          sectionIds: 'nutrition_eating_habits',
          next: '/onboarding/questionnaire/sleep',
          isChecked: false
        },
        {
          showIndex: 5,
          param: 'sleep',
          name: 'Sleep',
          sectionIds: 'sleep',
          isChecked: false
        }
      ]
    },
    // {
    //   showIndex: 5,
    //   tag: 'Billing Information',
    //   title:
    //     'Your questionnaire is completed. Lastly, we’d like to get your credit card on file.',
    //   description:
    //     'Add your credit card for incidentals or any additional services you may wish to sign up for later.',
    //   introduction: '',
    //   setupImage: setUpPayment,
    //   isDone: !payment_required,
    //   targetUrl: '/onboarding/payment',
    //   next: 'payment'
    // },
    {
      tag: 'Finished',
      title: 'You’re all done. \nAnd on your way to a better you.',
      introduction: 'we’ll use this information to design your action plan.',
      setupImage: setUpComplete,
      // btnHandle: () => skipOnboardingStep('onboarding_required', account.user),
      targetUrl: '/'
    }
  ]
  return (
    <LayoutContainer
      className="onboarding"
      isSetUpView={location.pathname === '/onboarding'}
    >
      <header>
        <img src={phsLogo} alt="logo" />
        <div className="opr">
          <LogoutIcon
            onClick={() => {
              if (navigator.userAgent.includes('life.apeiron.ios')) {
                window.webkit.messageHandlers.signOut.postMessage({})
              } else {
                logOutAction()
              }
            }}
          />
        </div>
      </header>
      {person.id ? (
        <>
          {location.pathname !== '/onboarding' && stepInfo && !hideStep && (
            <>
              <div className="show-step">
                <div className="step-info" onClick={() => setModalShow(true)}>
                  <ProgressCircle
                    percent={(stepInfo.showIndex / 5) * 100}
                    style={{ '--size': '40px', marginRight: '0.75rem' }}
                  >
                    <span>{stepInfo.showIndex}</span>
                  </ProgressCircle>
                  <div className="step-text">
                    STEP {stepInfo.showIndex} OF 5
                    <div className="tag">{stepInfo.tag}</div>
                  </div>
                  <AiFillCaretDown />
                </div>
              </div>
              <div className="side-step">
                <StepListContainer notSetUpView className="on-side">
                  {steps
                    .filter((item) => item.showIndex)
                    .map((item, index) => (
                      <li
                        key={index}
                        className={`${index === activeStep ? 'active' : ''} ${
                          item.isDone ? 'done' : ''
                        }`}
                      >
                        {item.isDone ? (
                          <CheckedCircleIcon />
                        ) : (
                          <span>{item.showIndex}</span>
                        )}{' '}
                        <div className="tag">{item.tag}</div>
                        {index === activeStep || item.isDone ? (
                          <AiOutlineUp />
                        ) : (
                          <AiOutlineDown />
                        )}
                      </li>
                    ))}
                </StepListContainer>
                <div className="opr">
                  <div onClick={logOutAction}>
                    <LogoutIcon />
                    LOG OUT
                  </div>
                  <div>
                    <MailIcon />
                    Have a question?
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="outlet-container">
            <Outlet
              context={{
                person,
                setPerson,
                stepInfo,
                setStepInfo,
                subActive,
                setSubActive,
                navigate,
                updateProfile,
                steps,
                activeStep,
                setActiveStep,
                setHideStep
              }}
            />
          </div>
        </>
      ) : (
        <SpinLoading
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}

      <ActionProgress
        modalShow={modalShow}
        setModalShow={setModalShow}
        steps={steps}
        stepInfo={stepInfo}
        subActive={subActive}
      />
    </LayoutContainer>
  )

  async function updateProfile(profile, callback) {
    if (_.isEmpty(profile)) return
    await updateProfileStatus(person.id, profile, (person) => {
      setPerson(person)
      callback && callback(person)
    })
  }
}

export {
  OnboardingLayout,
  SetupScreen,
  PreScreening,
  PersonalIntroductory,
  Questionnaire,
  WaiverForm,
  Payment
}
