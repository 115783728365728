import styled, { createGlobalStyle } from 'styled-components'

const Container = styled.div`
  .content {
    margin: 24px 16px;
    margin-top: 94px;
    padding-top: 24px;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: #29303e;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.3px;
    text-align: left;
    font-family: Heebo;
    margin-bottom: 24px;
    margin-top: 16px;
    color: #5c626a;
    a {
      color: #5ea7f8;
    }
  }
  .billing {
    margin-bottom: 24px;
    .title {
      color: #8d99a5;
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.3px;
      margin-bottom: -9px;
      margin-left: 10px;
      position: relative;
      z-index: 13;
      ::before {
        content: '*';
        color: #f5222d;
      }
    }
    .mask {
      background-color: white;
      width: 160px;
      height: 10px;
      position: absolute;
      margin-bottom: -5px;
      margin-left: 10px;
      z-index: 12;
    }
    .adm-dropdown {
      border-radius: 8px;
      border: 1px solid rgba(147, 147, 147, 0.2);
      background: #fff;
      display: block;
      .adm-dropdown-item {
        display: block;
        .adm-dropdown-item-title {
          display: flex;
          justify-content: space-between;
          padding: 16px;
          .adm-dropdown-item-title-text {
            color: #52606c;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.3px;
            text-transform: capitalize;
          }
          .adm-dropdown-item-title-arrow {
            svg {
              color: #3f3f3f;
            }
          }
        }
      }
    }
  }
  .tips {
    margin-top: 8px;
    margin-bottom: 24px;
    color: #264382;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  .license {
    padding: 16px;
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #e6e9f2;
    background: #fff;
    align-items: flex-start;
    /* input[type='checkbox'] {
      height: 35px;
      width: 35px;
      accent-color: #1fa734;
    }*/
    .adm-checkbox-custom-icon {
      margin-left: 0;
    }
    div {
      margin-left: 12px;
      color: #52606c;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      a {
        color: #5ea7f8;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        text-decoration-line: underline;
      }
    }
  }
`

export const GlobalPopup = createGlobalStyle`
  .adm-dropdown-popup .adm-popup-body{
    margin: 16px;
    margin-top: 0px;
    width: calc(100vw - 32px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export default Container
