import React, { useState, useEffect, useRef, useReducer } from 'react'
import styled, { css } from 'styled-components'
import { Swiper, Toast } from 'antd-mobile'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom' //Outlet, useNavigate, useLocation
import _ from 'lodash'
import moment from 'moment'
import NativeItem from './components/nativeItem'
import Indicator from './components/indicator'
import {
  getHealthySignals,
  getHealthySignalsSettings,
  getMeByHash,
  getHealthySignalsPercentageTarget
} from '../../../api'
import {
  prevWeekDate,
  nextWeekDate,
  getThisWeekRange
} from '../../../utils/dateHandles'

const Container = styled.div`
  header {
    background: #ffffff;
    height: 6.5rem;
    position: relative;
    .type-switch {
      position: absolute;
      background: #edf0ff;
      color: #0a2845;
      display: flex;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 1.5rem;
      padding: 2px;
      & > div {
        width: 7.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-family: Inter, Gilroy;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0px;
        text-align: center;
        border-radius: 100%;
        &.active {
          background: #0077e4;
          border-radius: 1.5rem;
          color: #ffffff;
        }
      }
    }
    .indicators {
      transform: rotatey(180deg);
      position: absolute;
      bottom: 0;
      width: 100vw;
      overflow: hidden;
      .adm-swiper-track-inner {
        ${(props) =>
          css`
            .adm-swiper-slide:nth-child(${props.activeIndex + 2 - 1})
              .adm-swiper-item
              > div {
              background: -webkit-linear-gradient(right, #ffffff, #383e48);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .adm-swiper-slide:nth-child(${props.activeIndex + 2 + 1})
              .adm-swiper-item
              > div {
              background: -webkit-linear-gradient(left, #ffffff, #383e48);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          `}
      }
    }
    .adm-swiper-indicator {
      bottom: 0;
      .adm-page-indicator-dot {
        background: transparent;
        display: none;
      }
      .adm-page-indicator-dot-active {
        width: 33vw;
        display: block;
        background: linear-gradient(221deg, #0077e4 0.45%, #0c4bc1 77.66%);
      }
    }
  }
  .page-indicators {
    display: flex;
    .active {
      color: blue;
    }
    & > div {
      width: 33vw;
      text-align: center;
    }
  }
  .container {
    height: calc(100vh - 6.5rem);
    transform: rotatey(180deg);
    .adm-swiper-indicator {
      display: none;
    }
  }
  .swiper-content {
    height: 100%;
  }
`

const initialState = {
  dataList: [{ isLoading: true }, { isLoading: true }],
  targets: {},
  settings: []
}

export default function NativeHSD(props) {
  const { hash } = useParams()
  const [searchParams] = useSearchParams()
  const ref = useRef(null)
  const indicatorsRef = useRef(null)
  const [pageLoading, setPageLoading] = useState(true)
  const [rangeType, setRangeType] = useState('week')
  const [activeIndex, setActiveIndex] = useState(getDefaultActive())
  const [showWorkoutPopup, setShowWorkoutPopup] = useState(false)
  const [activeGroups, setActiveGroups] = useState(
    activeIndex === 0
      ? [activeIndex, activeIndex + 1, activeIndex + 2]
      : [activeIndex - 1, activeIndex, activeIndex + 1]
  )
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let from, to

    if (searchParams.has('from') && searchParams.has('to')) {
      from = searchParams.get('from')
      to = searchParams.get('to')
    }
    dispatch({
      type: 'initial',
      payload: { from: from && moment(from), to: to && moment(to) }
    })
  }, [searchParams])

  const { dataList, targets, settings } = state

  return (
    <Container activeIndex={activeIndex}>
      <header>
        <div className="type-switch">
          <div
            className={rangeType === 'week' ? 'active' : ''}
            onClick={() => setRangeType('week')}
          >
            Week
          </div>
          <div
            className={rangeType !== 'week' ? 'active' : ''}
            onClick={() => setRangeType('avg')}
          >
            3-week AVG
          </div>
        </div>
        {!pageLoading && (
          <div className="indicators">
            <Swiper
              style={{ '--height': 100 }}
              defaultIndex={activeIndex}
              ref={indicatorsRef}
              allowTouchMove={false}
              slideSize={33.3}
            >
              <Swiper.Item>
                <div />
              </Swiper.Item>

              {dataList.map((item, index) => {
                return (
                  <Swiper.Item key={index}>
                    <Indicator
                      isActive={
                        dataList[activeIndex] &&
                        item.weekNth === dataList[activeIndex].weekNth
                      }
                      gesture={(clientX) => {
                        handleGesture(clientX, index)
                      }}
                      onClick={() => swiperTo(index)}
                      item={item}
                    />
                  </Swiper.Item>
                )
              })}
              <Swiper.Item>
                <div />
              </Swiper.Item>
            </Swiper>
          </div>
        )}
      </header>
      <div className="container">
        <Swiper
          style={{ '--height': '100%' }}
          defaultIndex={activeGroups.findIndex(
            (index) => index === activeIndex
          )}
          ref={ref}
          allowTouchMove={false}
          onIndexChange={onIndexChange}
          loop={true}
        >
          <Swiper.Item>
            {dataList[activeGroups[0]] ? (
              <NativeItem
                gesture={(clientX) => handleGesture(clientX, activeGroups[0])}
                {...{
                  settings,
                  targets,
                  item: dataList[activeGroups[0]]
                }}
                rangeType={rangeType}
                showWorkoutPopup={
                  showWorkoutPopup && activeGroups[0] === activeIndex
                }
                setShowWorkoutPopup={setShowWorkoutPopup}
              />
            ) : (
              <div />
            )}
          </Swiper.Item>
          <Swiper.Item>
            {dataList[activeGroups[1]] ? (
              <NativeItem
                gesture={(clientX) => handleGesture(clientX, activeGroups[1])}
                {...{
                  settings,
                  targets,
                  item: dataList[activeGroups[1]]
                }}
                rangeType={rangeType}
                showWorkoutPopup={
                  showWorkoutPopup && activeGroups[1] === activeIndex
                }
                setShowWorkoutPopup={setShowWorkoutPopup}
              />
            ) : (
              <div />
            )}
          </Swiper.Item>
          <Swiper.Item>
            {dataList[activeGroups[2]] ? (
              <NativeItem
                gesture={(clientX) => handleGesture(clientX, activeGroups[2])}
                {...{
                  settings,
                  targets,
                  item: dataList[activeGroups[2]]
                }}
                rangeType={rangeType}
                showWorkoutPopup={
                  showWorkoutPopup && activeGroups[2] === activeIndex
                }
                setShowWorkoutPopup={setShowWorkoutPopup}
              />
            ) : (
              <div />
            )}
          </Swiper.Item>
        </Swiper>
      </div>
    </Container>
  )

  function reducer(state, action) {
    if (action.type === 'initial') {
      const { from, to } = action.payload
      initial(from, to)
      return state // _.assign({}, state, action.payload)
    }

    return _.assign({}, state, action.payload || {})
  }

  async function initial(from, to) {
    if (!(from && to)) {
      const { startDate, endDate } = getThisWeekRange()
      from = startDate
      to = endDate
    }

    const { settings } = await getHealthySignalsSettings(hash)
    const { person } = await getMeByHash(hash)
    const result = await getHealthySignalsPercentageTarget(
      person.id,
      moment(from).format('YYYY-MM-DD'),
      hash
    )
    const targets = {}
    _(result).forEach((item) => {
      targets[item.domain] = item.target
    })

    const activeIndex = getDefaultActive()
    const dataList = new Array(activeIndex + 3).fill({
      isLoading: true
    })

    let _payload = await fetchData(from, to, true)
    dataList[activeIndex] = _payload
    // const dataList = [_payload]
    dispatch({ type: 'update', payload: { dataList, targets, settings } })
    const { startDate: _from, endDate: _to } = prevWeekDate(
      from.clone(),
      to.clone()
    )
    _payload = await fetchData(_from, _to)
    dataList[activeIndex + 1] = _payload
    if (activeIndex) {
      const { startDate: __from, endDate: __to } = nextWeekDate(
        from.clone(),
        to.clone()
      )
      _payload = await fetchData(__from, __to)
      dataList[activeIndex - 1] = _payload
    } else {
      const { startDate: __from, endDate: __to } = prevWeekDate(
        _from.clone(),
        _to.clone()
      )
      _payload = await fetchData(__from, __to)
      dataList[activeIndex + 2] = _payload
    }

    dispatch({ type: 'update', payload: { dataList } })
    setPageLoading(false)
    setShowWorkoutPopup(true)
  }

  async function fetchData(from, to, isInitial) {
    let loading
    if (isInitial) {
      loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
    }
    const requestType = 'week'
    const result = await getHealthySignals({
      rangeType: requestType,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      hash: hash
    })
    const { startDate } = getThisWeekRange()
    const payload = {
      dateInfo: { from: from.clone(), to: to.clone() },
      data: requestType === 'week' ? result.week_data : result.month_data,
      progress: result.progress,
      progressText: result.progress_text,
      moreProgressAvg: result.more_process_avg,
      isThisWeek: moment(from.format('YYYY-MM-DD')).isSame(
        startDate.format('YYYY-MM-DD')
      ),
      weekNth: moment(from).get('week')
    }
    if (loading) {
      setTimeout(() => {
        loading.close()
      }, 2000)
    }
    return payload
  }

  async function onIndexChange(changeIndex) {
    if (showWorkoutPopup) setShowWorkoutPopup(false)
    const listIndex = activeGroups[changeIndex]
    const { dateInfo, isThisWeek } = dataList[listIndex]
    setActiveIndex(listIndex)
    if (isThisWeek || !dateInfo) return

    const { from, to } = dateInfo

    let prePayload = dataList[listIndex + 1],
      nextPayload = dataList[listIndex - 1]

    if (!prePayload || prePayload.isLoading) {
      const { startDate, endDate } = prevWeekDate(from.clone(), to.clone())
      dataList[listIndex + 1] = await fetchData(startDate, endDate)
    }
    if (!nextPayload || nextPayload.isLoading) {
      const { startDate, endDate } = nextWeekDate(from.clone(), to.clone())
      dataList[listIndex - 1] = await fetchData(startDate, endDate)
    }
    switch (changeIndex) {
      case 0:
        activeGroups[2] = listIndex - 1
        activeGroups[1] = listIndex + 1
        break
      case 1:
        activeGroups[0] = listIndex - 1
        activeGroups[2] = listIndex + 1
        break
      case 2:
        activeGroups[0] = listIndex + 1
        activeGroups[1] = listIndex - 1
        break
      default:
        break
    }
    dispatch({ type: 'update', payload: { dataList } })
    setActiveGroups(activeGroups)
  }

  function handleGesture(clientX, index) {
    if (pageLoading) return
    const item = dataList[index]
    if (ref.current) {
      clientX > 0
        ? dataList[index + 1] && ref.current.swipeNext()
        : !item.isThisWeek &&
          !dataList[index - 1].isLoading &&
          ref.current.swipePrev()
    }
    if (indicatorsRef.current) {
      clientX > 0
        ? dataList[index + 1] && indicatorsRef.current.swipeNext()
        : !item.isThisWeek &&
          !dataList[index - 1].isLoading &&
          indicatorsRef.current.swipePrev()
    }
  }

  function swiperTo(index) {
    if (indicatorsRef.current) {
      indicatorsRef.current.swipeTo(index)
    }
    if (ref.current) {
      index > activeIndex ? ref.current.swipeNext() : ref.current.swipePrev()
    }
  }

  function getDefaultActive() {
    if (searchParams.has('from') && searchParams.has('to')) {
      const from = searchParams.get('from')
      const { startDate } = getThisWeekRange()
      return startDate.diff(moment(from), 'week')
    } else {
      return 0
    }
  }
}
