import React, { useState } from 'react'
import styled from 'styled-components'
// import { Button, Row, Col, Divider } from 'antd-mobile'
import _ from 'lodash'
// import { Growth } from '../../trends/customTooltip'
// import Chart from '../../trends/chart'
import { FlexBox } from '../../commonComponents/FlexBox'
import { Growth } from './Widgets'
import Chart from './TrendsChart/TrendsChart'

const Container = styled.div`
  border-top: ${(props) => (props.isFirst ? 'none' : '1px solid #e4e7eb')};
  &.open-detail {
    border-bottom: 1px solid #e4e7eb;
  }
  .main-container {
    /* display: flex; */
  }
  .metric-info {
    position: relative;
    padding: 0px;
    margin-top: 24px;
    .draggable-area {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .name {
      color: #323f4a;
      font-size: 1rem;
      font-weight: 600;
      & > span {
        margin-right: 10px;
      }
    }
    .vs {
      color: #ced3d9;
      font-size: 14px;
    }
    .add {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .discard {
        margin-left: 8px;
        width: 30px;
        height: 30px;
        background: rgba(154, 165, 177, 0.2);
        border: none;
        border-radius: 6px;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line-chart {
    svg {
      overflow: visible;
      position: relative;
      z-index: 3;
    }
  }
  .detail-container {
    padding: 12px 0;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .metric-note {
      padding: 0 6px;
      color: #7b8794;
      font-size: 9px;
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 8px;
      }
    }

    .ant-btn {
      border-radius: 6px;
      margin-left: 16px;
    }
  }
`

export default function CardiometabolicTrendsMetric(props) {
  const [showDetail] = useState(false) // setShowDetail
  const [notes, setNotes] = useState(null)
  const {
    metric,
    comparedMetric,
    dateRange,
    colorArr,
    view,
    showIndex,
    showNumber,
    showGraphType
  } = props

  return (
    <>
      <Container
        className={showDetail ? 'open-detail' : ''}
        isFirst={props.isFirst}
        view={view}
      >
        <div className="main-container">
          <div className="metric-info">
            <FlexBox
              flexDirection="column"
              justifyContent="space-between"
              style={{
                height: '100%'
              }}
            >
              <div className="name">
                <span>
                  {metric.name} {metric.unit && ` (${metric.unit})`}
                </span>
                <Growth
                  ratio={_.get(metric, 'detail.overall_ratio')}
                  up_color={metric.up_color}
                />
              </div>
            </FlexBox>
          </div>
          <div className="chart-container">
            <Chart
              {...{
                setNotes,
                showIndex,
                showNumber,
                showGraphType,
                getTheme,
                metric,
                comparedMetric,
                dateRange
              }}
            />
          </div>
        </div>
        <div className="detail-container">
          {notes &&
            notes.map((note, index) => (
              <div className="metric-note" key={index}>
                <span style={{ background: note.theme.primary }}></span>
                {note.name}
              </div>
            ))}
        </div>
      </Container>
    </>
  )

  function getTheme(categories) {
    if (categories[0] === 'Blood Metrics') {
      return colorArr.BloodPanel
    }
    return colorArr[categories[0]]
  }
}
