import React, { Component } from 'react'
import MarkdownIt from 'markdown-it'

import styled from 'styled-components'
import {
  AvatarIcon,
  GreenCheckIcon
} from '../../../asserts/wrappers/assessmentReview'

// Styled component for the information container
const InformationContainer = styled.div`
  border-radius: 8px;
  padding: 11px 16px;
  color: #264382;
  letter-spacing: 0.3px;
  border: 2px dashed #264382;
  text-align: center;
  position: relative;
  // Uncomment the below if needed
  // background: rgba(38, 67, 130, 0.05);
  // font-weight: 600;

  &.Opened,
  &.Sent,
  &.Locked {
    background: rgba(244, 245, 247, 0.5);
    border: none;
    color: #383e48;

    .content {
      min-height: auto;
    }
  }

  &.text-only {
    color: #383e48;
    text-align: left;
    font-family: 'Gilroy-Bold';

    .opr {
      padding: 5px 16px;
    }
  }

  &:hover .opr {
    display: flex;
  }

  &.Sent:hover .opr,
  &.Opened:hover .opr,
  &.Locked:hover .opr {
    display: none;
  }

  .null-state,
  .opr-button {
    cursor: pointer;
    text-transform: uppercase;
  }

  .opr {
    display: none;
    font-size: 32px;
    justify-content: flex-end;
    align-items: baseline;
    float: right;
    position: absolute;
    background-image: linear-gradient(
      to top right,
      rgba(255, 255, 255, 0.5) 100%,
      white 10%
    );
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    padding: 20px 16px;

    .opr-button {
      margin-left: 8px;
    }
  }

  .null-state .anticon {
    margin-right: 16px;
  }

  .content {
    min-height: 50px;
    display: flex;
    text-align: left;
    font-family: Gilroy;
    font-size: 14px;
    color: #70808e;

    .avatar {
      color: #479fec;
      font-size: 16px;
      padding-right: 16px;
    }

    strong {
      font-family: 'Gilroy-Bold';
    }
  }
`

class Information extends Component {
  state = {
    isEdit: false,
    initialValue: '',
    showBasicMarkdownFormat: false,
    showDirection: false
  }

  cancel = (status) => {
    const { updateEmailAction, updateEditingCount } = this.props
    this.refForm && this.refForm.reset()
    if (updateEditingCount) {
      updateEditingCount(-1)
    }

    if (updateEmailAction) {
      updateEmailAction(1)
    }

    this.setState({
      isEdit: false,
      showConfirm: false,
      initialValue: this.props.initialValue
    })
    if (status === 'done') {
      this.props.markAsDone()
    }
  }

  markDone = async () => {
    const params = await this.refForm.handleSubmit()
    this.setContentHtml(params.content, 'done')
    this.cancel()
  }

  handleShowBasicMarkdownFormatModal = (e) => {
    this.setState({
      showBasicMarkdownFormat: true
    })
  }

  handleBasicMarkdownFormatModalCancel = (e) => {
    this.setState({
      showBasicMarkdownFormat: false
    })
  }

  setContentHtml = (value, status) => {
    if (!value && status !== 'receive') return
    const { updateCommend } = this.props
    const md = new MarkdownIt('zero').enable([
      'heading',
      'list',
      'link',
      'autolink',
      'emphasis',
      'blockquote'
    ])
    const titleStyle =
      'font-weight: bold;color: #383E48;margin-top:0;margin-bottom:0.5em;'

    let result = md.render(value || '')

    result = result
      .replace('<h1>', `<h1 style="${titleStyle}">`)
      .replace('<h2>', `<h2 style="${titleStyle}">`)
      .replace('<h3>', `<h3 style="${titleStyle}">`)
      .replace('<h4>', `<h4 style="${titleStyle}">`)
      .replace('<h5>', `<h5 style="${titleStyle}">`)

    this.setState({ contentHtml: result, initialValue: value, isEdit: false })
    if (status === 'receive') {
      this.refForm && this.refForm.setFieldsValue({ content: value })
    } else if (status !== 'init') {
      updateCommend(value, status)
    }
  }

  clear = () => {
    this.setState({ contentHtml: '', initialValue: '' })
    this.props.updateCommend('', 'delete')
  }

  selectTemplate = (text) => {
    const { initialValue } = this.state
    let newContent
    if (initialValue) {
      newContent = `${initialValue} ${text}`
    } else {
      newContent = text
    }
    this.setState({ initialValue: newContent })
    this.refForm && this.refForm.setFieldsValue({ content: newContent })
  }

  componentDidMount() {
    const { initialValue } = this.props
    if (initialValue) {
      this.setContentHtml(initialValue, 'init')
    }
  }

  componentDidUpdate(prevProps) {
    const { initialValue, status } = this.props
    if (initialValue !== prevProps.initialValue) {
      this.setContentHtml(initialValue, 'receive')
    }
    if (status === 'Locked' && this.state.isEdit) {
      this.setState({ isEdit: false })
    }
  }

  render() {
    const { status, doneStatus, showDone, extraStyles, initialValue } =
      this.props
    const { contentHtml } = this.state
    return (
      (initialValue || contentHtml) && (
        <>
          <InformationContainer
            className={`${status}`}
            style={extraStyles || {}}
          >
            <div className="content">
              <div className="avatar">
                {doneStatus && showDone ? <GreenCheckIcon /> : <AvatarIcon />}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: contentHtml
                }}
              />
            </div>
          </InformationContainer>
        </>
      )
    )
  }
}

export default Information
