import React, { useState } from 'react'
import { Switch } from 'antd-mobile'
import styled from 'styled-components'
import _ from 'lodash'
import ProgressCircle from './progress'
import { CompactLine } from './segmentLine'
import { getColor } from '../index'
// import { getShowWeeklyDate } from '../../../../utils/dateHandles'

const Container = styled.div`
  transform: rotatey(180deg);
  padding: 1rem;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  height: 100%;
  overflow: auto;
  section {
    padding: 1rem;
    background: #ffffff;
    margin: 1rem 0;
    border-radius: 0.5rem;
  }
  .title {
    color: #383e48;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.31rem;
    svg {
      vertical-align: middle;
    }
    .target {
      color: #264382;
      font-family: Gilroy;
      font-size: 0.75rem;
    }
    .score-container {
      width: 3.125rem;
      line-height: 1.625rem;
      max-height: 1.625rem;
      border: 1px solid #8bb7e1;
      border-radius: 0.3125rem;
      font-size: 0.875rem;
      margin-left: 3.4375rem;
      text-align: center;
      font-weight: 700;
      font-family: Inter, Gilroy;
    }
  }
  .desc {
    color: #52606c;
    font-family: Inter, Gilroy;
    font-size: 0.75rem;
    line-height: 140%;
  }
  .description {
    color: #383e48;
    font-family: Gilroy;
    font-size: 0.875rem;
  }
  .progress {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avg-value {
      color: #5b6177;
      font-family: Inter, Gilroy;
      font-size: 0.875;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      margin-top: 1rem;
      span {
        color: #0a2845;
        font-size: 1rem;
        font-weight: 700;
      }
    }
    .title {
      font-family: Inter;
      font-weight: 700;
    }
    .content {
      width: 55%;
    }
  }
  .list {
    min-height: 25vh;
  }
  .nav-container {
    border-bottom: 1px solid #f1f3f6;
    margin: 0 -1rem;
    padding: 1rem;
    margin-top: -1rem;
    color: #323f4a;
    opacity: 0.7;
    font-size: 0.875rem;
    & ~ div {
      margin: 2rem 0;
      & > .title {
        display: flex;
        justify-content: space-between;
        .score,
        .no-data {
          color: #52606c;
        }
      }
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    & + div {
      margin-top: 1rem;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    & > span {
      margin-left: 0.56rem;
    }
    & + div {
      margin-top: 1rem;
    }
  }
  .detail-entry {
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: rgba(10, 40, 69, 0.05);
    padding: 1rem;
    color: #52606c;
    font-size: 0.875rem;
    align-items: flex-start;
    svg {
      margin-right: 0.5rem;
      width: 1.2rem;
    }
  }
  .list-label {
    font-family: Gilroy;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: #8596a4;
    & > div:last-child {
      text-align: right;
    }
  }
`

export default function NativeItem(props) {
  const {
    jumpTo,
    item,
    settings,
    targets,
    gesture,
    isLoading,
    rangeType,
    showWorkoutPopup,
    setShowWorkoutPopup
  } = props
  const [advancedChecked, setAdvancedChecked] = useState(false)
  const [client, setClient] = useState([0, 0])
  if (isLoading) return <div />
  const { progress, progressText, data, moreProgressAvg } = item || {} //dateInfo
  const cardList = settings.map((item) => {
    return (
      item && {
        ...item,
        data: (data || {})[item.domain],
        target: targets && targets[item.domain]
      }
    )
  })

  const hightestValue = getHighestValue(cardList, rangeType === 'week')
  const step = Math.round(hightestValue / 3)
  const ranges = [0, step, step * 2, step * 3]

  const getAdvancedText = () => {
    const scores = _.values(data).map((item) =>
      rangeType === 'week' ? item.seg_pct : item.more_seg_pct_avg
    )
    const maxScore = _.max(scores)
    if (maxScore > 300) {
      return 'A score of 300+ shows you have provided a significant amount of stimulus to that healthspan domain and should see major improvements over time'
    } else if (maxScore > 200) {
      return 'A score of 200 suggests you have done enough to make significant improvements to that healthspan domain '
    } else {
      return 'A score of 100 indicates you have done enough this week to maintain, and in some cases, make improvements to the given healthspan domain'
    }
  }
  return (
    <Container
      onClickCapture={() => {
        return showWorkoutPopup && setShowWorkoutPopup(false)
      }}
      onTouchStart={(e) => {
        const startClientX = e.changedTouches[0].clientX
        const startClientY = e.changedTouches[0].clientY
        setClient([startClientX, startClientY])
      }}
      onTouchEnd={(e) => {
        const endClientX = e.changedTouches[0].clientX
        const endClientY = e.changedTouches[0].clientY
        const [startX, startY] = client
        var deltaX = endClientX - startX
        var deltaY = endClientY - startY
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (endClientX - startX !== 0) {
            // 0 is click event
            gesture(endClientX - startX)
          }
        } else {
          showWorkoutPopup && setShowWorkoutPopup(false)
        }

        setClient([0, 0])
      }}
    >
      <section className="progress">
        <div style={{ width: '37%' }}>
          <ProgressCircle percent={progress} avgPercent={moreProgressAvg} />
          <div className="avg-value">
            3-week avg
            <br />
            <span>{moreProgressAvg}</span> %
          </div>
        </div>

        <div className="content">
          <div className="title">Exercise Signals Progress</div>
          <p className="desc">{progressText}</p>
        </div>
      </section>
      <section className="list">
        <div className="nav-container">
          <div className="nav">
            <Switch
              checked={advancedChecked}
              onChange={(isChecked) => setAdvancedChecked(isChecked)}
              style={{
                '--checked-color': '#05B800',
                '--height': '1.25rem',
                '--width': '2.1875rem'
              }}
            />
            <span>
              {advancedChecked ? 'View as percentage' : 'Advanced View'}
            </span>
          </div>
          {advancedChecked && <div>{getAdvancedText()}</div>}
        </div>
        {rangeType === 'week' && <DetailEntry {...{ jumpTo, cardList }} />}

        <div className="list-label">
          <div>
            Health Span <br /> Domains (HSD)
          </div>
          <div>
            Current <br /> score
          </div>
        </div>
        {cardList.map((item, index) => {
          if (!item.data) return null
          const isWeekly = rangeType === 'week'
          const score = isWeekly
            ? item.data.seg_pct
            : item.data.more_seg_pct_avg
          // const hightestValue = Math.max(item.target, score)
          // const step = Math.round(hightestValue / 3)
          // const ranges = [0, step, step * 2, step * 3]
          const bar = {
            widthReference: isWeekly ? 'seg_pct' : 'more_seg_pct_avg',
            score,
            workouts: item.data.seg_workouts,
            svgWidth: '100vw',
            id: `${item.domain}_compact_seg_pct`
          }
          const scorePercent = Math.round((score / (item.target || 100)) * 100)
          return (
            <div key={index}>
              <div className="title">
                <span>
                  {item.domain_label}{' '}
                  {scorePercent > 100 ? (
                    <CompletedChecked100 />
                  ) : (
                    <CompletedChecked />
                  )}
                  {advancedChecked && (
                    <span className="target">
                      <br /> Target: {item.target}
                    </span>
                  )}
                </span>
                <Score
                  {...{
                    score,
                    scorePercent,
                    showTarget: advancedChecked,
                    hightestValue
                  }}
                />
              </div>
              <CompactLine
                {...bar}
                key={index}
                label={item.domain_label}
                ranges={ranges}
                data={item.data}
                hightestValue={hightestValue}
                showTarget={advancedChecked}
                scorePercent={scorePercent}
                target={item.target}
                rangeType={rangeType}
                showWorkoutPopup={showWorkoutPopup}
              />
            </div>
          )
        })}
      </section>
    </Container>
  )
}

function Score(props) {
  const { score, scorePercent, showTarget, hightestValue } = props
  const targetColor =
    (score &&
      getColor(
        showTarget ? score : scorePercent,
        showTarget ? hightestValue : Math.max(scorePercent, 100)
      )) ||
    ' rgb(186, 203, 222)'
  return (
    <div
      className="score-container"
      style={{
        color: targetColor,
        border: `1px solid ${targetColor}`
      }}
    >
      {showTarget ? score : scorePercent + '%'}
    </div>
  )
}

function DetailEntry(props) {
  const { cardList } = props //jumpTo,
  const completedItems = cardList.filter(
    ({ target, data }) => data && data.seg_pct >= target
  )

  return (
    <div
      className="detail-entry"
      // onClick={() => jumpTo('/healthy-signal-detail')}
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.375 4.875C11.375 2.19375 9.18125 0 6.5 0C3.81875 0 1.625 2.19375 1.625 4.875C1.625 6.6625 2.6 8.2875 4.0625 9.1V10.5625H8.9375V9.1C10.4 8.2875 11.375 6.6625 11.375 4.875Z"
          fill="#3C92E8"
        />
        <path
          d="M5.6875 13H7.3125C8.20625 13 8.9375 12.2688 8.9375 11.375H4.0625C4.0625 12.2688 4.79375 13 5.6875 13Z"
          fill="#3C92E8"
        />
      </svg>
      <span>
        To fill your ring 100% you must complete all weekly targets. You have
        hit {completedItems.length} of your 7 weekly targets
      </span>
      {/* <AiOutlineRight /> */}
    </div>
  )
}

function CompletedChecked100() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" fill="#40C47C" />
      <path
        d="M4.78564 8.75L6.66064 10.625L11.5356 5.75"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  )
}
function CompletedChecked() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="7.5" cy="8" r="7.5" fill="#A5ABB2" />
      <path
        d="M4.28516 8.75L6.16016 10.625L11.0352 5.75"
        stroke="#868B92"
        strokeWidth="1.5"
      />
    </svg>
  )
}

function getHighestValue(list, isWeekly) {
  if (!list) return
  let array = list.map((item) => {
    const { target, data } = item
    let result = [target]
    if (data) {
      if (isWeekly) {
        result = [target, data.seg_pct]
      } else {
        result = [target, data.seg_pct, data.more_seg_pct_avg]
      }
    }
    return result
  })
  array = [].concat.call([0], ...array)
  return Math.round(Math.max(...array))
  // return Math.round(Math.max(...array) * 1.25)
}
