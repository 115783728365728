import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MarkdownIt from 'markdown-it'
import { AvatarIcon, GreenCheck } from './icons'

export const Container = styled.div`
  .information-container {
    border-radius: 8px;
    padding: 11px 16px;
    color: #264382;
    letter-spacing: 0.3px;
    border: 2px dashed #264382;
    text-align: center;
    position: relative;

    &.Opened {
      background: rgba(244, 245, 247, 0.5);
      border: none;
      color: #383e48;

      .content {
        min-height: auto;
      }
    }

    &.text-only {
      color: #383e48;
      text-align: left;
      font-family: 'Gilroy-Bold';

      .opr {
        padding: 5px 16px;
      }
    }
    &:hover .opr {
      display: flex;
    }
    &.Opened:hover .opr {
      display: none;
    }

    .opr {
      display: none;
      font-size: 32px;
      justify-content: flex-end;
      align-items: baseline;
      float: right;
      position: absolute;
      background-image: linear-gradient(
        to top right,
        rgba(255, 255, 255, 0.5) 90%,
        white 10%
      );
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      top: 0;
      left: 0;
      padding: 20px 16px;

      .opr-button {
        margin-left: 8px;
      }
    }

    .content {
      min-height: 50px;
      display: flex;
      text-align: left;
      font-family: Gilroy;
      font-size: 14px;
      color: #70808e;
      .avatar {
        color: #479fec;
        font-size: 16px;
        padding-right: 16px;
      }
      strong {
        font-family: 'Gilroy-Bold';
      }
      p {
        margin-top: 0;
        margin-bottom: 1em;
        font-weight: 600;
      }
      a {
        color: #1890ff;
      }
    }
  }
`

export default function Information(props) {
  const [initialValue, setInitialValue] = useState('')
  const [contentHtml, setContentHtml] = useState('')

  const setContentHtmlToMarkdown = (value, status) => {
    if (!value && status !== 'receive') return
    const md = new MarkdownIt('zero').enable([
      'heading',
      'list',
      'link',
      'autolink',
      'emphasis',
      'blockquote'
    ])
    const titleStyle =
      'font-weight: bold;color: #383E48;margin-top:0;margin-bottom:0.5em;'

    let result = md.render(value || '')

    result = result
      .replace('<h1>', `<h1 style="${titleStyle}">`)
      .replace('<h2>', `<h2 style="${titleStyle}">`)
      .replace('<h3>', `<h3 style="${titleStyle}">`)
      .replace('<h4>', `<h4 style="${titleStyle}">`)
      .replace('<h5>', `<h5 style="${titleStyle}">`)

    setContentHtml(result)
    setInitialValue(value)
  }

  useEffect(() => {
    const { initialValue } = props
    if (initialValue) {
      setContentHtmlToMarkdown(initialValue, 'init')
    }
  }, [props])

  const { status, doneStatus, showDone, extraStyles } = props
  return (
    <Container>
      {initialValue || contentHtml ? (
        <>
          <div
            className={`information-container ${status}`}
            style={extraStyles || {}}
          >
            <div className="content">
              <div className="avatar">
                {doneStatus && showDone ? <GreenCheck /> : <AvatarIcon />}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: contentHtml
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}
