import React, { useState } from 'react'

import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryScatter,
  VictoryGroup,
  VictoryLine,
  VictoryTooltip,
  VictorySharedEvents
} from 'victory'
import ContainerWidthSizer from '../../../commonComponents/ContainerWidthSizer'

const defaultDataLength = 9

export default function TimeLineGroup(props) {
  const [targetIndex, setTargetIndex] = useState(defaultDataLength - 1)
  const { score, selectScore, getLabelColor } = props
  const tickValuesX = geTickValuesX(score)

  const data = getData(tickValuesX, score, defaultDataLength)
  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="rgba(75, 89, 105,0)" />
            <stop offset="10%" stopColor="rgba(75, 89, 105, 0.5)" />
            <stop offset="100%" stopColor="rgb(75, 89, 105)" />
          </linearGradient>
        </defs>
      </svg>
      <ContainerWidthSizer>
        {({ width }) => (
          <VictorySharedEvents
            name="ShareEvents"
            events={[
              {
                childName: 'axis2',
                target: 'grid',
                eventHandlers: {
                  onClick: () => {
                    return [
                      {
                        childName: 'axis2',
                        target: 'grid',
                        mutation: ({ datum }) => {
                          clickGridHandle(datum)
                          return null
                        }
                      }
                    ]
                  }
                }
              }
            ]}
          >
            <VictoryChart width={width} height={380}>
              <VictoryBar
                barWidth={25}
                offsetY={110}
                data={tickValuesX.map((_, index) => ({
                  x: index,
                  y: 450,
                  y0: 45
                }))}
                style={{
                  data: {
                    fill: ({ datum }) => {
                      if (isNutritionQuestionnaire(tickValuesX[datum.x])) {
                        return 'url(#myGradient)'
                      } else {
                        return 'none'
                      }
                    }
                  },
                  labels: {
                    fontSize: 8,
                    fontWeight: 700
                  }
                }}
              />
              <VictoryAxis
                name="axis1"
                tickValues={tickValuesX.map((_, index) => index)}
                style={{
                  axis: { stroke: 'transparent' },
                  grid: {
                    stroke: ({ tickValue }) => {
                      if (tickValue === targetIndex)
                        return 'rgba(255,255,255,0.95)'
                      return 'transparent'
                    },
                    strokeDasharray: '3,3',
                    strokeWidth: ({ tickValue }) => {
                      if (tickValue === targetIndex) return 1
                      return 6
                    },
                    cursor: 'pointer'
                  },
                  tickLabels: { fill: 'transparent' }
                }}
              />

              <VictoryAxis
                dependentAxis
                tickValues={[0, 100, 200, 300, 400, 450]}
                orientation="right"
                style={{
                  axis: {
                    // stroke: 'rgba(255,255,255,0.95)',
                    // strokeDasharray: '3,3'
                    stroke: 'transparent'
                  },
                  tickLabels: {
                    fill: 'transparent' //'#ffffff'
                  }
                }}
              />
              {data.map((item, index) => renderChartGroup(item, index))}
              <VictoryAxis
                name="axis2"
                tickValues={tickValuesX.map((_, index) => index)}
                tickFormat={(t) =>
                  data[0].data[t].y ? tickValuesX[t].format('MM/DD') : ''
                }
                style={{
                  axis: {
                    stroke: 'transparent'
                  },
                  grid: {
                    stroke: 'transparent',
                    strokeWidth: 6,
                    cursor: ({ datum }) =>
                      defaultDataLength - datum <= score.length
                        ? 'pointer'
                        : 'none'
                  },
                  ticks: { stroke: 'grey', size: 0 },
                  tickLabels: { fill: 'white', fontSize: 12, padding: -15 }
                }}
              />
            </VictoryChart>
          </VictorySharedEvents>
        )}
      </ContainerWidthSizer>

      <div className="label-list">
        {data.reverse().map((item, index) => (
          <div key={index} className="label-item">
            <div>
              <div>{item.name}</div>{' '}
              <div
                style={{
                  color: getLabelColor(item.data[targetIndex].value)
                }}
              >
                {item.data[targetIndex].value}%
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )

  function renderChartGroup(item, index) {
    return (
      <VictoryGroup key={index}>
        <VictoryLine
          name="data-line"
          data={item.data}
          style={{
            data: { stroke: '#ffffff' }
          }}
          labelComponent={<VictoryTooltip active={true} />}
        />
        <VictoryLine
          data={item.emptyData}
          style={{
            data: {
              stroke: 'rgba(255,255,255,0.4)',
              strokeDasharray: '3,3'
            }
          }}
        />
        <VictoryScatter
          data={item.data}
          size={({ heightLight }) => {
            return heightLight ? 6 : 3
          }}
          style={{
            data: {
              fill: ({ heightLight }) => {
                return heightLight ? '#ffffff' : '#000000'
              },
              stroke: ({ heightLight }) => {
                return heightLight ? '#838C98' : '#ffffff'
              },
              strokeWidth: ({ heightLight }) => {
                return heightLight ? 4 : 2
              }
            }
          }}
        />
      </VictoryGroup>
    )
  }

  function isNutritionQuestionnaire(date) {
    const find =
      date &&
      score.find(
        (e) =>
          e.completion_date === date.format('YYYY-MM-DD') &&
          e.type === 'nutrition_questionnaire'
      )

    return find
  }

  function clickGridHandle(index) {
    const targetScore = score[defaultDataLength - index - 1]
    if (targetScore) {
      setTargetIndex(index)
      selectScore(targetScore)
    }
  }

  function getData(tickValuesX, list, targetIndex) {
    return props.items.map((item, index) => {
      const latestRecord = list[defaultDataLength - targetIndex]
      const latestData = latestRecord[item.name]

      item.data = tickValuesX.map((x, _idx) => {
        const target = list.find(
          (record) => record.completion_date === x.format('YYYY-MM-DD')
        )
        const value =
          target && typeof target[item.name] === 'number'
            ? target[item.name]
            : null

        let y = value

        if (y) {
          if (y < 0) {
            y = (index + 1) * 100
          } else {
            y =
              latestData > 0
                ? (index + 1) * 100 + (value - latestData)
                : y + (index + 1) * 100
          }
        } else if (y === 0) {
          y = (index + 1) * 100
        }
        return { x: _idx, y, value }
      })

      const latestDataIndex = item.data.findIndex((data) => data.y)
      item.emptyData = item.data.map(({ x }, _idx) => {
        return {
          x,
          y: _idx <= latestDataIndex ? item.data[latestDataIndex].y : null
        }
      })

      item.latestData = latestData

      return item
    })
  }
}

function geTickValuesX(list) {
  let tickValues = list.map((item) => moment(item.completion_date))
  const l = tickValues.length
  const latestData = tickValues[l - 1]
  if (l < defaultDataLength) {
    tickValues = tickValues.concat(
      new Array(defaultDataLength - l)
        .fill(0)
        .map((_, index) => moment(latestData).subtract(index + 1, 'months'))
    )
  }

  return tickValues.reverse()
}

// function Dot(props) {
//   return (
//     <svg
//       width="10"
//       height="10"
//       viewBox="0 0 10 10"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       x={props.x - 5}
//       y={(props.y && props.y - 5) || 0}
//     >
//       <circle
//         cx="5"
//         cy="5"
//         r="4"
//         fill="#0A1627"
//         stroke="#ffffff"
//         strokeWidth="2"
//       />
//     </svg>
//   )
// }
