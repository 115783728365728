import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, Input, Radio } from 'antd-mobile'
import Container from './style'
import Select from './item/select'
import DatePicker from './item/datePicker'

export default function BasicForm(props) {
  const {
    formData,
    initialValues,
    wrappedComponentRef,
    onChange,
    onEnter
  } = props
  const [formValues, setFormValues] = useState(_.cloneDeep(initialValues))
  const [form] = Form.useForm()

  useEffect(() => {
    if (wrappedComponentRef && form) {
      form.handleSubmit = async (_formData) => {
        await form.validateFields()
        const params = form.getFieldsValue(true)
        const formStruct = (_formData || formData)
          .filter(
            (item) =>
              !item.hideField ||
              (item.unless && isHide(item.unless, form, initialValues))
          )
          .map((item) => item.dbField)
          .filter((item) => item)
        return _.pick(params, formStruct)
      }

      form.syncFormValues = (updateValues) => {
        setFormValues(_.assign(formValues, updateValues))
      }
      wrappedComponentRef(form)
    }
  }, [form])

  return (
    <Container>
      <Form
        onKeyUp={enter}
        size="large"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        {formData.map((item, index) => {
            if (
              !item.hideField ||
              (item.unless && isHide(item.unless, form, initialValues))
            ) {
              return (
                <Form.Item
                    key={index}
                    name={item.dbField}
                    label={item.label}
                    rules={item.rules || []}
                    dependencies={item.dependencies}
                    {...(item.extraValidate || {})}
                  >
                    {renderFormItem(item)}
                  </Form.Item>
              )
            } else {
              return null
            }
          })}
      </Form>
    </Container>
  )

  function enter(event) {
    if (
      event.keyCode === 13 &&
      event.target.nodeName.toLowerCase() === 'input'
    ) {
      onEnter && onEnter()
    }
  }

  function onValuesChange(changedValues, allValues) {
    setFormValues(_.assign(formValues, allValues))
    if (onChange) {
      onChange(changedValues, allValues)
    }
  }

  function renderFormItem(item) {
    const { type, placeholder, disabled } = item
    switch (type) {
      case 'input':
        return (
          <Input
            suffix={item.suffix}
            prefix={item.prefix}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={item.addonBefore}
          />
        )
      case 'select':
        return <Select
        options={item.options}
        {...item.extraAttr}
        disabled={disabled}
        placeholder={placeholder}
        extraAttr={item.extraAttr || {}}
      />
      case 'date':
        return <DatePicker {...item} />
      case 'textarea':
        return (
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            {...item.extraAttr}
            disabled={disabled}
          />
        )

      case 'password':
        return (
          <Input.Password
            prefix={item.prefix}
            iconRender={item.iconRender}
            disabled={disabled}
          />
        )

      case 'radios':
        return (
          <Radio.Group>
            {item.options.map(({ label, value }, index) => (
              <Radio key={index} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        )

      case 'text':
        return (
          <div style={{ textAlign: item.align }}>
            {item.text || placeholder}
          </div>
        )
      default:
        return <div>{type}</div>
    }
  }
}

function isHide(unless, form, initialValues) {
  let value
  let result = false
  const findIndex = (data, value) => {
    return data.value.findIndex((item) => item === value)
  }
  if (unless.and) {
    for (let item of unless.and) {
      value = getValue(item.key)
      if (typeof item.value === 'string') {
        result = !!(value === item.value)
      } else {
        result = !!(findIndex(item, value) !== -1)
      }
      if (item.not) {
        result = !result
      }
      if (!result) {
        return false
      }
    }
    return true
  } else if (unless.or) {
    for (let item of unless.or) {
      value = getValue(item.key)
      if (typeof item.value === 'string') {
        result = !!(value === item.value)
      } else {
        result = !!(findIndex(item, value) !== -1)
      }
      if (item.not) {
        result = !result
      }
      if (result) {
        break
      }
    }
    return result
  } else {
    value = getValue(unless.key)
    if (typeof unless.value === 'string') {
      result = !!(value === unless.value)
    } else if (!unless.value) {
      return !!value
    } else {
      result = !!(unless.value.findIndex((item) => item === value) !== -1)
    }
    if (unless.not) {
      result = !result
    }
    return result
  }

  function getValue(key) {
    return (
      form.getFieldValue(key) || (initialValues && _.get(initialValues, key))
    )
  }
}
