import React from 'react'
import _, { max, min } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryScatter,
  VictoryLine
} from 'victory'

const Container = styled.div`
  padding: 1rem 1.5rem;
  .optimal {
    color: #40c47c;
    border-color: #40c47c;
  }
  .concern {
    color: #f5ba40;
    border-color: #f5ba40;
  }
  .at-risk,
  .risk {
    color: #ff6b00;
    border-color: #ff6b00;
  }
  .status-group {
    list-style: none;
    display: flex;
    justify-content: center;
    padding-top: 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    transform: scale(0.7);
    width: 117%;
    li:before {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border: 2px solid;
      border-radius: 0.5rem;
      margin-left: 1rem;
      margin-right: 0.3rem;
      background: currentColor;
    }
  }
  .chart {
    margin: 0 -1.25rem;
    position: relative;
    padding-left: 1.25rem;
    .chart-label {
      position: absolute;
      font-family: Gilroy-Bold;
      font-size: 0.75rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808e;
      height: 0.8rem;
      left: 1.56rem;
      top: 50%;
      margin-top: -0.3rem;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .label-text {
        transform: translateX(-50%) rotate(-90deg) scale(0.72);
      }
    }
  }
`

export default function GeneralChart(props) {
  const { choices, details, unit, className, original_id, subject } = props

  const isEatingWindow =
    (original_id && original_id.includes('eating_window')) ||
    ['Start of Eating Window', 'End of Eating Window'].includes(subject)
  const { data, tickValuesX, tickValuesY, highlightData } =
    getMetaData(isEatingWindow)

  return (
    <Container className={className}>
      {!isEatingWindow && (
        <ul className="status-group">
          <li className="optimal">OPTIMAL</li>
          <li className="concern">CONCERN</li>
          <li className="at-risk">AT RISK</li>
        </ul>
      )}
      <div className="chart">
        <div className="chart-label">
          <div className="label-text">{unit}</div>
        </div>
        <VictoryChart
          height={220}
          minDomain={{ x: 0.5, y: tickValuesY[0] }}
          padding={{ top: 40, right: 20, bottom: 40, left: 50 }}
          domainPadding={{ x: [10, 20] }}
        >
          <VictoryAxis
            tickValues={tickValuesX}
            tickFormat={(t) => {
              if (data[t - 1]) {
                return moment(
                  data[t - 1].completion_date || data[t - 1].date
                ).format('MMM DD')
              } else {
                return null
              }
            }}
            style={{
              tickLabels: {
                fill: '#70808E',
                fontSize: 10,
                fontFamily: 'Gilroy'
              },
              axis: {
                strokeDasharray: '3,3',
                stroke: 'rgba(0, 0, 0, 0.1)'
              }
            }}
          />

          <VictoryAxis
            dependentAxis
            tickValues={tickValuesY}
            tickFormat={(t) => {
              if (isEatingWindow) {
                return moment((t + 17000000) * 100000).format('h A')
              }
              if (
                props.type &&
                props.type.toLowerCase().includes('percentage') &&
                t === 100
              ) {
                return t + '%'
              }
              return t.toString()
            }}
            style={{
              axis: {
                stroke: 'rgba(0, 0, 0, 0.1)'
              },
              grid: {
                strokeDasharray: '3,3',
                stroke: 'rgba(0, 0, 0, 0.1)'
              },
              tickLabels: {
                fill: (e) => {
                  for (const { range, score } of choices) {
                    const [start, end] = range
                    if (Number(e) >= start && Number(e) <= end) {
                      return getStatus(score)
                    }
                  }
                  return '#52606C'
                },
                padding: 15,
                fontSize: 8,
                fontFamily: 'Gilroy',
                fontWeight: 600
              }
            }}
          />

          <VictoryBar
            barWidth={17}
            data={highlightData}
            labels={() => ['Nutrition', 'Questionnaire']}
            style={{
              data: {
                fill: ({ datum }) => datum && datum.status
              },
              labels: {
                fontSize: 8,
                fontWeight: 700,
                fontFamily: 'Gilroy'
              }
            }}
          />

          <VictoryLine
            data={data}
            style={{
              data: { stroke: '#E2E5E9', strokeWidth: 2 }
            }}
          />

          <VictoryScatter
            style={{
              labels: {
                fontSize: 8,
                fill: ({ datum }) => datum.status
              }
            }}
            data={data}
            dataComponent={
              <Dot name="data-scatter" maxY={_.cloneDeep(tickValuesY).pop()} />
            }
          />
        </VictoryChart>
      </div>
    </Container>
  )

  function getMetaData(isEatingWindow) {
    let tickValuesY = [],
      rangeArr = [],
      data = []

    if (isEatingWindow) {
      const today = moment().format('YYYY-MM-DD')
      data = _.cloneDeep(details)
        .reverse()
        .map((detail, index) => {
          const { date, score, score_value, type } = detail
          const value = moment(`${today} ${score_value}`, 'YYYY-MM-DD h:mm A')

          return {
            y: value._d.getTime() / 100000 - 17000000,
            x: index + 1,
            date,
            score,
            score_value,
            isEatingWindow,
            // percent,
            percent_color: null,
            status: '#2B4B8E',
            // isVirtual,
            type
          }
        })
      const values = [
        ...data.map(({ y }) => {
          const minute = Number(moment((y + 17000000) * 100000).format('m'))
          const hour = Number(moment((y + 17000000) * 100000).format('H'))
          return minute > 0 ? hour + 1 : hour
        }),
        ..._.flatten(choices.map((item) => item.range)).map((item) =>
          parseInt(item)
        )
      ]
      const _max = max(values),
        _min = min(values)
      const step = (_max - _min) / 3

      tickValuesY = [_min, _min + step, _min + step * 2, _max].map((item) => {
        const H = Math.floor(item)
        const M = Math.round((item % 1) * 60)
        return (
          moment(
            `${today} ${H > 10 ? H : '0' + H}:${M > 10 ? M : '0' + M}`,
            'YYYY-MM-DD HH:mm'
          )._d.getTime() /
            100000 -
          17000000
        )
      })
    } else {
      if (props.type === 'Food type') {
        tickValuesY = [0, 1, 2, 3]
        rangeArr = [
          { values: [0, 1], score: 1, status: getStatus(1) },
          { values: [1, 2], score: 2, status: getStatus(2) },
          { values: [2, 3], score: 3, status: getStatus(3) }
        ]
      } else {
        for (let i = 0, l = choices.length; i < l; i++) {
          const nextIndex = i + 1
          const { range, score } = choices[i]
          const nextChoice = choices[nextIndex]
          let _min, _max
          _min = range[0]
          if (nextChoice) {
            _max = nextChoice.range[0]
          } else if (range[1]) {
            _max = range[1]
          } else {
            _max = range[0] + (range[0] - choices[i - 1].range[0])
          }
          rangeArr.push({
            values: [_min, _max],
            score,
            status: getStatus(score)
          })
        }

        choices.forEach((e) => {
          tickValuesY = [...tickValuesY, ...e.range]
        })

        tickValuesY = _.uniq(tickValuesY)
          .filter((item) => _.isInteger(item))
          .sort((a, b) => a - b)
      }
      data = _.cloneDeep(details)
        .reverse()
        .map((detail, index) => {
          const { range, date, score, score_value, answer, type } = detail
          const isVirtual = answer && typeof answer.value === 'string'
          let value
          if (_.isNumber(score_value)) {
            value = score_value || (!isVirtual && answer.value) || 0
          } else {
            if (range.length === 2) {
              value = (range[0] + range[1]) / 2
            } else {
              value = range[0]
            }
          }

          let percent = 0
          let percent_color
          if (index !== 0) {
            const previousValue = details[index - 1].score_value
            if (previousValue === 0) {
              percent = score_value * 100
            } else {
              percent = ((score_value - previousValue) / previousValue) * 100
              percent = percent.toFixed(0)
            }
            percent_color = getPercentColor(
              previousValue,
              score_value,
              rangeArr
            )
          } else {
            percent_color = getPercentColor(null, score_value, rangeArr)
          }

          return {
            y: value,
            x: index + 1,
            date,
            score,
            score_value: value,
            percent,
            percent_color,
            status: getStatus(score),
            isVirtual,
            type
          }
        })
    }
    let n = 6

    if (data.length > n) {
      data = data.slice(data.length - n)
      data.forEach((item, index) => {
        item.x = index + 1
      })
    }
    const tickValuesX = new Array(n + 1).fill(0).map((_, index) => index)

    return {
      tickValuesX,
      data,
      highlightData: data
        .filter((item) => item.type === 'nutrition_questionnaire')
        .map(({ x, y, status }) => ({
          x,
          y: max(tickValuesY),
          status: transformStatus(status)
        })),
      tickValuesY,
      stackDomain: []
    }
  }

  function getPercentColor(prev, cur, range, cur_score) {
    const temp = range.find((e) => e.score === 3).values
    if (prev === cur || prev === null) {
      if (cur_score === 3) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }

    const average = (temp[0] + temp[1]) / 2
    const riseValue = cur - prev
    if (prev >= average) {
      // if previous score is greater than average, current score is better to down
      if (riseValue <= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue <= 0 && cur >= temp[1]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    } else {
      // if previous score is greater than average, current score is better to up
      if (riseValue >= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue >= 0 && cur <= temp[0]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }
  }
}

function getStatus(score) {
  let status
  switch (score) {
    case 1:
      status = '#FF6B00'
      break
    case 2:
      status = '#F5BA40'
      break
    case 3:
      status = '#40C47C'
      break
    default:
      break
  }
  return status
}

function transformStatus(status) {
  switch (status) {
    case '#40C47C':
      return 'rgba(64,196,124,0.2)'
    case '#F5BA40':
      return 'rgba(245,186,64,0.2)'
    case '#FF6B00':
      return 'rgba(255,107,0,0.2)'
    default:
      return 'transparent'
  }
}

function Dot(props) {
  const {
    maxY,
    datum: { isVirtual, score_value, status, isEatingWindow, type, y }
  } = props
  const dx = type === 'nutrition_questionnaire' && y === maxY ? 10 : 0
  return (
    <svg
      width="100"
      height="35"
      viewBox="0 0 100 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 7}
      y={(props.y && props.y - 18) || 0}
    >
      <text
        style={{ textAnchor: isEatingWindow ? 'start' : 'middle' }}
        dy={-5 + dx}
        dx={dx}
        x={isEatingWindow ? 0 : 7}
        fontSize={9}
        fill={status}
      >
        {Number(score_value) ? Math.round(score_value) : score_value}
      </text>
      <circle
        cx="7"
        cy="5"
        r="4"
        fill={props.datum.status} //"white"
        stroke={props.datum.status}
        strokeWidth="2"
        strokeDasharray={isVirtual ? 2 : 0}
      />
    </svg>
  )
}
