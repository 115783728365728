import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Divider, Toast } from 'antd-mobile'
import { getEmailDetailByHash } from '../../../api/summaryEmail'
import { getMeByHash } from '../../../api'
import Information from './components/information'
import { AOFIcon, LTAIcon } from './components/icons'
import EmailBG from '../../../asserts/images/email-bg.png'
import { useParams } from 'react-router-dom'
import RichText from './components/richText'
import styled from 'styled-components'
import LockStatus from '../../commonComponents/lockStatus'
import { userType } from '../../../utils'

export const SummaryContainer = styled.div`
  font-family: Gilroy;
  background: #F4F6F8;
  &.backup-style {
    .section-container > h3 {
      margin: 0;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -23px;
      padding: 18px 32px;
      font-size: 24px;
    }
    .information-container.Opened {
      border: 1px solid #70808E;
      margin: 16px 0 32px 0;
    }
  }
  img {
    max-width: 100%;
    width: 100%;
    // & + .section-container
    //   margin-top: 0
  }
  .section-container {
    background: #ffffff;
    padding: 20px;
    & > h3 {
      margin: 0;
      margin-bottom: 24px;
      padding: 14px 25px;
      font-size: 18px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
        display: none;
      }
      &:hover svg {
        display: block;
      }
      &.Overview {
        color: #383E48;
        padding: 0;
        margin: 0;
      }
      &.Rejuvenation {
        background: #5C00D1;
      }
      &.Exercise {
        background: #FF6B00;
      }
      &.Nutrition {
        background: #7BAD2D;
      }
      &.Weight {
        background: #3194EB;
      }
    }
    h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48;
    }
  }
  .goal-name {
    font-family: Gilroy-Bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #383e48;
    padding-bottom: 10px;
  }
  .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px;
    h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48;
    }
    p {
      color: #70808E;
    }
    .information-container {
      padding: 5px 0;
      background: transparent;
    }
  }

  .summary-date {
    font-family: Gilroy-Bold;
    font-size: 12px;
    color: #70808E;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .title {
    color: #383e48;
    padding-bottom: 0;
    padding-top: 0;
  }
  .extra-info {
    margin-top: 10px;
    background: rgba(244,245,247,.5);
    border: 1px solid #70808e;
    color: #383e48;
    min-height: 50px;
    display: flex;
    text-align: left;
    font-family: Gilroy;
    font-size: 14px;
    color: #70808e;
    .avatar
      color: #479fec;
      font-size: 16px;
      padding-right: 16px;
  }
  .profile-container {
    margin-top: 16px;
    padding-bottom: 0;
    .profile-info {
      width: 100%;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`

export default function SummaryEmail(props) {
  const { id, hash } = useParams()
  const [body, setBody] = useState(null)
  const [person, setPerson] = useState(null)

  useEffect(() => {
    async function initial() {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      const result = await getEmailDetailByHash(id, hash)
      const { person } = await getMeByHash(hash)
      setPerson(person)
      setBody({ ...result.body })
      loading.close()
    }
    initial()
  }, [id, hash])

  const isPartner = person && person.profile.account_type === userType.Partners
  if (isPartner) {
    return <LockStatus />
  } else {
    return <SummaryContainer>{body && render()}</SummaryContainer>
  }

  function render() {
    const { section_show, deleted_section, nutrition, weight } = body
    return (
      <>
        <img className="bg-image" src={EmailBG} alt="background" />
        {renderOverview()}
        {(section_show
          ? section_show.rejuvenation
          : !deleted_section.rejuvenation) && renderRejuvenation()}
        {(section_show ? section_show.exercise : !deleted_section.exercise) &&
          renderExercise()}
        {(section_show
          ? section_show.weight
          : !deleted_section.weight && weight && weight.length > 0) &&
          renderWeight()}
        {(section_show
          ? section_show.nutrition
          : !deleted_section.nutrition && nutrition.length > 0) &&
          renderNutrition()}
      </>
    )
  }

  function renderOverview() {
    const { overview, date_range, from, to } = body
    return (
      <div className="section-container">
        <div className="summary-date">
          {date_range ||
            `${from && moment(from).format('MMM DD')} - ${
              to && moment(to).format('MMM DD')
            }`}
        </div>
        <h3 className="title Overview">{overview.title}</h3>
        {overview.summary_introduction && (
          <div className="extra-information">
            <Information
              status="Opened"
              initialValue={overview.summary_introduction}
            />
          </div>
        )}
        <div className="profile-container">
          {overview.areas_of_focus && (
            <div className="profile-info">
              <RichText
                initialValue={overview.areas_of_focus}
                icon={<AOFIcon style={{ color: '#FF6B00' }} />}
                title="Long Term Goals"
              />
            </div>
          )}
          {overview.long_term_aspirations && (
            <div className="profile-info">
              <RichText
                icon={<LTAIcon style={{ color: '#FF6B00' }} />}
                title="Aspirations"
                initialValue={overview.long_term_aspirations}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  function renderRejuvenation() {
    const { rejuvenation, deleted_section } = body
    return (
      <div className="section-container">
        <h3 className="Rejuvenation">Rejuvenation</h3>
        <Information
          status="Opened"
          initialValue={rejuvenation.recommendation}
        />
        {!deleted_section.rejuvenation_quality && (
          <>
            <h4 className="sub-title">Quality of sleep</h4>
            <img src={rejuvenation.sleep_chart_url} alt="sleep_chart_url" />
          </>
        )}
        {rejuvenation.sleep_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  function renderExercise() {
    const { exercise, deleted_section } = body
    return (
      <div className="section-container">
        <h3 className="Exercise">Exercise</h3>
        <Information status="Opened" initialValue={exercise.recommendation} />
        {!deleted_section.exercise_target && (
          <>
            <h4 className="sub-title">Apeiron Life Training Target</h4>
            <img src={exercise.heart_chart_url} alt="heart_chart_url" />
            <div className="extra-information">
              <h5>What is my Aerobic Training Target?</h5>
              <p>
                For optimal health benefits, we recommend you aim for at least
                150 minutes per week of moderate-intensity activity (zone 2 -
                zone 3; 60-75% max heart rate). This includes running, cycling,
                swimming, and hiking.
              </p>
            </div>
            <Divider />
          </>
        )}

        {!deleted_section.exercise_workout && (
          <>
            <h4 className="sub-title">Workout</h4>
            <img src={exercise.workout_chart_url} alt="heart_chart_url" />
            {exercise.activity_goals.length > 0 &&
              !deleted_section.exercise_workout && <Divider />}
          </>
        )}
        {exercise.activity_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  function renderWeight() {
    const { weight, weight_recommendation } = body

    return (
      <div className="section-container">
        <h3 className="Weight">Body Weight</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information status="Opened" initialValue={weight_recommendation} />
        </div>
        {weight.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }

  function renderNutrition() {
    const { nutrition, nutrition_recommendation } = body
    return (
      <div className="section-container">
        <h3 className="Nutrition">Nutrition</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information
            status="Opened"
            initialValue={nutrition_recommendation}
          />
        </div>
        {nutrition.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }
}
