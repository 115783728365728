import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import TimeLineGroup from './timeline'
import BGImage from '../../../../asserts/images/nutrition-score-bg.png'
import { getSum } from '../../../../utils'
import MarkdownIt from 'markdown-it'

const items = [
  { name: 'Timing' },
  { name: 'Quality' },
  { name: 'Quantity' },
  { name: 'Total' }
]

const itemRatio = {
  quantity: 60,
  quality: 20,
  timing: 20
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  .background {
    position: absolute;
    width: 240%;
    right: -50%;
  }
  & > div {
    background: linear-gradient(
      180deg,
      rgba(11, 28, 51, 0.85) 0%,
      #0b1c33 100%
    );
    min-height: 36em;
    position: relative;
    z-index: 1;
    padding-bottom: 1em;
  }
  .total-score {
    padding: 1.71em 0;
    color: #ffffff;
    font-size: 1em;
    text-transform: capitalize;
    text-align: center;
    .value {
      font-weight: 700;
      font-size: 4.587em;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      line-height: 1.1;
      span {
        font-size: 0.48em;
        padding-top: 0.2em;
        padding-left: 0.2em;
      }
    }
    .date {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .chart-container {
    width: calc(50% + 25rem);
    margin-left: -21rem;
    position: relative;
    margin-top: -5rem;
  }
  .label-list {
    position: absolute;
    right: -5.5rem;
    top: 0;
    margin-top: 5em;
    .label-item {
      position: relative;
      margin: 2rem 0;
      padding: 0 0.67rem;
      width: 6rem;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 3;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      border-radius: 2px;
      background: #38414e;
      color: #f4f6fa;
      &:before {
        content: '';
        display: inline-block;
        border-color: transparent #38414e transparent transparent;
        border-width: 6px;
        border-style: solid;
        height: 0px;
        margin-left: calc(-0.67rem - 12px);
        margin-top: 0.25rem;
      }
      & > div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 6rem;
        height: 2.17rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .recommend-content {
    margin: -1em 20px 1em 20px;
    padding: 1.4em;
    font-size: 13px;
    line-height: 19px;
    color: #e7e9ec;
    background-color: rgba(74, 84, 97, 0.9);
    border-radius: 8px;
    h5 {
      color: #f4f6fa;
      margin: 0;
      letter-spacing: 1px;
    }
    p {
      margin: 0.5em 0;
    }
    .action {
      font-weight: 600;
      text-decoration: underline;
    }
  }
`

const getLabelColor = (record) => {
  if (record >= 80) {
    return '#40C47C'
  } else if (record >= 50) {
    return '#F5BA40'
  } else {
    return '#FF6B00'
  }
}

export default function ScoreChart(props) {
  const score = props.score.map((listItem) => {
    for (const item of items) {
      if (item.name === 'Total') {
        listItem[item.name] = Math.round(
          getSum(
            listItem.answers.map(
              (answer) =>
                (answer.percentage_score || 0) * (itemRatio[answer.type] || 1)
            )
          ) / 100
        )
      } else {
        listItem[item.name] =
          listItem.answers.find(
            (answer) => answer.type === item.name.toLowerCase()
          ).percentage_score || 0
      }
    }
    return listItem
  })
  const showScore = props.targetScore || score[0] || {}
  const isQuestionnaire = showScore.type === 'nutrition_questionnaire'
  const recommend =
    showScore && showScore.recommendation && showScore.recommendation.summary
  return (
    <Container>
      <img src={BGImage} alt="bg" className="background" />
      <div>
        <div className="total-score">
          <div>Total Score</div>
          <div
            className="value"
            style={{
              color: getLabelColor(showScore.Total)
            }}
          >
            {showScore.Total} <span>%</span>
          </div>
          <div className="date">
            {moment(showScore.completion_date || showScore.date).format(
              'MMM DD, YYYY'
            )}
          </div>
        </div>
        <div className="chart-container">
          <TimeLineGroup
            score={score}
            items={items}
            getLabelColor={getLabelColor}
            selectScore={props.setTargetScore}
          />
        </div>
        {isQuestionnaire ? (
          <div>{recommend && <Recommend initialValue={recommend} />}</div>
        ) : (
          <div className="recommend-content">
            <h5>EXECUTIVE SUMMARY</h5>
            <div>
              To optimize your score, view your personalized program in your{' '}
              <span
                className="action"
                onClick={() => {
                  if (window.webkit) {
                    window.webkit.messageHandlers.jsBridgeNutritionScore.postMessage(
                      {}
                    )
                  }
                }}
              >
                Apeiron Life Nutrition Signals
              </span>
              .
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

function Recommend(props) {
  const { initialValue } = props
  if (!initialValue) return null

  const md = new MarkdownIt('zero').enable([
    'heading',
    'list',
    'link',
    'autolink',
    'emphasis',
    'blockquote'
  ])
  const titleStyle =
    'font-weight: bold;color: #383E48;margin-top:0;margin-bottom:0.5em;'

  let result = md.render(initialValue || '')

  result = result
    .replace('<h1>', `<h1 style="${titleStyle}">`)
    .replace('<h2>', `<h2 style="${titleStyle}">`)
    .replace('<h3>', `<h3 style="${titleStyle}">`)
    .replace('<h4>', `<h4 style="${titleStyle}">`)
    .replace('<h5>', `<h5 style="${titleStyle}">`)

  return (
    <div className="recommend-content">
      <h5>EXECUTIVE SUMMARY</h5>
      <div
        dangerouslySetInnerHTML={{
          __html: result
        }}
      />
    </div>
  )
}
