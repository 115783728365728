import React from 'react'
import ReactDOMServer from 'react-dom/server'
import _ from 'lodash'
import { GenIcon } from 'react-icons'
import { XMLParser } from 'fast-xml-parser'

const camelCase = _.camelCase

const XMLParserOptions = {
  ignoreAttributes: false,
  attributeNamePrefix: '',
  attributesGroupName: 'attributes',
  textNodeName: '#text',
  // attributeNamePrefix: 'attr_',
  allowBooleanAttributes: true,
  trimValues: false,
  arrayMode: true
}

const XMLParserOptionForAttr = String(
  XMLParserOptions.attributesGroupName || ''
)
const XMLParserOptionForText = String(XMLParserOptions.textNodeName || '')

const parseXML = new XMLParser(XMLParserOptions)

export function createIconTreeFromSVG(svgSource, isMultiColor) {
  const SVGObject = parseXML.parse(ReactDOMServer.renderToString(svgSource))
  const SVGObjectRootNodeName = Object.keys(SVGObject)[0]

  const TreeFromSVGObject = createTreeFromSVGObject(
    SVGObject[SVGObjectRootNodeName],
    SVGObjectRootNodeName
  )

  return TreeFromSVGObject

  function createTreeAttrObject(attributes, nodeName) {
    return Object.keys(attributes)
      .filter((attributeName) => {
        return ![
          'class',
          // if nodeName is svg, then some attributes are removed
          ...(nodeName === 'svg'
            ? ['xmlns', 'xmlns:xlink', 'xml:space', 'width', 'height']
            : [])
        ].includes(attributeName)
      })
      .reduce((attrObject, attributeName) => {
        const AttributeValue = attributes[attributeName]
        const NewAttributeName = camelCase(attributeName)
        switch (NewAttributeName) {
          case 'fill': {
            if (
              AttributeValue === 'none' ||
              AttributeValue === 'currentColor' ||
              isMultiColor
            ) {
              attrObject[NewAttributeName] = AttributeValue
            }
            break
          }
          case 'pId':
            break
          default: {
            attrObject[NewAttributeName] = AttributeValue
            break
          }
        }
        return attrObject
      }, {})
  }

  function createChildren(childrenKeys, svgObject) {
    let result = childrenKeys.flatMap((key) => {
      if (_.isArray(svgObject[key])) {
        return svgObject[key].map((child) =>
          createTreeFromSVGObject(child, key)
        )
      }

      return createTreeFromSVGObject(svgObject[key], key)
    })
    return result
  }

  function createTreeFromSVGObject(svgObject, nodeName) {
    let childrenKeys = Object.keys(svgObject).filter((key) => {
      return ![
        'style',
        XMLParserOptionForAttr,
        XMLParserOptionForText,
        'defs',
        'use'
      ].includes(key)
    })
    // put at the end of the array of keys
    if (svgObject.defs) childrenKeys = [...childrenKeys, 'defs']
    if (svgObject.use) childrenKeys = [...childrenKeys, 'use']

    return {
      tag: nodeName,
      attr: createTreeAttrObject(
        svgObject[XMLParserOptionForAttr] || {},
        nodeName
      ),
      child: createChildren(childrenKeys, svgObject)
    }
  }
}
export default function CustomizeIcon(props) {
  const { source } = props
  const iconTree = createIconTreeFromSVG(source)
  const Icon = GenIcon(iconTree)
  return <Icon className="customize-icon" {...props} />
}
