import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useOutletContext, useParams } from 'react-router-dom' //useLocation,
import _ from 'lodash'
import { Toast } from 'antd-mobile'
import { QuestionsContainer, ProgressBar } from '../common' // StepFooter
import {
  getSectionDetails,
  getSessionOrInit
  // getRecordSessions,
  // updateProfileStatus
} from '../../../api'
import AnswerUpdater, {
  checkCompleted,
  verifyAnswer
} from '../common/answerUpdater'
import ControlBottom from '../../commonComponents/BottomControl'
import Success from './success'
import Questions from './questions'

// import { questionnaireConfig } from '../../../utils/constant'
export const ProgressQuestionnaire = [
  {
    showIndex: 1,
    param: 'lifestyle',
    name: 'lifestyle & goals',
    sectionIds: 'lifestyle_goals',
    next: '/onboarding/questionnaire/medical',
    isChecked: false
  },
  {
    showIndex: 2,
    param: 'medical',
    name: 'Medical history',
    sectionIds: 'medical_history',
    next: '/onboarding/questionnaire/medication',
    isChecked: false
  },
  {
    showIndex: 3,
    param: 'medication',
    name: 'medication & supplements',
    sectionIds: 'medications_supplements',
    next: '/onboarding/questionnaire/nutrition',
    isChecked: false
  },
  {
    showIndex: 4,
    param: 'nutrition',
    name: 'Nutrition Signals',
    sectionIds: 'nutrition_eating_habits',
    next: '/onboarding/questionnaire/sleep',
    isChecked: false
  },
  {
    showIndex: 5,
    param: 'sleep',
    name: 'Sleep',
    sectionIds: 'sleep',
    isChecked: false
  }
]

const initialState = {
  sections: [],
  answers: [],
  answerUpdater: null,
  loading: false,
  saveCount: 0,
  step: ProgressQuestionnaire[0]
}

export default function Questionnaire(props) {
  const params = useParams()

  const { person, setStepInfo, steps, navigate, updateProfile, setSubActive } =
    useOutletContext() //navigate, updateProfile
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)

  const [saveLoading, setSaveLoading] = useState(false)
  const containerRef = useRef(null)
  const [showError, setShowError] = useState(false)
  // const [next, setNext] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater } = state
      setSaveLoading(answerUpdater.isLoading())
      return _.assignIn(state, { answers: answerUpdater._answers })
    } else if (action.type === 'check answers') {
      return _.assign({}, state, { answers: action.answers })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  useEffect(() => {
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      setSaveLoading(true)
      let step = ProgressQuestionnaire[0]
      if (params.section) {
        step = ProgressQuestionnaire.find((e) => e.param === params.section)
      }
      setSubActive(step.showIndex)
      const answers = await getSessionOrInit(person.id, step.sectionIds)
      const sections = await getSectionDetails(person.id, step.sectionIds)
      dispatch({
        type: 'initial',
        payload: {
          sections,
          answers,
          step,
          answerUpdater: new AnswerUpdater({
            answers,
            sections,
            saveCallback: () => dispatch({ type: 'update answers' })
          })
        }
      })
      setSaveLoading(false)
      loading.close()
      window.scrollTo({ top: 0 })
    }

    initial()
  }, [person, params.section])

  useEffect(() => {
    const step = steps.find((item) => item.next === 'questionnaire')
    setStepInfo(step)
  }, [setStepInfo])

  const { sections, answerUpdater, step, answers } = state

  async function onCompleted() {
    setSaveLoading(true)
    const { isCompleted, answers: _answers } = await checkCompleted(
      answers,
      sections
    )

    if (isCompleted) {
      if (step.next) {
        navigate(step.next)
      } else {
        await updateProfile({
          onboarding_steps: { onboarding_required: false }
        })
        setSaveLoading(false)
        setShowSuccess(true)
        setTimeout(() => {
          if (navigator.userAgent.includes('life.apeiron.ios')) {
            window.webkit.messageHandlers.onboardingCompleted.postMessage({})
          }
        }, 3000)
      }
    } else {
      setShowError(true)
      setSaveLoading(false)
      dispatch({
        type: 'check answers',
        answers: _answers
      })
      setTimeout(() => {
        if (containerRef) {
          const invalidElem =
            containerRef.current.getElementsByClassName('invalid')[0]
          if (invalidElem) {
            window.scrollTo({
              top: invalidElem.offsetTop - 300,
              behavior: 'smooth'
            })
          }
        }
      }, 500)
    }
  }

  useEffect(() => {
    setConfirmBtnDisabled(saveLoading || showError)
  }, [saveLoading, showError])

  return (
    <>
      {showSuccess && <Success />}
      <ProgressBar
        activeIndex={step.showIndex}
        progress={ProgressQuestionnaire}
      />
      <QuestionsContainer ref={containerRef}>
        {showError && (
          <div className="error-message">
            Error: Please answer all required questions.
          </div>
        )}
        {sections.map(({ original_id, questions }) => {
          const answerInfo = answers.find(
            (item) => item.section_original_id === original_id
          )
          return (
            <div className="section-container" key={original_id}>
              <Questions
                {...{
                  original_id,
                  questions,
                  answerInfo,
                  updateAnswer,
                  setConfirmBtnDisabled
                }}
              />
            </div>
          )
        })}
      </QuestionsContainer>
      <ControlBottom
        confirmDisabled={confirmBtnDisabled}
        onClickBack={() => {
          navigate(-1)
        }}
        confirmLoading={saveLoading}
        onClickConfirm={onCompleted}
      />
    </>
  )

  async function updateAnswer(answer, original_id, question) {
    if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)
    if (!JSON.stringify(_answer).includes('verify')) {
      setSaveLoading(true)
      answerUpdater.autoSave(_answer, original_id)
      if (question.has_associated) {
        dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
      }
    } else {
      const answerInfo = answers.find((item) => item.id === _answer.session_id)
      const targetIndex = answerInfo.answers.findIndex(
        (an) => an.question_original_id === _answer.question_original_id
      )
      if (targetIndex > -1) {
        answerInfo.answers[targetIndex] = _answer
      } else {
        answerInfo.answers.push(_answer)
      }
      dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
    }
  }
}
