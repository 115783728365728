import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const Today = styled.div`
  font-family: Gilroy;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  color: #52606c;
  border: 1px solid #dee2ec;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  text-transform: uppercase;
  background: #ffffff;
`

export default function TodayTag(props) {
  return (
    <Today style={props.style}>
      today is {moment().format(props.format || 'MMM DD')}
    </Today>
  )
}
