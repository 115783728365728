import React from 'react'
import _ from 'lodash'
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryLabel,
  VictoryLine,
  VictoryArea,
  VictoryScatter
} from 'victory'
import {
  LABEL_TEXT_ANCHOR,
  MOBILE_OFFSET_SORCE_COMPARE_Y,
  MOBILE_OFFSET_NAME_COMPARE_Y
} from './constant'

export default function Graph(props) {
  let { compareData, dataList } = props
  const numberFontSize = 21
  let first = dataList[0]
  dataList = [first].concat(dataList.slice(1, 7).reverse())
  const score = dataList.map((item, index) => {
    const { score_manual, score_auto, isPrefill, latest_test_date } = item || {}
    const _score = (score_manual && score_manual.value) || score_auto

    return {
      x: index,
      y: _.isNumber(_score) ? _score : 75,
      axisLabel: _score || '--',
      isPrefill: !score_manual && isPrefill,
      date: latest_test_date,
      isEstimate: _.has(item, 'score_auto') && !_.isNumber(_score)
    }
  })
  const name = dataList.map((item) => item.name)
  let compareScatterEstimate
  const hasCompareScore = compareData.find((item) => {
    const { score_manual, score_auto } = item || {}
    const _score = (score_manual && score_manual.value) || score_auto
    return _score
  })
  if (hasCompareScore) {
    first = compareData[0]
    compareData = [first]
      .concat(compareData.slice(1, 7).reverse())
      .map((item, index) => {
        const { score_manual, score_auto, latest_test_date } = item || {}
        const _score = (score_manual && score_manual.value) || score_auto
        return {
          x: index,
          y: Number(_score) || 0, // _.isNumber(_score) ? _score : 75,
          axisLabel: _score || '--',
          date: latest_test_date
          // isEstimate: _.has(item, 'score_auto') && !_.isNumber(_score)
        }
      })

    compareScatterEstimate = _.cloneDeep(compareData).map(
      ({ x, y, isEstimate }) => ({
        x,
        y: isEstimate ? y : null
      })
    )
  }

  const scatterPreFillData = _.cloneDeep(score).map(({ x, y, isPrefill }) => ({
    x,
    y: isPrefill ? y : null
  }))

  const scatterEstimateData = _.cloneDeep(score).map(
    ({ x, y, isEstimate }) => ({
      x,
      y: isEstimate ? y : null
    })
  )
  return (
    <VictoryChart polar domain={{ y: [0, 100] }} startAngle={90} endAngle={450}>
      <VictoryPolarAxis
        name="graph-score"
        labelPlacement="vertical"
        tickValues={[0, 1, 2, 3, 4, 5, 6]}
        tickFormat={(t) =>
          hasCompareScore
            ? [score[t].axisLabel, ' / ', compareData[t].axisLabel]
            : [score[t].axisLabel, '', '']
        }
        tickLabelComponent={
          <VictoryLabel
            dy={({ datum }) => MOBILE_OFFSET_SORCE_COMPARE_Y[datum || 0] - 20}
            textAnchor={({ datum }) => LABEL_TEXT_ANCHOR[datum]}
            inline
            style={[
              {
                fill: ({ datum: t = 0 }) => {
                  return score[t] && score[t].isPrefill ? '#4C6072' : '#7FB800'
                },
                fontSize: numberFontSize
              },
              {
                fill: 'rgba(76,96,114,0.1)',
                fontSize: numberFontSize
              },
              { fill: '#0D2350', fontSize: numberFontSize }
            ]}
          />
        }
        style={{
          axis: {
            fill: 'rgba(13, 35, 80, 0.2)'
          },
          grid: {
            stroke: '#B1B8BD'
          }
        }}
      />

      <VictoryPolarAxis
        name="graph-label"
        tickValues={[0, 1, 2, 3, 4, 5, 6]}
        tickFormat={(t) => name[t].toUpperCase()}
        tickLabelComponent={
          <VictoryLabel
            dy={({ datum }) => MOBILE_OFFSET_NAME_COMPARE_Y[datum || 0] - 10}
            textAnchor={({ datum }) => LABEL_TEXT_ANCHOR[datum]}
          />
        }
        labelPlacement="vertical"
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: '#516960' },
          tickLabels: {
            fontSize: 10,
            fill: ({ tickValue: t }) =>
              score[t].axisLabel === '--' && !compareData
                ? '#E75F25'
                : 'rgba(76,96,114,.6)',
            transform: 'translate(0, 8)'
          }
        }}
      />
      <VictoryPolarAxis
        dependentAxis
        tickFormat={(t) => null}
        style={{
          grid: {
            stroke: (t) => (t === 100 ? '#0D2350' : '#B1B8BD')
          },
          axis: {
            stroke: 'transparent'
          }
        }}
      />

      {compareData && (
        <VictoryArea
          style={{ data: { fill: 'rgba(13, 35, 80, 0.2)' } }}
          data={compareData}
        />
      )}
      {compareData && (
        <VictoryLine
          data={compareData}
          style={{
            data: {
              stroke: '#0D2350',
              strokeWidth: 1.5
            }
          }}
        />
      )}
      {compareScatterEstimate &&
        compareScatterEstimate.find((item) => item.y) !== -1 && (
          <VictoryScatter
            data={compareScatterEstimate}
            dataComponent={
              <Pointer
                dasharray
                stroke="#0D2350"
                fill="rgba(13, 35, 80, 0.2)"
              />
            }
          />
        )}

      <VictoryArea
        style={{ data: { fill: 'rgba(127, 184, 0, 0.2)' } }}
        data={score}
      />
      <VictoryLine
        data={score}
        style={{
          data: {
            stroke: '#7FB800',
            strokeWidth: 1.5
          }
        }}
      />
      {scatterPreFillData.find((item) => item.y) !== -1 && (
        <VictoryScatter data={scatterPreFillData} dataComponent={<Pointer />} />
      )}
      {scatterEstimateData.find((item) => item.y) !== -1 && (
        <VictoryScatter
          data={scatterEstimateData}
          dataComponent={
            <Pointer dasharray stroke="#7FB800" fill="rgba(127, 184, 0, 0.2)" />
          }
        />
      )}
    </VictoryChart>
  )
}

function Pointer(props) {
  const { x, y, fill, stroke, dasharray } = props
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 6, y: y - 6 }}
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={fill || 'white'}
        stroke={stroke || '#0D2350'}
        strokeWidth="2"
        strokeDasharray={dasharray ? '2,2' : null}
      />
    </svg>
  )
}
