import React, { useState } from 'react'
import { Container } from './style'
import { Form, Input, Button } from 'antd-mobile'
import { Link, useParams } from 'react-router-dom'
import { passwordReset } from '../../api'
import success from '../../asserts/images/success.png'

export default function ResetPSW(props) {
  const { hash } = useParams()
  const [success, setSuccess] = useState(false)
  return (
    <Container>
      <div>
        {success ? (
          <>
            <div className="title">Password reset successful</div>
            <Successful />
          </>
        ) : (
          <>
            <div className="title">Enter a new password</div>
            <div className="form-container">
              <NewPassword hash={hash} setSuccess={setSuccess} />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

function NewPassword(props) {
  const { hash, setSuccess } = props
  const [sending, setSending] = useState(false)
  const [form] = Form.useForm()
  const onConfirm = async () => {
    await form.validateFields()
    const { password } = form.getFieldsValue()
    try {
      setSending(true)
      await passwordReset({
        password,
        hash
      })
      setSending(false)
      setSuccess(true)
    } catch (error) {
      setSending(false)
    }
  }
  return (
    <Form
      mode="card"
      layout="horizontal"
      requiredMarkStyle="none"
      form={form}
      footer={
        <Button
          block
          type="button"
          color="primary"
          size="large"
          onClick={onConfirm}
          loading={sending}
        >
          Confirm
        </Button>
      }
    >
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input placeholder="Password" clearable type="password" />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'This field is required' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              )
            }
          })
        ]}
      >
        <Input placeholder="Confirm password" clearable type="password" />
      </Form.Item>
    </Form>
  )
}

function Successful(props) {
  return (
    <div className="success-container">
      <img className="info-image" src={success} alt="success" />
      <p>
        You have successfully reset your password, please use your new password
        when logging in
      </p>

      <Link to="/sign-in">
        <Button block type="button" color="primary" size="large">
          Go back to login
        </Button>
      </Link>
    </div>
  )
}
