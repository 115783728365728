import React, { useState, useEffect, useMemo } from 'react'

export default function LabelLevelBar(props) {
  const {
    question: { choices, extra_info },
    answer,
    update
  } = props
  // answer = answer || this.state.levelBarAnswer
  const itemLength = (1 / choices.length) * 100 + '%'

  const [value, setValue] = useState(null)

  useEffect(() => {
    if (answer && !value) {
      setValue(answer.answer.value)
    }
  }, [answer, value])

  const labels = useMemo(() => {
    if (!extra_info.content) return []
    return extra_info.content
  }, [extra_info])

  return (
    <>
      <ul className='q-label-level-bar'>
        {choices.map((item, index) => (
          <li
            key={index}
            style={{ width: itemLength }}
            className={value === item.title.toString() ? 'selected' : ''}
            onClick={(e) => {
              const _value = item.title.toString()
              setValue(_value)
              const newAnswer = Object.assign(answer, {
                answer: { value: _value }
              })
              update(newAnswer, props.question)
            }}
          >
            <div className='title'>{item.title}</div>
            {labels.length > 0 && <div className='label'>{labels[index]}</div>}
          </li>
        ))}
      </ul>
      {answer?.verify && <p className='invalid'>Please select at least 1 option</p>}
    </>
  )
}
