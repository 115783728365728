import React from 'react'
import { Button, Checkbox, Radio, Space } from 'antd-mobile'
import pdfIcon from '../../../asserts/images/pdf.svg'
import viewIcon from '../../../asserts/images/view.svg'
import vectorIcon from '../../../asserts/images/vector.svg'
import radioUnuseIcon from '../../../asserts/icons/radio-unuse.svg'
import radioUseIcon from '../../../asserts/icons/radio-use.svg'
import classNames from 'classnames'
import { SignedIcon } from '.'

const WaiverMedical2 = ({
  relaseHealth,
  onClick,
  understand,
  setUnderstand,
  signedMedical,
  otherProvider,
  setOtherProvider
}) => {
  return (
    <div className="middle">
      <div className="title mHidden">Medical Release</div>
      <div className={classNames('dontRelease', { hidden: relaseHealth })}>
        You have confirmed you do not want to release your health information,
        data, or recommendations. Select the “Next” button to continue.
      </div>
      <div className="sign-title">Sign with E-SIGNATURE</div>
      {signedMedical.map((e, index) => (
        <div className={classNames('card', { disable: !relaseHealth })}>
          <div className="top">
            <img src={pdfIcon} alt="PDF" />
            <div>
              <div className="title">Medical Release Form - {index + 1}</div>
              <div className="desc">Please review and sign document.</div>
            </div>
          </div>
          {e !== undefined ? (
            <div className="signed">
              <SignedIcon /> Signed
            </div>
          ) : (
            <Button
              shape="default"
              color="primary"
              className="review"
              onClick={onClick}
              disabled={!understand || !relaseHealth}
            >
              <img src={viewIcon} alt="" />
              Preview & Sign
            </Button>
          )}
        </div>
      ))}
      <div
        className={classNames('other', {
          hidden: signedMedical.includes(undefined)
        })}
      >
        <div className="title">
          DO YOU HAVE ANY OTHER PROVIDER(S) THAT NEED A MEDICAL RELEASE ?
        </div>
        <Radio.Group value={otherProvider} onChange={setOtherProvider}>
          <Space direction="vertical">
            <Radio
              icon={(checked) =>
                checked ? (
                  <img src={radioUseIcon} alt="" />
                ) : (
                  <img src={radioUnuseIcon} alt="" />
                )
              }
              value={false}
            >
              No
            </Radio>
            <Radio
              icon={(checked) =>
                checked ? (
                  <img src={radioUseIcon} alt="" />
                ) : (
                  <img src={radioUnuseIcon} alt="" />
                )
              }
              value={true}
            >
              Yes
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="understand" onClick={() => setUnderstand(!understand)}>
        <Checkbox checked={understand} />I understand this is a legal
        representation of my signature.
      </div>
      <div className="secret">
        <img align="absmiddle" src={vectorIcon} alt="" />
        Apeiron Life is compliant with all major eSignature laws including
        ESIGN, UETA, European eIDAS, and eSignature service provided by
        HelloSign.
      </div>
    </div>
  )
}

export default WaiverMedical2
