import React from 'react'
import styled from 'styled-components'
import SegmentLine from './segmentLine'
import { Divider } from 'antd-mobile'
// import ChartContainer from './chart'

const Container = styled.div`
  padding: 1rem;
  background: white;
  border-bottom: 1px solid #e2f2ff;
  div {
    font-size: 0.75rem;
  }
  .skeleton {
    height: 12.5rem;
  }
  .header {
    color: #383e48;
    display: flex;
    justify-content: space-between;
    font-size: 1.0625rem;
    font-weight: 700;
    margin-bottom: 0.5625rem;
    text-align: right;
    .score-title {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 150%;
      color: #94a3af;
    }
    .score {
      font-size: 1.5rem;
      line-height: 150%;
    }
    .no-data {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 150%;
      letter-spacing: 0.3px;

      color: #94a3af;
    }
  }
  .range-bar {
    margin-top: 0.9375rem;
    color: #c0c7d1;
    font-size: 0.625rem;
    display: flex;
    justify-content: space-between;
    float: right;
    font-weight: 60;
    .bar-gride {
      position: relative;
      .adm-divider {
        position: absolute;
        height: 4.5rem;
        top: -5rem;
        left: 50%;
        margin: 0;
        border-style: dashed;
        border-width: 1px;
      }
      &:first-child .adm-divider {
        left: 0;
      }
      &:last-child .adm-divider {
        left: 100%;
      }
    }
  }
`

const ClearFloat = styled.div`
  clear: both;
`
// function checkHasData(data) {
//   if (data) {
//     return data.trends.filter((item) => item.pct).length > 0
//   }
//   return false
// }

// const ranges = [0, 25, 50, 100, 200, 300, 400, 500]

export default function HealthySignalCard(props) {
  const {
    svgWidth,
    rangeType,
    domain_label,
    weekly_seg_label,
    weekly_more_seg_label,
    monthly_seg_label,
    monthly_more_seg_label,
    target,
    domain
  } = props || {}
  const data = props.data || {}
  const score = data.seg_pct
  const moreScore = data.more_seg_pct_avg
  const hightestValue = Math.round(
    Math.max(score, moreScore, target || 100) * 1.25
  )
  const barLabels = [
    rangeType === 'week' ? weekly_seg_label : monthly_seg_label,
    rangeType === 'week' ? weekly_more_seg_label : monthly_more_seg_label
  ]
  const bars = [
    {
      seg_label: barLabels[0],
      widthReference: 'seg_pct',
      score,
      workouts: data.seg_workouts,
      target,
      hightestValue,
      svgWidth,
      id: `${domain}_seg_pct`
    },
    {
      seg_label: barLabels[1],
      widthReference: 'more_seg_pct_avg',
      score: moreScore,
      hightestValue,
      svgWidth,
      id: `${domain}_more_seg_pct_avg`
    }
  ]

  const step = Math.round(hightestValue / 3)
  const ranges = [0, step, step * 2, step * 3]

  return (
    <Container>
      <div className="header">
        <span className="title">{domain_label}</span>
        <div>
          <div className="score-title">1-{rangeType} Score</div>
          {score ? (
            <div className="score">
              {Math.round((score / (target || 100)) * 100) + '%'}
            </div>
          ) : (
            <div className="no-data">N/A</div>
          )}
        </div>
      </div>
      {bars.map((bar, index) => (
        <SegmentLine
          {...bar}
          key={index}
          ranges={ranges}
          data={data}
          hightestValue={hightestValue}
        />
      ))}

      <div className="range-bar" style={{ width: svgWidth }}>
        {ranges.map((value, index) => (
          <div key={index} className="bar-gride">
            <Divider direction="vertical" />
            <span>{value.toString()}</span>
          </div>
        ))}
      </div>
      <ClearFloat />
      {/* {checkHasData(data) && <ChartContainer {...props} />} */}
    </Container>
  )
}
