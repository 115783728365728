import React, { useState, useEffect } from 'react'
import { Radio, Grid } from 'antd-mobile'
import _ from 'lodash'
import radioUnuseIcon from '../../../asserts/icons/radio-unuse.svg'
import radioUseIcon from '../../../asserts/icons/radio-use.svg'
import styled from 'styled-components'

export const RadioLayout = styled.div`
  .adm-radio-custom-icon {
    line-height: normal;
    height: 21px;
  }
`

export default function YesOrNO(props) {
  const { answer, update, question } = props
  const [value, setValue] = useState(null)
  useEffect(() => {
    if (_.isBoolean(answer.answer.value)) {
      setValue(answer.answer.value)
    }
  }, [answer])

  return (
    <div className="q-form-group">
      <Radio.Group
        onChange={onChange}
        value={_.isBoolean(value) ? (value === true ? 'yes' : 'no') : null}
      >
        <Grid columns={2} gap={8}>
          <Grid.Item>
            <RadioLayout
              className={`q-form-item ${value === false ? 'valid' : ''}`}
            >
              <Radio
                value="no"
                icon={(checked) =>
                  checked ? (
                    <img src={radioUseIcon} alt="" />
                  ) : (
                    <img src={radioUnuseIcon} alt="" />
                  )
                }
              >
                No
              </Radio>
            </RadioLayout>
          </Grid.Item>
          <Grid.Item>
            <RadioLayout
              className={`q-form-item ${value === true ? 'valid' : ''}`}
            >
              <Radio
                value="yes"
                icon={(checked) =>
                  checked ? (
                    <img src={radioUseIcon} alt="" />
                  ) : (
                    <img src={radioUnuseIcon} alt="" />
                  )
                }
              >
                Yes
              </Radio>
            </RadioLayout>
          </Grid.Item>
        </Grid>
      </Radio.Group>

      {answer && answer.verify && (
        <p className="invalid">
          {answer.verify.message || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )

  function onChange(val) {
    const value = val === 'yes' ? true : false
    setValue(value)
    const newAnswer = _.assignIn(answer, { answer: { value } })
    update(newAnswer, question)
  }
}
