import React from 'react'
import EmptyImage from '../../asserts/images/client-empty.png'

export default function Empty(props) {
  return (
    <div style={{ paddingTop: props.paddingTop || '8em', textAlign: 'center' }}>
      <img
        src={props.emptyImage || EmptyImage}
        alt="empty"
        width="auto"
        style={{ width: '18em' }}
      />
      <h3
        style={{
          paddingTop: '4.3em',
          color: props.color || 'rgba(121, 135, 155, 0.8)',
          display: 'block',
          fontWeight: 'normal',
          border: 'none'
        }}
      >
        {props.message}
      </h3>
    </div>
  )
}
