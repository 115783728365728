import React from 'react'
import {
  QuestionContainer,
  MultipleChoice,
  YesOrNO,
  TextEntry,
  LevelBar,
  DatePicker,
  TimePicker,
  Select,
  Number,
  SliderContainer,
  SectionTitle,
  InlineEntries,
  Emergency,
  ResidenceAddress,
  IntroSingleChoice,
  AdditionalForm,
  SingleChoices,
  QuestionGroup,
  LabelLevelBar,
  WeeklyActivities
} from '../../commonComponents/questionnaireComponents'
// import { pattern } from '../../../utils/constant'
// import { Popover } from 'antd-mobile'
import _, { includes } from 'lodash'

export default function Question(props) {
  const {
    question,
    answers,
    updateAnswer,
    sessionId,
    extraInfo,
    setConfirmBtnDisabled
  } = props
  // const { question, answers, reIndex, person, updateAnswer } = props

  if (
    ['description', 'sub_title', 'banner', 'section_title'].includes(
      question.type
    ) ||
    typeof question === 'string'
  ) {
    return renderQuestionWithoutAnswer(question.type)
  }

  return (
    <QuestionContainer>
      <div
        className={`question ${question.hide_title ? 'hide-title' : ''} ${
          question.type === 'question_group' ? 'q-group' : ''
        }`}
      >
        {question.type !== 'sub_title' &&
          question.type !== 'banner' &&
          !question.hide_title && (
            <div className="q-title">
              {question.id && <span>{question.id}</span>}
              {question.required && <i className="required">*</i>}
              <p
                dangerouslySetInnerHTML={{
                  __html: question.question_html || question.question
                }}
              />
            </div>
          )}
        <div className="q-body">{renderQuestion()}</div>
      </div>
    </QuestionContainer>
  )

  function renderQuestion() {
    const answer = answers.find((item = {}) => {
      if (item.question_original_id) {
        return item.question_original_id === question.original_id
      }
      return item.question_id === question.id
    })
    return renderQuestionByType(answer)
  }

  function renderQuestionWithoutAnswer(type) {
    let element
    switch (type) {
      case 'description':
        element = (
          <div
            className="conponent description"
            dangerouslySetInnerHTML={{
              __html: _.replace(
                question.question_html || question.question,
                'font-size: 24px',
                'font-size: 1rem'
              )
            }}
          />
        )
        break
      case 'banner':
        element = (
          <div className="introduction banner ">
            <p dangerouslySetInnerHTML={{ __html: question.question }} />
          </div>
        )
        break
      case 'sub_title':
        element = subTitle()
        break
      case 'section_title':
        element = <SectionTitle question={question} />
        break
      default:
        break
    }

    if (typeof question === 'string') {
      element = (
        <div className="introduction bottom-line">
          {question.includes('\n') ? <pre>{question}</pre> : <p>{question}</p>}
        </div>
      )
    }

    return (
      <QuestionContainer>
        <div className="question">{element}</div>
      </QuestionContainer>
    )
  }

  function renderQuestionByType(answer) {
    const { type, allow_multiple_answer } = question
    if (!answer) {
      const isArrayAnswer = type === 'multi_choice' || allow_multiple_answer
      answer = {
        session_id: sessionId,
        question_original_id: question.original_id,
        answer: isArrayAnswer
          ? []
          : {
              value: null
            }
      }
      if (includes(['question_group', 'weekly_activiy_group'], question.type)) {
        answer.answer = {}
      }
    }

    const childProps = { question, answer, update: updateAnswer }

    switch (type) {
      case 'text_entry':
        return <TextEntry {...childProps} />
      case 'number':
        return <Number {...childProps} />
      case 'select':
        return <Select {...childProps} />
      case 'inline-entries':
        return <InlineEntries {...childProps} />
      case 'introductory_emergency':
        return <Emergency {...childProps} />
      case 'residence_address':
        return <ResidenceAddress {...childProps} /> //todo  match new struct not completed
      case 'intro_single_choice':
        return <IntroSingleChoice {...childProps} {...extraInfo} />
      case 'yes_no':
        return <YesOrNO {...childProps} />
      case 'single_choice':
        return <SingleChoices {...childProps} />
      case 'level_bar':
        return <LevelBar {...childProps} />
      case 'label_level_bar':
        return <LabelLevelBar {...childProps} />
      case 'multi_choice':
        return <MultipleChoice {...childProps} />
      case 'slider':
        return <SliderContainer {...childProps} /> // todo not completed and style adjust
      case 'date':
        return <DatePicker {...childProps} />
      case 'time':
        return <TimePicker {...childProps} />
      case 'additional_form':
        return <AdditionalForm {...childProps} /> // todo use antd-mobile form implement
      // todo
      case 'question_group':
        return <QuestionGroup {...childProps} />
      case 'weekly_activiy_group':
        return (
          <WeeklyActivities
            {...childProps}
            setConfirmBtnDisabled={setConfirmBtnDisabled}
          />
        )
      default:
        return <div>{type}</div>
    }
  }

  function subTitle() {
    return (
      <div className="q-sub-title">
        <div className="container">
          <img src={question.icon} alt="icon" className="icon" />
          <div className="content">{question.question}</div>
        </div>
      </div>
    )
  }
}
