import React, { useState } from 'react'
import { Container } from './style'
import { Form, Input, Button, AutoCenter } from 'antd-mobile'
import { saveUser, setTokenItem } from '../../utils'
import { passwordInitChange } from '../../api'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

import success from '../../asserts/images/success.png'
import { useNavigate } from 'react-router-dom'
import Header from '../commonComponents/header'

export default function ChangePsw(props) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleRPWD, setVisibleRPWD] = useState(false)

  return (
    <Container>
      <Header/>
      {isDone ? (
        <div>
          <AutoCenter>
            <div className="title"> Password updated!</div>
          </AutoCenter>
          <div className="success-container">
            <img className="info-image" src={success} alt="success" />
            <div>You have successfully updated your password, please use your new password when logging in</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="title">Enter a new password</div>
          <div className='desc'>
            <div>Please change your password on the first time login</div>
          </div>
          <div className="form-container">
            <Form
              mode="card"
              layout="horizontal"
              requiredMarkStyle="none"
              form={form}
            >
              <Form.Header><span className='required'>*</span> NEW PASSWORD</Form.Header>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'This field is required' }]}
                extra={
                  <div>
                    {!visible ? (
                      <AiOutlineEyeInvisible onClick={() => setVisible(true)} />
                    ) : (
                      <AiOutlineEye onClick={() => setVisible(false)} />
                    )}
                  </div>
                }
              >
                <Input placeholder="Password" clearable type={visible ? 'text' : 'password'} />
              </Form.Item>
              <Form.Header><span className='required'>*</span> VERIFY NEW PASSWORD</Form.Header>
              <Form.Item
                name="confirm_password"
                dependencies={['password']}
                extra={
                  <div>
                    {!visibleRPWD ? (
                      <AiOutlineEyeInvisible onClick={() => setVisibleRPWD(true)} />
                    ) : (
                      <AiOutlineEye onClick={() => setVisibleRPWD(false)} />
                    )}
                  </div>
                }
                rules={[
                  { required: true, message: 'This field is required' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords do not match!'
                        )
                      )
                    }
                  })
                ]}
              >
                <Input
                  placeholder="Confirm password"
                  clearable
                  type={visibleRPWD ? 'text' : 'password'}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    <div className='stickBottom'>
    <div><Button block type="button" color="primary" size="large" loading={loading} onClick={() => onConfirm()}>
          {isDone ? 'Continue' : 'Done'}
        </Button></div>
    </div>
    </Container>
  )

  async function onConfirm() {
    if(isDone){
      navigate('/2fa')
      return
    }
    setLoading(true)
    try {
      await form.validateFields()
      const { password } = form.getFieldsValue()
      const { token, person } = await passwordInitChange({ password })
      saveUser(person)
      setTokenItem(token)
      setLoading(false)
      setIsDone(true)
    } catch (err) {
      setLoading(false)
    }
  }
}
