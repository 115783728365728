import React from 'react'
import styled from 'styled-components'
import { ReactComponent as FileIcon } from '../../../asserts/icons/icon-onboarding-record-file.svg'
import { useNavigate } from 'react-router-dom'
import { RightOutline } from 'antd-mobile-icons'

const Container = styled.div`
  background: #f1f3f6;
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    justify-content: center;
    h2 {
      color: #323f4a;
      font-family: Gilroy;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.3px;
    }
    p {
      margin-top: 32px;
      color: #52606c;
      padding: 0 24px;
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.3px;
    }
  }

  .destination-view {
    background-color: white;
    border-radius: 12px;
    align-items: center;
    padding: 17px 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    cursor: pointer;
    align-self: stretch;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
`

export function RecordCompletedPage(props) {
  const { img, desc, destinations } = props
  const navigate = useNavigate()
  return (
    <Container>
      <div className="content">
        <h2>Success</h2>
        {img && <img src={img} alt="icon" />}
        {desc && desc}
        <p className="desc">
          Thank you for completing the questionnaire. You can now review your
          personalized result below.
        </p>
        {destinations.map((d) => {
          return (
            <div
              className="destination-view"
              onClick={() => {
                if (navigator.userAgent.includes('life.apeiron.ios')) {
                  d.inAppFunc()
                } else {
                  navigate(d.path)
                }
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FileIcon />
                <span style={{ marginLeft: 12 }}>{d.name}</span>
              </div>
              <RightOutline />
            </div>
          )
        })}
      </div>
    </Container>
  )
}
