import React, { Component } from 'react'
import moment from 'moment'
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai'
import { Divider } from 'antd-mobile'
import { CardContainer } from './index.styled'

class HealthCard extends Component {
  state = {
    score: null
  }

  getChange(score, compareScore) {
    if (!score || !compareScore) return null

    const change = score - compareScore
    return (
      <>
        {change > 0 ? (
          <AiOutlineCaretUp style={{ color: '#40C47C' }} />
        ) : (
          <AiOutlineCaretDown style={{ color: '#FF6B00' }} />
        )}{' '}
        {Math.abs(change)}
      </>
    )
  }

  getStatus = (score, name) => {
    const { compare } = this.props
    let compareScore
    if (compare) {
      compareScore =
        (compare.score_manual && compare.score_manual.value) ||
        compare.score_auto
    }
    let status = ''

    if (!score && score !== 0) {
      status = 'NONE'
    } else if (score <= 25) {
      status = 'Needs Attention'
    } else if (score > 25 && score <= 39) {
      status = 'Below Average'
    } else if (score > 39 && score <= 59) {
      status = 'Average'
    } else if (score > 59 && score <= 74) {
      status = 'Very Good'
    } else if (score > 74 && score <= 89) {
      status = 'Excellent'
    } else if (score > 89) {
      status = 'Exceptional'
    }
    return { status, compareScore }
  }

  checkSocreLegal = (score) => {
    return score ? score >= 5 && score <= 99 : true
  }

  editHandle = (fromProps) => {
    const { score_manual, score_auto, compare } = this.props
    this.setState({
      latest: (score_manual && score_manual.value) || score_auto,
      previous:
        (compare && compare.score_manual && compare.score_manual.value) ||
        (compare && compare.score_auto),
      isEditing: fromProps ? false : true
    })
  }

  renderFooter(score, status, compareScore) {
    const { latest_test_date, compare } = this.props
    const currentLatestData = latest_test_date
    const compareLatestData = compare && compare.latest_test_date

    return (
      <footer>
        {`${
          currentLatestData
            ? moment(currentLatestData).format('MMM DD, YYYY')
            : '--'
        } vs. ${
          compareLatestData
            ? moment(compareLatestData).format('MMM DD, YYYY')
            : '--'
        }`}
      </footer>
    )
  }

  render() {
    const {
      name,
      description,
      compare,
      missing_metrics,
      jumpToDataset,
      routeTypes,
      loginRole,
      score_manual,
      score_auto
      // isDefaultValue,
      // latest_test_date
    } = this.props
    // const { isEditing, latest, previous } = this.state
    // const latestLegal = this.checkSocreLegal(latest)
    // const previousLegal = this.checkSocreLegal(previous)
    const score = (score_manual && score_manual.value) || score_auto
    const { status, compareScore } = this.getStatus(score, name)
    return (
      <CardContainer status={status}>
        <div className="health-card">
          <div className="meta-content">
            <header>
              <div className="name">
                {name}
                <div className="status">
                  {status !== 'NONE' ? status : 'No Data'}
                </div>
              </div>
              <div className="score">
                {status !== 'NONE' ? score : '--'}
                {compare && (
                  <>
                    {' '}
                    <span className="split">/</span>{' '}
                    <span className="compare-data">
                      {compareScore ? compareScore : '--'}
                    </span>
                    <span className="change">
                      {this.getChange(score, compareScore)}
                    </span>
                  </>
                )}
              </div>
            </header>
            <Divider />
            {status !== 'NONE' ||
            loginRole === 'User' ||
            loginRole === 'Service_Provider' ? (
              <div className="description">{description}</div>
            ) : (
              <div className="description">
                <div>
                  The following metrics are missing or incorrect. Please check
                  and try again.
                </div>
                {missing_metrics && (
                  <ul>
                    {missing_metrics.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          jumpToDataset && jumpToDataset(item.group)
                        }
                        style={{
                          color: '#e75f25',
                          textDecoration: 'underline',
                          cursor:
                            routeTypes && routeTypes.match(item.type)
                              ? 'pointer'
                              : 'auto'
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {this.renderFooter(score, status, compareScore)}
          </div>
        </div>
      </CardContainer>
    )
  }
}

export default HealthCard
