import React from 'react'
import CustomizeIcon from './customizeIcon'

const checkedCircle = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" />
    <path
      d="M4.5 8.5L7 11L11.5 6.5"
      stroke="#FCFDFD"
      strokeMiterlimit="10"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CheckedCircleIcon = (props) => (
  <CustomizeIcon source={checkedCircle} {...props} />
)

const mail = (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M20.8509 18.7021H3.14881C2.39715 18.7021 1.78711 18.0921 1.78711 17.3404V2.3617C1.78711 1.61004 2.39715 1 3.14881 1H20.8509C21.6026 1 22.2126 1.61004 22.2126 2.3617V17.3404C22.2126 18.0921 21.6026 18.7021 20.8509 18.7021Z"
        stroke="#9AAAC9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M4.51074 5.08521L12.0001 11.2129L19.4895 5.08521"
        stroke="#9AAAC9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const MailIcon = (props) => <CustomizeIcon source={mail} {...props} />

const logout = (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M9.16992 6.44681V2.3617C9.16992 1.61004 9.77996 1 10.5316 1H20.0635C20.8152 1 21.4252 1.61004 21.4252 2.3617V18.7021C21.4252 19.4538 20.8152 20.0638 20.0635 20.0638H10.5316C9.77996 20.0638 9.16992 19.4538 9.16992 18.7021V14.617"
        stroke="#9AAAC9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M15.9787 10.532H1"
        stroke="#9AAAC9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M5.08511 6.44678L1 10.5319L5.08511 14.617"
        stroke="#9AAAC9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const LogoutIcon = (props) => (
  <CustomizeIcon source={logout} {...props} />
)
