import axios from 'axios'
import { getToken, removeTokenItem } from '../utils/storage'
import { baseURL } from '../utils/constant'
// import { message } from 'antd'
import { Toast } from 'antd-mobile'
import Cookies from 'js-cookie'

const message = {
  error: (errMessage) =>
    Toast.show({
      // icon: "fail",
      content: errMessage,
      maskClassName: 'toast-error-msg'
    })
}

// axios.defaults.withCredentials = true
const service = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 240000,
  transformRequest: (obj) => JSON.stringify(obj),
  headers: { 'Content-Type': 'application/json' }
})

service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers['Authorization'] = `Bearer ${getToken()}`
    }
    return config
  },
  (error) => {
    message.error(error.message)
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  (error) => {
    if (error.message === 'Request failed with status code 401') {
      removeTokenItem()
      window.location.pathname = '/sign-in'
    } else if (error.message === 'Request failed with status code 404') {
      console.error('404')
      const data = error.response && error.response.data
      data && data.message && message.error(data.message)
    } else {
      const data = error.response && error.response.data
      const messageStr = (data && data.message) || error.message
      console.error('error.response', error.response)
      console.error('catch err', data)
      messageStr && message.error(messageStr)
    }
    return Promise.reject((error.response && error.response.data) || error)
  }
)

service.postJson = (path, params) =>
  service({
    url: path,
    method: 'post',
    data: params
  })

service.getJson = (path, params) =>
  service({
    url: path,
    method: 'get',
    params: params || {}
  })

service.putJson = (path, params) =>
  service({
    url: path,
    method: 'put',
    data: params
  })

service.deleteJson = (path, params) =>
  service({
    url: path,
    method: 'delete',
    data: params
  })

service.delete = (path) =>
  service({
    url: path,
    method: 'delete'
  })

service.getDataWithCancelHandle = (path, cancelSource) =>
  service({
    url: path,
    method: 'get',
    cancelToken: cancelSource && cancelSource.token
  })

export default service

const hashKey = 'HASH'

export const ConfigHash = (hash) => {
  Cookies.set(hashKey, hash)
}

export const GetHash = () => {
  return Cookies.get(hashKey)
}

export const hashRequest = ({ hash, method, path, params, data }) => {
  if(!hash){
    hash = Cookies.get(hashKey)
  }
  const service = axios.create({
    baseURL,
    timeout: 240000,
    transformRequest: (obj) => JSON.stringify(obj),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Permalink ${hash}`
    }
  })
  const obj = {
    url: path,
    method
  }
  if (params) obj.params = params
  if (data) obj.data = data
  return service(obj)
}

export const hashGet = async ({ hash, method = 'GET', path, params, data }) => {
  const res = await hashRequest({hash, method, path, params, data})
  return res.data
}

export const hashPut = async ({ hash, method = 'PUT', path, params, data }) => {
  const res = await hashRequest({hash, method, path, params, data})
  return res.data
}

export const formDataRequest = ({
  path,
  method,
  data,
  onUploadProgress,
  cancelSource,
  propConfig = {},
  overWriteAbort
}) => {
  const config = {
    baseURL,
    timeout: 600000,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`
    },
    ...propConfig
    // transformRequest: obj => qs.stringify(obj)
  }
  if (onUploadProgress) {
    config.onUploadProgress = onUploadProgress
  }
  const service = axios.create(config)
  service.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data)
    },
    (error) => {
      return Promise.reject((error.response && error.response.data) || error)
    }
  )
  const result = service({
    url: path,
    method,
    data,
    cancelToken: cancelSource && cancelSource.token
  })

  return result
}
