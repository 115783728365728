import React, { useEffect } from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Modal, Divider } from 'antd-mobile'
import { AiOutlineRight } from 'react-icons/ai'
import styled from 'styled-components'
import { logOutAction, questionnaireConfig } from '../../../utils'
import { CheckedCircleIcon } from '../../commonComponents/icons'
import { WaiverFormKey } from '..'
import classNames from 'classnames'

const Container = styled.div`
  padding: 0 1.5rem;
  max-width: 45rem;
  margin: 0 auto;
  background: #ffffff;
  position: relative;
  padding-bottom: 3rem;
  .setup-image {
    /* margin-top: 24px; */
    text-align: center;
    img {
      max-width: 65%;
    }
  }
  .title {
    color: #29303e;
    font-size: 28px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.2px;
    margin-top: 38px;
    margin-bottom: 0px;
  }
  h3 {
    color: #323f4a;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .desc {
    color: #52606c;
    font-size: 14px;
    font-style: normal;
    font-family: Gilroy;
    font-weight: 500;
    line-height: 140%;
    margin: 0px;
  }
  .adm-divider {
    display: none;
  }
  .cta-start {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    height: 48px;
  }
  .cta-back {
    border: 1px solid #2b4b8f;
    color: #2b4b8f;
    margin-top: 16px;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .number {
        width: 30px;
        height: 30px;
        border: 2px solid #98a9bc;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #264382;
        font-size: 12px;
        font-weight: 800;
      }
      .name {
        color: #52606c;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        color: #52606c;
        margin-left: 14px;
        margin-right: 20px;
      }
      .time {
        padding: 4px 8px;
        color: #52606c;
        font-size: 12px;
        font-weight: 600;
        line-height: 116%;
        letter-spacing: 0.3px;
        border-radius: 22px;
        background: #e6e9f2;
      }
    }
    .done {
      svg {
        color: #05b800;
        height: 34px;
        width: 34px;
      }
      .number {
        background-color: #264382;
        color: #fff;
      }
    }
    .active {
      .number {
        background-color: #264382;
        color: #fff;
      }
    }
  }

  @media (min-width: 767px) and (min-height: 767px) {
    padding: 3rem;
    padding-bottom: 2.25rem;
    .setup-image {
      width: 38%;
      position: absolute;
      right: 3.75rem;
      top: 18%;
      img {
        max-width: 18rem;
      }
    }
    .title {
      width: 55%;
    }
    .adm-divider {
      display: block;
    }
    .adm-button {
      max-width: 12rem;
      margin: 0 auto;
      margin-top: 2.25rem;
    }
  }
`

export default function SetupScreen(props) {
  const { person, steps, activeStep, setActiveStep } = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    const {
      profile: {
        waiver_required,
        questionnaire_required,
        // payment_required,
        completed_signature,
        introductory_required,
        pre_screening_completed,
        pre_screening_locked,
        membership,
        onboarding_steps: {
          introductory_completed_steps,
          waiver_forms_skipped,
          // billing_info_skipped,
          questionnaire_skipped
        }
      }
    } = person
    let activeStep = 0
    if (pre_screening_locked) {
      Modal.alert({
        content: (
          <div>
            <p>
              Due to your medical history, our Medical Director Dr. Dev Mishra
              will reach out to you for discussion prior to participation in our
              Apeiron Life program.
            </p>
          </div>
        ),
        showCloseButton: true,
        confirmText: 'Log Out',
        afterClose: () => {
          if (navigator.userAgent.includes('life.apeiron.ios')) {
            window.webkit.messageHandlers.signOut.postMessage({})
          } else {
            logOutAction()
          }
        }
      })
    }
    if (!pre_screening_completed) {
      activeStep = 0
    } else if (!(completed_signature || []).includes(WaiverFormKey.LIABILITY)) {
      activeStep = 1
    } else if (
      introductory_required ||
      !(
        introductory_completed_steps &&
        introductory_completed_steps.length === 2
      )
    ) {
      activeStep = 2
    } else if (
      !(completed_signature || []).includes(WaiverFormKey.MEDICAL) &&
      (waiver_forms_skipped || []).length === 0
    ) {
      activeStep = 3
    } else if (
      questionnaire_required &&
      questionnaire_skipped.length < questionnaireConfig.sections.length
    ) {
      activeStep = 4
    } else {
      activeStep = 5
    }
    setActiveStep(activeStep)
    // console.info(`
    //   Ignore the warning about "findDomNode us deprecated..."
    //   This is react-mobile Popover issue
    //   No alternative scheme for now
    //   See here: https://mobile.ant.design/components/popover#what-if-the-warning-finddomnode-is-deprecated-in-strictmode-appears-in-the-browser-console
    // `)
  }, [person, setActiveStep])

  const step = steps[activeStep]
  return (
    <Container>
      <div className="setup-image">
        <img src={step.setupImage} alt="setup" />
      </div>
      <h1 className="title">{step.tag}</h1>
      <h3 className="intro">{step.introduction}</h3>
      <div className="desc">{step.description}</div>
      <div className="list">
        {steps
          .filter((e) => e.showIndex)
          .map((e, i) => (
            <div
              className={classNames(
                'item',
                { done: e.isDone },
                { active: i === activeStep }
              )}
            >
              {e.isDone ? (
                <CheckedCircleIcon />
              ) : (
                <div className="number">{e.showIndex}</div>
              )}
              <div className="name">{e.tag}</div>
              <div className="time">{e.min}</div>
            </div>
          ))}
      </div>
      <br />

      <Divider />

      {activeStep < 5 && (
        <Link to={steps[activeStep].next}>
          <Button
            className="cta-start"
            shape="default"
            color="primary"
            block
            size="large"
          >
            {activeStep === 0 ? 'GET STARTED ' : 'continue '}
            <AiOutlineRight
              style={{
                verticalAlign: ' -0.125em'
              }}
            />
          </Button>
        </Link>
      )}
      {activeStep > 1 && (
        <Button
          shape="default"
          color="default"
          block
          size="large"
          className="cta-back"
          onClick={() => {
            // const url = steps[activeStep - 1].targetUrl
            // navigate(url)
            setActiveStep(activeStep - 1)
          }}
        >
          Back
        </Button>
      )}
    </Container>
  )
}
