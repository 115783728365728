import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd-mobile'

const Container = styled.div`
  background: #f1f3f6;
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    justify-content: center;
    h2 {
      color: #323f4a;
      font-family: Gilroy;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.3px;
    }
    p {
      margin-top: 32px;
      color: #52606c;
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.3px;
    }
  }

  footer {
    background: white;
    padding: 30px 24px;
    box-sizing: border-box;
  }
`

export function CompletedPage(props) {
  const { title, img, desc, action, actionTitle, extraFooter } = props
  return (
    <Container>
      <div className="content">
        {title && <h2>{title}</h2>}
        {img && <img src={img} alt="icon" />}
        {desc && desc}
      </div>
      <footer>
        <Button block shape="rounded" color="primary" onClick={action}>
          {actionTitle}
        </Button>
        {extraFooter}
      </footer>
    </Container>
  )
}
