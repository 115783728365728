import styled from 'styled-components'
import IconDone from '../../../asserts/icons/onboarding/done.svg'
import { Button } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
// import { logOutAction } from '../../../utils'

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    margin-top: 88px;
    padding-top: 30px;
    height: calc(100vh - 295px);
    overflow-y: scroll;
    .title {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0.30000001192092896px;
      color: #323f4a;
      text-align: left;
      margin-top: 48px;
    }
    img {
      margin-top: 32px;
      margin-bottom: 32px;
    }
    p {
      text-align: center;
      margin-top: 0px;
    }
  }
  .control {
    border-top: 1px solid #e6e9f2;
    background: #fff;
    box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    .adm-button {
      padding: 13px 0;
      span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.3px;
      }
    }
    .adm-button-default {
      margin-top: 12px;
      border-radius: 30px;
      border: 1px solid #264382;
      background: #fff;
      span {
        color: #264382;
      }
    }
  }
`

const MembershipSuccess = () => {
  const navigate = useNavigate()

  const onClickDoit = () => {
    const url = '/onboarding/ready/'
    navigate(url)
  }

  // const onClickLater = () => {
  //   if (navigator.userAgent.includes('life.apeiron.ios')) {
  //     window.webkit.messageHandlers.signOut.postMessage({})
  //   } else {
  //     logOutAction()
  //   }
  // }

  return (
    <Container>
      <div className="content">
        <div className="title">Success</div>
        <img src={IconDone} alt="" />
        <p>
          Thank you for joining Apeiron Life. We are excited to welcome you as a
          new member!
        </p>
        <p>
          Your next step is to fill out our pre-assessment medical review. Once
          your medical clearance is completed, your Apeiron Life Concierge will
          reach out to begin scheduling.
        </p>
      </div>
      <div className="control">
        <Button shape="default" color="primary" onClick={onClickDoit}>
          DO IT NOW
        </Button>
        {/* <Button shape="default" color="default" onClick={onClickLater}>
          I WILL COME BACK LATER
        </Button> */}
      </div>
    </Container>
  )
}

export default MembershipSuccess
