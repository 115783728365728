import _ from 'lodash'
import axios, { hashRequest } from './axios'

export const getNutritionScore = async (personId, hash) => {
  const url = `/nutrition_score?person_id=${personId}`
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  const result = await axios.get(url)
  return result
}

export const getNutritionScoreDetail = async ({
  personId,
  questionId,
  hash,
  questionOriginalId,
  sectionVersion
}) => {
  let url = `/nutrition_score/detail?person_id=${personId}&section_version=${sectionVersion}`
  if (questionOriginalId) {
    url += `&question_original_id=${questionOriginalId}`
  }
  if (questionId) {
    if (typeof questionId === 'string' && _.isNaN(Number(questionId))) {
      url += `&question_subject=${questionId}`
    } else {
      url += `&question_id=${questionId}`
    }
  }

  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    result = result.data
  } else {
    result = await axios.get(url)
  }
  return result
}

export const getNutritionQuestionOriginalId = async (id, version, hash) => {
  const url = `/nutrition_score/question-original-id?section_version=${version}&question_id=${id}`

  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    result = result.data
  } else {
    result = await axios.get(url)
  }
  return result
}

export const getHealthySignalsSettings = async (hash) => {
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: '/healthy_signals/settings'
    })
    return result.data
  } else {
    return await axios.get('/healthy_signals/settings')
  }
}

export const getHealthySignals = async ({
  personId,
  rangeType,
  from,
  to,
  hash
}) => {
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: `/healthy_signals?range_type=${rangeType}&from=${from}&to=${to}`
    })
    return result.data
  } else {
    return await axios.get(
      `/healthy_signals?person_id=${personId}&range_type=${rangeType}&from=${from}&to=${to}`
    )
  }
}

export const getHealthStatus = async (personId, hash) => {
  let url = `/health_status?person_id=${personId}`
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  return axios.get(url)
}

export const getMicrobiomeScore = async (personId, from, to, hash) => {
  let url = `microbiome_score/details?person_id=${personId}`
  if (from) {
    url += `&start_date=${from}`
  }
  if (to) {
    url += `&end_date=${to}`
  }
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  return await axios.get(url)
}
