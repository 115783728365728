import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter } from 'victory'

export const dotColors = ['#316F89', '#3AA081', '#ACE1C8']

export const axisStyles = {
  axis: {
    strokeWidth: 1.5,
    stroke: '#8A969F',
    strokeDasharray: '6, 3'
  },
  grid: {
    strokeWidth: 1.5,
    stroke: 'rgba(138,150,159,.2)',
    strokeDasharray: '6, 3'
  },
  tickLabels: {
    fill: '#8A969F',
    fontSize: 12,
    padding: 10
  },
  axisLabel: {
    fill: '#8A969F',
    fontSize: 12,
    padding: 35
  }
}

export default function Logarithmic(props) {
  const { percentFatSeries, percentFatHistory, person, ui_version } = props
  let age = moment().diff(person.profile.date_of_birth, 'years')
  if (age < 5) {
    age = 5
  }
  const axisX = ui_version
      ? [age - 5, age, age + 5]
      : [15, 25, 35, 45, 55, 65, 75, 85],
    axisY = [0, 10, 20, 30, 40, 50, 60]
  const data = []
  for (const { level, color, type, series } of percentFatSeries) {
    const len = series.y.length
    const m = 3
    const a = new Array(series.x.length)
    const aa = PolyFit(series.x, series.y, len, a, m)
    data.push({
      aa,
      level,
      color,
      type,
      arrX: series.x,
      m
    })
  }
  const scatter = _.cloneDeep(percentFatHistory)
    .reverse()
    .map((item, index) => {
      const result = {
        data: [{ x: item[0], y: item[1] }],
        date: moment(item[2]).format('MM/DD/YYYY'),
        color: dotColors[index - 1] || '#BFBFBF'
      }
      if (index === 0) {
        result.dataComponent = <LargePointer />
      } else {
        result.style = { data: { fill: dotColors[index - 1] || '#BFBFBF' } }
        result.size = 5
      }
      return result
    })

  const historyMax = Math.max(...percentFatHistory.map((item) => item[1]))
  while (historyMax && historyMax > axisY[axisY.length - 1]) {
    axisY.push(axisY[axisY.length - 1] + 10)
  }

  return (
    <>
      <div className="tip-label">
        {data.map(({ level, color, type }, index) => (
          <div key={index}>
            {type === 'solid' ? (
              <span className="tip-line" style={{ background: color }} />
            ) : (
              <span>
                <span>
                  <svg
                    width="12"
                    height="2"
                    viewBox="0 0 12 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="1"
                      x2="12"
                      y2="1"
                      stroke={color}
                      strokeWidth="2"
                      strokeDasharray="2 2"
                    />
                  </svg>
                </span>
              </span>
            )}
            {level}
          </div>
        ))}
      </div>
      <div className="tip-label">
        {_.cloneDeep(scatter)
          .reverse()
          .map((item, index) => (
            <div key={index}>
              {item.dataComponent ? (
                <span>
                  <SmallPointer />
                </span>
              ) : (
                <span
                  className="tip-circle"
                  style={{ background: item.color }}
                />
              )}
              {item.date}
            </div>
          ))}
      </div>

      <VictoryChart
        width={500}
        height={450}
        minDomain={{ x: age - 5, y: 0 }}
        padding={{
          top: 20,
          left: 55,
          bottom: 60,
          right: 0
        }}
      >
        <VictoryAxis tickValues={axisX} label="AGE" style={axisStyles} />
        <VictoryAxis
          tickValues={axisY}
          dependentAxis
          style={axisStyles}
          label="BODY FAT %"
        />

        {data.map(({ arrX, aa, m, color, type }, index) => (
          <VictoryLine
            key={index}
            y={(d) => {
              return d.x >= 20 ? getY(d.x, arrX, aa, m) : null
            }}
            style={{
              data: {
                stroke: color,
                strokeDasharray: type === 'dotted' ? '3,3' : null
              }
            }}
          />
        ))}

        {scatter.map((item, index) => (
          <VictoryScatter key={index} {...item} />
        ))}
      </VictoryChart>
    </>
  )
}

export function PolyFit(x, y, n, a, m) {
  var i, j, k
  var z,
    p,
    c,
    g,
    q = 0,
    d1,
    d2
  var s = new Array(20)
  var t = new Array(20)
  var b = new Array(20)
  var dt = new Array(3)
  for (i = 0; i <= m - 1; i++) {
    a[i] = 0.0
  }
  if (m > n) {
    m = n
  }
  if (m > 20) {
    m = 20
  }
  z = 0.0
  for (i = 0; i <= n - 1; i++) {
    z = z + x[i] / (1.0 * n)
  }
  b[0] = 1.0
  d1 = 1.0 * n
  p = 0.0
  c = 0.0
  for (i = 0; i <= n - 1; i++) {
    p = p + (x[i] - z)
    c = c + y[i]
  }
  c = c / d1
  p = p / d1
  a[0] = c * b[0]
  if (m > 1) {
    t[1] = 1.0
    t[0] = -p
    d2 = 0.0
    c = 0.0
    g = 0.0
    for (i = 0; i <= n - 1; i++) {
      q = x[i] - z - p
      d2 = d2 + q * q
      c = c + y[i] * q
      g = g + (x[i] - z) * q * q
    }
    c = c / d2
    p = g / d2
    q = d2 / d1
    d1 = d2
    a[1] = c * t[1]
    a[0] = c * t[0] + a[0]
  }
  for (j = 2; j <= m - 1; j++) {
    s[j] = t[j - 1]
    s[j - 1] = -p * t[j - 1] + t[j - 2]
    if (j >= 3)
      for (k = j - 2; k >= 1; k--) {
        s[k] = -p * t[k] + t[k - 1] - q * b[k]
      }
    s[0] = -p * t[0] - q * b[0]
    d2 = 0.0
    c = 0.0
    g = 0.0
    for (i = 0; i <= n - 1; i++) {
      q = s[j]
      for (k = j - 1; k >= 0; k--) {
        q = q * (x[i] - z) + s[k]
      }
      d2 = d2 + q * q
      c = c + y[i] * q
      g = g + (x[i] - z) * q * q
    }
    c = c / d2
    p = g / d2
    q = d2 / d1
    d1 = d2
    a[j] = c * s[j]
    t[j] = s[j]
    for (k = j - 1; k >= 0; k--) {
      a[k] = c * s[k] + a[k]
      b[k] = t[k]
      t[k] = s[k]
    }
  }
  dt[0] = 0.0
  dt[1] = 0.0
  dt[2] = 0.0
  for (i = 0; i <= n - 1; i++) {
    q = a[m - 1]
    for (k = m - 2; k >= 0; k--) {
      q = a[k] + q * (x[i] - z)
    }
    p = q - y[i]
    if (Math.abs(p) > dt[2]) {
      dt[2] = Math.abs(p)
    }
    dt[0] = dt[0] + p * p
    dt[1] = dt[1] + Math.abs(p)
  }
  return a
}

function getY(x, xx, a, m) {
  var y = 0
  var ave = average(xx)

  var l = 0
  for (var i = 0; i < m; i++) {
    l = a[0]
    if (i > 0) {
      y += a[i] * Math.pow(x - ave, i)
    }
  }
  return y + l
}

function average(x) {
  var ave = 0
  var sum = 0
  if (x !== null) {
    for (var i = 0; i < x.length; i++) {
      sum += x[i]
    }
    ave = sum / x.length
  }
  return ave
}

export function SmallPointer(props) {
  return (
    <svg
      {...props}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8C6.20914 8 8 6.20914 8 4Z"
        fill="#2B4B8E"
      />
      <path
        d="M4 2L4.618 3.23442L6 3.43241L5 4.39321L5.23607 5.75L4 5.10942L2.76393 5.75L3 4.39321L2 3.43241L3.382 3.23442L4 2Z"
        fill="#86C2D8"
      />
    </svg>
  )
}

export function LargePointer(props) {
  const { x, y } = props
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 12, y: y - 12 }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.0204 12.1231C24.0204 5.5585 18.6987 0.236816 12.1341 0.236816C5.56948 0.236816 0.247803 5.5585 0.247803 12.1231C0.247803 18.6877 5.56948 24.0094 12.1341 24.0094C18.6987 24.0094 24.0204 18.6877 24.0204 12.1231Z"
          fill="#2B4B8E"
        />
        <path
          d="M12.135 6.18164L13.9715 9.8498L18.0782 10.4381L15.1066 13.2933L15.8081 17.3251L12.135 15.4215L8.46197 17.3251L9.16347 13.2933L6.19189 10.4381L10.2986 9.8498L12.135 6.18164Z"
          fill="#86C2D8"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="23.7726"
            height="23.7726"
            fill="white"
            transform="translate(0.248047 0.237793)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
