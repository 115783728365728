import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import { Button, Modal, Toast } from 'antd-mobile'
// import { BiErrorCircle } from 'react-icons/bi'
import styled from 'styled-components'
import {
  getSectionDetails,
  getSessionOrInit,
  sendPreScreeningEmail
} from '../../../api'
import { Question, StepFooter } from '../common'
import AnswerUpdater, {
  checkCompleted,
  verifyAnswer
} from '../common/answerUpdater'
import { QuestionsContainer } from '../common/style'
import { PreScreeningCompleted } from './completed'
import { logOutAction } from '../../../utils'

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  p {
    padding: 16px;
    margin-bottom: 0;
    background: white;
    font-family: Heebo;
    font-size: 12px;
    line-height: 140%;
    font-weight: 500;
    color: #52606c;
    border-bottom: 1px solid #e6e9f2;
    a {
      color: #5ea7f8;
      text-decoration-line: underline;
    }
  }
  footer {
    position: unset;
    box-shadow: none;
  }
`

const initialState = {
  section: null,
  answers: null,
  answerUpdater: null,
  saveCount: 0
}

export default function PreScreening(props) {
  const { person, setStepInfo, navigate, setHideStep, updateProfile, steps } =
    useOutletContext()
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(null)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater, answerInfo } = state
      setLoading(answerUpdater.isLoading())
      const _answerInfo = answerUpdater._answers.find(
        (item) => item.section_original_id === answerInfo.section_original_id
      )
      return _.assignIn(state, { answerInfo: _answerInfo })
    } else if (action.type === 'check answers') {
      const answerInfo = state.answerInfo
      answerInfo.answers = action.answers
      return _.assign({}, state, { answerInfo })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  // const { section, answers, answerUpdater } = state
  const { section, answerInfo, answerUpdater } = state

  const containerRef = useRef(null)

  useEffect(() => {
    const step = steps.find((item) => item.next === 'pre-screening')
    setStepInfo(step)

    async function initial() {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })

      const [section] = await getSectionDetails(person.id, 'pre_medical')
      const answers = await getSessionOrInit(person.id, 'pre_medical')

      dispatch({
        type: 'initial',
        payload: {
          section,
          answerInfo: answers[0] || {},
          answerUpdater: new AnswerUpdater({
            answers,
            sections: [section],
            saveCallback: () => {
              dispatch({ type: 'update answers' })
            }
          })
        }
      })
      loading.close()
    }

    initial()
  }, [person, setStepInfo])

  return (
    <>
      {completed ? (
        <PreScreeningCompleted anyYes={completed.anyYes} action={goNext} />
      ) : (
        section && (
          <QuestionsContainer ref={containerRef} paddingBottom={'15rem'}>
            <p style={{ margin: '0px' }}>
              Do you have any of the following conditions?
            </p>
            {/* {state.section ? 'has section' : 'no section'} */}
            {section.questions.map((q, index) => {
              return (
                <Question
                  key={index}
                  question={q}
                  person={person}
                  answers={(answerInfo && answerInfo.answers) || []}
                  updateAnswer={updateAnswer}
                  sessionId={answerInfo && answerInfo.id}
                />
              )
            })}
            <FooterContainer>
              <p>
                By clicking “Confirm” you acknowledge that this information will
                be shared with Apeiron Life for evaluation of your eligibility
                to become a member. For information on how we collect, use and
                share your information, please see our{' '}
                <a
                  target="_blank"
                  href="https://www.apeiron.life/privacy-policy"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
              <StepFooter
                showBack
                back={() => navigate(-1)}
                confirmOpr={
                  <Button
                    block
                    shape="rounded"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={onCompleted}
                  >
                    CONFIRM
                  </Button>
                }
              />
            </FooterContainer>
          </QuestionsContainer>
        )
      )}
    </>
  )

  async function updateAnswer(answer, question) {
    // if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)
    if (!_answer.verify) {
      setLoading(true)
      answerUpdater.autoSave(_answer, section.original_id)
    }
  }

  async function onCompleted() {
    const { isCompleted, answers } = await checkCompleted(
      [answerInfo],
      [section]
    )
    if (isCompleted) {
      const _answers = answers[0].answers
      const answerValues = _answers.map((item) => item.answer.value)
      const anyYes = answerValues.find((isYes) => isYes)
      if (anyYes) {
        Modal.confirm({
          title: 'Are you sure you want to proceed?',
          content:
            'By selecting "Confirm," you confirm that the information provided is accurate and final. Please double-check your responses before proceeding. ',
          onConfirm() {
            sendPreScreeningEmail(person.id)
            updateProfile(
              {
                pre_screening_completed: true,
                pre_screening_locked: true
              },
              (_person) => {
                logOutAction()
              }
            )
            setHideStep(true)
            setCompleted({ anyYes })
          },
          confirmText: 'Confirm',
          cancelText: 'Edit'
        })
      } else {
        setHideStep(true)
        setCompleted({ anyYes })
      }
    } else {
      dispatch({
        type: 'check answers',
        answers: answers[0].answers
      })
      setTimeout(() => {
        if (containerRef) {
          const invalidElem =
            containerRef.current.getElementsByClassName('invalid')[0]
          invalidElem &&
            invalidElem.parentNode.parentNode.parentNode.scrollIntoView({
              behavior: 'smooth'
            })
        }
      }, 500)
    }
  }

  async function goNext() {
    const loading = Toast.show({
      icon: 'loading',
      content: 'loading...'
    })
    if (completed.anyYes) {
      await updateProfile(
        {
          pre_screening_completed: true,
          pre_screening_locked: true
        },
        (_person) => {
          loading.close()
          logOutAction()
        }
      )
    } else {
      await updateProfile(
        {
          pre_screening_completed: true,
          pre_screening_locked: false
        },
        (_person) => {
          loading.close()
          navigate('/onboarding')
          setHideStep(false)
        }
      )
    }
  }
}
