import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CheckedCircleIcon } from '../../commonComponents/icons'

const Container = styled.div`
  background: #ffffff;
  padding: 1rem 1.5rem;
  overflow: hidden;
  .list-container {
    position: relative;
    list-style: none;
    padding: 0;
    display: flex;
    font-size: 0.75rem;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;
    > li {
      display: flex;
      justify-content: center;
      align-items: baseline;
      flex-direction: unset;
      align-items: center;
    }
    .active {
      .index-tag,
      .name {
        opacity: 1;
      }

      .index-tag {
        background: #264382;
      }
    }
    .index-tag {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      border-radius: 100%;
      background: #98a9bc;
      opacity: 0.6;
      color: #ffffff;
      margin-right: 0.5rem;
    }
    .customize-icon {
      color: #05b800;
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    .name {
      color: #38435e;
      opacity: 0.6;
      font-weight: 600;
      font-size: 0.786rem;
    }
    .separator {
      display: inline-block;
      margin: 0 0.75rem;
      background: #98a9bc;
      border-radius: 2rem;
      height: 2px;
      width: 1.56rem;
      vertical-align: middle;
    }
  }
  @media (min-width: 768px) and (min-height: 767px) {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
    margin-top: -2.25rem;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

/**
 *  progress: [{name, showIndex, isChecked?, reminder?}]
 *  activeIndex: number
 * */
export default function ProgressBar(props) {
  const { activeIndex, progress } = props
  const barRef = useRef(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  useEffect(() => {
    const index = activeIndex ? activeIndex - 1 : 0
    const target = barRef.current.querySelectorAll('li')[index]
    if (target) {
      setOffsetLeft(0 - target.offsetLeft)
    }
  }, [activeIndex])

  return (
    <Container>
      <ul
        className="list-container"
        ref={barRef}
        style={{ marginLeft: offsetLeft }}
      >
        {progress.map((item, index) => (
          <li key={index} className={index === activeIndex - 1 ? 'active' : ''}>
            {item.isChecked ? (
              <CheckedCircleIcon />
            ) : (
              <span className="index-tag">{item.showIndex}</span>
            )}
            <span className="name">
              {item.name}
            </span>
            {index !== progress.length - 1 && (
              <span className="separator"></span>
            )}
          </li>
        ))}
      </ul>
    </Container>
  )
}
