import { useState, useEffect, useRef } from 'react'
import { Dropdown, Space, SpinLoading, Checkbox, Toast } from 'antd-mobile'
import IconNote from '../../../asserts/icons/onboarding/note.svg'
import Container, { GlobalPopup } from './style'
import ControlBottom from '../../commonComponents/BottomControl'
import { useNavigate } from 'react-router-dom'
import { editClient, getMe, getMembershipFees } from '../../../api'
import Header from '../../commonComponents/header'
import { loadUser, logOutAction, saveUser, setTokenItem } from '../../../utils'
import MembershipSuccess from './success'
import { HealthAssessmentCard } from './healthAssessCard'
import PlanCard from './planCard'
import { MembershipPlans } from './plans'
import { AiFillCheckSquare, AiOutlineBorder } from 'react-icons/ai'
import { upperFirst, isNil } from 'lodash'

const amountFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

const Membership = () => {
  const navigate = useNavigate()

  const [showSuccess, setShowSuccess] = useState(false)
  const [checked, setChecked] = useState(true)
  const [membership, setMembership] = useState(null)
  const [prices, setPrices] = useState([
    {
      membership: 'evaluation2',
      price: 10000
    },
    {
      membership: 'remote lite',
      price: 12500
    },
    {
      membership: 'remote',
      price: 20000
    },
    {
      membership: 'bronze',
      price: 25000
    },
    {
      membership: 'silver',
      price: 35000
    },
    {
      membership: 'gold',
      price: 50000
    }
  ])
  const [billing, setBilling] = useState('quarterly')
  const [user, setUser] = useState(() => {
    if (navigator.userAgent.includes('life.apeiron.ios')) {
      return null
    } else {
      const user = loadUser()
      setMembership(user.profile.membership || 'module')
      getMembershipFees(user.id).then((e) => {
        setPrices(e)
      })
      return user
    }
  })

  const ref = useRef()

  const refreshPerson = async (token) => {
    setTokenItem(token)
    const { person } = await getMe()
    setUser(person)
    setMembership(person.profile.membership || 'module')
    getMembershipFees(person.id).then((e) => {
      setPrices(e)
    })
    saveUser(person)
  }

  useEffect(() => {
    window.handleMessageFromNative = (token) => {
      refreshPerson(token)
    }
    return () => {
      delete window.handleMessageFromNative
    }
  }, [])

  useEffect(() => {
    if (navigator.userAgent.includes('life.apeiron.ios')) {
      window.webkit.messageHandlers.loadUser.postMessage({})
    }
  }, [])

  const onClickConfirm = async () => {
    const params = {
      profile: {
        membership,
        billing_preference: billing
      }
    }

    const result = await editClient(
      params,
      user.id,
      '?client_select_membership=true'
    )
    saveUser(result.team.person)
    setShowSuccess(true)
  }

  const getPrice = (membership) => {
    const price = prices.find((i) => i.membership === membership)
    return amountFormat.format(price.discountedPrice || price.price)
  }

  const confirmable =
    ['evaluation2', 'gold', 'silver', 'bronze', 'remote', 'remote lite'].find(
      (item) => item === membership
    ) && checked

  return (
    <Container>
      <GlobalPopup />
      <Header
        showLogout={true}
        onClickLogout={() => {
          if (navigator.userAgent.includes('life.apeiron.ios')) {
            window.webkit.messageHandlers.signOut.postMessage({})
          } else {
            logOutAction()
          }
        }}
      />
      {showSuccess ? (
        <MembershipSuccess />
      ) : user ? (
        <>
          <div className="content">
            <div className="title">Membership Packages</div>
            <div className="desc">
              Please select which program you would like to join. We highly
              recommend the Gold Membership in order to maximize the benefits of
              your membership, but if you would prefer to start with the
              assessment and add the Silver or Gold membership as you learn more
              about the program, you can upgrade at any time. If you have any
              questions, please reach out to{' '}
              <a href="mailto:membership@apeiron.life">
                membership@apeiron.life
              </a>
            </div>
            <div className="billing">
              <div className="title">
                BILLING PREFERENCE{' '}
                <img
                  onClick={() => {
                    Toast.show({
                      content:
                        'You will be invoiced for the amount of the selected program.'
                    })
                  }}
                  src={IconNote}
                  alt=""
                />{' '}
              </div>
              <div className="mask"></div>
              <Dropdown ref={ref}>
                <Dropdown.Item key="billing" title={billing}>
                  {['quarterly', 'annually'].map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setBilling(item)
                          ref.current.close()
                        }}
                      >
                        {upperFirst(item)}
                      </div>
                    )
                  })}
                </Dropdown.Item>
              </Dropdown>
            </div>
            <HealthAssessmentCard
              selected={membership === 'evaluation2'}
              onClick={() => setMembership('evaluation2')}
              price={getPrice('evaluation2')}
            />
            <div className="tips">
              *All annual packages include Healthspan Assessment
            </div>
            <Space direction="vertical" style={{ '--gap': '8px' }}>
              {MembershipPlans.map((plan) => {
                return (
                  <PlanCard
                    key={plan.membership}
                    selected={plan.membership === membership}
                    plan={plan}
                    price={getPrice(plan.membership)}
                    onClick={() => setMembership(plan.membership)}
                  />
                )
              })}
            </Space>
          </div>
          <ControlBottom
            onClickConfirm={onClickConfirm}
            showBack={false}
            onClickBack={() => {
              navigate(-1)
            }}
            confirmBtn={'CONFIRM'}
            confirmDisabled={!confirmable}
          >
            <div className="license">
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked)
                }}
                icon={(checked) =>
                  checked ? (
                    <AiFillCheckSquare
                      style={{ fontSize: 22, color: '#1fa734' }}
                    />
                  ) : (
                    <AiOutlineBorder
                      style={{ fontSize: 22, color: '#52606c' }}
                    />
                  )
                }
              />
              <div>
                By purchasing an Apeiron Life Membership, you agree to the
                Apeiron Life{' '}
                <a
                  target="_blank"
                  href="https://www.apeiron.life/membership-agreement"
                  rel="noreferrer"
                >
                  Membership agreement
                </a>{' '}
                ,{' '}
                <a
                  target="_blank"
                  href="https://www.apeiron.life/terms-of-use"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  href="https://www.apeiron.life/privacy-policy"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </ControlBottom>
        </>
      ) : (
        <SpinLoading
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
    </Container>
  )
}

export default Membership
