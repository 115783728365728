class Storage {
  constructor(props) {
    this.props = props || {}
    this.source = this.props.source || window.localStorage
    this.initRun()
  }

  initRun() {
    const reg = new RegExp('__expires__')
    let data = this.source
    let list = Object.keys(data)
    if (list.length > 0) {
      list.map((key) => {
        if (!reg.test(key)) {
          let now = Date.now()
          let expires = data[`${key}__expires__`] || Date.now + 1
          if (now >= expires) {
            this.remove(key)
          }
        }
        return key
      })
    }
  }

  // deault expired 1d = 60*24 min
  set(key, value, expired = 60 * 24) {
    let source = this.source
    // source[key] = JSON.stringify(value)
    source.setItem(key, value)
    if (expired) {
      // source[`${key}__expires__`] = Date.now() + 1000 * 60 * expired
      source.setItem(`${key}__expires__`, Date.now() + 1000 * 60 * expired)
    }
    return value
  }

  get(key) {
    const source = this.source,
      expired = source.getItem(`${key}__expires__`) || Date.now + 1
    const now = Date.now()

    if (now >= expired) {
      this.remove(key)
      return
    }
    const value = source[key] // ? JSON.parse(source[key]) : source[key];
    return value
  }

  remove(key) {
    const source = this.source
    source.removeItem(key)
    source.removeItem(`${key}__expires__`)
  }
}

const storage = new Storage()
// const session = new Storage({ source: window.sessionStorage })

const tokenItem = 'phs-token'

export function setTokenItem(token) {
  // const role = getRole()
  const expired = 60 * 24 * 15 // 2 weeks
  return storage.set(tokenItem, token, expired)
  // const keep = getKeepLogged()
  // if (keep) {
  //   return storage.set(tokenItem, token, null)
  // } else {
  //   return storage.set(tokenItem, token)
  // }
}

export function getToken() {
  return storage.get(tokenItem)
}

export function removeTokenItem() {
  return storage.remove(tokenItem)
}

const userItem = 'phs-user'
const userItemSaveTime = 'phs-user-save-time'

export function saveUser(user) {
  storage.set(userItemSaveTime, new Date().getTime())
  return storage.set(userItem, JSON.stringify(user), null)
}

export const loadUser = () => {
  const result = storage.get(userItem)
  // const isExpire = storage.get(userItemSaveTime)
  const user = result && result !== 'undefined' && JSON.parse(result)
  // if (!isExpire && user) {
  //   refreshProfile(user.id)
  // }
  const { origin, pathname } = window.location
  if (
    !result &&
    pathname !== '/'
    // &&
    // !publicPath.find((path) => pathname.includes(path))
  ) {
    window.location.href = `${origin}`
  }
  return user
}

export function removeUserItem() {
  return storage.remove(userItem)
}
