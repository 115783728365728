import React from 'react'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryGroup,
  VictoryScatter
} from 'victory'
import moment from 'moment'
import HideWrap from '../components/HideWrap'
import HealthCard from '../components/HealthCard'
import Information from '../components/Information'
import { Pluralize } from '../../../utils'
import { ChartContainer, SectionStyle } from '../index.styled'
import { RangeBar } from '../components/RangeBar'
import {
  LargePointer,
  SmallPointer,
  axisStyles
} from '../components/Logarithmic'
import { FlexBox } from '../../commonComponents/FlexBox'
import { Divider } from 'antd-mobile'
import { useInView } from 'react-intersection-observer'
import _, { get } from 'lodash'
import BodyChart, { BodyChatName } from '../components/BodyChart'
import Polynomial from '../components/Polynomial'
import { ReactComponent as CycleIcon } from '../../../asserts/icons/arDeck/icon-test-type-cycle.svg'
import { ReactComponent as TreadmillIcon } from '../../../asserts/icons/arDeck/icon-test-type-treadmill.svg'
import { ReactComponent as OtherIcon } from '../../../asserts/icons/arDeck/icon-test-type-other.svg'
import {
  AerobicTestTypeCycleIcon,
  AerobicTestTypeOtherIcon,
  AerobicTestTypeTreadmillIcon
} from '../components/Widgets'

const Container = styled.section`
  ${SectionStyle}

  .chart {
    margin-top: 40px;
  }

  .test-type-view {
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin-right: 8px;
    }
  }
`

const NOTES_FIELDS = {
  AEROBIC_FITNESS: 'fitness',
  AEROBIC_FITNESS_TEST_TYPE: 'aerobic_fitness_test_type',
  AEROBIC_FITNESS_SUBMAX: 'aerobic_fitness_submax'
}

export function AerobicFitnessSection(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status },
    data: {
      vo2pk,
      submaxTest,
      heartRate,
      lactate,
      finishTime,
      vo2Peak,
      testType
    },
    visible
  } = props
  const data = dataList.find((item) => item.name === 'Aerobic fitness') || {}
  const compare =
    compareData.find((item) => item.name === 'Aerobic fitness') || {}

  const fitness = notes.find(
    (item) => item.pillar === NOTES_FIELDS.AEROBIC_FITNESS
  )

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })

  return (
    <HideWrap
      name="Aerobic Fitness"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
      ref={ref}
    >
      <Container>
        <h1 className="ar-section-title">Aerobic Fitness</h1>
        <HideWrap
          name="Aerobic Fitness - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <h3 className="ar-section-card-title">Summary</h3>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          {fitness && fitness.memo && (
            <Information
              title="COMMENT/NOTE"
              disMarkDone={true}
              initialValue={fitness.memo}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              updateEditingCount={updateEditingCount}
            />
          )}
        </HideWrap>

        {(vo2pk || heartRate || lactate) && (
          <>
            <Divider />
            <HideWrap
              name="Aerobic Fitness - chart group"
              // title="Chart group"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <div className="sub-title" style={{ height: 30 }}></div>

              <ChartGroup
                {...{
                  person,
                  ageScopes,
                  lactate,
                  notes,
                  updateEditingCount,
                  view,
                  vo2pk,
                  heartRate,
                  finishTime,
                  vo2Peak,
                  submaxTest,
                  testType
                }}
              />
            </HideWrap>
          </>
        )}
      </Container>
    </HideWrap>
  )
}

function ChartGroup(props) {
  const {
    person,
    ageScopes,
    lactate,
    notes,
    updateEditingCount,
    view,
    vo2pk,
    heartRate,
    finishTime,
    vo2Peak,
    submaxTest,
    testType
  } = props

  const groupNote = notes.find((item) => item.pillar === 'aerobic fitness')

  let summary = []
  let showVO2PkTrends = false

  if (vo2Peak) {
    vo2pk.datasets = vo2pk.datasets || []
    summary.push({
      label: 'VO2 Peak',
      value: vo2Peak.value,
      unit: vo2Peak.unit
    })
    summary.push({
      type: 'divider'
    })
    if (vo2pk.datasets[0] && vo2pk.datasets[0].test_date) {
      showVO2PkTrends = true
    }
  }
  if (finishTime) {
    summary.push({
      label: 'Total Exercise Time',
      value: finishTime.value,
      unit: Pluralize('min', finishTime.value || 1)
    })
    summary.push({
      type: 'divider'
    })
  }
  if (lactate) {
    summary.push({
      label: 'HR Max',
      value: lactate.heartRate.max_heart_rate,
      unit: 'bpm'
    })
  }

  const infoContainerStyle = {
    paddingTop: 85,
    paddingLeft: showVO2PkTrends ? 50 : 0,
    width: showVO2PkTrends ? 500 : '100%'
  }
  if (!showVO2PkTrends) {
    infoContainerStyle.marginRight = 0
  }

  return (
    <>
      <ChartContainer>
        <div>
          <div className="chart-group-summary">
            {summary.map((item, index) =>
              item.type === 'divider' ? (
                <Divider key={index} direction="vertical" />
              ) : (
                <div key={index} className="summary-item">
                  <div className="label">{item.label}</div>
                  <div className="value">
                    {item.value || '--'} {item.unit}
                  </div>
                </div>
              )
            )}
          </div>
          {vo2Peak && (
            <>
              <Divider />
              <div
                className="peak-rank score-list"
                style={{
                  overflow: 'hidden'
                }}
              >
                <div className="score-item">
                  <FlexBox alignItems="center" style={{ fontWeight: 600 }}>
                    <FlexBox alignItems="center">
                      Test Type:
                      {renderTestType(testType.value)}
                    </FlexBox>
                    <FlexBox
                      alignItems="center"
                      style={{
                        marginLeft: 65
                      }}
                    >
                      Submax: {submaxTest.level || '-'}
                    </FlexBox>
                  </FlexBox>
                  <RangeBar
                    {...vo2Peak}
                    {...{ person, ageScopes }}
                    showRangeArea
                    showRangeLevel
                    colorfulText
                    showUnit={false}
                  />
                </div>
              </div>
            </>
          )}
          {lactate && (
            <div className="zones-container">
              <FlexBox
                justifyContent="space-between"
                className="max-heart-rate"
              >
                <span>HR Max</span>
                <span className="align-end">
                  {get(lactate, 'heartRate.max_heart_rate') || '-'} bpm
                </span>
              </FlexBox>
              {[
                {
                  color: '#FFD466',
                  key: '_heartRateZone1'
                },
                {
                  color: '#FFB570',
                  key: '_heartRateZone2'
                },
                {
                  color: '#FF8933',
                  key: '_heartRateZone3'
                },
                {
                  color: '#FF6847',
                  key: '_heartRateZone4'
                },
                {
                  color: '#FF3D00',
                  key: '_heartRateZone5'
                }
              ]
                .map((zone, index) => (
                  <div key={index}>
                    <div>
                      <span
                        className="zone"
                        style={{ background: zone.color }}
                      />
                      Zone {index + 1}
                    </div>
                    <div>
                      <span>
                        {lactate.heartRate.zones[zone.key].percent * 100}%
                      </span>
                      <span className="align-end">
                        {index === 4
                          ? `${
                              lactate.heartRate.zones[zone.key]
                                .lowest_heart_rate
                            }+`
                          : `${
                              lactate.heartRate.zones[zone.key]
                                .lowest_heart_rate
                            } - ${
                              lactate.heartRate.zones[zone.key]
                                .highest_heart_rate
                            }`}
                      </span>
                    </div>
                  </div>
                ))
                .reverse()}
            </div>
          )}
        </div>
        <div className="chart">
          {vo2pk && person && (
            <VO2Chart data={vo2pk} person={person} testType={testType} />
          )}
        </div>

        <div className="chart">
          {heartRate && <HeartRateChart data={heartRate} />}
        </div>
        <div className="chart">{lactate && <Polynomial {...lactate} />}</div>
        {showVO2PkTrends && (
          <div style={{ paddingRight: 10, marginTop: 40 }}>
            <div className="sub-title" style={{ marginBottom: -25 }}>
              VO2 Peak - Treadmill Regressioin
            </div>
            <BodyChart
              series={_.cloneDeep(vo2pk.datasets).reverse()}
              name={BodyChatName.VO2PK}
            />
          </div>
        )}
      </ChartContainer>
      {groupNote && groupNote.memo && (
        <Information
          title="COMMENT/NOTE"
          disMarkDone={true}
          initialValue={groupNote.memo}
          status={view === 'Draft' ? 'Draft' : 'Opened'}
          updateEditingCount={updateEditingCount}
        />
      )}
    </>
  )

  function renderTestType(value) {
    switch (value) {
      case 'Cycle':
        return (
          <span className="test-type-view">
            <CycleIcon /> Cycle
          </span>
        )
      case 'Other':
        return (
          <span className="test-type-view">
            <OtherIcon /> Other
          </span>
        )
      case 'Treadmill':
        return (
          <span className="test-type-view">
            <TreadmillIcon /> Treadmill
          </span>
        )
      default:
        return '-'
    }
  }
}

function getTrendLinePoint(x, slope, intercept) {
  return { x: x, y: slope * x + intercept }
}

function linearRegression(y, x) {
  let lr = {}
  let n = y.length
  let sum_x = 0
  let sum_y = 0
  let sum_xy = 0
  let sum_xx = 0
  let sum_yy = 0

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i]
    sum_y += y[i]
    sum_xy += x[i] * y[i]
    sum_xx += x[i] * x[i]
    sum_yy += y[i] * y[i]
  }

  lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
  lr['intercept'] = (sum_y - lr.slope * sum_x) / n
  lr['r2'] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  )

  return lr
}

function HeartRateChart(props) {
  const axisX = [10, 20, 30, 40, 50, 60],
    axisY = [80, 100, 120, 140, 160, 180, 200, 220]

  function getPointers(series = { hrSeries: [], vo2Series: [] }) {
    const { hrSeries, vo2Series } = series
    const data = []
    if (vo2Series) {
      for (let i = 0; i < vo2Series.length; i++) {
        if (vo2Series[i] && hrSeries[i]) {
          data.push({
            x: vo2Series[i],
            y: hrSeries[i]
          })
        }
      }
    }
    return data
  }

  let dataArr = []
  for (const { testDate, series } of props.data) {
    const data = {
      testDate,
      color: '#BCC2CE',
      size: 3,
      data: getPointers(series)
    }

    if (data.data.length > 0) {
      const { slope, intercept, r2 } = linearRegression(
        data.data.map((item) => item.y),
        data.data.map((item) => item.x)
      )
      data.r2 = r2
      data.lineData = [
        getTrendLinePoint(10, slope, intercept),
        getTrendLinePoint(60, slope, intercept)
      ]
      data.getY = (x) => slope * x + intercept
    }
    dataArr.push(data)
  }
  dataArr = dataArr.reverse()

  if (dataArr[0]) {
    const l = dataArr.length
    dataArr[l - 1].color = '#2B4B8E'
    dataArr[l - 1].size = 5
    if (dataArr[l - 2]) {
      dataArr[l - 2].color = '#758CBB'
    }
  }

  return (
    <>
      <div className="tip-label right-align">
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-circle" style={{ background: item.color }} />
            {moment(item.testDate).format('M/D/YYYY')}
          </div>
        ))}
      </div>
      <div className="tip-label right-align">
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-line" style={{ background: item.color }} />
            Timelime for {moment(item.testDate).format('M/D/YYYY')}
            <br />
            <span className="space" />
            {item.r2 && <>r2 = {Math.round(item.r2 * 1000) / 1000}</>}
          </div>
        ))}
      </div>

      <VictoryChart
        width={450}
        height={400}
        padding={{
          top: 20,
          left: 50,
          bottom: 50,
          right: 10
        }}
        maxDomain={{ x: 60, y: 220 }}
      >
        <VictoryAxis
          tickValues={axisX}
          style={axisStyles}
          label="OXYGEN UPTAKE (ML/KG/MIN)"
        />
        <VictoryAxis
          dependentAxis
          tickValues={axisY}
          style={axisStyles}
          label="HEART RATE (BPM)"
        />

        {dataArr
          .filter((item) => item.data.length > 0)
          .map((item, index) => (
            <VictoryGroup key={index}>
              {item.size === 5 && (
                <VictoryScatter
                  data={item.data}
                  style={{ data: { fill: item.color } }}
                  size={item.size}
                />
              )}
              <VictoryLine
                data={item.lineData}
                style={{ data: { stroke: item.color, strokeWidth: 1.5 } }}
              />
            </VictoryGroup>
          ))}
      </VictoryChart>
    </>
  )
}

function VO2Chart(props) {
  const {
    series: { xAxis, yAxis },
    datasets
  } = props.data
  let age = 30
  if (props.person) {
    age = moment().diff(props.person.profile.date_of_birth, 'years')
  }

  const n = 14,
    step = 5

  let x = age - 5,
    y = 0
  const axisX = [],
    axisY = []
  for (let i = 0; i <= n; i++) {
    axisY.push(y)
    y += step
  }

  for (let i = 0; i <= 10; i++) {
    axisX.push(x)
    x += 1
  }

  function handleData(key) {
    const { series } = yAxis[key]
    const data = []
    for (const index of Object.keys(xAxis.age)) {
      if (xAxis.age[index] >= axisX[0] && xAxis.age[index] <= axisX[10]) {
        data.push({ x: xAxis.age[index], y: series[index] })
      }
    }
    return data
  }

  function handleDaraLinear(key) {
    const { color, series } = yAxis[key]
    const { slope, intercept } = linearRegression(series, xAxis.age)
    const data = [
      getTrendLinePoint(axisX[0], slope, intercept),
      getTrendLinePoint(axisX[10], slope, intercept)
    ]
    const dashLineData = [
      getTrendLinePoint(axisX[7], slope, intercept),
      getTrendLinePoint(axisX[10], slope, intercept)
    ]
    return {
      key,
      color,
      scatterData: handleData(key),
      data,
      dashLineData
    }
  }

  let lineDatas = []
  for (const key of Object.keys(yAxis)) {
    lineDatas.push(handleDaraLinear(key))
  }

  const testData =
    datasets &&
    datasets
      .filter((item) => item.age >= axisX[0] && item.age <= axisX[10])
      .map((item) => ({ x: item.age, y: item.value }))

  const max = (testData && Math.max(...testData.map((item) => item.y))) || 0
  while (max > y - 5) {
    axisY.push(y)
    y += step
  }
  return (
    <>
      <div className="tip-label">
        {Object.keys(yAxis).map((key, index) => (
          <div key={index}>
            <span
              className="tip-circle"
              style={{ background: yAxis[key].color }}
            />
            {key}
          </div>
        ))}
      </div>
      <div className="tip-label">
        <div>
          <span>
            <svg
              width="12"
              height="2"
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="1"
                x2="12"
                y2="1"
                stroke="#989CA2"
                strokeWidth="2"
                strokeDasharray="2 2"
              />
            </svg>
          </span>
          Predicted period
        </div>
        <div>
          <span>
            <SmallPointer />
          </span>
          VO2pk
        </div>
      </div>
      <VictoryChart
        width={450}
        height={400}
        padding={{
          top: 20,
          left: 50,
          bottom: 50,
          right: 10
        }}
      >
        <VictoryAxis
          tickValues={axisX}
          style={axisStyles}
          tickFormat={(t) =>
            [age - 5, age, age + 5].find((item) => item === t) ? t : null
          }
          label="AGE (YEARS)"
        />
        <VictoryAxis
          dependentAxis
          tickValues={axisY}
          style={axisStyles}
          label="OXYGEN UPTAKE (ML/KG/MIN)"
        />
        {lineDatas.map((item, index) => (
          <VictoryGroup key={index}>
            <VictoryLine
              data={item.data}
              style={{
                data: {
                  stroke: item.color,
                  strokeWidth: item.key === 'Average' ? 6 : 1.5
                }
              }}
            />
            {/* <VictoryLine
              data={item.dashLineData}
              key={index}
              style={{
                data: {
                  stroke: item.color,
                  strokeWidth: item.key === 'Average' ? 6 : 1.5,
                  strokeDasharray: '3,3'
                }
              }}
            /> */}
            {item.key === 'Average' && (
              <VictoryScatter
                data={item.scatterData}
                key={index}
                style={{ data: { fill: '#2B4B8E' } }}
              />
            )}
          </VictoryGroup>
        ))}
        {testData && testData.length > 0 && (
          <VictoryScatter
            data={testData}
            dataComponent={renderTestType(props.testType)}
          />
        )}
      </VictoryChart>
    </>
  )

  function renderTestType(type) {
    if (type) {
      switch (type.value) {
        case 'Other':
          return <AerobicTestTypeOtherIcon />
        case 'Treadmill':
          return <AerobicTestTypeTreadmillIcon />
        case 'Cycle':
          return <AerobicTestTypeCycleIcon />

        default:
          return <LargePointer />
      }
    } else {
      return <LargePointer />
    }
  }
}
