import React, { useState } from 'react'
import { Container } from './style'
import { Form, Input, Button } from 'antd-mobile'
import { pattern } from '../../utils'
import { forgotPassword } from '../../api'
import iconEmail from '../../asserts/icons/email.svg'
import sendEmail from '../../asserts/images/sendEmail.png'

export default function VerifyEmail(props) {
  const [step, setStep] = useState(0)
  const [params, setParams] = useState({})

  const stepContent = [
    {
      title: 'Enter your email',
      description: 'Check your email to verify the account',
      element: <EnterEmail {...{ params, setParams, setStep }} />
    },
    {
      title: 'We’ve sent a link to your email!',
      element: <EmailSent {...{ params, setParams, setStep }} />
    }
  ]

  return (
    <Container>
      <div>
        <div className="title">{stepContent[step].title}</div>
        <p>{stepContent[step].description}</p>
        <div className="form-container">{stepContent[step].element}</div>
      </div>
    </Container>
  )
}

function EnterEmail(props) {
  const { setStep, params, setParams } = props
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const onSend = async () => {
    await form.validateFields()
    const values = form.getFieldsValue()
    try {
      setLoading(true)
      params.email = values.email
      await forgotPassword(params)
      setLoading(false)
      setParams(params)
      setStep(1)
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <Form
      form={form}
      mode="card"
      layout="horizontal"
      requiredMarkStyle="none"
      initialValues={params}
      footer={
        <Button
          block
          type="button"
          color="primary"
          size="large"
          loading={loading}
          onClick={onSend}
        >
          Verify your email
        </Button>
      }
    >
      <Form.Item
        name="email"
        label={<img src={iconEmail} alt="icon" />}
        rules={[
          { required: true, message: 'This field is required' },
          {
            pattern: pattern.email,
            message: 'Please input a valid email'
          }
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
    </Form>
  )
}

function EmailSent(props) {
  const { setStep } = props
  return (
    <>
      <div className="center-image">
        <img src={sendEmail} alt="send" />
      </div>

      <p className="description short">
        You have received a link in your email, please check your email and
        access to the link to reset your password
      </p>
      <div className="resend" onClick={() => setStep(0)}>
        Not received? Resend it
      </div>
    </>
  )
}
