import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from './style'
import { Form, Input, Button, Checkbox, Toast } from 'antd-mobile'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { pattern } from '../../utils'
import iconEmail from '../../asserts/icons/email.svg'
import iconPsw from '../../asserts/icons/psw.svg'
import signInLogo from '../../asserts/icons/sign-in-logo.svg'
import { login } from '../../api'

export default function Login(props) {
  const [visible, setVisible] = useState(false)
  const [autoLoginChecked, setAutoLoginChecked] = useState(true)
  const [tosChecked, setTosChecked] = useState(true)
  const [form] = Form.useForm()

  const navigate = useNavigate()
  return (
    <Container hasBackground>
      <div style={{ marginTop: '7em' }}>
        <img src={signInLogo} alt="logo" />
        <div className="title">Welcome to Apeiron Life</div>
        <div className="form-container">
          <Form
            form={form}
            mode="card"
            layout="horizontal"
            requiredMarkStyle="none"
            footer={
              <>
                <div className="before-submit">
                  <Checkbox
                    checked={autoLoginChecked}
                    onChange={() => setAutoLoginChecked(!autoLoginChecked)}
                  />
                  Keep me logged in.
                </div>
                <div className="before-submit">
                  <Checkbox
                    checked={tosChecked}
                    onChange={() => setTosChecked(!tosChecked)}
                  />
                  I have read and agreed to the{' '}
                  <Link to="http://apeiron.life/terms-of-use">
                    Terms of Use
                  </Link>
                </div>
                <Button
                  block
                  type="submit"
                  color="primary"
                  size="large"
                  onClick={onSubmit}
                >
                  Connect
                </Button>
              </>
            }
          >
            <Form.Item
              name="email"
              label={<img src={iconEmail} alt="icon" />}
              rules={[
                { required: true, message: 'This field is required' },
                {
                  pattern: pattern.email,
                  message: 'Please input a valid email'
                }
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Header/>
            <Form.Item
              name="password"
              label={<img src={iconPsw} alt="icon" />}
              extra={
                <div>
                  {!visible ? (
                    <AiOutlineEyeInvisible onClick={() => setVisible(true)} />
                  ) : (
                    <AiOutlineEye onClick={() => setVisible(false)} />
                  )}
                </div>
              }
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input
                placeholder="Password"
                clearable
                type={visible ? 'text' : 'password'}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="login-footer">
          <p>
            Forgot password? <Link to="/verify-email">Recover here</Link>
          </p>
          <p>
            Never signed into your account before? Email{' '}
            <Link to="mailto:concierge@apeiron.life?subject=Apeiron waitlist request">
              concierge@apeiron.life
            </Link>{' '}
            and we we'll set you up
          </p>
        </div>
      </div>
    </Container>
  )

  async function onSubmit() {
    // if (!tosChecked) {
    //   Toast.show({
    //     content: 'Please accept the Terms of Use to continue',
    //     duration: 0,
    //     position: 'top'
    //   })
    //   return
    // }
    const loading = Toast.show({
      icon: 'loading',
      content: 'loading...'
    })
    try {
      await form.validateFields()
      const params = form.getFieldsValue()
      const person = await login(params)
      const {
        profile: {
          password_reset_required,
          enabled_2fa,
          onboarding_steps: { onboarding_required }
        }
      } = person
      loading.close()
      if (password_reset_required) {
        navigate('/change-psw')
      } else if (enabled_2fa) {
        navigate('/2fa')
      } else if (onboarding_required) {
        navigate('/onboarding')
      } else {
        navigate('/')
      }
    } catch (err) {}
  }
}

Login.routeProps = {}
