import React, { useState, useEffect } from 'react'
import { Picker, Button } from 'antd-mobile'
import styled from 'styled-components'
import { AiOutlineClockCircle } from 'react-icons/ai'
import _ from 'lodash'

const TimeContainer = styled.div`
  button {
    width: 100%;
    height: 58px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.3px;
    color: #5e687d;
    &:active {
      --border-radius: 8px;
    }
    .content {
      display: flex;
      justify-content: space-between;
    }
  }
`

const timeColumns = [
  [
    { label: '12', value: '12' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' }
  ],
  [
    { label: '00', value: '00' },
    { label: '15', value: '15' },
    { label: '30', value: '30' },
    { label: '45', value: '45' }
  ],
  [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' }
  ]
]

export default function Time(props) {
  const { answer, question, update } = props
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState([])
  useEffect(() => {
    if (answer && _.isEmpty(value)) {
      // answer.answer.value= '00:00 AM'
      const _value = answer.answer.value
      if (_value) {
        setValue([
          _value.substring(0, 2),
          _value.substring(3, 5),
          _value.substring(6)
        ])
      }
    }
  }, [answer, value])
  return (
    <TimeContainer>
      <Button
        onClick={() => {
          setVisible(true)
        }}
      >
        <div className="content">
          <span>
            {value.length > 0
              ? `${value[0]}:${value[1]} ${value[2]}`
              : 'Select time'}
          </span>
          <AiOutlineClockCircle />
        </div>
      </Button>
      <Picker
        className={`q-form-item ${answer && answer.required ? 'invalid' : ''}`}
        columns={timeColumns}
        // onConfirm={(_, timeString) => this.update(null, timeString)}
        value={value}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        onConfirm={(val) => {
          const newAnswer = _.assignIn(answer, {
            answer: { value: `${val[0]}:${val[1]} ${val[2]}` }
          })
          setValue(val)
          update(newAnswer, question)
        }}
      />
      {answer.verify && answer.verify.required && (
        <p className="invalid">Please select time</p>
      )}
    </TimeContainer>
  )
}
