import { get, concat } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory'
import { HSDScoreConfig } from './HSDSpiderGraph'

const GraphContainer = styled.div`
  margin-top: -40px;
`

const OFFSET_SORCE_COMPARE_Y = [20, 30, 30]
const SCORE_X = [0, 10, -10]
const LABEL_ANCHOR = ['middle', 'start', 'end']

export const HSDTriangleChart = (props) => {
  const triangles = [
    { y: 100, points: calculateTrianglePoints(100) },
    { y: 55.7, points: calculateTrianglePoints(55.7) },
    { y: 15, points: calculateTrianglePoints(15) }
  ]

  function calculateTrianglePoints(yValue) {
    const size = yValue
    return [
      { x: 0, y: size },
      { x: 1, y: size },
      { x: 2, y: size }
    ]
  }

  const domains = [
    {
      key: 'body_composition',
      name: ['BODY COMPOSITION'],
      lineHeight: [1]
    },
    {
      key: 'aerobic_fitness',
      name: ['AEROBIC', 'FITNESS'],
      lineHeight: [1.1, 1.1]
    },
    {
      key: 'muscular_fitness',
      name: ['MUSCLE', 'FITNESS'],
      lineHeight: [1.1, 1.1]
    }
  ]
  const data = domains.map((domain, index) => {
    const score =
      get(props, `${domain.key}.score_manual.value`) ||
      get(props, `${domain.key}.score_auto`)
    const config = score && HSDScoreConfig(score)
    return {
      x: index,
      y: score || 0,
      score: score,
      color: config ? config.color : 'rgba(77, 77, 77, 1)',
      text: concat(domain.name, config ? [`${score}`, config.text] : ['--']),
      lineHeight: concat(domain.lineHeight, config ? [1.5, 1] : [1.2])
    }
  })

  const textStyle = {
    fontSize: 15,
    fontFamily: 'Gilroy',
    fontWeight: 600
  }

  const commonTextStyle = {
    fill: (t) => {
      return data[t.index].color
    },
    ...textStyle
  }

  return (
    <GraphContainer>
      <VictoryChart
        polar
        domain={{ y: [0, 100] }}
        startAngle={270}
        endAngle={630}
        padding={{ top: 60, bottom: 60, left: 0, right: 0 }}
        containerComponent={
          <VictoryVoronoiContainer
            style={{
              touchAction: 'auto',
              height: '100%'
            }}
          />
        }
      >
        <VictoryArea
          style={{ data: { fill: 'rgba(205, 208, 214, 0.2)' } }}
          data={[100, 100, 100]}
        />
        <VictoryPolarAxis
          name="graph-score"
          labelPlacement="vertical"
          tickValues={[0, 1, 2]}
          tickLabelComponent={
            <VictoryLabel
              dx={(t) => SCORE_X[t.index]}
              dy={(t) => OFFSET_SORCE_COMPARE_Y[t.index || 0]}
              text={({ index }) => data[index].text}
              textAnchor={(t) => LABEL_ANCHOR[t.index]}
              lineHeight={({ index }) => data[index].lineHeight}
              style={[
                {
                  fill: (t) => {
                    return 'rgba(76, 96, 114, 0.6)'
                  },
                  ...textStyle
                },
                {
                  fill: (t) => {
                    return t.text.length > 3
                      ? 'rgba(76, 96, 114, 0.6)'
                      : data[t.index].color
                  },
                  ...textStyle
                },
                { ...commonTextStyle },
                { ...commonTextStyle }
              ]}
            />
          }
          style={{
            axis: { stroke: 'none' },
            grid: {
              stroke: 'rgba(119, 128, 139, 1)',
              strokeWidth: 1,
              strokeDasharray: '2,2'
            }
          }}
        />
        <VictoryPolarAxis
          name="graph-label"
          tickValues={[0, 1, 2]}
          tickFormat={(t) => null}
          style={{
            axis: { stroke: 'none' },
            grid: {
              stroke: 'rgba(119, 128, 139, 1)',
              strokeWidth: 1,
              strokeDasharray: '2,2'
            },
            tickLabels: {
              fontSize: 8,
              fill: (t) => 'rgba(76,96,114,.6)'
            }
          }}
        />

        <VictoryPolarAxis
          dependentAxis
          style={{ axis: { stroke: 'none' } }}
          tickFormat={(t) => null}
        />
        {triangles.map((triangle, index) => (
          <VictoryLine
            key={index}
            data={triangle.points}
            style={{
              data: {
                stroke: index === 0 ? 'black' : 'rgba(119, 128, 139, 1)',
                strokeWidth: 1
              }
            }}
          />
        ))}

        <VictoryLine
          data={data}
          style={{
            data: { stroke: 'rgba(64, 196, 124, 1)', strokeWidth: 2 }
          }}
        />
        <VictoryArea
          style={{ data: { fill: 'rgba(180, 232, 195, 0.2)' } }}
          data={data}
        />
        <VictoryScatter
          data={data}
          style={{
            data: {
              stroke: ({ datum }) => datum.color,
              strokeWidth: 2,
              fill: 'white'
            }
          }}
        />
      </VictoryChart>
    </GraphContainer>
  )
}
