import React, { useState, useEffect } from 'react'
import { Input, Picker } from 'antd-mobile'
import { AiOutlineDown } from 'react-icons/ai'
import styled from 'styled-components'
import { PlaceholderContainer } from './select'

const Container = styled.div`
  .adm-input,
  .picker-value-container {
    background: #fbfbfb;
    border: 1px solid rgba(147, 147, 147, 0.2);
  }
`

export default function Additional(props) {
  const { items, anArr, update, verify } = props

  const [values, setValues] = useState([])

  useEffect(() => {
    setValues(anArr)
  }, [anArr])

  const onChange = (value, index) => {
    values[index] = value
    setValues(values)
    update(values)
  }

  return (
    <Container>
      {(items || []).map((item, index) => {
        return (
          <div key={`additional_${index} `} className="additional-item">
            <div>
              {item.type === 'text_entry' && (
                <Input
                  placeholder={item.title || 'Please type here'}
                  className={`q-form-item ${
                    verify && verify[index] ? 'invalid' : ''
                  }`}
                  value={values[index]}
                  onChange={(val) => onChange(val, index)}
                />
              )}

              {item.type === 'select' && (
                <Select
                  value={values[index]}
                  setValue={(val) => onChange(val, index)}
                  _verify={verify && verify[index]}
                  placeholder={item.title || 'Please select'}
                  list={item.options.map((option) => ({
                    value: option,
                    label: option
                  }))}
                />
              )}
            </div>
            {verify && verify[index] && (
              <p className="invalid">{verify[index].message || 'Required'}</p>
            )}
          </div>
        )
      })}
    </Container>
  )
}

export function Select(props) {
  const { _verify, list, value, setValue, placeholder } = props
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div
        className={`q-form-item picker-value-container ${
          _verify ? 'invalid' : ''
        } `}
        onClick={() => {
          setVisible(true)
        }}
      >
        {value ? (
          <div>{list.find((item) => item.value === value).label}</div>
        ) : (
          <PlaceholderContainer>
            {placeholder || 'Please select'}
          </PlaceholderContainer>
        )}
        <AiOutlineDown />
      </div>
      <Picker
        columns={[list]}
        onConfirm={([value]) => {
          setValue(value)
        }}
        value={value}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
    </>
  )
}
