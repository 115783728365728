import styled, { css } from 'styled-components'
import IRBg from '../../asserts/images/physical-assessment/bg_recommendation_01.png'

export const Container = styled.div`
  position: relative;
  section {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 14px;
    background-color: #ffffff;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  margin-top: 112px;
  font-size: 18px;
  font-family: Gilroy;
  color: #323f4a;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .message {
    margin-bottom: 8px;
  }
`

export const SectionStyle = css`
  padding: 24px 24px 51px 24px;
  overflow: hidden;
  .ar-section-title {
    padding: 0 0 21px 0;
    margin-bottom: 30px;
    font-size: 1.85rem;
    letter-spacing: 0.3px;
    color: #323f4a;
    font-weight: 600;
    border-bottom: 1px solid rgba(227, 230, 234, 1);
  }

  .ar-section-group-title {
    color: rgba(76, 96, 114, 1);
    font-size: 1.5rem;
    letter-spacing: 0.3px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  h3.ar-section-card-title {
    font-size: 18px;
    font-weight: 600;
    color: #4c6072;
    line-height: 27px;
    letter-spacing: 0.3px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #4c6072;
    margin-bottom: 16px;
  }
`

export const ClientBasicInfoContainer = styled.section`
  position: relative;
  padding: 34px 24px 36px 24px;
  font-family: Gilroy;
  .header {
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    img:first-child {
      height: 32px;
    }
    img:nth-child(2) {
      margin-top: 80px;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .title {
    margin-top: 64px;
    padding-right: 90px;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.15;
    color: #323f4a;
  }
  .info-section {
    margin-top: 23px;
    .item {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: #4c6072;
      font-weight: 500;
      .value {
        margin-left: 8px;
        font-weight: 600;
      }
      &:not(:first-child) {
        margin-top: 4px;
      }
    }
  }
  .bg-section {
    img {
      position: absolute;
    }
    .bg-2 {
      right: 0;
      bottom: 0;
    }
  }
`

export const ScoreColorGroupStyle = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  font-size: 0.64rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(50, 58, 70, 0.5);
  li {
    padding-left: 16px;
    display: flex;
    align-items: center;
  }
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`

export const RecommendationContainer = styled.section`
  ${SectionStyle}
  padding-top: 106px;
  .edit-btn {
    margin-left: 36px;
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #264382;
    border-color: #264382;
    .icon {
      margin-right: 10px;
    }
  }
  .subtitle {
    font-size: 1.71rem;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: rgb(76, 96, 114);
  }
  /* 1. Banner Section */
  .banner-section {
    padding: 30px 40px;
    height: 160px;
    border-radius: 8px;
    background: url(${IRBg}) no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logos-wrapper {
      display: flex;
      align-items: center;
      img {
        margin-right: 18px;
        height: 32px;
      }
    }
    .title {
      font-size: 1.72rem;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.3px;
      color: #ffffff;
    }
  }
  /* 2. Highest Priorities Section */
  .highest-priorities-section {
    padding: 36px 0;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .items-section {
      margin: 24px -8px 0;
      display: flex;
      flex-direction: column;
      .highest-priority-item {
        margin: 0 8px;
        margin-bottom: 16px;
        flex: 1;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid rgba(227, 230, 234, 1);
        background: rgba(250, 250, 250, 1);
        .title-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(227, 230, 234, 1);
          button {
            padding: 0;
            width: 36px;
            min-width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eff1f3;
          }
        }
      }
    }
  }
  /* 3. Integrated Recommendation Section */
  .ir-section {
    margin-top: 36px;
    display: flex;
    .editor-section {
      flex: 1;
    }
  }
  /* 4. Exercise, Nutrition, Rejuvenation */
  .step-section {
    margin-top: 60px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .subtitle {
      margin-bottom: 24px;
    }
    .step-item {
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      .top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-section {
          display: flex;
          align-items: center;
          .title {
            margin-left: 16px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.3px;
          }
        }
        button {
          padding: 0;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eff1f3;
        }
      }
      .editor-section {
        margin-top: 16px;
      }
    }
  }
  /* 5. Referrals Section */
  .referrals-section {
    margin-top: 60px;
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-btn {
        padding: 0 24px;
        height: 40px;
        border-color: rgb(38, 67, 130);
        color: rgb(38, 67, 130);
        &:disabled {
          opacity: 0.7;
        }
      }
    }
    .bottom-section {
      margin-top: 24px;
    }
    /* .add-more-section {
      margin-top: 56px;
      padding-top: 14px;
      border-top: 1px solid rgba(215, 215, 215, 1);
      .add-more-btn {
        padding: 0;
        color: rgba(38, 67, 130, 1);
      }
    } */
  }
`

export const ChartContainer = styled.div`
  .tip-label {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.7rem;
    color: rgba(50, 58, 70, 0.5);
    text-transform: uppercase;
    font-family: Gilroy;
    white-space: nowrap;
    &:nth-child(2) {
      margin-top: 10px;
    }
    span {
      margin-right: 2px;
      svg {
        vertical-align: middle;
      }
    }
    .tip-circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }
    .space {
      display: inline-block;
      width: 10px;
    }
    .tip-line {
      display: inline-block;
      margin-top: -1px;
      width: 8px;
      height: 2px;
      vertical-align: middle;
    }
    &.right-align {
      justify-content: flex-end;
    }
    & > div {
      margin-left: 16px;
    }
  }
  .zones-container {
    font-size: 0.86rem;
    font-weight: 600;
    color: #4c6072;
    margin-top: 20px;
    margin-bottom: 28px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 20px;
    span.align-end {
      text-align: end;
    }
    .max-heart-rate {
      font-weight: 1.2rem;
      color: rgba(76, 96, 114, 1);
      margin-bottom: 9px;
    }
    & > div {
      padding: 1px 0;
      display: flex;
      justify-content: space-between;
      span {
        display: inline-block;
        min-width: 70px;
      }
      .zone {
        min-width: 5.2px;
        width: 5.2px;
        height: 12px;
        border-radius: 3px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
  .chart-group-summary {
    display: flex;
    margin-bottom: 40px;
    .adm-divider-vertical {
      height: 40px;
      margin: 0 13px;
    }
    .summary-item {
      /* min-width: 30%; */
      .label {
        color: #4c6072;
        font-size: 0.86rem;
        padding-bottom: 10px;
      }
      .value {
        color: #5c6a7c;
        font-size: 1.07rem;
        font-weight: 600;
      }
    }
  }
  .score-list {
    margin-top: 40px;
    .body-item {
      display: flex;
      justify-content: space-between;
    }
    .score-item {
      padding-bottom: 20px;
      color: #4c6072;
      font-size: 15px;
      border-bottom: 1px solid #e3e6ea;
      &.v2:last-child {
        border: none;
      }
      .r-item {
        margin: 10px 0;
        text-align: right;
      }
      .h-item {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
      }
      .value {
        font-size: 24px;
        font-weight: 700;
        &.green {
          color: #40c47c;
        }
        &.red {
          color: #ff6b00;
        }
        .anticon {
          font-size: 14px;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }
  }
  .peak-rank {
    margin-top: 0;
    .range-item .value {
      margin-top: -15px !important;
    }
    .score-item {
      border: none;
      .ranges {
        margin-top: 40px;
      }
    }
  }
`
