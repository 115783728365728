import { ActionSheet } from 'antd-mobile'
import styled from 'styled-components'
import classNames from 'classnames'
import { ARHeaderSections } from '../wrapper'
import { Link } from 'react-scroll'

const ModalContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  .header {
    position: sticky;
    background-color: white;
    height: 20px;
    text-align: center;
    .line {
      display: inline-block;
      width: 70px;
      background-color: rgba(217, 217, 217, 1);
    }
  }
  .list {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .row {
      display: flex;
      align-items: center;
      .number {
        width: 30px;
        height: 30px;
        border: 2px solid #98a9bc;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #264382;
        font-size: 12px;
        font-weight: 800;
      }
      .name {
        color: #52606c;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        color: #52606c;
        margin-left: 14px;
        margin-right: 14px;
      }
    }

    .active {
      .number {
        border-color: rgb(242, 242, 242);
        background-color: #ff6c01;
        color: #fff;
      }
      .name {
        font-weight: 600;
      }
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #e6e9f2;
      border-bottom: 1px solid #e6e9f2;
    }
    .active:last-child {
      border-bottom: none;
    }
    .done {
      svg {
        color: #05b800;
        height: 34px;
        width: 34px;
      }
      .name {
        color: #a5abb2;
      }
    }
  }
`

const PositionSheet = (props) => {
  const { modalShow, setModalShow, section, person } = props
  let sections = Object.keys(ARHeaderSections)
    .map((key) => ARHeaderSections[key])
    .sort((a, b) => a.index - b.index)
  if (person.profile.membership === 'basic') {
    sections = sections.filter(
      (section) =>
        section.index !== 9 && section.index !== 10 && section.index !== 8
    )
  }
  return (
    <ActionSheet
      visible={modalShow}
      onClose={() => setModalShow(false)}
      extra={
        <ModalContainer>
          <div className="header">
            <div className="line" />
          </div>
          <div className="list">
            {sections.map((e, index) => (
              <div
                key={index}
                className={classNames('item', {
                  active: index + 1 === section.index
                })}
              >
                <Link to={e.name} smooth={true} offset={-58}>
                  <div className="row">
                    <div className="number">{index + 1}</div>
                    <div className="name">{e.name}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </ModalContainer>
      }
    />
  )
}

export default PositionSheet
