import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Picker } from 'antd-mobile'
import { AiFillCaretDown } from 'react-icons/ai'
import styled from 'styled-components'

Select.propTypes = {
  options: PropTypes.array,
  initialValue: PropTypes.string,
  extraAttr: PropTypes.object,
  placeholder: PropTypes.string
}

export default function Select(props) {
  const {
    options,
    placeholder,
    disabled,
    value,
    extraAttr,
    onChange,
    onSelectChange
  } = props

  const [visible, setVisible] = useState(false)

  return <Container>
      <Button className='select-htn' onClick={() => setVisible(true)} disabled={disabled}>{value || placeholder || 'Please select'} <AiFillCaretDown/></Button>
      <Picker
        onClose={() => setVisible(false)}
        onConfirm={triggerChange}
        value={[value]}
        visible={visible}
        placeholder={placeholder}
        disabled={disabled}
        columns={[options]}
        {...extraAttr}
      >
    </Picker>
  </Container>

  function triggerChange(value) {
    if (onChange) {
      onChange(value[0])
    }
    if (onSelectChange) {
      onSelectChange(value[0])
    }
    setVisible(false)
  }
}

const Container = styled.div`
.select-htn{
  width: 100%;
  padding: 0;
  border: 0;
  span{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
`
