import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd-mobile'
import success from '../../asserts/images/success.png'

export default function Finish2FA(props) {
  return (
    <div className="main-container">
      <div>
        <img src={success} alt="done" />
        <h2>All set!</h2>
        <p>You have successfully set up 2FA.</p>
        <Link to="/">
          <Button block type="button" color="primary" size="large">
            Done
          </Button>
        </Link>
      </div>
    </div>
  )
}
