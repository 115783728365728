import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { Outlet, useLocation } from 'react-router-dom'
import Set2FA from './setting'
import EnterPhone from './phone'
import Verify2FA from './verify'
import Finish2FA from './done'

import { loadUser } from '../../utils'
import Header from '../commonComponents/header'

const LayoutContainer = styled.div`
  height: 100vh;
  padding: 1.714rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy';
  .main-container {
    justify-content: space-around;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 3px 3px 30px 0px rgba(0, 0, 0, 0.03);
    .back{
      text-align: left;
      color: #264382;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      svg{
        margin-right: 10px;
      }
    }
    h2 {
      color: #323F4A;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; 
      margin-bottom: 16px;
      letter-spacing: 0.3px;
    }
    button {
      margin-top: 32px;
      margin-top: 24px;
    }
    .adm-form {
      text-align: left;
    }
    .setting-container {
      margin-top:22px;
      p {
        font-weight: 400;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.3px;
        padding:0px 12px;
      }
      button {
        font-weight: 600;
        font-size: 1em;
        letter-spacing: 0.3px;
      }
    }

    .phone-container,.verify-container{
      .lighter-text{
        margin-top:32px;
        margin-bottom:4px;
      }
      h2{
        margin-top:0px;
        margin-bottom:16px;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.3px;
      }
      p{
        margin-top:0px;
        margin-bottom:16px;
      }
      .adm-form-item{
        background: #F5F6F8;
      }
      button{
        margin-top:0px;
      }
    }

    .verify-container{
       .resend{
        font-weight: 600;
       }
       p{
        margin-bottom:0px;
       }
       .continue{
        margin-top: 32px;
        margin-bottom: 24px;
       }
       .adm-passcode-input {
          --cell-gap: 24px;
          --border-color: #F5F6F8;
          --cell-size: 46px;
          margin-top:16px;
          .adm-passcode-input-cell{
            background-color: #F5F6F8;
          }
        }
    }
  }
  .error-code {
    .adm-passcode-input-cell {
      border-color: var(--adm-color-danger);
    }
    p {
      color: var(--adm-color-danger);
    }
  }
`
function TwoFALayout(props) {
  const person = loadUser()

  const location = useLocation()

  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.type === 'initial') {
        return {
          phone: person.profile.phone_number,
          showBack: state.showBack
        }
      } else if (action.type === 'check show back') {
        let showBack = state.showBack
        if (location.pathname !== '/2fa/done' && location.pathname !== '/2fa') {
          showBack = true
        } else {
          showBack = false
        }

        return {
          phone: person.profile.phone_number,
          showBack
        }
      } else if (action.type === 'set phone') {
        return {
          phone: action.phone,
          showBack: state.showBack
        }
      }
      return state
    },
    { phone: null, showBack: false }
  )

  useEffect(() => {
    dispatch({ type: 'initial' })
  }, [])
  useEffect(() => {
    dispatch({ type: 'check show back' })
  }, [location.pathname])

  const { phone } = state

  return (
    <LayoutContainer>
      <Header/>
      <Outlet
        context={{
          person,
          phone,
          setPhone: (phone) => dispatch({ type: 'set phone', phone })
        }}
      />
    </LayoutContainer>
  )
}

export { TwoFALayout, Set2FA, EnterPhone, Verify2FA, Finish2FA }
