import React, { useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import CheckeIcon from '../../../asserts/icons/onboarding/membership-packages-white-checked-icon.svg'
import CardBg from '../../../asserts/icons/onboarding/membership-packages-health-card-bg.svg'
import styled from 'styled-components'
import { AiOutlineDown, AiOutlineUp, AiOutlineCheck } from 'react-icons/ai'

const HealthAssessmentMetrics = [
  'Cognitive Health',
  'Body Composition',
  'Bone Health',
  'Movement Quality',
  'Balance',
  'Muscle Fitness',
  'Cardiovascular Fitness',
  'Advanced Blood Panel',
  'Comprehensive Assessment Review',
  'Exercise, Nutrition, Supplement, Sleep recommendations',
  '6 weeks of exercise programming & app access',
  '2 complimentary training/nutrition sessions'
]

const Card = styled.div`
  background-image: url(${CardBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 16px 16px 0px 16px;
  color: white;
  border-radius: 8px;
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 1px;
    .price {
      letter-spacing: 0.13px;
      margin-top: 4px;
    }

    button {
      width: ${(props) => (props.selected ? '160px' : '120px')};
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      color: ${(props) => (props.selected ? 'white' : '#BEC7D9')};
      background-color: ${(props) => (props.selected ? '#2b4b8f' : 'white')};
      border: ${(props) => (props.selected ? 'none' : '1px solid #BEC7D9')};
    }
  }
  .card-desc {
    margin-top: 16px;
    color: #c0e9ff;
    font-family: Heebo;
    font-size: 12px;
    line-height: 16.8px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .adm-button:active::before {
    opacity: 0;
  }
  .button-content {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2.8px;
    font-size: 14px;
    font-weight: 600;
    height: 32px;
    svg {
      margin-right: 8px;
    }
  }
  .adm-divider {
    opacity: 0.3;
  }
  .hide-content {
    display: none;
  }
`

const Metric = styled.div`
  display: flex;
  align-items: center;
  font-family: Heebo;
  letter-spacing: 0.3px;
  line-height: 18px;
  img {
    margin-right: 12px;
  }
  margin-bottom: 16px;
`

export const HealthAssessmentCard = ({ selected, onClick, price }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <Card selected={selected}>
      <div className="card-title">
        <div>
          <div>
            HEALTH <br />
            ASSESSMENT
          </div>
          <div className="price">{price}</div>
        </div>

        <Button color="primary" onClick={onClick}>
          <Space>
            {selected && (
              <AiOutlineCheck style={{ color: '#05B800', fontSize: 12 }} />
            )}
            {selected ? 'SELECTED' : 'SELECT'}
          </Space>
        </Button>
      </div>
      <div className="card-desc">
        Advanced scientific testing of 350+ healthspan indicators with
        comprehensive analysis and personalized recommendations and programming
      </div>
      <div className={`${collapsed ? 'hide-content' : ''}`}>
        <Divider />
        {HealthAssessmentMetrics.map((metric, index) => {
          return (
            <Metric key={index}>
              <img src={CheckeIcon} alt="checked" />
              <span>{metric}</span>
            </Metric>
          )
        })}
      </div>
      <Divider
        style={{
          marginBottom: 0
        }}
      />
      <Button
        block
        fill="none"
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        <div className="button-content">
          {collapsed ? <AiOutlineDown /> : <AiOutlineUp />}
          {collapsed ? 'SHOW MORE' : 'SHOW LESS'}
        </div>
      </Button>
    </Card>
  )
}
