import React from 'react'
import _, { capitalize, concat, round, uniq } from 'lodash'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory'
import moment from 'moment'
import { calcTextWidth } from '../../../../utils/String+Extension'

import healthkitIcon from '../../../../asserts/icons/devices/icon_source_hk.png'
import garminIcon from '../../../../asserts/icons/devices/icon_source_garmin.png'
import ouraIcon from '../../../../asserts/icons/devices/icon_source_oura.png'
import PolarIcon from '../../../../asserts/icons/devices/icon_source_polar.png'
import WithingsIcon from '../../../../asserts/icons/devices/icon_source_withings.png'
import ApeironScaleIcon from '../../../../asserts/icons/ic-biome-weight-apeiron-scale.svg'
import { BiomeWeightHoverContainer } from '../index.style'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { FlexBox } from '../../../commonComponents/FlexBox'

export function BiomeWeightChart({ weight, from, width }) {
  const { yTickValues, step, data } = calculatePoints(weight)

  let xAxisTicks = data.map((e) => e.x)
  let xAxisTickCount = from ? weight.length : 7
  if (xAxisTicks.length < xAxisTickCount) {
    xAxisTicks = concat(
      xAxisTicks,
      new Array(xAxisTickCount - xAxisTicks.length)
        .fill(0)
        .map((_, index) => `${index}`)
    )
  }
  if (data.length === 0) {
    return (
      <VictoryChart
        width={500}
        height={150}
        padding={{ top: 25, bottom: 20, left: 20, right: 30 }}
      >
        <VictoryAxis
          tickValues={xAxisTicks}
          tickFormat={(t) => ' '}
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: (tick) => {
                return 'rgba(0, 0, 0, 0.5)'
              },
              strokeDasharray: '3, 3'
            },
            tickLabels: { fontSize: 13, padding: 5, fill: '#70808E' }
          }}
        />
        <VictoryAxis
          dependentAxis
          offsetX={505}
          tickValues={[0, step, 2 * step, 3 * step]}
          tickFormat={(t) => {
            return t + yTickValues[0]
          }}
          style={{
            axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '3, 3' },
            grid: {
              stroke: 'rgba(0, 0, 0, 0.1)',
              strokeDasharray: '3, 3'
            },
            tickLabels: {
              fontSize: 13,
              padding: 4,
              fill: '#70808E',
              fontFamily: 'Gilroy',
              fontWeight: 600
            }
          }}
        />
      </VictoryChart>
    )
  } else {
    return (
      <>
        <VictoryChart
          width={500}
          height={150}
          padding={{ top: 25, bottom: 20, left: 20, right: 30 }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            tickValues={xAxisTicks}
            tickFormat={(t) => ' '}
            style={{
              axis: { stroke: 'transparent' },
              grid: {
                stroke: (tick) => {
                  return 'rgba(0, 0, 0, 0.5)'
                },
                strokeDasharray: '3, 3'
              },
              tickLabels: { fontSize: 13, padding: 5, fill: '#70808E' }
            }}
          />

          <VictoryAxis
            dependentAxis
            offsetX={505}
            tickValues={[0, step, 2 * step, 3 * step]}
            tickFormat={(t) => {
              return t + yTickValues[0]
            }}
            style={{
              axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '3, 3' },
              grid: {
                stroke: 'rgba(0, 0, 0, 0.1)',
                strokeDasharray: '3, 3'
              },
              tickLabels: {
                fontSize: 13,
                padding: 4,
                fill: '#70808E',
                fontFamily: 'Gilroy',
                fontWeight: 600
              }
            }}
          />
          <VictoryArea style={{ data: { fill: 'url(#linear)' } }} data={data} />
          <VictoryLine
            style={{
              data: { stroke: '#7BAD2D', strokeWidth: '2px' }
            }}
            data={data}
          />
          <VictoryScatter
            dataComponent={<Scatter color={'rgba(123, 173, 45, 1)'} />}
            data={data}
            labelComponent={
              <VictoryTooltip
                // active={true}
                labelComponent={<HoverComp />}
                flyoutHeight={70}
                flyoutWidth={30}
                flyoutStyle={{
                  stroke: 'transparent',
                  strokeWidth: 2,
                  fill: 'transparent'
                }}
              />
            }
            labels={({ datum }) => {
              return datum.text
            }}
            style={{
              labels: {
                fontSize: 15,
                fontFamily: 'Gilroy',
                fontWeight: 600,
                fill: 'rgba(123, 173, 45, 1)'
              }
            }}
          />
        </VictoryChart>
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgba(123, 173, 45, 0.5)" />
              <stop offset="100%" stopColor="rgba(123, 173, 45, 0)" />
            </linearGradient>
          </defs>
        </svg>
      </>
    )
  }

  function calculatePoints(originDatas) {
    let filteredData = _.filter(originDatas, (d) => d.value)
    if (from) {
      filteredData = filteredData.slice(-7)
    }
    const allWeight = filteredData.map((d) => d.value)
    let yTickValues
    let step = 1
    if (allWeight.length === 0) {
      yTickValues = [152, 151, 150, 149].reverse()
    } else if (allWeight.length === 1) {
      let max = Math.ceil(allWeight[0]) + 1
      yTickValues = [max, max - 1, max - 2, max - 3].reverse()
    } else {
      let max = Math.ceil(Math.max(...allWeight))
      let min = Math.floor(Math.min(...allWeight))

      while ((max - min) % 3 !== 0) {
        max += 1
        min -= 1
      }

      step = (max - min) / 3
      yTickValues = [
        max,
        max - 1 * step,
        max - 2 * step,
        max - 3 * step
      ].reverse()
    }
    return {
      yTickValues,
      step,
      data: filteredData.map((d, index) => {
        let percent = 0
        if (index > 0) {
          const previousValue = filteredData[index - 1].value
          if (previousValue === 0) {
            percent = 100
          } else {
            percent = ((d.value - previousValue) / previousValue) * 100
            percent = percent.toFixed(0)
          }
        } else {
          return {
            x: d.date,
            y: d.value - yTickValues[0],
            text: round(d.value, 1),
            source: d.source
          }
        }
        return {
          x: d.date,
          y: d.value - yTickValues[0],
          text: round(d.value, 1),
          source: d.source,
          percent
        }
      })
    }
  }

  function HoverComp(props) {
    const {
      x,
      y,
      datum: { _x, text, source, percent }
    } = props

    const sourceStr = ConvertWeightSource(source)
    const textWidth = calcTextWidth(sourceStr, { size: 14.5 })

    let overWidth = x - 80 + (textWidth < 150 ? 150 : textWidth) - width
    if (overWidth < 0) {
      overWidth = 0
    }
    if (x < 40) {
      overWidth = -20
    }
    if (_x === 1) {
      overWidth = -40
    }

    return (
      <g>
        {/* <rect
          height={60}
          width={textWidth < 150 ? 150 : textWidth}
          x={x - 80 - overWidth}
          y={y - 11.6 - 28}
          rx="6"
          style={{
            stroke: '#E6E9F2',
            strokeWidth: 2,
            fill: 'white'
          }}
        /> */}
        <foreignObject
          x={x - 80 - overWidth}
          y={y - 11.6 - 50}
          height={104}
          width={(textWidth < 150 ? 150 : textWidth) + 60}
        >
          <BiomeWeightHoverContainer>
            <div className="weight-source-data">
              <span>Weight</span>
              {ConvertSourceToComp(source)}
            </div>
            <div className="weight-data-container">
              <div>
                <span className="dot" />
                <span className="date">
                  {moment(props.datum.x, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                </span>
              </div>
              <div>
                <span>{text}</span>
                {percent && (
                  <span className="changes">
                    <FlexBox alignItems="center">
                      {percent > 0 ? (
                        <AiOutlineArrowUp
                          style={{
                            fontSize: 12,
                            color: '#9aa5b1'
                          }}
                        />
                      ) : (
                        <AiOutlineArrowDown
                          style={{
                            fontSize: 12,
                            color: '#9aa5b1'
                          }}
                        />
                      )}
                      {Math.abs(percent)}%
                    </FlexBox>
                  </span>
                )}
              </div>
            </div>
          </BiomeWeightHoverContainer>
        </foreignObject>
      </g>
    )
  }
}

function Scatter(props) {
  return (
    <svg
      width="12"
      height="12"
      x={props.x - 5}
      y={props.y - 5}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill="white"
        stroke={props.color}
        strokeWidth="3"
      />
    </svg>
  )
}

export function ConvertWeightSource(source) {
  if (source) {
    switch (source) {
      case 'dataset':
        return 'Apeiron Scale'
      default:
        return isFirstCharUpperCase(source) ? source : capitalize(source)
    }
  }
  return 'Manual Entry'
}

function isFirstCharUpperCase(str) {
  const firstChar = _.first(str)
  return firstChar >= 'A' && firstChar <= 'Z'
}

const SourceContainer = styled.div`
  display: flex;
  align-items: center;
  * {
    margin-left: 4px;
  }
`

const ManualTag = styled.span`
  border-radius: 4px;
  font-size: 12px;
  color: #8596a4;
  background-color: #f1f3f6;
  padding: 2px 7px;
`

const ImgTag = styled.img`
  height: 24px;
`

export function ConvertSourceToComp(source) {
  if (!source) {
    return (
      <SourceContainer>
        <ManualTag>Manual</ManualTag>
      </SourceContainer>
    )
  }
  return (
    <SourceContainer>
      {uniq(source.split(', ')).map((item, index) => {
        switch (item.toLocaleLowerCase()) {
          case 'garmin':
            return <ImgTag src={garminIcon} alt="garmin" key={index} />
          case 'ouraring':
            return <ImgTag src={ouraIcon} alt="ouraring" key={index} />
          case 'healthkit':
            return <ImgTag src={healthkitIcon} alt="healthkit" key={index} />
          case 'polar':
            return <ImgTag src={PolarIcon} alt="polar" key={index} />
          case 'withings':
            return <ImgTag src={WithingsIcon} alt="withings" key={index} />
          case 'apeiron scale':
          case 'dataset':
            return (
              <ImgTag src={ApeironScaleIcon} alt="apeiron scale" key={index} />
            )
          default:
            return <ManualTag key={index}>Manual</ManualTag>
        }
      })}
    </SourceContainer>
  )
}
