import React, { useState, useEffect } from 'react'
import { Input, TextArea } from 'antd-mobile'
import _ from 'lodash'

export default function TextEntry(props) {
  const { question, answer = {}, update } = props
  const [value, setValue] = useState('')

  useEffect(() => {
    const an = answer.answer.value
    if (typeof an === 'string' || typeof an === 'number') {
      setValue(an)
    }
  }, [answer])

  const isMultiLine = question.options_layout === 'multi-line'

  const childProps = {
    placeholder: 'Please type here',
    value,
    onChange: (value) => {
      setValue(value)
      update(_.assignIn(answer, { answer: { value } }), question)
    }
  }

  const hasError = answer.verify && answer.verify.required

  return (
    <>
      {isMultiLine ? (
        <TextArea
          {...childProps}
          autoSize
          className={`q-form-item ${answer.verify ? 'invalid' : ''}`}
        />
      ) : (
        <InputWrapper
          className={`q-form-item ${answer.verify ? 'invalid' : ''}`}
          inputProps={childProps}
          unit={question.unit}
        />
      )}
      {hasError && <p className="invalid">Please type this input</p>}
    </>
  )
}

export function InputWrapper(props) {
  return (
    <div className={props.className}>
      <Input {...props.inputProps} />
      {props.unit && <span className="q-form-inner-after">{props.unit}</span>}
    </div>
  )
}
