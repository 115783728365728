import React from 'react'
import Party from '../../../asserts/icons/onboarding/pre-screen-completed-party.svg'
import Success from '../../../asserts/icons/onboarding/pre-screen-completed-success.svg'
import { CompletedPage } from '../common/completed'

export function PreScreeningCompleted(props) {
  const { anyYes, action } = props
  return (
    <CompletedPage
      title={anyYes ? 'Success' : null}
      img={anyYes ? Success : Party}
      desc={
        anyYes ? (
          <p>
            Thank you for completing our Pre-Assessment Medical Review. Your
            health and safety is a top priority to us. Based on your responses,
            we would like to know more about your heart health so we can
            customize the safest physical assessment for you at Apeiron Life.
            Our medical director, Dr. Dev Mishra, will reach out for a brief
            discussion to see how we can best support you. Thank you for
            understanding.
          </p>
        ) : (
          <p>
            Thank you for completing our pre-assessment medical review. Please
            click "NEXT" to add Logistical and Initial Intake details that will
            help us design your action plan. <br />
            <br />
            This should take less than 15 minutes. We look forward to learning
            more about you and supporting your health and well-being!
          </p>
        )
      }
      action={action}
      actionTitle={anyYes ? 'LOG OUT' : 'NEXT'}
      // extraFooter={
      //   <Button
      //     className="cta-back"
      //     block
      //     fill="outline"
      //     color="primary"
      //     onClick={logOutAction}
      //     style={{ marginTop: '1rem' }}
      //   >
      //     I WILL COME BACK LATER
      //   </Button>
      // }
    />
  )
}
