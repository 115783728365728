import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef
} from 'react'
import _ from 'lodash'
import { InputWrapper } from './textEntry'

const Number = (props, ref) => {
  const { question, answer = {}, update, ...args } = props
  const [value, setValue] = useState('')

  useEffect(() => {
    const an = answer.answer.value
    if (an && !value) {
      setValue(an)
    }
  }, [answer, value])

  useImperativeHandle(ref, () => {
    return {
      clearValue: () => {
        setValue('')
      }
    }
  })

  return (
    <div className="q-form-item-container">
      {/* <Input
        className={`q-form-item ${answer?.verify ? 'invalid' : ''}`}
        onChange={(value) => {
          setValue(value)
          update(_.assignIn(answer, { answer: { value } }), question)
        }}
        value={value}
        type="number"
        placeholder="Please type here"
        {...args}
      /> */}
      <InputWrapper
        inputProps={{
          type: 'number',
          placeholder: props.placeholder || 'Please type here',
          value: value,
          onChange: (value) => {
            setValue(value)
            update(_.assignIn(answer, { answer: { value: value } }), question)
          }
        }}
        unit={_.get(question, 'unit')}
        className={`q-form-item ${answer?.verify ? 'invalid' : ''}`}
        {...args}
      />
      {answer?.verify && <p className="invalid">Please type number here</p>}
    </div>
  )
}

export default forwardRef(Number)
