import React, { useState, useEffect } from 'react'
import { DatePicker } from 'antd-mobile'
import moment from 'moment'

export default function Date(props) {
  const { format, disabledDate, disabled, extraAttr, onChange } = props

  const FORMAT = format || 'MM/DD/YYYY'

  const [value, setValue] = useState(null)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (props.value) {
      const date = moment(props.value, FORMAT)
      setValue(date)
    }
  }, [props.value])

  const triggerChange = (date) => {
    if (onChange) {
      if (extraAttr && extraAttr.returnDate) {
        onChange(date)
      } else {
        onChange(date && moment(date).format(FORMAT))
      }
    }
  }

  return (
    <>
      <div
        className={'picker-value-container'}
        onClick={() => {
          setVisible(true)
        }}
      >
        <div>{value ? value.format(FORMAT) : FORMAT}</div>
      </div>
      {
        <DatePicker
          onConfirm={triggerChange}
          format={FORMAT}
          visible={visible}
          value={value?.toDate()}
          precision="day"
          disabledDate={disabledDate}
          disabled={disabled}
          onClose={() => {
            setVisible(false)
          }}
          {...extraAttr}
        />
      }
    </>
  )
}
