import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Switch } from 'antd-mobile'
import styled from 'styled-components'
import _ from 'lodash'
import ProgressCircle from './components/progress'
// import { AiOutlineRight } from 'react-icons/ai'
import { CompactLine } from './components/segmentLine'
import { getColor } from './index'

const Container = styled.div`
  padding: 1rem;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  section {
    padding: 1rem;
    background: #ffffff;
    margin: 1rem 0;
    border-radius: 0.5rem;
  }
  .title {
    color: #383e48;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.31rem;
    padding-right: 1rem;
    svg {
      vertical-align: middle;
    }
    .target {
      color: #264382;
      font-family: Gilroy;
      font-size: 0.75rem;
    }
    .score-container {
      width: 3.125rem;
      line-height: 1.625rem;
      max-height: 1.625rem;
      border: 1px solid #8bb7e1;
      border-radius: 0.3125rem;
      font-size: 0.875rem;
      margin-left: 3.4375rem;
      text-align: center;
      font-weight: 700;
      font-family: 'Inter';
    }
  }
  .desc {
    color: #52606c;
    font-family: Inter, Gilroy;
    font-size: 0.75rem;
    line-height: 140%;
  }
  .description {
    color: #383e48;
    font-family: Gilroy;
    font-size: 0.875rem;
  }
  .progress {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: Inter;
      font-weight: 700;
    }
    .content {
      width: 55%;
    }
  }
  .list {
    min-height: 25vh;
  }
  .nav-container {
    border-bottom: 1px solid #f1f3f6;
    margin: 0 -1rem;
    padding: 1rem;
    margin-top: -1rem;
    color: #323f4a;
    opacity: 0.7;
    font-size: 0.875rem;
    & ~ div {
      margin: 2rem 0;
      & > .title {
        display: flex;
        justify-content: space-between;
        .score,
        .no-data {
          color: #52606c;
        }
      }
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    & + div {
      margin-top: 1rem;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    & > span {
      margin-left: 0.56rem;
    }
    & + div {
      margin-top: 1rem;
    }
  }
  .detail-entry {
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: rgba(10, 40, 69, 0.05);
    padding: 1rem;
    color: #52606c;
    font-size: 0.875rem;
    align-items: center;
  }
`

export default function HealthySignalCompact(props) {
  const { cardList, jumpTo, progress, progressText, rangeType, data } =
    useOutletContext()
  const [advancedChecked, setAdvancedChecked] = useState(false)
  const getAdvancedText = () => {
    const scores = _.values(data).map((item) =>
      rangeType === 'week' ? item.seg_pct : item.more_seg_pct_avg
    )
    const maxScore = _.max(scores)
    if (maxScore > 300) {
      return 'A score of 300+ shows you have provided a significant amount of stimulus to that healthspan domain and should see major improvements over time'
    } else if (maxScore > 200) {
      return 'A score of 200 suggests you have done enough to make significant improvements to that healthspan domain '
    } else {
      return 'A score of 100 indicates you have done enough this week to maintain, and in some cases, make improvements to the given healthspan domain'
    }
  }
  return (
    <Container>
      <section className="progress">
        <div style={{ width: '37%' }}>
          <ProgressCircle percent={progress} />
        </div>
        <div className="content">
          <div className="title">Healthy Signals Progress</div>
          <p className="desc">{progressText}</p>
        </div>
      </section>
      <section className="list">
        <div className="nav-container">
          <div className="nav">
            <Switch
              checked={advancedChecked}
              onChange={(isChecked) => setAdvancedChecked(isChecked)}
              style={{
                '--checked-color': '#05B800',
                '--height': '1.25rem',
                '--width': '2.1875rem'
              }}
            />
            <span>
              {advancedChecked ? 'View as percentage' : 'Advanced View'}
            </span>
          </div>
          {advancedChecked && <div>{getAdvancedText()}</div>}
        </div>
        {rangeType === 'week' && <DetailEntry {...{ jumpTo, cardList }} />}

        {cardList.map((item, index) => {
          if (!item.data) return null
          const isWeekly = rangeType === 'week'
          const score = isWeekly
            ? item.data.seg_pct
            : item.data.more_seg_pct_avg
          const hightestValue = Math.max(item.target, score)
          const step = Math.round(hightestValue / 3)
          const ranges = [0, step, step * 2, step * 3]
          const bar = {
            widthReference: isWeekly ? 'seg_pct' : 'more_seg_pct_avg',
            score,
            workouts: item.data.seg_workouts,
            svgWidth: '100vw',
            id: `${item.domain}_compact_seg_pct`
          }
          const scorePercent = Math.round((score / (item.target || 100)) * 100)
          return (
            <div key={index}>
              <div className="title">
                <span>
                  {item.domain_label}{' '}
                  {scorePercent > 100 && <CompletedChecked />}
                  {advancedChecked && (
                    <span className="target">
                      <br /> Target: {item.target}
                    </span>
                  )}
                </span>
                <Score
                  {...{
                    score,
                    scorePercent,
                    showTarget: advancedChecked,
                    hightestValue
                  }}
                />
              </div>
              <CompactLine
                {...bar}
                key={index}
                ranges={ranges}
                data={item.data}
                hightestValue={hightestValue}
                showTarget={advancedChecked}
                scorePercent={scorePercent}
                target={item.target}
                rangeType={rangeType}
              />
            </div>
          )
        })}
      </section>
    </Container>
  )
}

function Score(props) {
  const { score, scorePercent, showTarget, hightestValue } = props
  const targetColor =
    (score &&
      getColor(
        showTarget ? score : scorePercent,
        showTarget ? hightestValue : Math.max(scorePercent, 100)
      )) ||
    ' rgb(186, 203, 222)'
  return (
    <div
      className="score-container"
      style={{
        color: targetColor,
        border: `1px solid ${targetColor}`
      }}
    >
      {showTarget ? score : scorePercent + '%'}
    </div>
  )
}

function DetailEntry(props) {
  const { cardList } = props //jumpTo,
  const completedItems = cardList.filter(
    ({ target, data }) => data && data.seg_pct >= target
  )

  return (
    <div
      className="detail-entry"
      // onClick={() => jumpTo('/healthy-signal-detail')}
    >
      <span>
        To fill your ring 100% you must complete all weekly targets. You have
        hit {completedItems.length} of your 7 weekly targets
      </span>
      {/* <AiOutlineRight /> */}
    </div>
  )
}

function CompletedChecked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="7.5" fill="#40C47C" />
      <path
        d="M4.78564 8.75L6.66064 10.625L11.5356 5.75"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  )
}
