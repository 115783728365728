import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { GradientLine, StripeLine, SEGPCT } from './gradientLines'
// import moment from 'moment'
import { Popover } from 'antd-mobile'
import { getSum, Pluralize } from '../../../../utils/common'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

const SegmentLineContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 0.75rem;
    color: #5b6177;
    font-family: Inter, Gilroy;
    &.no-target {
      margin-top: 0.9375rem;
    }
  }
  .bar-container {
    position: relative;
    width: calc(100vw - 7.5rem);
    .stripe-line svg {
      border-radius: 2rem;
    }
    .gradient-line {
      position: absolute;
      top: 0;
      overflow: hidden;
    }
    .workout-line-score {
      position: absolute;
      top: 6px;
      font-weight: 700;
      @media screen and (min-width: 481px) {
        top: -0.375rem;
      }
    }
  }
`

function getBarPCTWidth(pct = 0, ranges = []) {
  const rangeCount = ranges.length - 1
  const targetRangeIndex = ranges.findIndex(
    (value, index) =>
      value <= pct && (ranges[index + 1] > pct || !ranges[index + 1])
  )

  let widthPCT = targetRangeIndex * (100 / rangeCount)
  if (pct > ranges[targetRangeIndex] && ranges[targetRangeIndex + 1]) {
    const extra = pct - ranges[targetRangeIndex]
    widthPCT +=
      ((extra / (ranges[targetRangeIndex + 1] - ranges[targetRangeIndex])) *
        100) /
      rangeCount
  }

  return widthPCT
}

export default function SegmentLine(props) {
  const {
    seg_label,
    score,
    workouts,
    svgWidth,
    target,
    ranges,
    widthReference,
    data,
    hightestValue,
    id
  } = props

  const width = getBarPCTWidth(data[widthReference], ranges) + '%'
  const targetWidth = target && getBarPCTWidth(target, ranges) + '%'
  return (
    <SegmentLineContainer>
      <span className={target ? '' : 'no-target'}>{seg_label}</span>
      <div className="bar-container">
        {target && (
          <div>
            <StripeLine
              svgWidth={svgWidth}
              target={target}
              hightestValue={hightestValue}
              id={`segment_${id}`}
            />
          </div>
        )}
        <div className="gradient-line" style={{ width }}>
          <GradientLine
            svgWidth={svgWidth}
            hightestValue={hightestValue}
            id={id}
          />
        </div>
        <div className="workout-line-score" style={{ top: -8 }}>
          <SEGPCT
            {...{
              width,
              text: score,
              svgWidth,
              target,
              targetWidth,
              hightestValue,
              id
            }}
          />
        </div>
        {workouts && (
          <Workouts
            totalPCT={score}
            workouts={workouts}
            ranges={ranges}
            totalWidth={width}
            label={seg_label}
          />
        )}
      </div>
    </SegmentLineContainer>
  )
}

const PopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5b6177;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 0;
  & > svg {
    color: #a4b9d2;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }
  }
  .popup-content {
    display: flex;
    padding: 0 1rem;
  }
  .score {
    width: 2.875rem;
    height: 2.875rem;
    line-height: 2.875rem;
    text-align: center;
    margin-left: 0.625rem;
    border-radius: 100%;
    background: #f6f6f7;
    color: #3d4358;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Inter';
  }
  .name {
    margin-bottom: 0.625rem;
    span {
      color: #94a3af;
      font-size: 0.5625rem;
    }
  }
  .info {
    display: flex;
    & > div {
      min-width: 2.625rem;
      margin-right: 0.9375rem;
    }
    span {
      color: #94a3af;
      font-size: 0.5625rem;
    }
  }
`

const WorkoutContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 4px;
  display: flex;
  z-index: 9;
  &.target-cursor {
    min-height: 0.625rem;
    &:after {
      content: '';
      width: 0.125rem;
      background: #264382;
      height: calc(100% + 0.25rem);
      position: absolute;
      right: ${(props) => props.afterRight};
      top: -0.125rem;
    }
  }

  .workout-item {
    border: 1px solid #ffffff;
    height: 8px;
    border-radius: 8px;
    outline: 1px solid #ffffff;
    box-sizing: border-box;
    margin-top: 1px;
    &:last-child {
      border-right: none;
    }
    &:hover,
    &.active {
      border-color: #0272e1;
      border-right: 1px solid;
      z-index: 2;
    }
  }
`
function Workouts(props) {
  const {
    ranges,
    totalWidth,
    containerStyle,
    targetCursor,
    target,
    totalPCT,
    setTotalGradientWidth,
    label,
    showWorkoutPopup
  } = props
  const [workouts, setWorkouts] = useState([])
  const [visibleIndex, setVisibleIndex] = useState(null)
  useEffect(() => {
    let _workouts = _.cloneDeep(props.workouts)
    let ratio = 1
    let totalPct = 0,
      position = 0
    for (const workout of _workouts) {
      const { hsd_pct } = workout
      totalPct += hsd_pct
      const width = getBarPCTWidth(totalPct * ratio, ranges)
      workout.left = position + '%'
      workout.width = Math.floor(width - position) + '%'
      position = Math.floor(width)
    }
    if (totalWidth) {
      const _totalWidth = Math.floor(Number(totalWidth.replace('%', '')))
      const __totalWidth = getSum(
        _workouts.map((item) => Number(item.width.replace('%', '')))
      )
      if (_totalWidth < __totalWidth) {
        const diff = (__totalWidth - _totalWidth) / _workouts.length
        _workouts = _workouts.map((item) => {
          item.width = Number(item.width.replace('%', '')) - diff + '%'
          return item
        })
      }
    }

    const recalculateTotalWidth = getSum(
      _workouts.map((item) => Number(item.width.replace('%', '')))
    )
    setTotalGradientWidth(recalculateTotalWidth + '%')

    setWorkouts(_workouts)
  }, [props.workouts, ranges, totalWidth, setTotalGradientWidth])

  useEffect(() => {
    if (label === 'Aerobic Fitness' && showWorkoutPopup) {
      setVisibleIndex(workouts.length - 1)
    }
  }, [workouts, showWorkoutPopup, label])
  let afterRight = '0'
  if (targetCursor && target < totalPCT) {
    afterRight = 100 - (target / totalPCT) * 100 + '%'
  }
  return (
    <WorkoutContainer
      className={` ${targetCursor ? 'target-cursor' : ''}`}
      style={containerStyle || {}}
      afterRight={afterRight}
    >
      {workouts.map((workout, index) => {
        const { id } = workout
        return (
          <Popover
            trigger="click"
            placement="top"
            visible={visibleIndex === index}
            onVisibleChange={(visible) => {
              if (visible) {
                setVisibleIndex(index)
              } else if (index === visibleIndex) {
                setVisibleIndex(null)
              }
            }}
            content={
              <PopContent>
                {index > 0 && (
                  <AiOutlineLeft
                    onClick={() =>
                      visibleIndex > 0 && setVisibleIndex(visibleIndex - 1)
                    }
                  />
                )}
                <div className="popup-content">
                  <div>
                    <div className="name">
                      {workout.activity_type_shown || workout.type}
                    </div>

                    <div className="info">
                      <div>
                        <span>Time</span>
                        <br />
                        {Math.round(workout.duration / 60)}{' '}
                        {Pluralize(
                          'min',
                          Math.round(workout.duration / 60) || 1
                        )}
                      </div>
                      <div>
                        <span>Intensity</span>
                        <br />
                        {workout.intensity}
                      </div>
                    </div>
                  </div>
                  <div className="score">{workout.hsd_pct}</div>
                </div>
                {index < workouts.length - 1 && (
                  <AiOutlineRight
                    onClick={() =>
                      visibleIndex < workouts.length - 1 &&
                      setVisibleIndex(visibleIndex + 1)
                    }
                  />
                )}
              </PopContent>
            }
            key={id}
          >
            <div
              className={`workout-item ${
                visibleIndex === index ? 'active' : ''
              }`}
              style={{
                width: workout.width,
                // index === workouts.length - 1
                //   ? `calc(${workout.width} - 0.25rem)`
                //   : workout.width,
                left: workout.left
                // background:
                //   index === workouts.length - 1
                //     ? 'rgba(155,0,0,0.3)'
                //     : 'transparent'
              }}
            ></div>
          </Popover>
        )
      })}
    </WorkoutContainer>
  )
}

export function CompactLine(props) {
  const {
    score,
    rangeType,
    ranges,
    widthReference,
    data,
    hightestValue,
    id,
    label,
    workouts,
    target,
    showTarget,
    scorePercent,
    showWorkoutPopup
  } = props
  const _width = getBarPCTWidth(data[widthReference], ranges)
  const width = hightestValue > target ? '99.5%' : Math.min(_width, 99.5) + '%'
  const [totalGradientWidth, setTotalGradientWidth] = useState(width)

  const svgWidth = 'calc(100% - 0.25rem)'
  return (
    <SegmentLineContainer>
      <div className="bar-container" style={{ width: '100%' }}>
        {hightestValue > score && rangeType === 'week' && (
          <div className="stripe-line">
            <StripeLine
              svgWidth={svgWidth}
              target={target}
              hightestValue={hightestValue}
              id={id}
            />
          </div>
        )}
        <div
          className="gradient-line"
          style={{ width: totalGradientWidth }} // marginTop: '-0.1875rem'
        >
          <GradientLine
            svgWidth="calc(100vw - 1.75rem)"
            hightestValue={
              showTarget ? hightestValue : Math.max(scorePercent, 100)
            }
            id={`compact_${id}`}
            noRadius
          />
        </div>
        <div
          className="workout-line-score"
          style={{ width: '100%' }} //'calc(100% - 0.25rem)'
        >
          {workouts && rangeType === 'week' && (
            <Workouts
              totalPCT={score}
              workouts={workouts}
              label={label}
              ranges={ranges}
              totalWidth={width}
              containerStyle={{ top: 0 }}
              targetCursor={showTarget ? true : false}
              target={target}
              setTotalGradientWidth={setTotalGradientWidth}
              showWorkoutPopup={showWorkoutPopup}
            />
          )}
        </div>
      </div>
    </SegmentLineContainer>
  )
}
