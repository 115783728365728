import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Radio, Input } from 'antd-mobile'
import styled from 'styled-components'
import radioUnuseIcon from '../../../asserts/icons/radio-unuse.svg'
import radioUseIcon from '../../../asserts/icons/radio-use.svg'

const Container = styled.div`
  .adm-input-element {
    background: #fbfbfb;
    height: 52px;
    border: 1px solid #93939333;
    border-radius: 8px;
    padding: 0px 15px;
  }
  .adm-radio-custom-icon {
    line-height: normal;
    height: 21px;
  }

  .text-entry {
    padding-bottom: 16px;
    .adm-input {
      --font-size: 1rem;
      padding-top: 0px !important;
    }
    .adm-input,
    .invalid {
      border: none !important;
    }
    .invalid > .adm-input-element {
      border: 1px solid rgba(252, 46, 46, 0.5);
    }
    p,
    .invalid {
      margin-bottom: 0px;
    }
  }
`

export default function SingleChoices(props) {
  const {
    question: { choices },
    answer,
    update
  } = props

  const [answerObj, setAnswerObj] = useState({ value: null })

  useEffect(() => {
    if (answer && (!answerObj.value || answer.verify)) {
      setAnswerObj(answer.answer)
    }
  }, [answer, answerObj])

  return (
    <div className="q-form-group">
      {choices.map((choice, index) => {
        const isChecked = answerObj.value === choice.title
        const verify = _.get(answer, 'verify.choices', [])[index]
        return (
          <Container
            key={index}
            className={`q-form-item ${isChecked ? 'valid' : ''} ${
              verify ? 'invalid' : ''
            }`}
          >
            <Radio
              onChange={() => preUpdate({ value: choice.title })}
              // onClick={(e) => preUpdate({ value: choice.title })}
              checked={isChecked}
              icon={(checked) =>
                checked ? (
                  <img src={radioUseIcon} alt="" />
                ) : (
                  <img src={radioUnuseIcon} alt="" />
                )
              }
            >
              {choice.title.toString()}
            </Radio>

            {choice.allow_text_entry && (
              <div className="text-entry">
                <Input
                  placeholder="Please type here"
                  className={verify && verify.textRequired ? 'invalid' : ''}
                  value={isChecked ? answerObj.text : ''}
                  onChange={(val) =>
                    preUpdate({ value: choice.title, text: val })
                  }
                />
                {verify && verify.textRequired && (
                  <p className="invalid">Please type this input</p>
                )}
              </div>
            )}
          </Container>
        )
      })}
      {answer.verify && answer.verify.required && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </div>
  )

  function preUpdate(answerObj) {
    const newAnswer = _.assignIn(answer, {
      answer: answerObj
    })
    setAnswerObj(answerObj)
    update(newAnswer, props.question)
  }
}
