import React, {
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef
} from 'react'
import { Picker } from 'antd-mobile'
import { AiOutlineDown } from 'react-icons/ai'
import _, { get, isNumber } from 'lodash'
import styled from 'styled-components'
import classNames from 'classnames'

export const PlaceholderContainer = styled.div`
  color: #ccc;
`

const Select = (props, ref) => {
  const {
    answer = {},
    question: { options },
    update,
    disabled = false,
    placeholder = 'Please select'
  } = props

  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState(null)

  useEffect(() => {
    const an = get(answer, 'answer.value') || ''
    if (typeof an === 'string' || typeof an === 'number') {
      setValue(an)
    }
  }, [answer])

  useImperativeHandle(ref, () => {
    return {
      clearValue: () => {
        setValue(null)
      }
    }
  })

  // const value = useMemo(() => {
  //   const an = answer.answer.value
  //   if (typeof an === 'string' || typeof an === 'number') {
  //     return an
  //   }
  //   return null
  // }, [answer.answer])

  const list = options.map(({ value, label }, index) => ({
    value: value.toString(),
    label
  }))

  const title = useMemo(
    () =>
      list.find(
        (item) =>
          item.value === value ||
          (isNumber(value) && value === Number(item.value))
      )?.label,
    [list, value]
  )

  return (
    <div className="q-form-item-container">
      <div
        className={classNames('q-form-item picker-value-container', {
          invalid: answer?.verify,
          disabled
        })}
        onClick={() => {
          !disabled && setVisible(true)
        }}
      >
        {value ? (
          <div>{title}</div>
        ) : (
          <PlaceholderContainer>{placeholder}</PlaceholderContainer>
        )}
        <AiOutlineDown />
      </div>
      <Picker
        columns={[list]}
        onConfirm={([value]) => {
          setValue(value)
          update(_.assignIn(answer, { answer: { value } }), props.question)
        }}
        value={value ? [value] : []}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
      {answer?.verify && (
        <p className="invalid">
          {answer.verifyMsg || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )
}

export default forwardRef(Select)
