import React, { useState, useEffect, useRef } from 'react'

const ContainerWidthSizer = ({ children, style, onClick }) => {
  const [width, setWidth] = useState(0)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.getBoundingClientRect().width)
    }
  }, [])

  return (
    <div ref={containerRef} style={style || {}} onClick={onClick}>
      {children({ width })}
    </div>
  )
}

export default ContainerWidthSizer
