import styled from 'styled-components'
import IconSuccess from '../../../asserts/images/success.png'

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  background-color: #f1f3f6;
  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #323f4a;
  }
  img {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  p {
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
  }
`

const Success = () => {
  return (
    <Container>
      <div className="title">Success</div>
      <img src={IconSuccess} alt="" />

      <p>
        Thank you for completing onboarding. Your Apeiron Life Concierge will
        assist with all of your Healthspan Assessment details.
      </p>
      <p>We cannot wait to help you reach your health and wellness goals!</p>
    </Container>
  )
}

export default Success
