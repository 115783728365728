import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryLabel,
  VictoryLine
} from 'victory'
import LineChart from './LineChart'
import BarChart from './BarChart'

import CustomTooltip from './customTooltip'
import { calculateDataRatio } from './chartHandle'

import ContainerWidthSizer from '../../../commonComponents/ContainerWidthSizer'
import { ColorLuminance, colors as detailColors } from '../../../../utils'

const lighter = 0.3

function RangeAxisChart(props) {
  const {
    dateRange,
    metric,
    comparedMetric,
    showNumber,
    active,
    axisX,
    strokeColor,
    fontFamily,
    strokeActiveColor,
    fontSize,
    showGraphType,
    showIndex,
    setActive,
    chartPadding,
    travelData
  } = props

  const normalChart = [metric, comparedMetric].find(
    (item) => item && !item.ranges
  )

  for (const target of [metric, comparedMetric || {}]) {
    const { ranges, data, metric_key } = target
    const isCompared =
      comparedMetric && metric_key === comparedMetric.metric_key
    if (ranges) {
      let maxHistory = Math.max(...data.map((item) => item.y))
      const _ranges = ranges
        .map((item) => {
          const [min, max, color] = item
          const colorObj = detailColors.find((item) => item.key === color)
          return {
            min: min && Number(min),
            max: max && Number(max),
            color:
              colorObj &&
              (isCompared && !normalChart
                ? ColorLuminance(colorObj.value, lighter)
                : colorObj.value)
          }
        })
        .sort(
          (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
        )

      let range_ticks = _ranges.map((r, idx) => idx)
      range_ticks.push(range_ticks.length)
      const tickFormatY = (idx) => {
        const range = _ranges[idx - 1]
        if (!range) return ''
        if (range.max && range.min) {
          return `${range.min}-${range.max}`
        } else if (range.max) {
          return `< ${range.max}`
        } else {
          return `> ${range.min}`
        }
      }
      const tickLabelsYFill = ({ index }) => {
        return _ranges[index - 1] && _ranges[index - 1].color
      }

      let dataSet = []
      data.map((item) => {
        const amount = Number(item.y)
        let idx = 1
        for (const range of _ranges) {
          if (
            (!range.min || range.min <= amount) &&
            (!range.max || range.max >= amount)
          ) {
            break
          }
          idx++
        }
        const currRange = _ranges[idx - 1]
        const nextRange = ranges[idx]
        if (currRange && currRange.max && !currRange.min) {
          currRange.min = currRange.max - currRange.max
        }
        let y = idx - 1
        if (currRange && _.isNumber(item.y)) {
          if (!nextRange && !currRange.max && _.isNumber(currRange.min)) {
            if (amount !== currRange.min && maxHistory - currRange.min > 0) {
              y += (amount - currRange.min) / (maxHistory - currRange.min)
            } else {
              y = idx
            }
          } else if (currRange && currRange.max && _.isNumber(currRange.min)) {
            y += (amount - currRange.min) / (currRange.max - currRange.min)
          } else {
            y = idx
          }
          if (y < 0) {
            y = 0
          } else if (y > range_ticks[range_ticks.length - 1]) {
            y = range_ticks[range_ticks.length - 1]
          }
        }

        dataSet.push({
          x: item.x,
          value: item.y,
          y: _.isNumber(item.y) ? y : null,
          color: (currRange && currRange.color) || '#D2D2D2'
        })
        return item
      })
      let baseValue = dataSet.find((item) => item.value)
      if (baseValue) {
        dataSet = calculateDataRatio(dataSet, {
          date: baseValue.x,
          value: baseValue.value
        })
      }
      _.assign(target, {
        range_ticks,
        dataSet,
        tickFormatY,
        tickLabelsYFill
      })
    }
  }

  if (comparedMetric) {
    unifyAxisY(metric, comparedMetric, normalChart)
  }

  let lineArr = []
  let rangeCharts
  if (normalChart) {
    rangeCharts = [metric, comparedMetric].find((item) => item && item.ranges)
    lineArr = handleLineData(rangeCharts.dataSet)
  } else {
    lineArr = handleLineData(metric.dataSet)
  }

  function showNormalChart() {
    const chartProps = {
      showIndex,
      showNumber,
      dateRange,
      charts: [metric, comparedMetric], //[comparedMetric],
      active,
      setActive,
      strokeColor,
      fontFamily,
      strokeActiveColor,
      fontSize,
      chartPadding,
      travelData,
      lineArr
    }

    return (
      <>
        {showGraphType === 'line' ? (
          <LineChart {...chartProps} isCompared />
        ) : (
          <BarChart {...chartProps} isCompared />
        )}
      </>
    )
  }

  // const lineArr = rangeCharts ? handleLineData(rangeCharts.dataSet) : []

  const rangeChartProps = {
    dateRange,
    axisX,
    active,
    strokeActiveColor,
    strokeColor,
    fontFamily,
    fontSize,
    showNumber,
    setActive,
    hasNormalChart: !!normalChart,
    chartPadding,
    lineArr,
    showGraphType
  }

  return (
    <>
      {normalChart && showNormalChart()}

      {rangeCharts ? (
        <ChartContainer
          {...rangeChartProps}
          metric={rangeCharts}
          hideStoker={comparedMetric}
          isCompared={comparedMetric && comparedMetric.ranges}
        />
      ) : (
        <>
          <ChartContainer
            {...rangeChartProps}
            metric={metric}
            comparedMetric={comparedMetric}
            hideStoker
            isTooltipContainer
            containerStyle={{
              position: 'absolute',
              width: '100%',
              zIndex: 10
            }}
          />
          <ChartContainer
            {...rangeChartProps}
            metric={metric}
            comparedMetric={comparedMetric}
          />
        </>
      )}
    </>
  )
}

export default RangeAxisChart

function ChartContainer(props) {
  const {
    metric,
    comparedMetric,
    dateRange,
    axisX,
    active,
    strokeActiveColor,
    strokeColor,
    fontFamily,
    fontSize,
    showNumber,
    setActive,
    hideStoker,
    isTooltipContainer,
    containerStyle,
    hasNormalChart,
    isCompared,
    chartPadding,
    lineArr,
    showGraphType
  } = props
  const { range_ticks, dataSet, tickFormatY, tickLabelsYFill, metric_key } =
    metric

  const isLineChart = showGraphType === 'line'
  const hideScatter = hasNormalChart || isTooltipContainer
  const hasData = dataSet.filter((item) => item.value)
  let tooltipDataSet = metric.dataSet
  if (comparedMetric) {
    tooltipDataSet = _.concat(
      metric.dataSet,
      comparedMetric ? comparedMetric.dataSet : []
    ).filter((item) => item.y)
  }

  return (
    <ContainerWidthSizer style={containerStyle || {}}>
      {({ width }) => (
        <VictoryChart width={width} height={100} padding={chartPadding}>
          <VictoryAxis
            tickValues={axisX}
            tickFormat={(t) => moment(t).format('MMM')}
            name="axis_x"
            style={{
              grid: {
                stroke: hideStoker
                  ? 'transparent'
                  : (tick) =>
                      active
                        ? active.x === tick
                          ? strokeActiveColor
                          : strokeColor
                        : strokeColor,
                strokeDasharray: '6, 3'
              },
              axis: { stroke: hideStoker ? 'transparent' : strokeColor },
              tickLabels: {
                fill: 'transparent'
              }
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={range_ticks}
            tickFormat={tickFormatY}
            orientation={isCompared ? 'right' : 'left'}
            style={{
              axis: {
                stroke: isTooltipContainer ? 'transparent' : strokeColor
              },
              grid: {
                stroke: hideStoker ? 'transparent' : strokeColor,
                strokeDasharray: '6, 3'
              },
              tickLabels: {
                fill: isTooltipContainer ? 'transparent' : tickLabelsYFill,
                fontFamily,
                fontSize
              }
            }}
          />

          {comparedMetric && !isTooltipContainer && (
            <VictoryAxis
              dependentAxis
              orientation="right"
              tickValues={comparedMetric.range_ticks}
              tickFormat={comparedMetric.tickFormatY}
              style={{
                axis: {
                  stroke: isTooltipContainer ? 'transparent' : strokeColor
                },
                grid: {
                  stroke: hideStoker ? 'transparent' : strokeColor,
                  strokeDasharray: '6, 3'
                },
                tickLabels: {
                  fill: isTooltipContainer
                    ? 'transparent'
                    : comparedMetric.tickLabelsYFill,
                  fontFamily,
                  fontSize
                }
              }}
            />
          )}

          {/* {!isTooltipContainer && lineGroup()} */}
          {!hideScatter &&
            isLineChart &&
            lineArr.map((item, index) => (
              <VictoryLine
                key={`${metric_key}_${item.keyIndex}_${index}`}
                data={item.data}
                style={{
                  data: item.dataStyle
                }}
              />
            ))}

          {!hideScatter && (
            <VictoryScatter
              name="data"
              data={dataSet}
              dataComponent={
                isLineChart ? (
                  <LineScatter active={active} />
                ) : (
                  <Scatter active={active} />
                )
              }
              labelComponent={
                <VictoryLabel
                  renderInPortal={true}
                  dy={({ datum }) => {
                    const index = hasData.findIndex(({ x }) => x === datum.x)
                    if (index >= 0) {
                      return index % 2 === 0 ? -10 : 30
                    }
                    return 0
                  }}
                />
              }
              style={{
                labels: {
                  fill: ({ datum }) => datum.color,
                  lighter,
                  fontSize
                }
              }}
              labels={({ datum }) => (showNumber ? datum.value : null)}
            />
          )}
          {!hideScatter && comparedMetric && (
            <VictoryScatter
              name="data"
              data={comparedMetric.dataSet}
              dataComponent={<LineScatter active={active} />}
              style={{
                labels: {
                  fill: ({ datum }) => ColorLuminance(datum.color, lighter),
                  fontSize
                }
              }}
              labels={({ datum }) => (showNumber ? datum.value : null)}
            />
          )}
          {/* one more scatter to show tooltip */}
          {isTooltipContainer && (
            <VictoryScatter
              data={tooltipDataSet}
              size={10}
              labels={({ datum }) => (showNumber ? datum.y : null)}
              labelComponent={
                <VictoryTooltip
                  flyoutComponent={
                    <CustomTooltip
                      containerWidth={width}
                      setActive={setActive}
                      metrics={
                        comparedMetric ? [metric, comparedMetric] : [metric]
                      }
                      dateRange={dateRange}
                    />
                  }
                />
              }
              style={{
                data: {
                  fill: 'transparent'
                },
                labels: {
                  fill: 'transparent',
                  fontSize
                }
              }}
            />
          )}
        </VictoryChart>
      )}
    </ContainerWidthSizer>
  )
}

function LineScatter(props) {
  const { x, y, datum, active } = props
  return (
    <svg
      width="8"
      height="8"
      x={x - 4}
      y={y - 4}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="4"
        r="2"
        fill="white"
        stroke={datum.color}
        strokeOpacity={active ? (active.x === datum.x ? 1 : 0.5) : 1}
        strokeWidth="2"
      />
    </svg>
  )
}

export function Scatter(props) {
  const { x, y, datum, active } = props
  return (
    <svg
      width="8"
      x={x - 4}
      y={y - 4}
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="4"
        r="2"
        fill="white"
        stroke={datum.color}
        strokeOpacity={active ? (active.x === datum.x ? 1 : 0.5) : 1}
        strokeWidth="1.5"
      />
    </svg>
  )
}

function unifyAxisY(metric, compared, normalChart) {
  if (normalChart) {
    const rangeChart = metric.ranges ? metric : compared
    const { range_ticks } = rangeChart
    while (range_ticks.length < normalChart.axisY.length) {
      range_ticks.push(range_ticks.length)
    }
    while (range_ticks.length > normalChart.axisY.length) {
      const y0 = normalChart.axisY[0],
        y1 = normalChart.axisY[1],
        yn = normalChart.axisY[normalChart.axisY.length - 1]
      normalChart.axisY.push(yn + (y1 - y0))
    }
    rangeChart.range_ticks = range_ticks
    if (range_ticks[0] === 0 && normalChart.axisY[0] > 0) {
      const minAxisY = Math.min(...normalChart.axisY)
      const step = (Math.max(...normalChart.axisY) - minAxisY) / 3
      rangeChart.dataSet.forEach((item) => {
        if (item.y) {
          item.y = item.y * step + minAxisY
        }
      })
    } else {
      const ratio =
        normalChart.axisY[normalChart.axisY.length - 1] /
        range_ticks[range_ticks.length - 1]
      rangeChart.dataSet.map((item) => (item.y = item.y * ratio || null))
    }
  } else {
    const { range_ticks: t1 } = metric
    const { range_ticks: t2 } = compared
    while (t1.length < t2.length) {
      t1.push(t1.length)
    }
    while (t1.length > t2.length) {
      t2.push(t2.length)
    }
    metric.range_ticks = t1
    compared.range_ticks = t2
  }
}

function handleLineData(dataset) {
  const linesArr = []
  const dots = _.cloneDeep(dataset).filter((item) => item.y)
  for (const _i in dots) {
    const i = parseInt(_i)
    if (i < dots.length - 1) {
      const diff =
        dataset.findIndex((item) => item.x === dots[i + 1].x) -
        dataset.findIndex((item) => item.x === dots[i].x)
      linesArr.push({
        data: [dots[i], dots[i + 1]],
        keyIndex: dots[i].x,
        dataStyle: {
          stroke: dots[i + 1].color,
          strokeDasharray: diff === 1 ? 'none' : '6, 3'
        }
      })
    }
  }
  return linesArr
}
