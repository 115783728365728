/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Select } from './addition'
import { usePlacesWidget } from 'react-google-autocomplete'
// import { states } from '../introductory/survey.json'
import styled from 'styled-components'

const Container = styled.div`
  .additional-item {
    display: flex;
    flex-wrap: wrap;

    p.invalid {
      margin: 0;
    }
    .ant-input {
      width: 100%;
      font-size: 1rem;
      background-color: #fbfbfb;
      border: 1px solid rgba(147, 147, 147, 0.2);
      color: var(--adm-color-text);
      &.invalid {
        border: 1px solid rgba(252, 46, 46, 0.5);
      }
    }

    input:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .picker-value-container {
      flex-grow: 1;
      width: 100%;
      background-color: #fbfbfb;
      border: 1px solid rgba(147, 147, 147, 0.2);

      &.invalid {
        border: 1px solid rgba(252, 46, 46, 0.5);
      }
    }

    input::placeholder {
      color: #ccc;
    }
  }
`

const states = [
  {
    state: 'Alabama',
    standard: 'Ala.',
    postal: 'AL'
  },
  {
    state: 'Alaska',
    standard: 'Alaska',
    postal: 'AK'
  },
  {
    state: 'Arizona',
    standard: 'Ariz.',
    postal: 'AZ'
  },
  {
    state: 'Arkansas',
    standard: 'Ark.',
    postal: 'AR'
  },
  {
    state: 'California',
    standard: 'Calif.',
    postal: 'CA'
  },
  {
    state: 'Canal Zone',
    standard: 'C.Z.',
    postal: 'CZ'
  },
  {
    state: 'Colorado',
    standard: 'Colo.',
    postal: 'CO'
  },
  {
    state: 'Connecticut',
    standard: 'Conn.',
    postal: 'CT'
  },
  {
    state: 'Delaware',
    standard: 'Del.',
    postal: 'DE'
  },
  {
    state: 'District of Columbia',
    standard: 'D.C.',
    postal: 'DC'
  },
  {
    state: 'Florida',
    standard: 'Fla.',
    postal: 'FL'
  },
  {
    state: 'Georgia',
    standard: 'Ga.',
    postal: 'GA'
  },
  {
    state: 'Guam',
    standard: 'Guam',
    postal: 'GU'
  },
  {
    state: 'Hawaii',
    standard: 'Hawaii',
    postal: 'HI'
  },
  {
    state: 'Idaho',
    standard: 'Idaho',
    postal: 'ID'
  },
  {
    state: 'Illinois',
    standard: 'Ill.',
    postal: 'IL'
  },
  {
    state: 'Indiana',
    standard: 'Ind.',
    postal: 'IN'
  },
  {
    state: 'Iowa',
    standard: 'Iowa',
    postal: 'IA'
  },
  {
    state: 'Kansas',
    standard: 'Kan.',
    postal: 'KS'
  },
  {
    state: 'Kentucky',
    standard: 'Ky.',
    postal: 'KY'
  },
  {
    state: 'Louisiana',
    standard: 'La.',
    postal: 'LA'
  },
  {
    state: 'Maine',
    standard: 'Maine',
    postal: 'ME'
  },
  {
    state: 'Maryland',
    standard: 'Md.',
    postal: 'MD'
  },
  {
    state: 'Massachusetts',
    standard: 'Mass.',
    postal: 'MA'
  },
  {
    state: 'Michigan',
    standard: 'Mich.',
    postal: 'MI'
  },
  {
    state: 'Minnesota',
    standard: 'Minn.',
    postal: 'MN'
  },
  {
    state: 'Mississippi',
    standard: 'Miss.',
    postal: 'MS'
  },
  {
    state: 'Missouri',
    standard: 'Mo.',
    postal: 'MO'
  },
  {
    state: 'Montana',
    standard: 'Mont.',
    postal: 'MT'
  },
  {
    state: 'Nebraska',
    standard: 'Neb.',
    postal: 'NE'
  },
  {
    state: 'Nevada',
    standard: 'Nev.',
    postal: 'NV'
  },
  {
    state: 'New Hampshire',
    standard: 'N.H.',
    postal: 'NH'
  },
  {
    state: 'New Jersey',
    standard: 'N.J.',
    postal: 'NJ'
  },
  {
    state: 'New Mexico',
    standard: 'N.M.',
    postal: 'NM'
  },
  {
    state: 'New York',
    standard: 'N.Y.',
    postal: 'NY'
  },
  {
    state: 'North Carolina',
    standard: 'N.C.',
    postal: 'NC'
  },
  {
    state: 'North Dakota',
    standard: 'N.D.',
    postal: 'ND'
  },
  {
    state: 'Ohio',
    standard: 'Ohio',
    postal: 'OH'
  },
  {
    state: 'Oklahoma',
    standard: 'Okla.',
    postal: 'OK'
  },
  {
    state: 'Oregon',
    standard: 'Ore.',
    postal: 'OR'
  },
  {
    state: 'Pennsylvania',
    standard: 'Pa.',
    postal: 'PA'
  },
  {
    state: 'Puerto Rico',
    standard: 'P.R.',
    postal: 'PR'
  },
  {
    state: 'Rhode Island',
    standard: 'R.I.',
    postal: 'RI'
  },
  {
    state: 'South Carolina',
    standard: 'S.C.',
    postal: 'SC'
  },
  {
    state: 'South Dakota',
    standard: 'S.D.',
    postal: 'SD'
  },
  {
    state: 'Tennessee',
    standard: 'Tenn.',
    postal: 'TN'
  },
  {
    state: 'Texas',
    standard: 'Texas',
    postal: 'TX'
  },
  {
    state: 'Utah',
    standard: 'Utah',
    postal: 'UT'
  },
  {
    state: 'Vermont',
    standard: 'Vt.',
    postal: 'VT'
  },
  {
    state: 'Virgin Islands',
    standard: 'V.I.',
    postal: 'VI'
  },
  {
    state: 'Virginia',
    standard: 'Va.',
    postal: 'VA'
  },
  {
    state: 'Washington',
    standard: 'Wash.',
    postal: 'WA'
  },
  {
    state: 'West Virginia',
    standard: 'W.Va.',
    postal: 'WV'
  },
  {
    state: 'Wisconsin',
    standard: 'Wis.',
    postal: 'WI'
  },
  {
    state: 'Wyoming',
    standard: 'Wyo.',
    postal: 'WY'
  },
  {
    state: 'Alberta',
    standard: 'Alta.',
    postal: 'AB'
  },
  {
    state: 'British Columbia',
    standard: 'B.C.',
    postal: 'BC'
  },
  {
    state: 'Manitoba',
    standard: 'Man.',
    postal: 'MB'
  },
  {
    state: 'New Brunswick',
    standard: 'N.B.',
    postal: 'NB'
  },
  {
    state: 'Newfoundland and Labrador',
    standard: 'N.L.',
    postal: 'NL'
  },
  {
    state: 'Northwest Territories',
    standard: 'N.W.T.',
    postal: 'NT'
  },
  {
    state: 'Nova Scotia',
    standard: 'N.S.',
    postal: 'NS'
  },
  {
    state: 'Nunavut',
    standard: 'Nun.',
    postal: 'NU'
  },
  {
    state: 'Ontario',
    standard: 'Ont.',
    postal: 'ON'
  },
  {
    state: 'Prince Edward Island',
    standard: 'P.E.I.',
    postal: 'PE'
  },
  {
    state: 'Quebec',
    standard: 'Que.',
    postal: 'QC'
  },
  {
    state: 'Saskatchewan',
    standard: 'Sask.',
    postal: 'SK'
  },
  {
    state: 'Yukon',
    standard: 'Yukon',
    postal: 'YT'
  }
]

const APP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

export default function SearchMap(props) {
  const { item, update, an } = props
  // const [isCompleted, setCompleted] = useState(
  //   an && an.required ? !an.required : !!an
  // )
  const [isCompleted, setCompleted] = useState(false)
  const [answer, setAnswer] = useState({})
  const mapInformation = _.cloneDeep(item.map_information)
  const triggerInfo = _.find(mapInformation, (info) => info.trigger_key)
  _.pull(mapInformation, triggerInfo)

  useEffect(() => {
    an && setAnswer(an)
    setCompleted(an && an.required ? !an.required : !!an)
  }, [an])

  const verify = (answerObj) => {
    _.forIn([triggerInfo, ...mapInformation], (info) => {
      if (info.required && !answerObj[info.key]) {
        answerObj[info.key] = { required: true }
      }
    })
    setAnswer(_.cloneDeep(answerObj))
    update(answerObj, props.question)
  }

  const { ref } = usePlacesWidget({
    apiKey: APP_KEY,
    onPlaceSelected: (place) => {
      setCompleted(true)
      const {
        address_components,
        place_id,
        formatted_address,
        geometry,
        html_attributions
      } = place
      _.forIn(mapInformation, (info) => {
        if (info.type) {
          answer[info.key] = _.get(
            _.find(address_components, (com) =>
              _.includes(com.types, info.type)
            ),
            'short_name'
          )
        }
      })
      if (triggerInfo) {
        answer[triggerInfo.key] = ref.current.value
      }
      _.assign(answer, {
        address_components,
        place_id,
        formatted_address,
        geometry,
        html_attributions
      })
      verify(answer)
    },
    options: {
      types: ['address'],
      // Case insensitive, country code list: https://www.iban.com/country-codes
      componentRestrictions: { country: ['us', 'ca'] }
    }
  })

  const onChange = (value, key) => {
    _.unset(answer, 'required')
    answer[key] = value
    verify(answer)
  }

  return (
    <Container>
      <div className="additional-item" key="trigger-item">
        <input
          ref={ref}
          className={` ant-input q-form-item ${
            (answer && answer.required) ||
            (triggerInfo &&
              answer[triggerInfo.key] &&
              answer[triggerInfo.key].required)
              ? 'invalid'
              : ''
          }`}
          type="text"
          placeholder="Please type here"
          defaultValue={
            triggerInfo && _.isString(answer[triggerInfo.key])
              ? answer[triggerInfo.key]
              : ''
          }
          onChange={(e) => {
            onChange(e.target.value, triggerInfo.key)
          }}
          onBlur={() => setCompleted(true)}
        />
        {answer && answer.required && (
          <p className="invalid">{answer.message || 'Required'}</p>
        )}
        {triggerInfo &&
          answer[triggerInfo.key] &&
          answer[triggerInfo.key].required && (
            <p className="invalid">
              {answer[triggerInfo.key].message || 'Required'}
            </p>
          )}
      </div>
      {isCompleted &&
        mapInformation.map((info, index) => {
          return (
            <div key={`map_additional_${index} `} className="additional-item">
              {info.has_list ? (
                <Select
                  value={
                    _.isString(answer[info.key]) ? answer[info.key] : undefined
                  }
                  setValue={(val) => onChange(val, info.key)}
                  _verify={answer[info.key] && answer[info.key].required}
                  list={states.map(({ postal }) => ({
                    value: postal,
                    label: postal
                  }))}
                />
              ) : (
                <input
                  placeholder={info.title || 'Please type here'}
                  className={`ant-input q-form-item ${
                    answer[info.key] && answer[info.key].required
                      ? 'invalid'
                      : ''
                  }`}
                  // defaultValue={info.defaultValue || ''}
                  onChange={(e) => onChange(e.target.value, info.key)}
                  value={
                    _.isString(answer[info.key])
                      ? answer[info.key]
                      : info.forceValue || ''
                  }
                  disabled={info.disabled}
                />
              )}
              {answer[info.key] && answer[info.key].required && (
                <p className="invalid">
                  {answer[info.key].message || 'Required'}
                </p>
              )}
            </div>
          )
        })}
    </Container>
  )
}
