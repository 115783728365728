import React from 'react'
import styled from 'styled-components'
import { handleMetricRanges } from '../wrapper'
import { RangeColors } from '../../../utils'
import { lowerCase } from 'lodash'

const RangeBarContainer = styled.div`
  // width: 500px;
  color: #4c6072;
  .name {
    font-size: 15px;
  }
  .ranges {
    display: flex;
    margin-top: 50px;
    margin-bottom: 15px;
    .range-item {
      height: 6px;
      border: 1px solid #ffffff;
      position: relative;
      .value {
        margin-top: -6px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        span {
          font-size: 18px;
          color: #4c6072;
          font-family: Gilroy-Bold;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 22px;
          text-align: center;
          max-width: 88px;
        }
      }
      .range-area {
        font-size: 12px;
        color: #4c6072;
        text-align: center;
        width: 100%;
        margin-top: 10px;
      }
      .range-level {
        font-size: 12px;
        text-align: center;
        color: #989ca2;
      }
    }
  }
`

export function RangeBar(props) {
  const {
    ranges,
    name,
    value,
    person,
    ageScopes,
    level,
    unit,
    color,
    hideValueNumber,
    hideName,
    showRangeArea,
    showLevelOnNameRight,
    showRangeLevel,
    showUnit = true,
    colorfulText,
    rangeValueNumberPrefix
  } = props

  let _ranges = handleMetricRanges(ranges, person, ageScopes)

  return (
    <RangeBarContainer>
      <div className="ranges" style={{ marginBottom: showRangeArea ? 30 : 15 }}>
        {_ranges.map((item, index) => (
          <div
            key={index}
            className={`${item.name} range-item`}
            style={{
              width: 100 / _ranges.length + '%',
              background: item.color || '#8A969F'
            }}
          >
            {level === item.name && (
              <>
                <ValueIcon
                  color={item.color || '#8A969F'}
                  style={getPointExtraStyle(value, item)}
                  {...{
                    value,
                    unit,
                    showUnit,
                    hideValueNumber,
                    colorfulText,
                    showRangeArea,
                    rangeValueNumberPrefix
                  }}
                />
              </>
            )}

            {showRangeArea && (
              <div
                className="range-area"
                style={{ color: colorfulText ? item.color : '#4c6072' }}
              >
                {formatRangeArea(item)}
              </div>
            )}
            {showRangeLevel && <div className="range-level">{item.name} </div>}
          </div>
        ))}
      </div>
      {!hideName && (
        <div
          className="name"
          style={
            showLevelOnNameRight
              ? {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }
              : {}
          }
        >
          <span>{name}</span>
          {showLevelOnNameRight && (
            <span style={props.levelStyle || { color: RangeColors[color] }}>
              {level}
            </span>
          )}
        </div>
      )}
    </RangeBarContainer>
  )

  function formatRangeArea(range) {
    if (range.max && range.min) {
      return `${range.min}-${range.max}`
    } else if (range.max) {
      return `< ${range.max}`
    } else {
      return `> ${range.min}`
    }
  }

  function getPointExtraStyle(value, range) {
    let left = 50
    let correctionOffset = -50
    if (range.max && range.min) {
      left = ((value - range.min) / (range.max - range.min)) * 100
    }
    if (Math.round(left) === 0) {
      correctionOffset = 0
    } else if (Math.round(left) === 100) {
      correctionOffset = -100
    }
    return {
      left: left + '%',
      transform: `translateX(${correctionOffset}%)`
    }
  }
}

export function RangeBarUnitFormat(raw) {
  const unit = lowerCase(raw)
  switch (unit) {
    case 'degrees':
      return '°'
    case 'percent':
      return '%'
    case 'n a':
      return ''
    default:
      return raw
  }
}

export function ValueIcon(props) {
  return (
    <div className="value" style={props.style || {}}>
      {!props.hideValueNumber && (
        <span style={{ color: props.colorfulText ? props.color : '#4c6072' }}>
          {props.rangeValueNumberPrefix ? props.rangeValueNumberPrefix : ''}
          {props.value}
          {props.showUnit && (props.unit ? RangeBarUnitFormat(props.unit) : '')}
        </span>
      )}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="7.99994"
          r="6"
          fill="white"
          stroke={props.color}
          strokeWidth="4"
        />
      </svg>
    </div>
  )
}
