import React from 'react'
import styled from 'styled-components'
import { SectionStyle } from '../index.styled'
import EditableText from '../components/EditableText'
import { useInView } from 'react-intersection-observer'

const Container = styled.section`
  ${SectionStyle}
  position: relative;
`
const NOTES_FIELDS = {
  INTRODUCTION_NOTE: 'introduction_note'
}

export function IntroductionSection(props) {
  const { notes, visible } = props
  const note = notes.find((n) => n.pillar === NOTES_FIELDS.INTRODUCTION_NOTE)
  const { ref } = useInView({
    threshold: 1,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })
  return (
    <Container ref={ref}>
      <h1 className="ar-section-title">Introduction</h1>
      <EditableText
        value={(note && note.memo) || ''}
        placeholder={'Please type here.'}
        editing={false}
        setEditing={() => {}}
        fontStyle={{
          fontSize: '1.07rem',
          fontColor: 'rgba(112, 128, 142, 1)'
        }}
      />
    </Container>
  )
}
