/**
 * only used for onboarding logistics
 * */
import React, { useState, useEffect } from 'react'
import { InputWrapper } from './textEntry'
import { Grid } from 'antd-mobile'
import _ from 'lodash'

export default function InlineEntries(props) {
  const { question, answer, update } = props
  const [values, setValues] = useState(['', ''])

  useEffect(() => {
    const an = answer.answer.value
    if (an) {
      setValues(an)
    }
  }, [answer])

  const hasError =
    answer.verify &&
    answer.verify.items &&
    answer.verify.items.find((an) => an && (an.required || an.message))

  return (
    <>
      <Grid columns={2} gap={8}>
        {question.items.map((item, index) => (
          <Grid.Item key={index}>
            <InputWrapper
              inputProps={{
                placeholder: 'Please type here',
                value: values[index],
                onChange: (value) => {
                  const newValues = _.cloneDeep(values)
                  newValues[index] = value
                  setValues(newValues)
                  update(
                    _.assignIn(answer, { answer: { value: newValues } }),
                    question
                  )
                }
              }}
              className={`q-form-item ${
                answer.verify &&
                answer.verify.items &&
                answer.verify.items[index]
                  ? 'invalid'
                  : ''
              }`}
              unit={item.unit}
            />
          </Grid.Item>
        ))}
      </Grid>

      {hasError && (
        <p className="invalid">
          {hasError.message || 'Please type these input'}
        </p>
      )}
    </>
  )
}
