export const MembershipPlans = [
  {
    membership: 'gold',
    items: [
      <div>
        <strong>Monthly </strong>health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home</strong>, and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>125 sessions:</strong>
        <ul>
          <li>Training</li>
          <li>Yoga</li>
          <li>Nutrition</li>
          <li>Pilates</li>
          <li>Chiropratic</li>
          <li>Acupuncture</li>
          <li>Swimming</li>
          <li>Mindfulness</li>
          <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>,
      <div>Weekly monitoring and recommendations from our experts</div>,
      <div>Continuous Glucose Monitoring (CGM) Program</div>,
      <div>Microbiome Analysis Program</div>,
      <div>Micronutrient Assessment</div>,
      <div>Sleep Consultation and Brain Map</div>,
      <div>Joint Health and Performance Recovery Module</div>
      // <div>Golf Module</div>
    ]
  },
  {
    membership: 'silver',
    items: [
      <div>
        <strong>Monthly </strong>health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home</strong>, and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>100 sessions:</strong>
        <ul>
          <li>Training</li>
          <li>Yoga</li>
          <li>Nutrition</li>
          <li>Pilates</li>
          <li>Chiropratic</li>
          <li>Acupuncture</li>
          <li>Swimming</li>
          <li>Mindfulness</li>
          <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>,
      <div>Weekly monitoring and recommendations from our experts</div>
    ]
  },
  {
    membership: 'bronze',
    items: [
      <div>
        <strong>Monthly </strong>health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home</strong>, and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>25 sessions:</strong>
        <ul>
          <li>Training</li>
          <li>Yoga</li>
          <li>Nutrition</li>
          <li>Pilates</li>
          <li>Chiropratic</li>
          <li>Acupuncture</li>
          <li>Swimming</li>
          <li>Mindfulness</li>
          <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>
    ]
  },
  {
    membership: 'remote',
    items: [
      <div>
        <strong>Quarterly </strong>health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Basic App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Quarterly coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>remote</strong> progress assessments
      </div>,
      <div>
        <strong>Virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>15 VIRTUAL sessions:</strong>
        <ul>
          <li>Training</li>
          <li>Yoga</li>
          <li>Nutrition</li>
          <li>Mat Pilates</li>
          <li>Mindfulness</li>
        </ul>
      </div>
    ]
  },
  {
    membership: 'remote lite',
    items: [
      <div>
        <strong>Quarterly </strong>health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Basic App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Quarterly coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>
    ],
    noExtra: true
  }
]
