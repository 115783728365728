import React from 'react'
import styled from 'styled-components'
import Lock from '../../asserts/images/lock.png'

const Container = styled.div`
  width: 100%;
  text-align: center;
  height: 100vh;
  font-size: 1rem;
  line-height: 150%;
  color: #8596a4;
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
  }
  img {
    width: 4em;
    margin-bottom: 1.5em;
  }
`

export default function LockStatus(props) {
  return (
    <Container>
      <div>
        <img src={Lock} alt="lock" />
        <p>
          These features are available for our <br /> core members. If you are
          interested in <br /> joining our 1 year membership,
          <br /> please let us know.
        </p>
      </div>
    </Container>
  )
}
