import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import { SectionStyle } from '../index.styled'

const Wrapper = styled.div`
  position: relative;
  .view-icon {
    position: absolute;
    right: ${(props) => (props.isSection ? '200px' : 0)};
    top: ${(props) =>
      props.isSection ? (props.type === 'hide' ? '45px' : '50px') : 0};
  }
  .just-title {
    border: none !important;
    padding: 0 !important;
    height: auto;
    color: #e2e5e9 !important;
  }
`

const SectionContainer = styled.section`
  ${SectionStyle}

  padding-top: 12px;
  padding-bottom: 12px;

  .ar-section-title {
    margin-bottom: 10px;
  }
  .just-title {
    border: none;
  }
`

const HideWrap = forwardRef(function HideWrap(props, ref) {
  const [type, setType] = useState('show')
  const { name, title, isSection, status, id } = props
  useEffect(() => {
    if (status.includes(name)) {
      setType('hide')
    }
  }, [])
  return (
    <Wrapper isSection={isSection} type={type} id={id} ref={ref}>
      {type === 'show' && props.children}
      {type === 'hide' &&
        (isSection ? (
          <SectionContainer>
            <h1 className="ar-section-title just-title">{title || name}</h1>
          </SectionContainer>
        ) : (
          <div className="sub-title just-title">{title || name}</div>
        ))}
    </Wrapper>
  )
})

export default HideWrap
