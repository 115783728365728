import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Divider, Toast } from 'antd-mobile'
import { getEmailDetailByHash } from '../../../api/summaryEmail'
import Information from '../summaryEmail/components/information'
import EmailBG from '../../../asserts/images/email-bg.png'
import { getMeByHash } from '../../../api'
import { userType } from '../../../utils/constant'
import LockStatus from '../../commonComponents/lockStatus'
import { SummaryContainer } from '../summaryEmail'

export default function SummaryEmailBackup(props) {
  const { id, hash } = useParams()
  const [person, setPerson] = useState(null)
  const [body, setBody] = useState(null)

  useEffect(() => {
    const initial = async () => {
      const loading = Toast.show({
        icon: 'loading',
        content: 'loading...'
      })
      const result = await getEmailDetailByHash(id, hash)
      const { person } = await getMeByHash(hash)
      // console.log({ ...result })
      setPerson(person)
      setBody({ ...result.body })
      loading.close()
    }
    initial()
  }, [id, hash])

  const isPartner = person && person.profile.account_type === userType.Partners
  if (isPartner) return <LockStatus />
  return (
    <SummaryContainer className="backup-style">
      {body && (
        <>
          <img className="bg-image" src={EmailBG} alt="background" />
          {renderOverview()}
          {!body.deleted_section.rejuvenation && renderRejuvenation()}
          {!body.deleted_section.exercise && renderExercise()}
          {!body.deleted_section.nutrition &&
            body.nutrition.length > 0 &&
            renderNutrition()}
        </>
      )}
    </SummaryContainer>
  )

  function renderOverview() {
    const { overview, from, to } = body
    return (
      <div className="section-container">
        <div className="summary-date">
          {`${from && moment(from).format('MMM DD')} - ${
            to && moment(to).format('MMM DD')
          }`}
        </div>
        <h3 className="title Overview">{overview.title}</h3>
        <Information
          status="Opened"
          initialValue={overview.summary_introduction}
        />

        {overview.rejuvenation_chart_url && (
          <>
            <img
              src={overview.rejuvenation_chart_url}
              alt="rejuvenation_chart_url"
            />
            <Information
              status="Opened"
              initialValue={overview.rejuvenation_recommendation}
            />
          </>
        )}
        {overview.exercise_chart_url && (
          <>
            <img src={overview.exercise_chart_url} alt="exercise_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.exercise_recommendation}
            />
          </>
        )}
        {overview.weight_chart_url && (
          <>
            <img src={overview.weight_chart_url} alt="weight_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.weight_recommendation}
            />
          </>
        )}
        {overview.nutrition_chart_url && (
          <>
            <img src={overview.nutrition_chart_url} alt="nutrition_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.nutrition_recommendation}
            />
          </>
        )}
      </div>
    )
  }

  function renderRejuvenation() {
    const { rejuvenation, deleted_section } = body
    return (
      <div className="section-container">
        <h3 className="Rejuvenation">Rejuvenation</h3>
        {!deleted_section.rejuvenation_quality && (
          <>
            <h4 className="sub-title">Quality of sleep</h4>
            <Information
              status="Opened"
              initialValue={rejuvenation.recommendation}
            />
            <img src={rejuvenation.sleep_chart_url} alt="sleep_chart_url" />
            <div className="extra-information">
              <h5>Analyzing Your Sleep Data</h5>
              <p>
                To determine how well you slept week after week, we take into
                account your average heart rate variability (HRV), sleep
                duration, and bedtime.
              </p>
            </div>
            <div style={{ marginBottom: 20 }}>
              <Information
                status="Opened"
                initialValue={rejuvenation.custom_text}
              />
            </div>
          </>
        )}

        {rejuvenation.sleep_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <Information status="Opened" initialValue={item.custom_text} />
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  function renderExercise() {
    const { exercise, deleted_section } = body
    return (
      <div className="section-container">
        <h3 className="Exercise">Exercise</h3>
        {!deleted_section.exercise_target && (
          <>
            <h4 className="sub-title">Apeiron Life Training Target</h4>
            <Information
              status="Opened"
              initialValue={exercise.heart_recommendation}
            />
            <img src={exercise.heart_chart_url} alt="heart_chart_url" />
            <div className="extra-information">
              <h5>What is my Aerobic Training Target?</h5>
              <p>
                For optimal health benefits, we recommend you aim for at least
                150 minutes per week of moderate-intensity activity (zone 2 -
                zone 3; 60-75% max heart rate). This includes running, cycling,
                swimming, and hiking.
              </p>
            </div>
          </>
        )}

        <Information
          status="Opened"
          initialValue={exercise.heart_custom_text}
        />
        <Divider />

        {!deleted_section.exercise_workout && (
          <>
            <h4 className="sub-title">Workout</h4>
            <Information
              status="Opened"
              initialValue={exercise.workout_recommendation}
            />
            <img src={exercise.workout_chart_url} alt="heart_chart_url" />
            <Information
              status="Opened"
              initialValue={exercise.workout_custom_text}
            />
            {exercise.activity_goals.length > 0 &&
              !deleted_section.exercise_workout && <Divider />}
          </>
        )}
        {exercise.activity_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <Information status="Opened" initialValue={item.custom_text} />
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  function renderNutrition() {
    const { nutrition, nutrition_recommendation } = body
    return (
      <div className="section-container">
        <h3 className="Nutrition">Nutrition</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information
            status="Opened"
            initialValue={nutrition_recommendation}
          />
        </div>
        {nutrition.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }
}
