import React, { useState } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import { SectionStyle } from '../index.styled'
import EditableText from '../components/EditableText'
import { HSDScoreConfig, HSDSpiderGraph } from '../components/HSDSpiderGraph'
import { HSDTriangleChart } from '../components/HSDTriangleGraph'
import { FlexBox } from '../../commonComponents/FlexBox'
import { useInView } from 'react-intersection-observer'
import { compactMap } from '../../../utils/array+extensions'

const Container = styled.section`
  ${SectionStyle}

  .summary-text-container {
    padding: 36px;
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(230, 233, 242, 1);
    margin-bottom: 24px;

    .note {
      flex-grow: 1;
    }

    button {
      margin-left: 40px;
    }
  }
`

const NOTES_FIELDS = {
  HSD_SUMMARY_NOTE: 'hsd_summary_note'
}

export function HSDSummarySection(props) {
  const { data, notes, handleCommendSave, person, visible } = props
  const [noteEditing, setNoteEditing] = useState(false)
  const note = notes.find((n) => n.pillar === NOTES_FIELDS.HSD_SUMMARY_NOTE)
  const graphData = get(data, '0.snapshot', {})
  const membership = get(person, 'profile.membership')
  const { ref } = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })
  return (
    <Container ref={ref}>
      <h1 className="ar-section-title">Health Span domain Summary</h1>
      <div className="summary-text-container">
        <FlexBox justifyContent="space-between">
          <EditableText
            className={'note'}
            value={(note && note.memo) || '-'}
            editing={noteEditing}
            setEditing={setNoteEditing}
            fontStyle={{
              fontColor: 'rgba(76, 96, 114, 1)'
            }}
            onSave={(text) => {
              handleCommendSave(text, NOTES_FIELDS.HSD_SUMMARY_NOTE)
            }}
          />
        </FlexBox>
      </div>
      <div className="ar-section-group-title">Physical Assessment Results</div>
      {membership === 'basic' ? (
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <HSDTriangleChart {...graphData} />
        </div>
      ) : (
        <div
          style={{
            margin: '20px 0'
          }}
        >
          <HSDSpiderGraph {...graphData} />
        </div>
      )}
      <KeyWinsOrImprovement {...{ graphData, notes }} />
    </Container>
  )
}

const KeyWinsOrImprovementContainer = styled.div`
  border: 1px solid #e6e9f2;
  margin-top: 36px;
  background-color: rgba(250, 250, 250, 1);
  .notes-half-container {
    padding: 16px 24px;
    color: rgba(50, 63, 74, 1);
    /* &:first-child {
      border-right: 1px solid rgba(230, 233, 242, 1);
    } */
    .notes-title {
      padding: 16px 0;
      font-size: 1.43rem;
      line-height: 1.5;
      letter-spacing: 0.3px;

      border-bottom: 1px solid rgba(230, 233, 242, 1);
    }

    .single-note-container {
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .note-pillar-title {
        font-size: 1.14rem;
        line-height: 1.5;
      }

      .domain-score {
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
`

const DomainName = {
  muscular_fitness: 'Muscle Fitness',
  joint_fitness: 'Movement Quality',
  balance: 'Balance',
  cognitive_health: 'Cognitive Health',
  body_composition: 'Body Composition',
  aerobic_fitness: 'Aerobic Fitness'
}

const KeyWinsOrImprovement = (props) => {
  const { notes } = props

  const domains = Object.keys(props.graphData).map((domainKey) => {
    const domain = props.graphData[domainKey]
    const score = get(domain, 'score_manual.value') || get(domain, 'score_auto')
    const config = score && HSDScoreConfig(score)
    return {
      color: config ? config.color : 'grey',
      score: score,
      key: domainKey
    }
  })

  const keyWinNotes = compactMap(
    ['hsd_key_wins_1_note', 'hsd_key_wins_2_note', 'hsd_key_wins_3_note'],
    (noteKey) => notes.find((n) => n.pillar === noteKey)
  )

  const keyImprovementNotes = compactMap(
    [
      'hsd_key_improvement_1_note',
      'hsd_key_improvement_2_note',
      'hsd_key_improvement_3_note'
    ],
    (noteKey) => notes.find((n) => n.pillar === noteKey)
  )

  return (
    <KeyWinsOrImprovementContainer>
      <div className="notes-half-container">
        <div className="notes-title">Key Wins:</div>
        {keyWinNotes.map((note, index) => {
          const domain = domains.find((d) => d.key === note.memo)
          return (
            <div className="single-note-container" key={index}>
              <div className="note-pillar-title">{DomainName[domain.key]}</div>
              <div
                className="domain-score"
                style={{
                  color: (domain && domain.color) || 'grey'
                }}
              >
                {(domain && domain.score) || '-'}
              </div>
            </div>
          )
        })}
      </div>
      <div className="notes-half-container">
        <div className="notes-title">Area of Improvement:</div>
        {keyImprovementNotes.map((note, index) => {
          const domain = domains.find((d) => d.key === note.memo)
          return (
            <div className="single-note-container" key={index}>
              <div className="note-pillar-title">{DomainName[domain.key]}</div>
              <div
                className="domain-score"
                style={{
                  color: (domain && domain.color) || 'grey'
                }}
              >
                {(domain && domain.score) || '-'}
              </div>
            </div>
          )
        })}
      </div>
    </KeyWinsOrImprovementContainer>
  )
}
