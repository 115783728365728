import React from 'react'
import _, { capitalize } from 'lodash'
import styled from 'styled-components'
import { RangeColors } from '../../../utils'

import Left from '../../../asserts/images/physical-assessment/balance-l.svg'
import Middle from '../../../asserts/images/physical-assessment/balance-m.svg'
import Right from '../../../asserts/images/physical-assessment/balance-r.svg'

import QuietStandImage from '../../../asserts/images/physical-assessment/ic-quiet-stand.png'
import SingleLegStanceImage from '../../../asserts/images/physical-assessment/ic-single-let-stance.svg'
import SingleLegSquatImage from '../../../asserts/images/physical-assessment/illustration.svg'

import AsymmetryChart from '../components/AsymmetryChart'
import { SectionStyle } from '../index.styled'
import { MovementMetricCard } from './MovementQualitySection'
import classNames from 'classnames'
import { ScoreColorGroup } from '../components/Widgets'
import HideWrap from '../components/HideWrap'
import HealthCard from '../components/HealthCard'
import Information from '../components/Information'
import { Divider } from 'antd-mobile'
import { useInView } from 'react-intersection-observer'
import { RenderImageUrl } from '../wrapper'

const Container = styled.section`
  ${SectionStyle}

  overflow: hidden;

  .single-leg-stance-status-list {
    color: #4c6072;
    margin-top: 36px;
    .row {
      height: 64px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6e9f2;
      & > :first-child {
        width: 28%;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    /* & > div {
      border-bottom: 1px solid #e6e9f2;
      height: 64px;
      &:last-child {
        border-bottom: none;
      }
    } */
    .status {
      padding: 5px 12px;
      border-radius: 6px;
      color: white;
      font-weight: 600;
    }
    .optimal {
      background-color: green;
    }
  }
`

const BalanceContainer = styled.div`
  color: #4c6072;
  margin-bottom: 90px;
  .range-list {
    margin-bottom: 22px;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(50, 58, 70, 0.5);
    &.center {
      display: block;
      text-align: center;
      padding: 0;
      margin-left: -24px;
      margin-top: 24px;
      li {
        display: inline-block;
      }
    }
    li {
      padding-left: 24px;
    }
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
  .composite-score-container {
    background: rgba(38, 67, 130, 0.05);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-size: 1.14rem;
    margin-bottom: 17px;
    font-weight: 600;
    .score {
      color: #0d2350;
      font-family: Gilroy;
      font-weight: 700;
      text-align: right;
      font-size: 1.43rem;

      .previous {
        font-size: 1rem;
        font-weight: 500;
        color: #8596a4;
        width: 80px;
      }
    }
    .level {
      font-family: 'Gilroy';
      font-size: 1.07rem;
    }
    .previous {
      font-family: 'Gilroy';
      font-size: 15px;
      color: #4c6072;
    }
  }
  .balance-layout {
    margin-top: 50px;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    .name {
      /* margin: 30px 0; */
      max-width: 140px;
    }
    .image {
      position: relative;
      & > img {
        width: 75px;
        margin-left: 40px;
      }
      .values {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 136%;
        top: 67px;
        div {
          background: #f4f6f9;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          line-height: 32px;
          font-size: 0.86rem;
          color: #0d2350;
          font-family: Gilroy;
          font-weight: 700;
        }
      }
    }
  }
  .asymmetry-chart-container {
    position: relative;

    .name {
      font-size: 0.86rem;
      font-weight: 600;
    }

    .chart {
      margin-left: -40px;
      margin-right: -40px;
    }
  }
  .chart-container {
    position: relative;
    /* padding-bottom: 160px; */
    margin: 0 -60px;
    & > div {
      position: absolute;
    }
    .label {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      text-transform: uppercase;
      .value {
        font-size: 24px;
      }
    }
  }
`

const { red, yellow, green } = RangeColors
const Tips = [
  {
    name: 'Optimal',
    color: green
  },
  {
    name: 'Concern',
    color: yellow
  },
  {
    name: 'At risk',
    color: red
  }
]

export function BalanceSection(props) {
  const {
    ageScopes,
    person,
    hideSectionHandle,
    hideSection,
    arDetail: { notes, status },
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    dataList,
    compareData,
    view,
    loginRole,
    data: {
      Composit_Reach_Distance_Left,
      Composit_Reach_Distance_Right,
      anterior_reach_left,
      anterior_reach_right,
      postero_medial_reach_left,
      postero_medial_reach_right,
      postero_lateral_reach_left,
      postero_lateral_reach_right,
      anterior_reach_diff,
      postero_medial_reach_diff,
      postero_lateral_reach_diff,
      eyes_open_lt_10_left,
      eyes_closed_lt_10_left,
      eyes_open_lt_10_right,
      eyes_closed_lt_10_right,
      loss_of_balance_left,
      knee_collapses_inward_left,
      loss_of_balance_right,
      knee_collapses_inward_right,
      mean_force_asymmetry,
      quiet_stand_total_excursion,
      quiet_stand_total_excursion_asymmetry,
      single_leg_stance_asymmetry,
      single_leg_stance_total_excursion_left,
      single_leg_stance_total_excursion_right
    },
    visible,
    squatImage
  } = props

  const data = dataList.find((item) => item.name === 'Balance') || {}
  const compare = compareData.find((item) => item.name === 'Balance') || {}

  const balance = notes.find((item) => item.pillar === 'balance')

  // get value by ui_version
  const _Composit_Reach_Distance_Left = Composit_Reach_Distance_Left.value
  const _Composit_Reach_Distance_Right = Composit_Reach_Distance_Right.value

  const metrics = [
    {
      name: 'Quiet Stand',
      image: QuietStandImage,
      imageStyle: {
        width: 140
      },
      bars: [
        {
          ...mean_force_asymmetry,
          name: 'Mean Force Asymmetry'
        },
        {
          ...quiet_stand_total_excursion,
          name: 'Total Excursion'
        }
      ],
      asymmetry: {
        ...quiet_stand_total_excursion_asymmetry
      },
      asymmetryText: 'Total Excursion Asymmetry'
    },
    {
      name: 'Single leg stance',
      image: SingleLegStanceImage,
      bars: [
        {
          ...single_leg_stance_total_excursion_right,
          name: 'Total Excursion Right'
        },
        {
          ...single_leg_stance_total_excursion_left,
          name: 'Total Excursion Left'
        }
      ],
      asymmetry: {
        ...single_leg_stance_asymmetry
      },
      asymmetryText: 'Total Excursion Asymmetry'
    }
  ]

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })

  return (
    <Container ref={ref}>
      <h1 className="ar-section-title">Balance</h1>
      <HideWrap
        name="Balance - summary"
        title="summary"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <h3 className="ar-section card-title">Summary</h3>
        <HealthCard
          {...data}
          compare={compare}
          save={save}
          loginRole={loginRole}
          status={view === 'Draft' ? 'Draft' : 'Opened'}
          onChangeHandle={onScoreChangeHandle}
          disEdit={status === 1 || view === 'Preview'}
          cancel={cancelEdit}
        />
        {balance && balance.memo && (
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={balance.memo}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'balance', status)
            }
            updateEditingCount={updateEditingCount}
          />
        )}
      </HideWrap>

      <Divider />
      <HideWrap
        name="Balance - Movement Efficiency Y Balance"
        title="Movement Efficiency Y Balance"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <h3 className="ar-section-card-title">Movement Efficiency Y Balance</h3>
        <BalanceContainer>
          <div className="composite-score-container">
            <div style={{ width: 130 }}>
              Composite Score Left
              <div
                className="level"
                style={{
                  color: RangeColors[Composit_Reach_Distance_Left.color]
                }}
              >
                {Composit_Reach_Distance_Left.level}
              </div>
            </div>
            <div
              className="score"
              style={{
                color: RangeColors[Composit_Reach_Distance_Left.color]
              }}
            >
              {_.isNumber(_Composit_Reach_Distance_Left)
                ? _Composit_Reach_Distance_Left
                : '--'}

              {Composit_Reach_Distance_Left.previous && (
                <div className="previous">
                  Previous Score – {Composit_Reach_Distance_Left.previous}
                </div>
              )}
            </div>
          </div>
          <div className="composite-score-container">
            <div style={{ width: 130 }}>
              Composite Score Right
              <div
                className="level"
                style={{
                  color: RangeColors[Composit_Reach_Distance_Right.color]
                }}
              >
                {Composit_Reach_Distance_Right.level}
              </div>
            </div>
            <div
              className="score"
              style={{
                color: RangeColors[Composit_Reach_Distance_Right.color]
              }}
            >
              {_.isNumber(_Composit_Reach_Distance_Right)
                ? _Composit_Reach_Distance_Right
                : '--'}

              {/* {Composit_Reach_Distance_Right.previous && ( */}
              <div className="previous">Previous Score – 58</div>
              {/* )} */}
            </div>
          </div>
          <div className="balance-layout">
            <div className="image">
              <img src={Left} alt="placeholder" />
              <div className="values">
                <div>
                  {_.isNumber(anterior_reach_right)
                    ? anterior_reach_right
                    : '--'}
                </div>
                <div>
                  {_.isNumber(anterior_reach_left) ? anterior_reach_left : '--'}
                </div>
              </div>
            </div>
            <div className="asymmetry-chart-container">
              <div className="name">Anterior Reach Difference (%)</div>
              <div className="chart">
                <AsymmetryChart
                  metric={anterior_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />
              </div>
            </div>
          </div>
          <div className="balance-layout">
            <div className="image">
              <img src={Middle} alt="placeholder" />
              <div className="values">
                <div>
                  {_.isNumber(postero_medial_reach_right)
                    ? postero_medial_reach_right
                    : '--'}
                </div>
                <div>
                  {_.isNumber(postero_medial_reach_left)
                    ? postero_medial_reach_left
                    : '--'}
                </div>
              </div>
            </div>
            <div className="asymmetry-chart-container">
              <div className="name">Posterior Medial Reach Difference (%)</div>
              <div className="chart">
                <AsymmetryChart
                  metric={postero_medial_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />
              </div>
            </div>
          </div>
          <div className="balance-layout">
            <div className="image">
              <img src={Right} alt="placeholder" />
              <div className="values">
                <div>
                  {_.isNumber(postero_lateral_reach_right)
                    ? postero_lateral_reach_right
                    : '--'}
                </div>
                <div>
                  {_.isNumber(postero_lateral_reach_left)
                    ? postero_lateral_reach_left
                    : '--'}
                </div>
              </div>
            </div>
            <div className="asymmetry-chart-container">
              <div className="name">Posterior Lateral Reach Difference (%)</div>
              <div className="chart">
                <AsymmetryChart
                  metric={postero_lateral_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />
              </div>
            </div>
          </div>

          <ul className="range-list center">
            {Tips.map((item, index) => (
              <li key={index}>
                <span style={{ background: item.color }} /> {item.name}
              </li>
            ))}
          </ul>
        </BalanceContainer>
      </HideWrap>
      <Divider />
      <ScoreColorGroup domain={'balance'} />
      <HideWrap
        name="Balance - Quiet Stand"
        title="Quiet Stand"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <MovementMetricCard
          height={'400px'}
          nameMarginTop={'0px'}
          {...metrics[0]}
          {...{
            person,
            ageScopes
          }}
        />
      </HideWrap>
      <Divider />
      <HideWrap
        name="Balance - Single Leg Stance"
        title="Single Leg Stance"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <MovementMetricCard
          height={'400px'}
          nameMarginTop={'0px'}
          {...metrics[1]}
          {...{
            person,
            ageScopes
          }}
        />
      </HideWrap>
      <div className="single-leg-stance-status-list">
        <div>
          <div className="row">
            <span>Side</span>
            <strong>Balance Time Eyes Open</strong>
          </div>
          <div className="row">
            <span>Left</span>
            <span
              className={classNames('status')}
              style={{
                backgroundColor:
                  RangeColors[eyes_open_lt_10_left.color] || 'lightgray'
              }}
            >
              {eyes_open_lt_10_left.level
                ? capitalize(eyes_open_lt_10_left.level)
                : '--'}
            </span>
          </div>
          <div className="row">
            <span>Right</span>
            <span
              className={classNames('status')}
              style={{
                backgroundColor:
                  RangeColors[eyes_open_lt_10_right.color] || 'lightgray'
              }}
            >
              {eyes_open_lt_10_right.level
                ? capitalize(eyes_open_lt_10_right.level)
                : '--'}
            </span>
          </div>
        </div>

        <div
          style={{
            marginTop: 29
          }}
        >
          <div className="row">
            <span>Side</span>
            <strong>Balance Time Eyes Closed</strong>
          </div>
          <div className="row">
            <span>Left</span>
            <span
              className={classNames('status')}
              style={{
                backgroundColor:
                  RangeColors[eyes_closed_lt_10_left.color] || 'lightgray'
              }}
            >
              {eyes_closed_lt_10_left.level
                ? capitalize(eyes_closed_lt_10_left.level)
                : '--'}
            </span>
          </div>
          <div className="row">
            <span>Right</span>
            <span
              className={classNames('status')}
              style={{
                backgroundColor:
                  RangeColors[eyes_closed_lt_10_right.color] || 'lightgray'
              }}
            >
              {eyes_closed_lt_10_right.level
                ? capitalize(eyes_closed_lt_10_right.level)
                : '--'}
            </span>
          </div>
        </div>
      </div>
      <HideWrap
        name="Balance - Single Leg Squat"
        title="Single Leg Squat"
        status={hideSection}
        view={view}
      >
        <div
          style={{
            marginTop: 52
          }}
        >
          <div
            style={{
              marginBottom: 21,
              fontSize: 20,
              letterSpacing: 0.3,
              color: 'rgba(50, 63, 74, 1)'
            }}
          >
            Single leg Squat
          </div>
          <RenderImageUrl
            url={squatImage || SingleLegSquatImage}
            width={'100%'}
            height={180}
          />
          <div className="single-leg-stance-status-list">
            <div>
              <div className="row">
                <span>Side</span>
                <strong>Loss of balance</strong>
              </div>
              <div className="row">
                <span>Left</span>
                <span
                  className={classNames('status')}
                  style={{
                    backgroundColor:
                      RangeColors[loss_of_balance_left.color] || 'lightgray'
                  }}
                >
                  {loss_of_balance_left.level
                    ? capitalize(loss_of_balance_left.level)
                    : '--'}
                </span>
              </div>
              <div className="row">
                <span>Right</span>
                <span
                  className={classNames('status')}
                  style={{
                    backgroundColor:
                      RangeColors[loss_of_balance_right.color] || 'lightgray'
                  }}
                >
                  {loss_of_balance_right.level
                    ? capitalize(loss_of_balance_right.level)
                    : '--'}
                </span>
              </div>
            </div>

            <div
              style={{
                marginTop: 29
              }}
            >
              <div className="row">
                <span>Side</span>
                <strong>Knee collapses inward</strong>
              </div>
              <div className="row">
                <span>Left</span>
                <span
                  className={classNames('status')}
                  style={{
                    backgroundColor:
                      RangeColors[knee_collapses_inward_left.color] ||
                      'lightgray'
                  }}
                >
                  {knee_collapses_inward_left.level
                    ? capitalize(knee_collapses_inward_left.level)
                    : '--'}
                </span>
              </div>
              <div className="row">
                <span>Right</span>
                <span
                  className={classNames('status')}
                  style={{
                    backgroundColor:
                      RangeColors[knee_collapses_inward_right.color] ||
                      'lightgray'
                  }}
                >
                  {knee_collapses_inward_right.level
                    ? capitalize(knee_collapses_inward_right.level)
                    : '--'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </HideWrap>
    </Container>
  )
}
