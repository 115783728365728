import React, { useState, useEffect } from 'react'
import { DatePicker } from 'antd-mobile'
import { AiOutlineClockCircle } from 'react-icons/ai'
import moment from 'moment'
import _ from 'lodash'

const minDate = new Date(1900, 0, 1)

export default function DateControl(props) {
  const { question, answer, update } = props || {}

  const [value, setValue] = useState(null)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (answer && !value) {
      setValue(answer.answer.value)
    }
  }, [answer, value])

  return (
    <>
      <div
        className={`q-form-item picker-value-container ${
          answer && answer.verify ? 'invalid' : ''
        } `}
        onClick={() => {
          setVisible(true)
        }}
      >
        <div>
          {value ? moment(value).format('YYYY-MM-DD') : 'Please select a date'}
        </div>
        <AiOutlineClockCircle />
      </div>
      {
        <DatePicker
          onConfirm={(val) => {
            const valueString = moment(val).format('YYYY-MM-DD')
            const newAnswer = _.assignIn(answer, {
              answer: { value: valueString }
            })
            setValue(valueString)
            update(newAnswer, question)
          }}
          visible={visible}
          min={minDate}
          onClose={() => {
            setVisible(false)
          }}
        />
      }
      {answer && answer.verify && <p className="invalid">Required </p>}
    </>
  )
}
