import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { getInterpolateColor } from '../../../../utils'
import frontBg from '../../../../asserts/images/dial-plat.png'

const Container = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 0;
  }
  .progress-circle {
    .progress-circle-trail {
      stroke-dashoffset: 0;
      transform-origin: 0px 0px;
      transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s,
        stroke 0.3s ease 0s, strokeWidth 0.06s ease 0.3s, opacity 0.3s ease 0s;
      fill-opacity: 0;
      stroke: #0000000a;
    }
    .progress-circle-path {
      transform-origin: 0px 0px;
      transition: stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s,
        stroke ease 0s, strokeWidth ease 0.3s, opacity ease 0s;
      fill-opacity: 0;
    }
  }
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 1.5rem;
    line-height: 1;
    white-space: normal;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .back-range {
    width: 100%;
    padding-top: 100%;
    background: #e9ebf3;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 100%;
    &:before {
      content: '';
      display: block;
      background: #ffffff;
      width: 80%;
      padding-top: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
  }
  .front-bg {
    background-size: 94% 94%;
    background-position: center;
    background-image: url(${frontBg});
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    padding-top: 100%;
    top: 0;
    right: 0;
  }
`

const colorDictionary = {
  blue: ['#BACCDD', '#8BB7E2', '#6FAAE5', '#549EE6', '#3991E8'],
  green: ['#3991E8', '#74AC90', '#A7C33F', '#A7C33E', '#A4C243'],
  yellow: ['#A4C243', '#CCC326', '#F2C407', '#F3C407', '#F4C50A']
}

const getRingColors = (percent) => {
  if (!_.isNumber(percent)) {
    return colorDictionary.blue
  }
  if (percent <= 100) {
    return colorDictionary.blue
  } else if (percent <= 200) {
    return colorDictionary.green
  } else {
    return colorDictionary.yellow
  }
}

export default function ProgressCircle(props) {
  const { percent } = props
  const { points, colors } = getPathPoints(percent, getRingColors(percent))
  const len = points.length
  const _percent = percent || 0
  const textColor = getInterpolateColor(
    [
      { color: colors[Math.max(len - 1, 0)], offset: 0 },
      { color: colors[len], offset: 1 }
    ],
    (_percent - Math.floor(_percent / 25) * 25) / 25
  )

  return (
    <Container>
      <div className="back-range"></div>
      <svg
        className="progress-circle"
        viewBox="0 0 100 100"
        role="presentation"
      >
        {points.map((point, index) => {
          const [[x1, y1], [x2, y2]] = point
          return (
            <path
              key={index}
              d={`M${x1} ${y1} A46 46 0 0 1 ${x2} ${y2}`}
              fill="none"
              strokeWidth="10"
              stroke={`url(#linear_gradient_${index + 1})`}
              strokeLinecap="round"
            />
          )
        })}
        {new Array(Math.ceil(points.length / 4)).fill().map((_, index) => (
          <defs key={index} name="linear_gradient_">
            {colors[index * 4 + 1] && (
              <linearGradient
                id={`linear_gradient_${index * 4 + 1}`}
                gradientUnits="userSpaceOnUse"
                x1="50"
                y1="0"
                x2="100"
                y2="50"
              >
                <stop offset="0.167924" stopColor={colors[index * 4]} />
                <stop offset="0.861674" stopColor={colors[index * 4 + 1]} />
              </linearGradient>
            )}

            {colors[index * 4 + 2] && (
              <linearGradient
                id={`linear_gradient_${index * 4 + 2}`}
                gradientUnits="userSpaceOnUse"
                x1="100"
                y1="50"
                x2="50"
                y2="100"
              >
                <stop offset="0.167924" stopColor={colors[index * 4 + 1]} />
                <stop offset="0.861674" stopColor={colors[index * 4 + 2]} />
              </linearGradient>
            )}
            {colors[index * 4 + 3] && (
              <linearGradient
                id={`linear_gradient_${index * 4 + 3}`}
                gradientUnits="userSpaceOnUse"
                x1="50"
                y1="100"
                x2="0"
                y2="50"
              >
                <stop offset="0.167924" stopColor={colors[index * 4 + 2]} />
                <stop offset="0.861674" stopColor={colors[index * 4 + 3]} />
              </linearGradient>
            )}
            {colors[index * 4 + 4] && (
              <linearGradient
                id={`linear_gradient_${index * 4 + 4}`}
                gradientUnits="userSpaceOnUse"
                x1="0"
                y1="50"
                x2="50"
                y2="0"
              >
                <stop offset="0.1" stopColor={colors[index * 4 + 3]} />
                <stop offset="0.8" stopColor={colors[index * 4 + 4]} />
              </linearGradient>
            )}
          </defs>
        ))}
      </svg>
      <div className="front-bg"></div>
      <span className="progress-text" style={{ color: textColor.color }}>
        {_.isNumber(percent) && (
          <span>
            <strong>{percent}</strong>%
          </span>
        )}
      </span>
    </Container>
  )
}

function getPathPoints(percent, colors) {
  let _colors = _.cloneDeep(colors)
  const positions = [
      [50, 5],
      [95, 50],
      [50, 95],
      [5, 50]
    ],
    r = 46
  let _percent = percent
  if (percent > 200) {
    _percent = percent - 100
  }
  const count = Math.ceil(_percent / 25)
  let points = []
  for (let i = 0; i < count; i++) {
    const diff = _percent - 25 * i
    let _point = positions[(i + 1) % 4]
    if (diff < 25) {
      const segmentDeg = ((Math.PI / 2) * diff) / 25
      const sin = Math.floor(Math.sin(segmentDeg) * r),
        cos = Math.floor(Math.cos(segmentDeg) * r)
      const _i = i % 4
      if (_i % 2 === 0) {
        _point = calculatePoint(sin, cos, _i)
      } else {
        _point = calculatePoint(cos, sin, _i)
      }
    }
    points.push([positions[i % 4], _point])
  }

  const len = points.length
  if (len > 4) {
    const addColors = [...new Array(len - 5).fill(colors[1])]
    _colors.splice(3, 0, colors[3])
    _colors.splice(2, 0, colors[2])
    _colors.splice(1, 0, ...addColors)
  }

  return {
    points,
    colors: _colors
  }
}

function calculatePoint(x, y, i) {
  let _x, _y
  switch (i) {
    case 0:
      _x = 50 + x
      _y = 50 - y
      break
    case 1:
      _x = 50 + x
      _y = 50 + y
      break
    case 2:
      _x = 50 - x
      _y = 50 + y
      break
    case 3:
      _x = 50 - x
      _y = 50 - y
      break
    default:
      return undefined
  }
  return [_x, _y]
}
