/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect, useCallback } from 'react'
import { Radio, Toast } from 'antd-mobile'
import _ from 'lodash'
import Additional from './addition'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { AiOutlineClose } from 'react-icons/ai'
import filesize from '../../../utils/filesize'
import { formDataRequest } from '../../../api/axios'

export default function IntroSingleChoice(props) {
  const {
    answer,
    question: { choices, answer_format },
    update,
    formatSource
  } = props
  const [values, setValues] = useState({})

  useEffect(() => {
    if (answer) {
      setValues(answer.answer.value)
    }
  }, [answer])

  return (
    <div className="q-form-group">
      {choices.map((choice, index) => {
        const answerObj = values[index]
        const isChecked = !!answerObj
        const verify = _.get('answer', 'verify.choices', [])[index]
        return (
          <div
            key={index}
            className={`q-form-item ${answerObj ? 'valid' : ''} ${
              verify ? 'invalid' : ''
            }`}
          >
            <Radio
              onChange={(val) => {
                const obj = {
                  [index]: []
                }
                radioClickHandle(obj)
              }}
              value={choice.id || index}
              checked={isChecked}
            >
              {choice.title}
            </Radio>

            {choice.type === 'attachment' && isChecked && (
              <Attachment
                answers={answerObj}
                {...choice.handleAttachment}
                update={(attachments) => {
                  updateAnswer({ [choice.id || index]: attachments })
                }}
              />
            )}

            {choice.additional_information && isChecked && (
              <Additional
                items={choice.additional_information}
                anArr={(answerObj && answerObj.additional_answer) || []}
                update={(anArr) => {
                  if (answerObj.additional_answer) {
                    updateAnswer({
                      [choice.id || index]: { additional_answer: anArr }
                    })
                  } else {
                    updateAnswer({ [choice.id || index]: anArr })
                  }
                }}
                verify={answer.verify && answer.verify.additional}
              />
            )}
          </div>
        )
      })}
      {answer.verify && answer.verify.required && (
        <p className="invalid">
          {answer.verify.message || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )

  function radioClickHandle(answerObj) {
    if (formatSource && answer_format) {
      let _obj = {}
      _.forIn(answerObj, (obj, key) => {
        _obj[key] = applyAnswer(answer_format.format[key], formatSource)
      })
      answerObj = _obj
    }
    updateAnswer(answerObj)
  }

  function updateAnswer(answerObj) {
    const newAnswer = _.assignIn(answer, { answer: { value: answerObj } })
    setValues(answerObj)
    update(newAnswer, props.question)
  }
}

function applyAnswer(format, answer) {
  if (!format) return null
  let answerObj
  answerObj = answerObj || {}
  if (format.text_key) {
    answerObj.text = answer[format.text_key]
  } else if (format.additional_key) {
    answerObj.additional_answer = formatting(format.additional_key)
  } else if (format.attachment_key) {
    answerObj = formatting(format.attachment_key)
  } else {
    answerObj = formatting(format)
  }

  function formatting(format) {
    if (_.isString(format)) {
      return answer[format]
    } else if (_.isArray(format)) {
      return format.map((item) => formatting(item))
    } else {
      if (format.key) {
        if (format.isChecked) {
          // question position 1-3, 1-6
          let additional_answer
          if (format.format_key) {
            const obj = formatting(format.format_key)
            additional_answer = obj
          }
          return {
            isChecked: formatting(format.key),
            additional_answer
          }
        } else {
          return formatting(format.key)
        }
      } else {
        let obj = {}
        _.forIn(format, (value, key) => {
          obj[key] = formatting(value.isChecked ? value : value.key || value)
        })
        return obj
      }
    }
  }

  return answerObj
}

// function Attachment(props) {
//   // const { update, action, removeAttach, editAttach } = props
//   const [fileList, setFileList] = useState([])
//   return (
//     <ImageUploader
//       value={fileList}
//       maxCount={1}
//       onChange={setFileList}
//       upload={mockUpload}
//       children={<>Upload</>}
//     />
//   )
//   async function mockUpload(file) {
//     console.log(
//       '🚀 ~ file: introSingleChoices.js:161 ~ mockUpload ~ file:',
//       file
//     )
//     // await sleep(3000)
//     return {
//       url: URL.createObjectURL(file)
//     }
//   }
// }

const TapZone = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(147, 147, 147, 0.2);
  background: #fbfbfb;
  padding: 16px;
  margin-bottom: 16px;
  color: #5e687d;
  font-family: Heebo;
  font-size: 14px;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.3px;
  span {
    opacity: 0.4;
  }
`

const FileZone = styled.div`
  margin-bottom: 16px;
  .file-container {
    background-color: rgba(232, 236, 240, 0.6);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 8px;

    .file-type {
      width: 47px;
      height: 47px;
      background: white;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 700;
      color: #52606c;
      flex-basis: 59px;
      p {
        text-align: center;
      }
    }

    .file-info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      margin-right: 8px;
      font-size: 12px;
      overflow: hidden;
      .file-title {
        color: #52606c;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.3px;
      }
      .file-name-size {
        color: #bac3ce;
        font-family: Heebo;
        line-height: 150%; /* 18px */
        letter-spacing: 0.704px;
        word-break: break-all;
      }
    }
  }
`

function Attachment(props) {
  const { removeAttach, editAttach, update, action } = props
  const [answers, setAnswers] = useState(_.cloneDeep(props.answers))
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        Toast.show({
          content: 'Only one file is allowed to be selected for upload'
        })
      }
      const formData = new FormData()
      acceptedFiles.forEach((file) => {
        formData.append('files', file)
      })
      const obj = {
        method: 'post',
        path: action,
        data: formData
      }
      const toast = Toast.show({
        icon: 'loading',
        content: 'Uploading',
        maskClickable: false
      })
      try {
        const response = await formDataRequest(obj)
        if (response) {
          let results = answers
          if (!_.isArray(results)) {
            results = []
          }
          let attach = response.attachment
          const editRes = await editAttach(attach, {
            size: `${_.head(acceptedFiles).size}`,
            title: 'Insurance information'
          })
          attach = editRes.attachment
          results.push(attach)
          update(results)
          setAnswers(results)
          toast.close()
        } else {
          toast.close()
        }
      } catch (error) {
        console.error('Upload error:', error)
        toast.close()
      }
    },
    [action, answers, editAttach, update]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': [],
      'image/svg': [],
      'image/jpg': [],
      'image/jpeg': [],
      'application/pdf': []
    },
    maxFiles: 1
  })

  const remove = async (attach) => {
    const toast = Toast.show({
      icon: 'loading',
      content: 'Deleting…',
      maskClickable: false
    })
    await removeAttach(attach)
    _.remove(answers, function (an) {
      return an.id === attach.id
    })
    update(answers)
    toast.close()
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {answers && answers.length > 0 ? (
        <FileZone>
          {answers.map((file, index) => {
            return (
              <div className="file-container" key={index}>
                <div className="file-type">
                  <p>
                    {file.name
                      .substring(file.name.lastIndexOf('.') + 1)
                      .toUpperCase()}
                  </p>
                </div>
                <div className="file-info">
                  <span className="file-title">{file.title}</span>
                  <span className="file-name-size">{`${file.name} - ${filesize(
                    Number(file.size),
                    { round: 1 }
                  )}`}</span>
                </div>
                <AiOutlineClose
                  style={{
                    fontSize: '16px',
                    color: '#52606C',
                    width: '32px',
                    height: '19px'
                  }}
                  onClick={(e) => remove(file) && e.stopPropagation()}
                />
              </div>
            )
          })}
        </FileZone>
      ) : (
        <TapZone>
          <span>+ Attach File</span>
        </TapZone>
      )}
    </div>
  )
}
