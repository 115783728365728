import { hashRequest } from './axios'

export const getEmailDetailByHash = async (emailId, hash) => {
  const result = await hashRequest({
    hash,
    method: 'GET',
    path: `/summary_email/details?id=${emailId}`
  })
  return result.data
}
