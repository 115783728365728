import phsLogo from '../../../asserts/icons/latest-logo.svg'
import { LogoutIcon, MailIcon } from '../icons'
import Container from './style.js'

const Header = (props) => {
    const {showLogout, showMail, onClickLogout, onClickmail} = props
    return <Container>
    <img src={phsLogo} alt="logo" />
      <div className="opr">
          {showMail && <MailIcon className="mail"  onClick={() => {
            if(onClickmail) onClickmail()
          }}/>}
          {showLogout && <LogoutIcon className="logout" onClick={() => {
            if(onClickLogout) onClickLogout()
          }}/>}
        </div>
  </Container>
}

export default Header
