import React from 'react'
import Success from '../../../asserts/icons/onboarding/pre-screen-completed-success.svg'
import { CompletedPage } from '../common/completed'

export function MemberInfoDone(props) {
  const { action } = props
  return (
    <CompletedPage
      title={'Success'}
      img={Success}
      desc={
        <>
          <p>
            Thank you for completing our forms. A member of our Concierge team
            will reach out to you shortly to schedule the rest of your Apeiron
            Life appointments.
          </p>
          <p
            style={{
              marginTop: 12
            }}
          >
            Prior to your coming in for your assessment, we'll need to gather
            some more information about your current lifestyle. This will help
            us personalize your assessment and tailor your results and
            recommendations to your goals and needs.
          </p>
        </>
      }
      action={action}
      actionTitle={'PROCEED'}
      // extraFooter={
      //   <Button
      //     className="cta-back"
      //     block
      //     fill="outline"
      //     color="primary"
      //     onClick={logOutAction}
      //     style={{ marginTop: '1rem' }}
      //   >
      //     SAVE FOR LATER
      //   </Button>
      // }
    />
  )
}
