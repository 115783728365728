import styled from 'styled-components'
import Background from '../../asserts/images/login-bg.png'

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  background: ${(props) =>
    props.hasBackground ? `url(${Background})` : 'transparent'};
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  padding: 2.3em;
  box-sizing: border-box;
  & > div {
    width: 100%;
  }
  .title {
    font-weight: 500;
    color: #323F4A;
    font-size: 1.8em;
    line-height: 1.5;
    padding: 12px 0;
    text-align: center;
  }

  .desc{
    > div{
      width: 236px;
      text-align:center;
    }
    display:flex;
    justify-content: center;
    line-height:1.4rem;
    color: #52606C;
    margin-bottom:16px;
  }

  .center-image {
    padding: 2em 0;
  }

  .resend {
    color: #2b4b8f;
  }

  .adm-form {
    text-align: left;
  }

  .form-container {
    margin: 0 -12px;
    .required{
      color:red;
    }
    .adm-list-item-content-prefix {
      width: auto;
    }
    .adm-form-footer {
      padding: 0.75em 12px;
      .before-submit {
        margin: 0 0 1em 0;
        .adm-checkbox {
          vertical-align: bottom;
          margin-right: 0.5em;
        }
      }
    }
  }

  .stickBottom{
    max-width:100vw;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid #E6E9F2;
    background:#FFFFFF;
    > div{
      padding:30px 32px;
      width:calc(100vw - 60px);
    }
  }

  .success-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    div{
      width: 258px;
      text-align: center;
    }
  }
  .info-image {
    margin: 30px;
    width: 24%;
  }

  .login-footer {
    color: #787d85;
  }
`
