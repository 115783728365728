import axios, { hashRequest } from './axios'
import { setTokenItem, saveUser } from '../utils'

const initAccount = async (path, params, setLoginAccount) => {
  try {
    const res = await axios.post(path, params)
    saveUser(res.person)
    setTokenItem(res.token)
    setLoginAccount && setLoginAccount(res.person)
    // init(res.person)
    return res.person
  } catch (error) {
    throw error
  }
}

export const refreshProfile = async (id) => {
  const result = await getPeopleById(id)
  saveUser(result.person)
}

export const login = (params, setLoginAccount) =>
  initAccount('/people/login', params, setLoginAccount)

export const register = (params, setLoginAccount) =>
  initAccount('/people', params, setLoginAccount)

export const logOut = () => axios.post('/people/logout')

export const getPeopleById = (id) =>
  axios.get(`/people/${id}`).then((result) => {
    return {
      person: result.ghostPerson || result.person
    }
  })

export const getPeopleByPermanentLink = () => {
  return hashRequest({ method: 'GET', path: '/permanent_link/person' })
}

export const getMeByHash = async (hash) => {
  const result = await hashRequest({
    hash: hash,
    method: 'GET',
    path: '/people/me'
  })
  return result.data
}

export const getMe = (id) =>
  axios.get('/people/me').then((result) => {
    return {
      person: result.ghostPerson || result.person
    }
  })

export const passwordInitChange = (params) =>
  axios.post('/people/change_password', params)

export const enable2FA = (params, id) =>
  axios.post(`/people/${id}/enable_2fa`, params)

export const verify2FA = (params, id) =>
  axios.post(`/people/${id}/verify_2fa`, params)

export const editClient = (client, id, query) => {
  let url = `/clients/${id}`
  if (query) {
    url += query
  }
  return axios.putJson(url, client)
}

export const editClientByHash = (data, id, query) => {
  let url = `/clients/${id}`
  if (query) {
    url += query
  }
  return hashRequest({
    method: 'PUT',
    path: url,
    data
  })
}

export const forgotPassword = (params) =>
  axios.post('/people/forgot_password', params)

export const passwordReset = (params) =>
  axios.post('/people/reset_password', params)

export const updateProfileStatus = async (id, profile, callback) => {
  const {
    team: { person }
  } = await axios.putJson(`/clients/${id}`, { profile })
  await saveUser(person)
  callback && callback(person)
  return person
}

export const getHealthySignalsPercentageTarget = async (
  personId,
  day,
  hash
) => {
  const url = `/healthy_signals/percentage-targets?person_id=${personId}&day=${day}`
  if (hash) {
    const result = await hashRequest({
      hash: hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  return axios.get(url)
}

// todo remove
export const postAnswer = (
  { answer, id, section_id, nutrition_date },
  person_id
) => {
  let url = `/survey/${id}/answers/${section_id}?person_id=${person_id}`
  if (nutrition_date) {
    url += `&nutrition_date=${nutrition_date}`
  }
  return axios.postJson(url, answer)
}

export const getRangeAgeScopes = (hash) => {
  if (hash) {
    return hashRequest({
      hash: hash,
      method: 'GET',
      path: '/range_age_scope'
    })
  }
  return axios.get('/range_age_scope')
}
