import moment from 'moment'
import Decimal from 'decimal.js'
import pluralize from 'pluralize'
import { logOut } from '../api'
import { removeUserItem } from './storage'
import axios from 'axios'

export const hadleForm = {}

export const getAxisX = (startDate, endDate) => {
  const axisX = []
  let count = 1
  axisX.push(moment(startDate))
  while (moment(startDate).add(count, 'days').isBefore(endDate)) {
    axisX.push(moment(startDate).add(count, 'days'))
    count++
  }
  axisX.push(moment(endDate))
  return axisX
}

export const getSum = (inputArr) => {
  if (toString.call(inputArr) !== '[object Array]') return false

  var total = new Decimal(0)
  for (var i = 0; i < inputArr.length; i++) {
    if (!inputArr[i]) {
      continue
    }
    const x = new Decimal(inputArr[i])
    if (x.isNaN()) {
      continue
    }
    // total += Number(inputArr[i])
    total = total.add(x)
  }
  return Math.round(total.toNumber() * 10) / 10
}

const irregulars = [
  'bpm',
  'ml',
  'BMI',
  'G',
  'ms',
  'Boolean',
  'Unknown',
  '%',
  'cm',
  'ml/kg/min',
  'String',
  'mg/dl'
]
for (let item of irregulars) {
  pluralize.addIrregularRule(item, item)
}

export const Pluralize = pluralize

export const logOutAction = () => {
  logOut()
  removeUserItem()
  window.location.pathname = '/sign-in'
}

export const formatInch = (inch) => {
  const _inch = inch % 12
  return {
    height_ft: (inch - _inch) / 12,
    height_in: _inch
  }
}

export const parseInch = (ft, inch) => {
  return Number(ft || 0) * 12 + Number(inch || 0)
}

export const remoteImgToBase64 = (url) => {
  return axios.get(url, { responseType: 'blob' }).then(
    (response) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(response.data)
      })
  )
}

// export const remoteImgToBase64 = (url) =>
//   new Promise((resolve, reject) => {
//     var request = require('request').defaults({ encoding: null })
//     request.get(url, function (error, response, body) {
//       if (!error && response.statusCode === 200) {
//         const data =
//           'data:' +
//           response.headers['content-type'] +
//           ';base64,' +
//           Buffer.from(body).toString('base64')
//         resolve(data)
//       } else {
//         reject(error)
//       }
//     })
//   })

export function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}
