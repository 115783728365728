import React, { useRef, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import phsLogo from '../../asserts/icons/latest-logo.svg'
import { AutoCenter, Button, Space, Dialog } from 'antd-mobile'

const LayoutContainer = styled.div`
  height: 100vh;
  background: white;
  header {
    z-index: 1;
    padding: 1.75rem 1.5rem;
    position: sticky;
    top: 0;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  .action-container {
    padding: 20px;
  }
`

const baseUrl =
  process.env.REACT_APP_WORKFLOW === 'production'
    ? 'https://app.apeiron.life'
    : 'https://app-staging.apeiron.life'

const appScheme =
  process.env.REACT_APP_WORKFLOW === 'production'
    ? 'life.apeiron.ios://'
    : 'life.apeiron.ios.staging://'

const appStoreUrl =
  'https://apps.apple.com/cn/app/apeiron-wellness-concierge/id1471466406?l=en-GB'

export default function Redirected(props) {
  const [queryParams] = useSearchParams()
  const initialized = useRef(false)
  const target = queryParams.get('target')

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      Dialog.show({
        content: 'We need to open the Apeiron Life App to view this content.',
        closeOnAction: true,
        actions: [
          {
            key: 'allow',
            text: 'Allow',
            onClick: () => {
              const a = document.createElement('a')
              a.setAttribute('href', appScheme + target)
              a.click()
              setTimeout(() => {
                if (!document.hidden) {
                  window.location.href = appStoreUrl
                }
              }, 3000)
            }
          },
          {
            key: 'no',
            text: 'No'
          }
        ]
      })
    }
  }, [target])

  return (
    <LayoutContainer>
      <header>
        <img src={phsLogo} alt="logo" />
      </header>
      <div className="action-container">
        <Space direction="vertical" block style={{ '--gap': '24px' }}>
          <AutoCenter>This page only available in the iPhone App</AutoCenter>
          <Button
            block
            color="primary"
            fill="outline"
            onClick={() => {
              window.location.href = appStoreUrl
            }}
          >
            Go to App Store
          </Button>
          <Button
            block
            shape="rounded"
            color="primary"
            onClick={() => {
              window.location.href = baseUrl + target
            }}
          >
            Already installed? Open In the App
          </Button>
        </Space>
      </div>
    </LayoutContainer>
  )
}
