import React from 'react'
import { Radio, Space } from 'antd-mobile'
import radioUnuseIcon from '../../../asserts/icons/radio-unuse.svg'
import radioUseIcon from '../../../asserts/icons/radio-use.svg'

const WaiverMedical = ({relaseHealth, setPermison}) => {
    return (
        <div className='middle'>
          <div className='title'>Medical Release</div>
          <div className='quesiton'>As part of your Apeiron Life membership, we would love to integrate with your current care team to share assessment results, health information, and ongoing updates on your progress. This way, we can all coordinate on how to best assist you in your health and wellness journey. Members often enjoy us collaborating with:</div>
          <ul className='description'>
            <li><strong>Primary Care Physicians (highly encouraged)</strong></li>
            <li>Specialist Physicians</li>
            <li>Personal Trainers</li>
            <li>Massage Therapists</li>
            <li>Chiropractors</li>
            <li>Other medical and wellness providers</li>
          </ul>
          <div className='quesiton bottom'>In order to do so, we need your permission to release such information. Do you want your health information, data, or recommendations released to anyone?</div>
          <Radio.Group value={relaseHealth} onChange={setPermison}>
            <Space direction='vertical'>
              <Radio icon={(checked) => checked ? <img src={radioUseIcon} alt=''/> : <img src={radioUnuseIcon} alt=''/>} value={true}>Yes</Radio>
              <Radio icon={(checked) => checked ? <img src={radioUseIcon} alt=''/> : <img src={radioUnuseIcon} alt=''/>} value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </div>
    )
  }

  export default WaiverMedical
