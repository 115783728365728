import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Information from '../components/Information'
import Spine from '../../../asserts/images/physical-assessment/img-1-spine.png'
import Hip from '../../../asserts/images/physical-assessment/img-2-hip.png'
import { RangeColors } from '../../../utils'
import { RenderImageUrl } from '../wrapper'
import moment from 'moment'
import { ValueIcon } from './RangeBar'
import { Grid } from 'antd-mobile'

const DXAContainer = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 70px;
  }
  .media-container {
    display: flex;
    justify-content: space-between;
  }
  .summary-container {
    margin-top: 60px;
    text-align: right;
    font-size: 15px;
    color: #4c6072;
    div[class^='summary'] {
      display: flex;
      justify-content: space-between;

      & > div {
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
    }

    .summary-header {
      padding-bottom: 32px;
      border-bottom: 1px solid #e3e6ea;
      &.bold {
        font-family: Gilroy-Bold;
      }
    }
    .summary-body {
      border-bottom: 1px solid #e3e6ea;
      padding: 20px 0;
      &.bold {
        font-weight: 700;
      }
      & > div {
        min-width: 50px;
        &:first-child {
          text-align: left;
        }
      }
    }
    .summary-flex-direction-column {
      flex-direction: column;
      position: relative;
      & > div:first-child {
        position: absolute;
      }
    }
    .summary-footer {
      padding-top: 16px;
      color: #70808e;
    }
  }
  .disclaimer {
    text-align: right;
    color: #bac2c9;
    font-size: 15px;
    margin-bottom: 40px;
  }
`
const RangeBarView = styled.div`
  .ranges {
    margin-top: 10px;
  }
  .level-label {
    font-size: 15px;
    font-family: 'Gilroy-Bold';
    line-height: 21px;
    text-align: right;
  }
`

export default function DxaSummary(props) {
  const {
    dxaSummary,
    view,
    person,
    detail,
    ui_version,
    ageScopes,
    notes,
    handleCommendSave,
    updateEditingCount
  } = props
  const getAXAValue = (key) => {
    const target = dxaSummary.find((item) => item.label === key)
    return _.isNumber(target && target.value)
      ? target.value
      : (target && target.value) || '--'
  }

  const renderRangeBar = (targetKey) => {
    const arg = getAXAValue(targetKey)
    return (
      <RangeBarView>
        <RangeBar {...arg} {...{ ageScopes, person }} hideValue />
        <div className="level-label" style={{ color: RangeColors[arg.color] }}>
          {arg.level}
        </div>
      </RangeBarView>
    )
  }

  const dxa = notes.find((item) => item.pillar === 'dxa')

  return (
    <DXAContainer>
      <div className="container">
        <div className="media-container">
          <Grid columns={2} gap={16}>
            <Grid.Item>
              <RenderImageUrl
                url={detail.DXA && detail.DXA.spine_chart}
                width={160}
                height={160}
              />
            </Grid.Item>
            <Grid.Item>
              <RenderImageUrl
                url={(detail.DXA && detail.DXA.spine) || Spine}
                width={160}
                height={160}
              />
            </Grid.Item>
          </Grid>
        </div>
        <div className="summary-container">
          <div className={`summary-header ${ui_version ? 'bold' : ''}`}>
            <div>Region</div>
            <div>
              BMD (g/cm<sup>2</sup>)
            </div>
            <div>T-score</div>
            <div>Z-score</div>
          </div>
          <div className={`summary-body ${ui_version ? '' : 'bold'}`}>
            <div>Total</div>
            <div>{getAXAValue('spine_total_bmd')}</div>
            <div>{getAXAValue('lumbar_spine_t-score')}</div>
            <div>{getAXAValue('lumbar_spine_z-score')}</div>
          </div>
          {ui_version && (
            <div className="summary-body summary-flex-direction-column">
              <div>Age-ranking</div>
              {renderRangeBar('lumbar_spine_z_score_age_ranking')}
            </div>
          )}
          <div className="summary-footer">
            <div>Total BMD CV 1.0%</div>
            <div>
              {getAXAValue('who_classification_spine') !== '--' && (
                <>
                  WHO Classification: {getAXAValue('who_classification_spine')}
                </>
              )}
              <br />
              {getAXAValue('frax_bone_risk') !== '--' && (
                <>Fracture Risk: {getAXAValue('frax_bone_risk')}</>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="media-container">
          <Grid columns={2} gap={16}>
            <Grid.Item>
              <RenderImageUrl
                url={detail.DXA && detail.DXA.neck_chart}
                width={160}
                height={160}
              />
            </Grid.Item>
            <Grid.Item>
              <RenderImageUrl
                url={(detail.DXA && detail.DXA.neck) || Hip}
                width={160}
                height={160}
              />
            </Grid.Item>
          </Grid>
        </div>
        <div className="summary-container">
          <div className={`summary-header ${ui_version ? 'bold' : ''}`}>
            <div>Region</div>
            <div>
              BMD (g/cm<sup>2</sup>)
            </div>
            <div>T-score</div>
            <div>Z-score</div>
          </div>
          <div className="summary-body">
            <div>Neck</div>
            <div>{getAXAValue('femoral_neck_bmd')}</div>
            <div>{getAXAValue('femoral_neck_t-score')}</div>
            <div>{getAXAValue('femoral_neck_z-score')}</div>
          </div>

          <div className={`summary-body ${ui_version ? '' : 'bold'}`}>
            <div>Total</div>
            <div>{getAXAValue('total_hip_bmd')}</div>
            <div>{getAXAValue('total_hip_t-score')}</div>
            <div>{getAXAValue('total_hip_z-socre')}</div>
          </div>
          <div className="summary-body summary-flex-direction-column">
            <div>Age-ranking</div>
            {renderRangeBar('femoral_neck_z_score_age_ranking')}
          </div>
          <div className="summary-footer">
            <div>Total BMD CV 1.0%</div>
            <div>
              {getAXAValue('who_classification_hip') !== '--' && (
                <>WHO Classification: {getAXAValue('who_classification_hip')}</>
              )}
              <br />
              {getAXAValue('frax_hip_risk') !== '--' && (
                <>Fracture Risk: {getAXAValue('frax_hip_risk')}</>
              )}
            </div>
          </div>
        </div>
      </div>

      <Information
        title="COMMENT/NOTE"
        disMarkDone={true}
        initialValue={dxa ? dxa.memo : ''}
        status={view === 'Draft' ? 'Draft' : 'Opened'}
        updateCommend={(value, status) =>
          handleCommendSave(value, 'dxa', status)
        }
        updateEditingCount={updateEditingCount}
      />
    </DXAContainer>
  )
}

const RangeBarContainer = styled.div`
  .ranges {
    display: flex;
    margin-top: 50px;
    margin-bottom: 15px;
    .range-item {
      height: 6px;
      border: 1px solid #ffffff;
      position: relative;
      .has-value {
        font-weight: 700;
        line-height: 1.2;
        margin-top: -57px;
        white-space: nowrap;
        .level,
        .value-number {
          display: inline-block;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .level {
          font-size: 16px;
        }
        .value-number {
          font-size: 20px;
          color: #4c6072;
          padding-bottom: 15px;
        }
      }
      .value {
        margin-top: -7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        span {
          font-size: 18px;
          color: #4c6072;
          font-family: Gilroy-Bold;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -25px;
          white-space: nowrap;
        }
      }
    }
    .range-area {
      font-size: 12px;
      color: #4c6072;
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }
  }
`

function RangeBar(props) {
  const {
    ranges,
    value,
    person,
    ageScopes,
    level,
    unit,
    showRangeName,
    showUnit,
    hideValue,
    color,
    showRangeArea,
    hideLevel
  } = props
  const gender = person.profile.gender
  const age = moment().diff(person.profile.date_of_birth, 'years')

  const targetScope = ageScopes.find(({ scope: [min, max] }) => {
    if (age >= min && age <= max) return true
    return false
  })

  const ageTag = targetScope && targetScope.tag

  let _ranges = _.cloneDeep(ranges)

  try {
    _ranges = ranges
      .map((range) => ({
        scope:
          range[`${gender}_age_scope`] && range[`${gender}_age_scope`][ageTag],
        name: range.name
      }))
      .filter((range) => range)
      .map(({ scope, name }) => {
        const [min, max, color] = scope
        return {
          min: min,
          max: max,
          color: RangeColors[color],
          name
        }
      })
      .sort((a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE))
  } catch (err) {
    _ranges = (ranges || [])
      .sort((a, b) => a.order - b.order)
      .map((range) => {
        range.color =
          range[`${gender}_age_scope`][ageTag] &&
          RangeColors[range[`${gender}_age_scope`][ageTag][2]]
        return range
      })
  }

  function renderValueIcon(item) {
    let iconStyle = {}
    if (showRangeArea) {
      iconStyle = getPointExtraStyle(value, item)
    }

    return (
      <div className="has-value">
        <div
          className="level"
          style={{
            color: hideValue || hideLevel ? 'transparent' : RangeColors[color],
            marginLeft: iconStyle ? iconStyle.left : null
          }}
        >
          {level}
        </div>
        <br />
        <div
          className="value-number"
          style={{
            opacity: hideValue ? 0 : 1,
            marginLeft: iconStyle ? iconStyle.left : null
          }}
        >
          {value}
          {unit}
        </div>
        <br />
        <ValueIcon
          color={item.color || '#8A969F'}
          style={iconStyle}
          hideValueNumber
          value={
            showRangeName
              ? item.name
              : value +
                (unit && (unit.toLocaleLowerCase() === 'kg' || showUnit)
                  ? unit
                  : '')
          }
        />
      </div>
    )
  }

  return (
    <RangeBarContainer>
      <div className="ranges">
        {_ranges.map((item, index) => (
          <div
            key={index}
            className={`${item.name} range-item`}
            style={{
              width: 100 / _ranges.length + '%',
              background: item.color || '#8A969F'
            }}
          >
            {level === item.name && renderValueIcon(item)}
            {showRangeArea && (
              <div className="range-area" style={{ color: '#4c6072' }}>
                {formatRangeArea(item)}
              </div>
            )}
          </div>
        ))}
      </div>
    </RangeBarContainer>
  )

  function getPointExtraStyle(value, range) {
    let left = 50
    let correctionOffset = -50
    if (range.max && range.min) {
      left = ((value - range.min) / (range.max - range.min)) * 100
    }
    if (Math.round(left) === 0) {
      correctionOffset = 0
    } else if (Math.round(left) === 100) {
      correctionOffset = -100
    }
    return {
      left: left + '%',
      transform: `translateX(${correctionOffset}%)`
    }
  }

  function formatRangeArea(range) {
    if (range.max && range.min) {
      return `${range.min}-${range.max}`
    } else if (range.max) {
      return `< ${range.max}`
    } else {
      return `> ${range.min}`
    }
  }
}
