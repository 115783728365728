import React, { useState, useEffect } from 'react'

export default function LevelBar(props) {
  const {
    question: { choices, extra_info },
    answer,
    update
  } = props
  // answer = answer || this.state.levelBarAnswer
  const itemLength = (1 / choices.length) * 100 + '%'

  const [value, setValue] = useState(null)

  useEffect(() => {
    if (answer && !value) {
      setValue(answer.answer.value)
    }
  }, [answer, value])

  return (
    <>
      <ul className="q-level-bar">
        {choices.map((item, index) => (
          <li
            key={index}
            style={{ width: itemLength }}
            className={value === item.title.toString() ? 'selected' : ''}
            onClick={(e) => {
              const _value = item.title.toString()
              setValue(_value)
              const newAnswer = Object.assign(answer, {
                answer: { value: _value }
              })
              update(newAnswer, props.question)
            }}
          >
            {item.title}
          </li>
        ))}
      </ul>
      <ul className="q-level-bar-extra">
        {extra_info &&
          extra_info.content.map((item) => {
            return <li style={{ width: itemLength }}>{item}</li>
          })}
      </ul>
      {answer && answer.verify && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </>
  )
}
