import { Button } from 'antd-mobile'
import arrowRight from '../../../asserts/icons/arrow-right.svg'
import arrowLeft from '../../../asserts/icons/onboarding/arrow-left.svg'
import styled from 'styled-components'

const Container = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--divider, #e6e9f2);
  background: #fff;
  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.05);
  .control {
    display: flex;
    justify-content: space-between;
    padding: 30px 24px;
    background: #fff;
    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    button {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      padding: 13px 24px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 10px;
        }
      }
    }
  }
`

const ControlBottom = (props) => {
  const {
    showBack = true,
    showConfirm = true,
    onClickBack,
    onClickConfirm,
    children,
    confirmLoading,
    confirmDisabled
  } = props
  return (
    <Container>
      {children}
      <div className="control">
        <div
          className="back"
          onClick={() => {
            if (onClickBack) onClickBack()
          }}
        >
          {showBack && (
            <>
              <img src={arrowLeft} alt="" /> BACK
            </>
          )}
        </div>
        <Button
          className="next"
          loading={confirmLoading}
          disabled={confirmLoading || confirmDisabled}
          style={{ display: showConfirm ? 'block' : 'none' }}
          shape="default"
          color="primary"
          onClick={() => {
            if (onClickConfirm) onClickConfirm()
          }}
        >
          {props.confirmBtn || 'NEXT'} <img src={arrowRight} alt="" />
        </Button>
      </div>
    </Container>
  )
}

export default ControlBottom
