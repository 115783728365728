import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import BannerBG from '../../../asserts/images/ass-report-bg.png'
import { useInView } from 'react-intersection-observer'

const BannerContainer = styled.div`
  background: url(${BannerBG}) no-repeat;
  max-height: 375px;
  background-size: 100%;
  padding: 361px 41px 41px 24px;
  position: relative;
  margin-bottom: 14px;
  .avatar {
    width: 70px;
    height: 70px;
    background: #f1f3f6;
    margin-bottom: 20px;
    border-radius: 70px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .report-title {
    color: #f8fafa;
    font-size: 36px;
    letter-spacing: 0.3px;
    line-height: 115%;
    margin-bottom: 10px;
  }
  .title {
    color: #f8fafa;
    opacity: 0.6;
    font-size: 16px;
  }
`

export default function BannerSection(props) {
  const { person, period, id, visible } = props

  const { ref } = useInView({
    threshold: 1,
    onChange: (inView) => {
      visible(Boolean(inView))
    }
  })

  return (
    <BannerContainer id={id} ref={ref}>
      <div className="avatar" id="avatar">
        {person.avatar && <img src={person.avatar.oss_url} alt="avatar" />}
      </div>
      <h1 className="report-title">
        {(person.profile && person.profile.nickname) || person.first_name}'s
        Comprehensive Report
      </h1>
      <div className="title">{moment(period).format('MMM YYYY')}</div>
    </BannerContainer>
  )
}
