import React, { useState } from 'react'
import styled from 'styled-components'
import { getShowWeeklyDate } from '../../../../utils/dateHandles'

const Container = styled.div`
  height: 2.25rem;
  transform: rotatey(180deg);
  font-family: Inter, Gilroy;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;
  line-height: 2.25rem;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props.isActive ? '#0077E4' : '#383E48')};
`

export default function Indicator(props) {
  const { item, gesture, isActive, onClick } = props
  const [client, setClient] = useState([0, 0])

  const { dateInfo } = item || {}
  const showStr =
    (dateInfo && getShowWeeklyDate(dateInfo.from, dateInfo.to)) || ''
  return (
    <Container
      isActive={isActive}
      onTouchStart={(e) => {
        const startClientX = e.changedTouches[0].clientX
        const startClientY = e.changedTouches[0].clientY
        setClient([startClientX, startClientY])
      }}
      onTouchEnd={(e) => {
        const endClientX = e.changedTouches[0].clientX
        const endClientY = e.changedTouches[0].clientY
        const [startX, startY] = client
        var deltaX = endClientX - startX
        var deltaY = endClientY - startY
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (endClientX - startX !== 0) {
            // 0 is click event
            gesture(endClientX - startX)
          }
        }

        setClient([0, 0])
      }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {showStr.split(',')[0]}
    </Container>
  )
}
