import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { SpinLoading } from 'antd-mobile'

const Container = styled.div`
  .switch-date-range {
    display: flex;
    font-size: 0.875rem;
    font-family: 'Gilroy';
    font-weight: 500;
    padding: 0.4375rem 0.625rem;
    justify-content: space-between;
    align-items: center;
    color: #52606c;
    text-transform: uppercase;
    border-top: 1px solid #dee2ec;
    border-bottom: 1px solid #dee2ec;
    letter-spacing: 0.3px;
    background: #ffffff;
    .arrow {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 100%;
      text-align: center;
      line-height: 1.875rem;
      &.hide {
        visibility: hidden;
      }
    }
  }
`

export default function DateRange(props) {
  const { prevDate, nextDate, showDate, dataLoading } = props

  const hideArrow = props.hideArrow || ''
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [showDate])

  const switchData = (callback) => {
    if (dataLoading) return
    setLoading(true)
    callback()
  }
  return (
    <Container>
      <div className="switch-date-range">
        <span className={`arrow ${hideArrow.includes('left') ? 'hide' : ''}`}>
          <AiOutlineLeft onClick={() => switchData(prevDate)} />
        </span>
        <span>
          {loading || dataLoading ? (
            <SpinLoading style={{ '--size': '24px' }} />
          ) : (
            showDate
          )}
        </span>
        <span className={`arrow ${hideArrow.includes('right') ? 'hide' : ''}`}>
          <AiOutlineRight onClick={() => switchData(nextDate)} />
        </span>
      </div>
    </Container>
  )
}
