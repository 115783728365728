import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import HealthCard from '../components/HealthCard'
import Information from '../components/Information'
import HideWrap from '../components/HideWrap'

import { ChartContainer, SectionStyle } from '../index.styled'
import BodyMale from '../../../asserts/images/physical-assessment/body-male.svg'
import BodyFemale from '../../../asserts/images/physical-assessment/body-female.svg'
import { RangeBar } from '../components/RangeBar'
import { RangeColors } from '../../../utils'
import Logarithmic from '../components/Logarithmic'
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai'

import { useInView } from 'react-intersection-observer'
import { Divider } from 'antd-mobile'
import { Growth } from '../components/Widgets'
import BodyChart from '../components/BodyChart'

const Container = styled.section`
  ${SectionStyle}
`

const BodyContainer = styled.div`
  padding: 0 80px;
  .total-score {
    display: flex;
    justify-content: space-between;
    width: 500px;
    margin: 0 auto;
    color: #4c6072;
    font-size: 32px;
    line-height: 150%;
    font-weight: 600;
    div {
      text-align: center;
    }
    span {
      color: #4c6072;
      font-size: 18px;
    }
  }

  .image-around {
    display: flex;
    justify-content: space-between;
    img {
      height: 500px;
    }
  }
  .label {
    width: 70px;
    line-height: 1.2;
    font-size: 0.86rem;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #4c6072;
    opacity: 0.6;
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  .level {
    font-size: 1.14rem;
    font-weight: 700;
    color: #4c6072;
    cursor: pointer;
  }
`

export function BodyCompSection(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status },
    data,
    visible
  } = props

  const ui_version = true

  const bodyCompData =
    dataList.find((item) => item.type === 'body_composition') || {}
  const bodyCompCompare =
    compareData.find((item) => item.type === 'body_composition') || {}

  const bodyComp = notes.find((item) => item.pillar === 'body comp')

  const { ref } = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })

  return (
    <HideWrap
      name="Body Composition"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
      ref={ref}
    >
      <Container>
        <h1 className="ar-section-title">Body Composition</h1>
        <HideWrap
          name="Body Composition - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <h3 className="ar-section-card-title">Summary</h3>
          <HealthCard
            {...bodyCompData}
            compare={bodyCompCompare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          {bodyComp && bodyComp.memo && (
            <Information
              title="COMMENT/NOTE"
              disMarkDone={true}
              initialValue={bodyComp ? bodyComp.memo : ''}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              updateCommend={(value, status) =>
                handleCommendSave(value, 'body comp', status)
              }
              updateEditingCount={updateEditingCount}
            />
          )}
        </HideWrap>
        {data && (
          <>
            <HideWrap
              name="Body Composition - Result"
              title="Body Composition"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <h3 className="ar-section-card-title">Body Composition</h3>
              <BodyCompResult
                {...{
                  ui_version,
                  bodyCompResults: data,
                  compareData,
                  dataList,
                  person,
                  ageScopes,
                  notes,
                  handleCommendSave,
                  updateEditingCount
                }}
              />
            </HideWrap>{' '}
          </>
        )}
      </Container>
    </HideWrap>
  )
}

const BodyCompValueContainer = styled.div`
  .value {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .level {
      font-size: 16px;
    }
  }
  .ranges {
    margin-top: 27px !important;
    .range-item .value {
      margin-top: -6px;
      span {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .body-item-list {
    margin-top: 40px;
    .body-item-container {
      padding: 12px 0;
      border-bottom: 1px solid #e6e9f2;
      &:last-child {
        border-bottom: none;
      }
      .body-item-name {
        font-size: 15px;
        line-height: 21px;
        color: #4c6072;
      }
      .body-item-content {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        align-items: center;
        margin-top: 4px;
        .body-score-value {
          font-size: 24px;
          line-height: 32.4px;
          color: black;
        }
        .body-score-level {
          font-size: 16px;
          line-height: 21.6px;
        }
        .optimal {
          color: #40c47c;
        }
      }
    }
  }
`

function BodyCompResult(props) {
  const {
    ui_version,
    bodyCompResults: {
      percentFatSeries,
      percentFatHistory,
      percent_fat,
      fat_mass_lbs,
      vat,
      lean_mass_lbs,
      height_inches,
      weight_lbs,
      almi,
      AppendicularLMI,
      target_body_weight,
      target_fat_mass,
      target_lean_mass,
      total_body_fat_am
    },
    dataList,
    compareData,
    person,
    ageScopes
  } = props

  const bodyCompScore = {
    latest: dataList.find((data) => data.type === 'body_composition'),
    previous: compareData.find((data) => data.type === 'body_composition')
  }

  const showScoreList = [
    {
      name: 'Healthspan Body Comp Score',
      previous:
        (bodyCompScore.previous.score_manual &&
          bodyCompScore.previous.score_manual.value) ||
        bodyCompScore.previous.score_auto,
      score:
        (bodyCompScore.latest.score_manual &&
          bodyCompScore.latest.score_manual.value) ||
        bodyCompScore.latest.score_auto,
      status: ['red', 'green']
    },
    {
      name: 'Percent Fat',
      status: ['green', 'red'],
      metricUnit: _.get(percent_fat, 'unit'),
      previous: _.get(percent_fat, 'previous'),
      unitKey: 'metricUnit',
      showScore: _.get(percent_fat, 'value'),
      scoreKey: 'showScore',
      score: _.get(total_body_fat_am, 'value'),
      unit: _.get(total_body_fat_am, 'unit'),
      ranges: _.get(total_body_fat_am, 'ranges'),
      level: _.get(total_body_fat_am, 'level'),
      color: _.get(total_body_fat_am, 'color'),
      rangeValueNumberPrefix: 'Age Rank: '
    },
    {
      name: 'Visceral Adipose Tissue',
      ...vat,
      score: vat.value,
      status: ['green', 'red'],
      showRangeArea: true
    },
    {
      name: 'Appendicular Lean Mass',
      previous: _.get(AppendicularLMI, 'previous'),
      metricUnit: _.get(AppendicularLMI, 'unit'),
      showScore: _.get(AppendicularLMI, 'value'),
      scoreKey: 'showScore',
      unitKey: 'metricUnit',
      score: _.get(almi, 'value'),
      unit: _.get(almi, 'unit'),
      status: ['red', 'green'],
      ranges: _.get(almi, 'ranges'),
      level: _.get(almi, 'level'),
      color: _.get(almi, 'color'),
      rangeValueNumberPrefix: 'Age Rank: '
    }
  ]

  const showBodyItems = [
    {
      name: 'Height',
      value: height_inches,
      unit: '"'
    },
    {
      name: 'Weight',
      unit: 'lbs',
      desired: target_body_weight,
      up_color: 'neutral',
      extraStyle: {
        color: '#6A7EA9',
        background: '#F4F6F8'
      },
      ...weight_lbs
    },
    {
      name: 'Fat Mass',
      desired: target_fat_mass,
      up_color: 'red',
      ...fat_mass_lbs
    },
    {
      name: 'Lean Mass',
      desired: target_lean_mass,
      up_color: 'green',
      ...lean_mass_lbs
    }
  ]

  const getStatus = (item) => {
    let result = {
      color: '',
      status: ''
    }

    if (item.previous && item.score) {
      if (item.previous < item.score) {
        result.color = item.status[1]
        result.status = 'up'
      } else if (item.previous > item.score) {
        result.color = item.status[0]
        result.status = 'down'
      }
    }
    return result
  }

  return (
    <>
      <ChartContainer style={{ paddingBottom: 35 }}>
        <div style={{ marginTop: -20 }}>
          <div className="score-list">
            {showScoreList.map((item, index) => {
              const { color, status } = getStatus(item)
              const scoreKey = item.scoreKey || 'score'
              const unitKey = item.unitKey || 'unit'
              return (
                <div className="score-item" key={index}>
                  <div className="h-item">
                    <div>{item.name}</div>
                    {item.previous && <div>Previous – {item.previous}</div>}
                  </div>

                  <BodyCompValueContainer>
                    <div className={`value ${item.ranges ? '' : color}`}>
                      <div>
                        {_.isNumber(item[scoreKey]) ? (
                          <>
                            {item[scoreKey]}
                            {item[unitKey]}
                            {!item.ranges &&
                              status &&
                              (status === 'up' ? (
                                <AiOutlineCaretUp />
                              ) : (
                                <AiOutlineCaretDown />
                              ))}
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                      <div
                        className="level"
                        style={{ color: item.color && RangeColors[item.color] }}
                      >
                        {item.ranges && item.level}
                      </div>
                    </div>
                    {item.ranges && (
                      <RangeBar
                        {...item}
                        {...{ person, ageScopes }}
                        hideName
                        value={item.score}
                        hideValueNumber={
                          item.rangeValueNumberPrefix ? false : true
                        }
                        rangeValueNumberPrefix={item.rangeValueNumberPrefix}
                      />
                    )}
                  </BodyCompValueContainer>
                </div>
              )
            })}
          </div>
        </div>
        <div
          className="chart small"
          style={{
            marginTop: 24
          }}
        >
          <Logarithmic
            {...{ percentFatSeries, percentFatHistory, person, ui_version }}
          />
        </div>
      </ChartContainer>
      <Divider />
      <ChartContainer>
        <div>
          <div className="score-list">
            {showBodyItems.map((item, index) => (
              <div
                key={index}
                className={`score-item ${ui_version ? 'v2' : ''}`}
              >
                <div className="body-item">
                  <div>
                    <div className="h-item">
                      {item.name}{' '}
                      {item.rate !== 0 && (
                        <span style={{ marginLeft: 10 }}>
                          <Growth
                            ratio={item.rate}
                            up_color={item.up_color}
                            extraStyle={item.extraStyle || {}}
                          />
                        </span>
                      )}
                    </div>
                    {!item.series && (
                      <div className="value">
                        {_.isNumber(item.value) ? (
                          <>
                            {item.value}
                            {item.unit}
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                    )}
                  </div>
                  <div className="r-item">
                    {item.previous && (
                      <span>
                        Previous – {item.previous} <br />
                      </span>
                    )}
                    {item.ageRank && (
                      <span>
                        Age-Ranking - {item.ageRank} <br />
                      </span>
                    )}
                    {item.desired && <span>Desired - {item.desired}</span>}
                  </div>
                </div>
                {item.series && (
                  <div style={{ margin: '-30px 0' }}>
                    <BodyChart series={item.series} name={item.name} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div>
          <BodyStatus {...props} />
        </div>
      </ChartContainer>
    </>
  )
}

function BodyStatus(props) {
  const {
    ageScopes,
    person,
    bodyCompResults: {
      arms_difference,
      legs_difference,
      left_arm_fat,
      trunk_fat,
      left_leg_fat,
      vat,
      right_leg_fat,
      right_arm_fat
    }
  } = props
  const ImageUrl = person.profile.gender === 'male' ? BodyMale : BodyFemale
  return (
    <>
      <BodyContainer>
        <div className="image-around" style={{ position: 'relative' }}>
          {/* half right body */}
          <div
            className="text-right"
            style={{ position: 'absolute', top: 5, left: -100 }}
          >
            <div
              className="label"
              style={{ position: 'relative', left: 48, top: 10 }}
            >
              LBM of Right Arm
            </div>

            <div
              style={{
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: 48,
                top: 10
              }}
              className="level"
            >
              {_.isNumber(right_arm_fat) ? `${right_arm_fat}%` : '--'}
            </div>

            <div className="label" style={{ marginTop: 52 }}>
              LBM Of TRUNK
            </div>
            <div
              style={{ marginLeft: -10, border: 'none', cursor: 'default' }}
              className="level"
            >
              {_.isNumber(trunk_fat) ? `${trunk_fat}%` : '--'}
            </div>

            <div
              className="label"
              style={{
                marginLeft: -50,
                marginTop: 55,
                position: 'relative',
                left: 100
              }}
            >
              LBM Of RIGHT LEG
            </div>
            <div
              style={{
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: 50
              }}
              className="level"
            >
              {_.isNumber(right_leg_fat) ? `${right_leg_fat}%` : '--'}
            </div>
          </div>

          <img
            src={ImageUrl}
            alt=""
            style={{ margin: '0 auto', height: 267 }}
          />

          {/* half left body */}
          <div style={{ position: 'absolute', right: -80, top: 5 }}>
            <div
              className="label"
              style={{
                marginRight: -30,
                position: 'relative',
                left: -28,
                top: 10
              }}
            >
              LBM of Left Arm
            </div>
            <div
              style={{
                marginRight: -30,
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: -29,
                top: 10
              }}
              className="level"
            >
              {_.isNumber(left_arm_fat) ? `${left_arm_fat}%` : '--'}
            </div>

            <div className="label" style={{ marginRight: 0, marginTop: 65 }}>
              Vat LEVEL
            </div>
            <div
              style={{ marginRight: -15, border: 'none', cursor: 'default' }}
              className="level"
            >
              {vat ? (
                <>
                  {vat.value} cm²
                  {/* cm<sup>2</sup> */}
                </>
              ) : (
                '--'
              )}
            </div>

            <div
              className="label"
              style={{
                marginRight: -50,
                marginTop: 55,
                position: 'relative',
                left: -47
              }}
            >
              LMB OF LEFT LEG
            </div>
            <div
              style={{
                marginRight: -50,
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: -45
              }}
              className="level"
            >
              {_.isNumber(left_leg_fat) ? `${left_leg_fat}%` : '--'}
            </div>
          </div>
        </div>
      </BodyContainer>
      <div
        style={{
          marginTop: 60
        }}
      >
        <RangeBar
          {...arms_difference}
          person={person}
          name="Arm Symmetry"
          ageScopes={ageScopes}
          showLevelOnNameRight
        />
        <RangeBar
          {...legs_difference}
          person={person}
          name="Leg Symmetry"
          ageScopes={ageScopes}
          showLevelOnNameRight
        />
      </div>
    </>
  )
}
