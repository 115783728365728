import React from 'react'
import { Divider, Button, Space } from 'antd-mobile'
import styled from 'styled-components'
import { AiOutlineCheck } from 'react-icons/ai'
import IconCheck from '../../../asserts/icons/onboarding/check.svg'

const Card = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? '#E2F2FF' : 'white')};
  border: ${(props) => (props.selected ? '1px solid #448EF7' : 'unset')};
  .plan-main-content {
    font-family: Heebo;
    font-weight: 400;
    .plan-tips {
      color: #6d7d8c;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .plan-item {
      display: flex;
      align-items: flex-start;
      color: #52606c;
      img {
        margin-right: 16px;
      }
      ul {
        margin-top: 0;
        padding-inline-start: 20px;
        margin-bottom: 0;
      }
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #313251;
  font-weight: 600;
  font-family: Gilroy;
  .plan-name {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.84;
    letter-spacing: 1px;
    background: ${(props) => {
      switch (props.plan) {
        case 'gold':
          return 'linear-gradient(264deg, #f9d423 -70.74%, #f89500 90.01%)'
        case 'sliver':
          return 'linear-gradient(226deg, #bdbbbe 3.21%, #9d9ea3 113.49%)'
        case 'bronze':
          return 'linear-gradient(299deg, #dfa579 0%, #a2604e 70.04%)'
        default:
          return 'linear-gradient(88deg, #89a8ba 4.45%, #9eb7c6 113.97%)'
      }
    }};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  button {
    width: ${(props) => (props.selected ? '160px' : '120px')};
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.8px;
    color: ${(props) => (props.selected ? 'white' : '#BEC7D9')};
    background-color: ${(props) => (props.selected ? '#2b4b8f' : 'white')};
    border: ${(props) => (props.selected ? 'none' : '1px solid #BEC7D9')};
  }
`

export default function PlanCard({ selected, plan, onClick, price }) {
  return (
    <Card selected={selected} onClick={onClick}>
      <Header selected={selected} plan={plan.membership}>
        <div>
          <span className="plan-name">{plan.membership}</span>
          <div className="plan-price">{price}</div>
        </div>
        <Button color="primary">
          <Space>
            {selected && (
              <AiOutlineCheck style={{ color: '#05B800', fontSize: 12 }} />
            )}
            {selected ? 'SELECTED' : 'SELECT'}
          </Space>
        </Button>
      </Header>
      {selected ? (
        <Divider style={{ borderColor: 'rgb(68, 142, 247, 0.2)' }} />
      ) : (
        <Divider />
      )}

      <div className="plan-main-content">
        <div className="plan-tips">
          Includes Healthspan Assessment and adds:
        </div>
        <Space style={{ '--gap': '12px', marginTop: 16 }} direction="vertical">
          {plan.items.map((item, index) => {
            return (
              <div className="plan-item" key={index}>
                <img src={IconCheck} alt="" />
                {item}
              </div>
            )
          })}
          {plan.noExtra ? (
            <></>
          ) : (
            <div>*can add on service session packages if desired</div>
          )}
        </Space>
      </div>
    </Card>
  )
}
