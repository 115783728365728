import styled from 'styled-components'

const Container = styled.div`
.adm-list-body{
    background-color: rgba(0,0,0,0);
    .adm-list-item{
        margin-top: 22px;
        border-radius: 4px;
        border: 2px solid #E6E9F2;
        background: #FFF;
        padding: 16px;
        .adm-list-item-content{
            border: none;
            .adm-list-item-content-main{
            position: relative;
            padding: 0;
            .adm-list-item-title{
                position: absolute;
                top:-29px;
                color: #8D99A5;
                font-size: 12px;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.3px;
                text-transform: uppercase;
                .adm-form-item-label{
                    color: #8D99A5;
                    font-weight: 500;
                    line-height: 150%;
                    letter-spacing: 0.3px;
                    text-transform: uppercase;
                }
            }
        }   
        }
    }
}

`

export default Container
