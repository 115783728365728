import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import checkIcon from '../../../asserts/icons/ic-checked.png'
import warningIcon from '../../../asserts/icons/ic-warning.png'
import { SectionStyle } from '../index.styled'
import { autoSource } from '../../../utils'
import { useInView } from 'react-intersection-observer'

const Container = styled.section`
  ${SectionStyle}
  .tags {
    display: flex;
    justify-content: flex-end;
    margin-top: -47px;
    span {
      margin-left: 16px;
      display: block;
      padding: 2px 8px;
      background: rgba(217, 220, 227, 0.2);
      border: 1px solid rgba(33, 57, 89, 0.2);
      border-radius: 19px;
      color: #5a657c;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 12px;
      svg {
        margin-right: 6px;
        vertical-align: middle;
        margin-top: -2px;
      }
    }
    &.User {
      margin-top: -67px;
    }
  }
  .device-and-appstore-link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
    .device-status-container {
      color: rgba(76, 96, 114, 1);
      font-weight: 600;
      font-size: 1.07rem;
      margin-top: 31px;
      row-gap: 24px;
      .devices-container {
        display: flex;
        align-items: center;
        margin-top: 17px;
        flex-wrap: wrap;
        row-gap: 24px;
        .item {
          margin-right: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .item-images {
            position: relative;
            cursor: pointer;
            .status {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 12px;
            }
          }
          .sync-info-title {
            font-size: 0.642rem;
            color: rgba(96, 101, 109, 1);
            margin-top: 8px;
          }
          .sync-info {
            margin-top: 4px;
            color: #60656d;
            &.error {
              color: #e75f25;
            }
          }
        }
      }
    }
    & > img {
      padding-top: 20px;
      cursor: pointer;
      height: 60px;
    }
  }
`

export function PersonalInfoSection(props) {
  const {
    person,
    latest_height,
    latest_weight,
    residence,
    id,
    device_info,
    visible
  } = props

  const { ref } = useInView({
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) {
        visible()
      }
    }
  })
  return (
    <Container id={id} ref={ref}>
      <h1 className="ar-section-title">Personal Information</h1>
      <Detail
        person={person}
        {...{ latest_height, latest_weight, residence }}
      />
      <div className="device-and-appstore-link-container">
        <div className="device-status-container">
          Connected Wearables
          <div className="devices-container">
            {autoSource.map((item, index) => {
              const device = _.get(device_info, item.value, {})
              return (
                <div key={index} className="item">
                  <div className="item-images">
                    <img src={item.leftExtra} alt="" style={{ width: 48 }} />
                    {device.connection_status === 'Connected' && (
                      <img className="status" src={checkIcon} alt="check" />
                    )}
                    {device.connection_status === 'Disconnect' && (
                      <img className="status" src={warningIcon} alt="warning" />
                    )}
                  </div>
                  <div className="sync-info-title">LAST SYNCED:</div>
                  <span
                    className={`sync-info ${
                      device.connection_status === 'Disconnect' ? 'error' : ''
                    }`}
                  >
                    {device.last_sync
                      ? moment(
                          device.last_sync.toString().substr(0, 15),
                          'ddd MMM D YYYY'
                        ).format('MM/DD/YY')
                      : '--'}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Container>
  )
}

const DetailContainer = styled.div`
  background-color: rgba(250, 250, 250, 1);
  .user {
    height: fit-content;
    padding: 32px 24px;
    border: 1px solid #e3e6ea;
    border-radius: 4px;
    .profile-item {
      width: 50%;
      display: inline-block;
      letter-spacing: 0.3px;
      font-size: 0.92rem;
      margin-bottom: 30px;
      color: rgba(76, 96, 114, 1);
      .label {
        margin-bottom: 0px;
      }
      .value {
        font-size: 1.07rem;
        font-weight: 600;
      }
    }
    .profile-item:last-child {
      width: 100%;
      margin-bottom: 0;
    }
  }
`

function Detail(props) {
  const { person, latest_height, latest_weight, residence } = props
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const height = latest_height || person.profile.height_inch
  const weight = latest_weight || person.profile.weight_lbs

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: false
  })

  const profileItems = [
    {
      label: 'Gender',
      value: _.upperFirst(person.profile.gender)
    },
    {
      label: 'Age',
      value: age || '--'
    },
    {
      label: 'Height',
      value: height ? `${height} inches` : '--'
    },
    {
      label: 'Weight',
      value: weight ? `${formatter.format(weight)} lbs` : '--'
    },
    {
      label: 'Residence',
      value: residence || '--'
    }
  ]

  return (
    <DetailContainer>
      <div className="user">
        {profileItems.map((item, index) => (
          <div className="profile-item" key={index}>
            <div className="label">{item.label}</div>
            <div className="value">{item.value}</div>
          </div>
        ))}
      </div>
    </DetailContainer>
  )
}
