import styled from 'styled-components'

const Container = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 24px;
    border-top: 1px solid  #E6E9F2;
    background: #FFF;
    text-align: left !important;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    .opr{
        .logout{
            margin-left: 26px;
            font-size: 20px;
        }
    }
`

export default Container
