import React, { useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'
import CardiometabolicTrendsMetric from './CardiometabolicTrendsMetric'
// import PageLoading from '../../UI/status/loading'
// import { getStatistics } from '../../../api/trends'
import _ from 'lodash'
import { useImmer, useImmerReducer } from 'use-immer'

export const TrendsDateRanges = [
  {
    name: '7 days',
    barWidth: 20,
    // picker: 'week',
    formatX: 'ddd',
    picker: 'blank',
    formatTooltip: 'ddd D',
    type: 'week'
  },
  {
    name: '14 days',
    barWidth: 12,
    formatX: 'M/DD',
    // picker: 'week',
    picker: 'blank',
    type: 'bi_weekly'
  },
  {
    name: '30 days',
    barWidth: 8,
    // picker: 'month',
    type: 'custom',
    picker: 'blank',
    formatX: 'MMM/D'
  },
  {
    name: '90 days',
    barWidth: 12,
    // picker: 'quarter',
    picker: 'blank',
    // type: 'quarterly',
    type: 'custom',
    formatX: 'M/DD'
  },
  {
    name: '365 days',
    barWidth: 20,
    type: 'annual',
    // type: 'blank',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Lifetime',
    barWidth: 8,
    picker: 'date',
    type: 'lifetime'
    // formatX: 'M/D/YY'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
    // formatX: ''
  }
]

const colorArr = {
  BloodPanel: {
    primary: '#CF072A',
    barBg: ['#CF4658', '#CF072A']
    // lighter: {}
  }
}

const Container = styled.div`
  margin-top: 30px;
  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 99999;
  }
  .tips {
    list-style: none;
    text-align: right;
    right: 40px;
    position: absolute;
    margin-top: -50px;
    li {
      padding: 0 5px;
      display: inline-block;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: gray;
        display: inline-block;
        margin-right: 6px;
        vertical-align: baseline;
      }
      &.blood:before {
        background-color: ${colorArr.BloodPanel.primary};
      }
    }
  }

  .main-content {
    padding: 0 0 20px 0;
    background: #ffffff;
    border-radius: 12px;

    .tool-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date-tips {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        li {
          display: inline-block;
          padding: 10px 8px;
          border: 1px solid #e4e7eb;
          color: #7b8794;
          cursor: pointer;
          &:not(:last-child) {
            border-right: none;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          &.active {
            color: #3e4c59;
            background: #cbd2d9;
          }
          &:first-child {
            border-radius: 6px 0px 0px 6px;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
      .ant-calendar-picker {
        width: 227px;
        input {
          color: #616e7c;
        }
      }
      .divider {
        border-left: 1px solid #e3e7eb;
        height: 40px;
      }
      .show-number-container {
        // border-left: 1px solid #e3e7eb;
        // padding-left: 22px;
        line-height: 40px;
      }
      .chart-type {
        background: #ebecf2;
        border-radius: 4px;
        color: #9aa5b1;
        font-size: 14px;
        padding: 2px;
        span {
          display: inline-block;
          padding: 8px;
          cursor: pointer;
          &.active {
            color: #3e4c59;
            background: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
    .chart-container {
      position: relative;
      /* width: 75%; */
    }
    .axis-bar {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      button {
        background: #dceefb;
        border-radius: 6px;
        color: #0f609b;
      }
      button[disabled] {
        border-color: #d9d9d9;
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }
      // .chart-container {
      //   padding: 0 40px 0 10px;
      // }
    }
  }
`

const CardiometabolicTrends = (props) => {
  const { metrics, view } = props

  const [state, dispatch] = useImmerReducer(
    (draft, action) => {
      _.assign(draft, action)
    },
    {
      from: '',
      to: '',
      selectedDate: 0,
      showNumber: true,
      showGraphType: 'line'
    }
  )
  const [mappedMetrics, setMappedMetrics] = useImmer([])

  useEffect(() => {
    if (props.storedData) {
      dispatch({
        from: props.storedData.from,
        to: props.storedData.to,
        showNumber: props.storedData.show_number,
        showGraphType: props.storedData.show_graph_type,
        selectedDate: TrendsDateRanges.findIndex(
          (range) => range.type === props.storedData.rangeType
        )
      })
      setMappedMetrics(metrics)
    }
  }, [])

  const dateRange = TrendsDateRanges[state.selectedDate || 0]

  const renderMetricContainer = () => {
    return mappedMetrics.map((item, index) => (
      <CardiometabolicTrendsMetric
        key={index}
        isFirst={index === 0}
        {...{
          view: view,
          showIndex: index,
          showNumber: state.showNumber,
          showGraphType: state.showGraphType,
          dateRange,
          colorArr,
          metric: item
        }}
      />
    ))
  }

  return (
    <Container>
      <div className="main-content">{renderMetricContainer()}</div>
    </Container>
  )

  function processMetrics(metrics, statistics) {
    return metrics.map((item) => {
      let { metric_key, add_metrics } = item
      const { values, detail, aggregate_mode } =
        _.find(statistics, {
          metric_key
        }) || {}
      return _.assign(item, {
        values,
        detail,
        aggregate_mode,
        add_metrics: add_metrics || [],
        categories: ['Blood Metrics']
      })
    })
  }
}

export default CardiometabolicTrends
