import { ActionSheet } from 'antd-mobile'
import styled from 'styled-components'
import { CheckedCircleIcon } from '../../commonComponents/icons'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import classNames from 'classnames'

const ModalContainer = styled.div`
  width: 100%;
  .top {
    text-align: end;
  }
  .list {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .number {
        width: 30px;
        height: 30px;
        border: 2px solid #98a9bc;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #264382;
        font-size: 12px;
        font-weight: 800;
      }
      .name {
        color: #52606c;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        color: #52606c;
        margin-left: 14px;
        margin-right: 14px;
      }
      .time {
        padding: 4px 8px;
        color: #52606c;
        font-size: 12px;
        font-weight: 600;
        line-height: 116%;
        letter-spacing: 0.3px;
        border-radius: 22px;
        background: #e6e9f2;
      }
    }
    .active {
      .number {
        background-color: #264382;
        color: #fff;
      }
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #e6e9f2;
      border-bottom: 1px solid #e6e9f2;
    }
    .active:last-child {
      border-bottom: none;
    }
    .done {
      svg {
        color: #05b800;
        height: 34px;
        width: 34px;
      }
      .name {
        color: #a5abb2;
      }
    }

    .sub {
      display: flex;
      margin-top: 12px;
      .number {
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #bcc7d3;
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        margin-right: 16px;
      }
      .name {
        color: #38435e;
        color: #bcc7d3;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.3px;
      }
    }
    .fisrt {
      margin-top: 24px;
    }
    .current {
      .number {
        background: #98a9bc;
      }
      .name {
        color: #38435e;
      }
    }
  }
`

const ActionProgress = (props) => {
  const { modalShow, setModalShow, steps, stepInfo, subActive } = props
  return (
    <ActionSheet
      visible={modalShow}
      onClose={() => setModalShow(false)}
      extra={
        <ModalContainer>
          <div className="top">
            <AiOutlineCloseCircle
              size={30}
              onClick={() => setModalShow(false)}
            />
          </div>
          <div className="list">
            {steps
              .filter((e) => e.showIndex)
              .map((e, index) => (
                <div
                  key={index}
                  className={classNames(
                    'item',
                    { active: index + 1 === stepInfo?.showIndex },
                    { done: e.isDone }
                  )}
                >
                  <div className="top">
                    {e.isDone ? (
                      <CheckedCircleIcon />
                    ) : (
                      <div className="number">{e.showIndex}</div>
                    )}
                    <div className="name">{e.tag}</div>
                    <div className="time">{e.min}</div>
                  </div>
                  {index + 1 === stepInfo?.showIndex &&
                    e.subSteps &&
                    e.subSteps.map((p, pIndex) => (
                      <div
                        key={pIndex}
                        className={classNames(
                          'sub',
                          { fisrt: pIndex === 0 },
                          { current: subActive === pIndex + 1 }
                        )}
                      >
                        <div className="number">{p.showIndex}</div>
                        <div className="name">{p.name}</div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </ModalContainer>
      }
    />
  )
}

export default ActionProgress
